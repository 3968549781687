import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Badge, Box, Button, Drawer, IconButton, Stack, styled, Tooltip, Typography } from '@mui/material'

import React, { useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import { useRecoilState } from 'recoil'
import emoji from 'remark-emoji'
import { Pluggable } from 'unified'
import { useMessaging } from '../../hooks/useMessaging/useMessaging'
import { sessionState } from '../../state'
import { ZappMessage, ZappMessageUserActionRecord } from '../../types/ZappMessage'
import { ActionRequired } from '../ActionRequired/ActionRequired'
import { SendMessageAction } from './SendMessageAction'

const drawerWidth = 500

const EmojiBox = styled(Box)({
  display: 'flex',
  backgroundColor: '#2B2D3D',
  padding: '3px 5px',
  borderRadius: '7px',
  cursor: 'pointer',
})

export const Alerts: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [session] = useRecoilState(sessionState)
  const isAdmin = !!session.user?.admin
  const isSupervisor = session.user?.supervisor
  const { requiresAction, newMessages, messages, receivedMessage, actionMessage, loading } = useMessaging()

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setIsOpen(open)
  }

  useEffect(() => {
    if (isAdmin && !loading) {
      messages
        .filter(
          (message) =>
            !message.receivedBy.some((action) => action.user === session.user?.email) &&
            message.user !== session.user?.email
        )
        .forEach(receivedMessage)
    }
  }, [isOpen, session.user?.email, messages, receivedMessage, isAdmin, loading])

  const parseOrderNo = (orderNo: string) => {
    return (
      <a
        href={`/v2/orders?orderId=${window.btoa(`OrderNo:${orderNo}`)}`}
        style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
      >
        <Typography variant="overline" color="primary" sx={{ fontSize: '14px', fontWeight: 600 }}>
          #{orderNo}
        </Typography>
        <OpenInNewIcon sx={{ color: 'primary.main', fontSize: '18px', ml: 0.5 }} />
      </a>
    )
  }

  const renderUserList = (actionRecords: ZappMessageUserActionRecord[], icon: string) => {
    return (
      <Box
        sx={{
          color: '#000',
          padding: 0.5,
        }}
      >
        {!actionRecords.length && <Typography>None 🥺</Typography>}
        {actionRecords.map((record) => (
          <Typography key={record.user}>
            {icon} {record.user.split('@')[0]} on <small>{record.timestamp.toDate().toLocaleString()}</small>
          </Typography>
        ))}
      </Box>
    )
  }

  const renderMessage = (zappMessage: ZappMessage) => {
    const isReceived = zappMessage.receivedBy.some((action) => action.user === session.user?.email)
    const isActioned = zappMessage.actionedBy.some((action) => action.user === session.user?.email)

    return (
      <Box bgcolor="#404258" m={1} p={1} borderRadius="6px" key={zappMessage.id}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4">
            {zappMessage.user.split('@')[0].charAt(0).toUpperCase() + zappMessage.user.split('@')[0].slice(1)}
          </Typography>
          <Typography variant="overline">{zappMessage.createdAt.toDate().toLocaleString()}</Typography>
        </Box>

        {!!zappMessage.orderNo && (
          <Box display="flex" alignItems="center">
            <Typography variant="h4" sx={{ mr: 1 }}>
              Order Number:
            </Typography>
            {parseOrderNo(zappMessage.orderNo)}
          </Box>
        )}

        <Markdown remarkPlugins={[emoji as Pluggable]} children={zappMessage.message} />

        <Box display="flex" justifyContent="space-between" mt={3.5}>
          <Stack spacing={1} flexDirection="row" height="fit-content" useFlexGap>
            <Tooltip
              title={renderUserList(zappMessage.receivedBy, '👀')}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: '#E5F8FF',
                  },
                },
              }}
            >
              <EmojiBox>
                <span>👀</span>
                <Typography sx={{ ml: 1 }}>{zappMessage.receivedBy.length}</Typography>
              </EmojiBox>
            </Tooltip>
            {zappMessage.isAction && (
              <Tooltip
                title={renderUserList(zappMessage.actionedBy, '✅')}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#E5F8FF',
                    },
                  },
                }}
              >
                <EmojiBox>
                  <span>✅</span>
                  <Typography sx={{ ml: 1 }}>{zappMessage.actionedBy.length}</Typography>
                </EmojiBox>
              </Tooltip>
            )}
          </Stack>
          {!isAdmin && !isSupervisor && (
            <Box>
              <Button
                variant="outlined"
                sx={{ mr: 1 }}
                onClick={(e) => {
                  e.stopPropagation()
                  return receivedMessage(zappMessage)
                }}
                disabled={isReceived}
              >
                Received
                {isReceived && ' 👀'}
              </Button>
              {zappMessage.isAction && (
                <Button
                  variant="outlined"
                  onClick={(e) => {
                    e.stopPropagation()
                    return actionMessage(zappMessage)
                  }}
                  disabled={!isReceived || isActioned}
                >
                  Actioned
                  {isActioned && ' ✅'}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    )
  }

  return (
    <>
      {requiresAction && <ActionRequired onClick={() => setIsOpen(true)} />}
      <IconButton onClick={toggleDrawer(true)}>
        {newMessages > 0 ? (
          <Badge badgeContent={newMessages} color="error">
            <NotificationsActiveIcon fontSize="large" sx={{ fill: 'white' }} />
          </Badge>
        ) : (
          <NotificationsNoneIcon fontSize="large" sx={{ fill: 'white' }} />
        )}
      </IconButton>

      <Drawer
        sx={{
          width: drawerWidth,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
      >
        <Box display="flex" flexDirection="column" p={2}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h1">Alerts</Typography>
            {(isAdmin || isSupervisor) && <SendMessageAction />}
          </Box>
          <Box role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            {messages?.map(renderMessage)}
          </Box>
        </Box>
      </Drawer>
    </>
  )
}
