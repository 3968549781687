import { Paper, Table, TableContainer } from '@mui/material'
import { WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import orderBy from 'lodash/orderBy'
import { FC, useCallback, useMemo } from 'react'
import useLocalStorage from 'react-use-localstorage'
import { useRecoilState } from 'recoil'
import { TableBody } from '../../../components/Table'
import { SortDirection, SortSettings, TableColumn, TableHead } from '../../../components/TableHead'
import { sessionState } from '../../../state'
import { WarehouseTableStats } from '../../../types/WarehouseTableStats'
import { sumMetricByOrigin } from '../../../utils/warehouseStatsV2/sumMetricByOrigin'
import { WarehouseStatsTableRowV2 } from './WarehouseStatsTableRowV2'

const COLUMNS: TableColumn[] = [
  {
    id: 'code',
    label: 'Code',
    sortByProp: 'warehouse.shortName',
  },
  {
    id: 'revenue',
    label: 'Revenue',
    sortByProp: 'revenueNow',
  },
  {
    id: 'fulfilled',
    label: 'Fulfilled',
    sortByProp: 'fulfilled',
  },
  {
    id: 'cancelled',
    label: 'Cancelled',
    sortByProp: 'cancelled',
  },
  {
    id: 'out-of-stock',
    label: 'Has OOS',
    sortByProp: 'oosOrders',
  },
  {
    id: 'unfulfilled',
    label: 'Unfulfilled',
    sortByProp: 'unfulfilled',
  },
  {
    id: 'unassigned',
    label: 'Unassigned',
    sortByProp: 'unassigned',
  },
  {
    id: 'waiting',
    label: 'Waiting',
    sortByProp: 'waiting',
  },
  {
    id: 'picking',
    label: 'Picking',
    sortByProp: 'picking',
  },
  {
    id: 'packing',
    label: 'Packing',
    sortByProp: 'packing',
  },
  {
    id: 'pending',
    label: 'Pending 3P',
    sortByProp: 'pending',
  },
  {
    id: 'assigned',
    label: 'Assigned',
    sortByProp: 'assigned',
  },
  {
    id: 'in-delivery',
    label: 'In Delivery',
    sortByProp: 'inDelivery',
  },
  {
    id: 'avg-time-in-store',
    label: 'Avg time in store (hourly)',
    sortByProp: 'avgTimeInStore',
  },
  {
    id: 'delivery-avg',
    label: 'Delivery Avg (hourly)',
    sortByProp: 'avgDeliveryTime',
  },
  {
    id: 'delivered-to-promise',
    label: 'DTP',
    sortByProp: 'dtp',
  },
  {
    id: 'delivered-within-20min',
    label: 'D20',
    sortByProp: 'd20',
  },
  {
    id: 'delivered-within-30min',
    label: 'D30',
    sortByProp: 'd30',
  },
  {
    id: 'delivered-within-45min',
    label: 'D45',
    sortByProp: 'd45',
  },
  {
    id: 'delivered-within-60min',
    label: 'D60',
    sortByProp: 'd60',
  },
]

interface Props {
  warehouseStats: WarehouseStatsV2 | undefined
  lastWeekSameTimeStats: WarehouseStatsV2 | undefined
  hourlyStats: WarehouseStatsV2 | undefined
}

export const WarehouseStatsTableV2: FC<Props> = ({ warehouseStats, lastWeekSameTimeStats, hourlyStats }) => {
  const [session] = useRecoilState(sessionState)
  const isAdmin = session?.user?.admin

  const [storedSortSettings, setSortSettings] = useLocalStorage('warehouseListOrder')

  const handleSortSettingsChange = useCallback(
    (settings: SortSettings) => {
      setSortSettings(JSON.stringify(settings))
    },
    [setSortSettings]
  )

  const sortSettings = useMemo(() => {
    try {
      return JSON.parse(storedSortSettings)
    } catch (error) {}

    return undefined
  }, [storedSortSettings])

  const columns = useMemo(() => {
    return COLUMNS.filter(({ id }) => id !== 'revenue' || isAdmin)
  }, [isAdmin])

  const sortedData = useMemo(() => {
    const statsByWarehouse = Object.values(warehouseStats?.statsByWarehouse ?? {})

    const transformed = statsByWarehouse.map(
      (item) =>
        ({
          warehouse: item.warehouse,
          shortName: item.warehouse.shortName,
          revenueNow: sumMetricByOrigin(item.revenue, 'total'),
          revenueLastWeek: sumMetricByOrigin(
            lastWeekSameTimeStats?.statsByWarehouse[item.warehouse.id]?.revenue ?? {},
            'total'
          ),
          fulfilled: sumMetricByOrigin(item.orders, 'fulfilledOrdersNum'),
          cancelled: sumMetricByOrigin(item.orders, 'cancelledOrdersNum'),
          oosOrders: sumMetricByOrigin(item.orders, 'ordersWithOOSItemsNum'),
          unfulfilled: sumMetricByOrigin(item.orders, 'unfulfilledOrdersNum'),
          waiting: sumMetricByOrigin(item.picking, 'waitingOrdersNum'),
          picking: sumMetricByOrigin(item.picking, 'pickingOrdersNum'),
          packing: sumMetricByOrigin(item.picking, 'packingOrdersNum'),
          pending: sumMetricByOrigin(item.delivery, 'pendingTasksNum'),
          unassigned: sumMetricByOrigin(item.delivery, 'unassignedTasksNum'),
          assigned: sumMetricByOrigin(item.delivery, 'assignedTasksNum'),
          inDelivery: sumMetricByOrigin(item.delivery, 'activeTasksNum'),
          avgTimeInStore: hourlyStats?.statsByWarehouse[item.warehouse.id].picking.ZAPP?.avgTimeInWarehouseMs ?? 0,
          avgDeliveryTime: hourlyStats?.statsByWarehouse[item.warehouse.id].delivery.ZAPP?.avgDeliveryTime ?? 0,
          dtp: item.delivery.ZAPP?.completedTasksWithinPromisePercent,
          d20: item.delivery.ZAPP?.completedTasksWithinTargets?.in20MinPercent,
          d30:
            item.delivery.ZAPP?.completedTasksWithinTargets?.in20MinPercent +
            item.delivery.ZAPP?.completedTasksWithinTargets?.in30MinPercent,
          d45:
            item.delivery.ZAPP?.completedTasksWithinTargets?.in20MinPercent +
            item.delivery.ZAPP?.completedTasksWithinTargets?.in30MinPercent +
            item.delivery.ZAPP?.completedTasksWithinTargets?.in45MinPercent,
          d60:
            item.delivery.ZAPP?.completedTasksWithinTargets?.in20MinPercent +
            item.delivery.ZAPP?.completedTasksWithinTargets?.in30MinPercent +
            item.delivery.ZAPP?.completedTasksWithinTargets?.in45MinPercent +
            item.delivery.ZAPP?.completedTasksWithinTargets?.in60MinPercent,
        } as WarehouseTableStats)
    )

    return orderBy(transformed, sortSettings?.sortByProp ?? 'fulfilled', sortSettings?.direction ?? SortDirection.DESC)
  }, [warehouseStats, hourlyStats, lastWeekSameTimeStats, sortSettings])

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead columns={columns} sortSettings={sortSettings} onSortSettingsChange={handleSortSettingsChange} />
        <TableBody>
          {sortedData.map((item) => (
            <WarehouseStatsTableRowV2 key={item.warehouse.shortName} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
