import { Stack } from '@mui/material'
import { OrderOrigin } from '@quickcommerceltd/zappboard'
import { FC, useMemo } from 'react'

interface Props {
  size?: number
  orderOrigin?: OrderOrigin
  deliveryProvider?: string
}

export const DeliveryProviderBadge: FC<Props> = (props) => {
  const { size = 25, orderOrigin, deliveryProvider } = props

  const [icon, color] = useMemo(() => {
    if (deliveryProvider) {
      switch (deliveryProvider) {
        case 'UBER':
          return ['/icons/uber-direct.svg', '#000']
        case 'STUART':
          return ['/icons/STUART.svg', '#00BEAA']
      }
    }

    if (orderOrigin) {
      switch (orderOrigin) {
        case 'UBER':
          return ['/icons/UBER.svg', '#00A45F']
        case 'DELIVEROO':
          return ['/icons/DELIVEROO.svg', '#2FDAAA']
        case 'JET':
          return ['/icons/JET.svg', '#FF8000']
      }
    }

    return [undefined, '#000']
  }, [deliveryProvider, orderOrigin])

  if (!icon) return null

  return (
    <Stack width={size} height={size} bgcolor={color} borderRadius={1} justifyContent="center">
      <img src={icon} alt="" />
    </Stack>
  )
}
