import { useMemo } from 'react'

export function useDeepCompareMemo<T extends (...args: any) => any>(
  callback: T,
  dependencies: unknown[]
): ReturnType<T> {
  function arrayToKey(dependencies: unknown[]): string {
    return dependencies
      .filter((dependency) => dependency !== undefined)
      .map((dependency) => {
        if (Array.isArray(dependency)) return arrayToKey(dependency)
        if (typeof dependency === 'object' && dependency !== null) {
          return Object.entries(dependency).map(([key, value]) => `${key}:${arrayToKey([value])}`)
        }
        return String(dependency)
      })
      .join('-')
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  const key = useMemo(() => arrayToKey(dependencies), dependencies)
  return useMemo(callback, [key])
  /* eslint-enable react-hooks/exhaustive-deps */
}
