import { TextField as MuiTextField, styled } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const useSearchbarStyles = makeStyles()(() => ({
  wrapper: {
    display: 'flex',
    gap: '12px',
    width: '100%',
    maxWidth: '800px',
    margin: '0 auto',
  },
}))

export const TextField = styled(MuiTextField)(() => ({
  width: '100%',

  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',

    // outline
    '& fieldset': {
      borderColor: '#AFB3D8',
    },
    '&.Mui-focused fieldset': {
      border: '2px solid white',
    },

    // icon in input
    '& .MuiIconButton-root': {
      color: '#DADADA',
    },

    '& .MuiOutlinedInput-adornedStart': {
      padding: '0',
    },

    '& .MuiOutlinedInput-adornedEnd': {
      padding: '0',
    },

    // input
    '& .MuiOutlinedInput-input': {
      color: 'white',
      fontSize: '14px',

      '&::placeholder': {
        color: '#AFB3D8',
        opacity: 1,
      },
    },
  },
}))
