import { Box, Divider, Stack, styled, Typography } from '@mui/material'
import React from 'react'
import { MinMaxWarehouseMetric } from '../../types/MinMaxValue'
import { shortCurrencyFmt } from '../../utils/formatCurrencyShort'
import { MinMaxTooltip } from '../MinMaxTooltip/MinMaxTooltip'
import { NumberChangeChip } from '../NumberChangeChip/NumberChangeChip'
import { WidgetPanel } from '../WidgetPanel/WidgetPanel'

interface Props {
  currentDate: string
  pastDate: string
  pastDateEoD: string
  revenueSoFar: number
  revenueSoFarPast: number
  projectedToday: number
  revenuePast: number
  loading?: boolean
  link?: string
  todayTooltip?: MinMaxWarehouseMetric | null
  wowTooltip?: MinMaxWarehouseMetric | null
  projectedTooltip?: MinMaxWarehouseMetric | null
  wowEoDTooltip?: MinMaxWarehouseMetric | null
}

const RevenueBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '4px',
  height: 'fit-content',
  marginBottom: '8px',
}))

const revenueSectionPrimary = (
  date: string,
  currentValue: number,
  compareValue: number,
  tooltip?: MinMaxWarehouseMetric | null
) => (
  <RevenueBox>
    <Stack>
      {!!tooltip && tooltip.min.warehouse !== tooltip.max.warehouse ? (
        <MinMaxTooltip minMax={tooltip}>
          <Typography sx={{ fontSize: '30px', lineHeight: '36px', fontWeight: 500 }}>
            {shortCurrencyFmt.format(currentValue)}
          </Typography>
        </MinMaxTooltip>
      ) : (
        <Typography sx={{ fontSize: '30px', lineHeight: '36px', fontWeight: 500 }}>
          {shortCurrencyFmt.format(currentValue)}
        </Typography>
      )}
      <Typography sx={{ fontSize: '12px', lineHeight: '14px', fontWeight: 500, color: 'text.secondary' }}>
        {date}
      </Typography>
    </Stack>
    <NumberChangeChip currentValue={currentValue} compareValue={compareValue} />
  </RevenueBox>
)

const revenueSectionSecondary = (
  date: string,
  currentValue: number,
  compareValue: number,
  tooltip?: MinMaxWarehouseMetric | null
) => (
  <RevenueBox>
    <Stack>
      {!!tooltip && tooltip.min.warehouse !== tooltip.max.warehouse ? (
        <MinMaxTooltip minMax={tooltip}>
          <Typography sx={{ fontSize: '24px', lineHeight: '29px', fontWeight: 500, color: 'text.secondary' }}>
            {shortCurrencyFmt.format(compareValue)}
          </Typography>
        </MinMaxTooltip>
      ) : (
        <Typography sx={{ fontSize: '24px', lineHeight: '29px', fontWeight: 500, color: 'text.secondary' }}>
          {shortCurrencyFmt.format(compareValue)}
        </Typography>
      )}
      <Typography sx={{ fontSize: '10px', lineHeight: '12px', fontWeight: 500, color: 'text.secondary' }}>
        {date}
      </Typography>
    </Stack>
    <NumberChangeChip currentValue={currentValue} compareValue={compareValue} percentage={false} />
  </RevenueBox>
)
export const RevenuePanel: React.FC<Props> = ({
  currentDate,
  pastDate,
  pastDateEoD,
  revenueSoFar,
  revenueSoFarPast,
  projectedToday,
  revenuePast,
  loading,
  link,
  todayTooltip,
  wowTooltip,
  projectedTooltip,
  wowEoDTooltip,
}) => {
  return (
    <WidgetPanel title="Revenue" loading={loading || !currentDate || !pastDate} link={link}>
      {revenueSectionPrimary(currentDate, revenueSoFar, revenueSoFarPast, todayTooltip)}
      {revenueSectionSecondary(pastDate, revenueSoFar, revenueSoFarPast, wowTooltip)}
      <Divider sx={{ my: 0.5 }} />
      <Typography sx={{ fontSize: '18px', lineHeight: '21px', fontWeight: 500 }} gutterBottom>
        Projected
      </Typography>
      {revenueSectionPrimary(currentDate, projectedToday, revenuePast, projectedTooltip)}
      {revenueSectionSecondary(pastDateEoD, projectedToday, revenuePast, wowEoDTooltip)}
    </WidgetPanel>
  )
}
