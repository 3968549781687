import { Stack, styled, Tooltip } from '@mui/material'
import { WarehouseStatus, WarehouseStoreStatusV2 } from '@quickcommerceltd/zappboard'
import { FC, useMemo } from 'react'

interface Props {
  warehouse: WarehouseStoreStatusV2
}

export const WarehouseStatusIndicator: FC<Props> = ({ warehouse }) => {
  const storefronts = useMemo(() => {
    return Object.entries({ STORE: warehouse.status, ...warehouse.storefronts })
      .sort(([providerA], [providerB]) => {
        if ([providerA, providerB].includes('STORE')) return 0
        return providerA.localeCompare(providerB)
      })
      .map(([provider, status]) => ({
        provider,
        status: status as WarehouseStatus,
      }))
  }, [warehouse])

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {/* Contingency Status */}
      {warehouse.isInContingencyMode && (
        <Tooltip title={['Contingency Mode', warehouse.isInContingencyModeFor].filter(Boolean).join(': ')}>
          <ContingencyStatus alt="Contingency Status" src="/icons/umbrella.svg" />
        </Tooltip>
      )}

      {/* Storefront statuses */}
      {storefronts.map(({ provider, status }) => (
        <Tooltip key={provider} title={`${provider} is ${status}`}>
          <StatusTile src={`/icons/${provider}.svg`} status={status} />
        </Tooltip>
      ))}
    </Stack>
  )
}

const ContingencyStatus = styled('img')({
  width: 16,
  height: 16,
  filter: 'invert(1)',
})

const StatusTile = styled('img')<{ status: WarehouseStatus }>(({ status }) => ({
  width: 20,
  height: 20,
  padding: 1,
  borderRadius: 2,
  objectFit: 'contain',

  ...(status === WarehouseStatus.OPEN && {
    backgroundColor: 'rgb(0, 128, 92)',
  }),

  ...([WarehouseStatus.CLOSED, WarehouseStatus.TEMPORARILY_CLOSED, WarehouseStatus.DRAFT].includes(status) && {
    backgroundColor: 'rgb(153, 15, 15)',
  }),
}))
