import { FulfillmentStatus, Order } from '@quickcommerceltd/zappboard'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useRef, useState } from 'react'
import { db, orderTimestampConverter } from '../../firebase'

export const useStoreViewData = (storeId: string): Order[] => {
  const [orders, setOrders] = useState<Order[]>([])
  const unsub = useRef<() => void>()

  const now = DateTime.now()
  const last12Hours = useMemo(() => now.minus({ hours: 12 }).toJSDate(), [now.hour]) // eslint-disable-line react-hooks/exhaustive-deps
  const dbQuery = useMemo(() => {
    return query(
      collection(db, `warehouseStats/${storeId}/orders`),
      where('fulfillmentStatus', '==', FulfillmentStatus.UNFULFILLED),
      where('createdAt', '>', last12Hours),
      orderBy('createdAt', 'desc')
    ).withConverter(orderTimestampConverter)
  }, [last12Hours, storeId])

  useEffect(() => {
    unsub.current = onSnapshot(dbQuery, (snapshot) => {
      const orders: Order[] = []
      snapshot.forEach((doc) => orders.push(doc.data() as Order))
      setOrders(orders)
    })

    return () => {
      if (unsub.current) {
        unsub.current()
      }
    }
  }, [dbQuery])

  return orders
}
