import { Order, OrderDefectType } from '@quickcommerceltd/zappboard'
import React, { useMemo, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import { useLocation, useNavigate } from 'react-router-dom'
import { getBarLineChartConfig } from '../../components/BarLineChart/getBarLineChartConfig'
import { ErrorAlertV2 } from '../../components/ErrorAlertV2/ErrorAlertV2'
import { NoDataAvailable } from '../../components/NoDataAvailable/NoDataAvailable'
import { OrderListV2 } from '../../components/OrderListV2/OrderListV2'
import { SingleMetricPanel } from '../../components/SingleMetricPanel/SingleMetricPanel'
import { WidgetPanel } from '../../components/WidgetPanel/WidgetPanel'
import { useCommonWarehouseStatsV2 } from '../../hooks/useCommonWarehouseStatsV2/useCommonWarehouseStatsV2'
import { useL2OrderFilters } from '../../hooks/useL2OrderFilters/useL2OrderFilters'
import { getStatsIdToLocalString, getStatsIdToLocalTime } from '../../utils/warehouseStatsV2/getCommonTimeData'
import { getOOSDefectHourlyChartData, getOOSDefectMetrics } from '../../utils/warehouseStatsV2/getOOSDefectMetrics'
import { PageLayout } from '../PageLayout'
import { PanelLayout } from '../PanelLayout'
import { withAuthentication } from '../withAuthentication'

const OOSDefects: React.FC = () => {
  const searchQuery = new URLSearchParams(useLocation().search)
  const navigate = useNavigate()
  const [selectedOrderId, setSelectedOrderId] = useState(searchQuery.get('orderId') ?? '')
  const {
    originFilter,
    warehouseFilter,
    latestAcc,
    latestHourly,
    lastWeekHourly,
    lastWeekSameTimeAcc,
    yesterdaySameTimeAcc,
    errorLatest,
    errorHourly,
    lastWeekError,
    lastWeekEndOfDayError,
    lastWeekStatsSameTimeError,
    yesterdayStatsSameTimeError,
    totalManagedWarehouses,
    loadingLatest,
    loadingHourly,
    lastWeekLoading,
    lastWeekStatsSameTimeLoading,
    yesterdayStatsSameTimeLoading,
  } = useCommonWarehouseStatsV2(24)

  // generate oos metrics for each time line
  const oosMetricsLatest = useMemo(() => getOOSDefectMetrics(latestAcc, originFilter), [latestAcc, originFilter])
  const oosMetricsHourly = useMemo(() => getOOSDefectMetrics(latestHourly, originFilter), [latestHourly, originFilter])
  const oosMetricsLastWeekHourly = useMemo(
    () => getOOSDefectMetrics(lastWeekHourly, originFilter),
    [lastWeekHourly, originFilter]
  )
  const oosMetricsLastWeekSameTime = useMemo(
    () => getOOSDefectMetrics(lastWeekSameTimeAcc, originFilter),
    [lastWeekSameTimeAcc, originFilter]
  )
  const oosMetricsYesterdaySameTime = useMemo(
    () => getOOSDefectMetrics(yesterdaySameTimeAcc, originFilter),
    [yesterdaySameTimeAcc, originFilter]
  )

  // hourly metrics for charting
  const hourlyOosMetrics = useMemo(() => getOOSDefectHourlyChartData(oosMetricsHourly), [oosMetricsHourly])
  const hourlyOosMetricsLastWeek = useMemo(
    () => getOOSDefectHourlyChartData(oosMetricsLastWeekHourly),
    [oosMetricsLastWeekHourly]
  )

  const orderFilters = useL2OrderFilters({
    originFilter,
    warehouseFilter,
    totalManagedWarehouses,
    customFilters: [['orderDefectTypes', 'array-contains', OrderDefectType.ITEM_OOS]],
  })

  const setOrder = (order: Order) => {
    setSelectedOrderId(order.id)
    navigate(`?orderId=${order.id}`, { replace: true })
  }

  const error =
    errorLatest ||
    errorHourly ||
    lastWeekError ||
    lastWeekEndOfDayError ||
    lastWeekStatsSameTimeError ||
    yesterdayStatsSameTimeError

  if (error) {
    return <ErrorAlertV2 error={error} />
  }

  const oosChartProps = {
    barLabel: 'Today',
    barData: hourlyOosMetrics.map((metric) => metric.chartData),
    lineLabel: 'Last Week',
    lineData: hourlyOosMetricsLastWeek.map((metric) => metric.chartData),
  }

  const { data, options } = getBarLineChartConfig({
    lineLabel: oosChartProps.lineLabel,
    lineData: oosChartProps.lineData,
    barLabel: oosChartProps.barLabel,
    barData: oosChartProps.barData,
    maintainAspectRatio: false,
    borderWidth: 3,
  })

  const hasNoChartData = !oosChartProps.lineData.length && !oosChartProps.barData.length

  const chart = hasNoChartData ? (
    <NoDataAvailable title="Timeline" />
  ) : (
    <WidgetPanel
      title="Timeline"
      sx={{ width: '100%', height: '300px' }}
      loading={loadingHourly || lastWeekLoading || loadingLatest || lastWeekStatsSameTimeLoading}
    >
      <Chart type="bar" data={data} options={options} style={{ paddingBottom: 22, maxHeight: 240 }} />
    </WidgetPanel>
  )

  const oosLatestAcc = oosMetricsLatest[0]
  const oosLastWeekAcc = oosMetricsLastWeekSameTime[0]
  const oosYesterdayAcc = oosMetricsYesterdaySameTime[0]
  const oosLatestHourly = oosMetricsHourly[oosMetricsHourly.length - 1]

  const hourlyTime = latestHourly.length ? getStatsIdToLocalTime(latestHourly?.[latestHourly.length - 1]?.id) : null
  const dodTime = getStatsIdToLocalString(yesterdaySameTimeAcc?.[0]?.id)
  const wowTime = getStatsIdToLocalString(lastWeekSameTimeAcc?.[0]?.id)

  return (
    <PageLayout title="OOS Defects">
      <PanelLayout>
        <SingleMetricPanel
          heading={oosLatestAcc?.oosDefects >= 0 ? `${oosLatestAcc.oosDefects.toFixed(0)}` : 'N/A'}
          subHeading="Today"
          miniHeading={`Now, since 00:00`}
          loading={loadingLatest}
        />
        <SingleMetricPanel
          heading={oosLatestHourly?.oosDefects >= 0 ? `${oosLatestHourly.oosDefects.toFixed(0)}` : 'N/A'}
          subHeading="Today, This Hour"
          miniHeading={`Now, since ${hourlyTime ? hourlyTime.toFormat('HH:mm') : 'N/A'}`}
          loading={loadingHourly}
        />
        <SingleMetricPanel
          heading={oosYesterdayAcc?.oosDefects >= 0 ? `${oosYesterdayAcc.oosDefects.toFixed(0)}` : 'N/A'}
          subHeading={'DoD Comparison'}
          miniHeading={`${dodTime}, since 00:00`}
          loading={yesterdayStatsSameTimeLoading}
        />
        <SingleMetricPanel
          heading={oosLastWeekAcc?.oosDefects >= 0 ? `${oosLastWeekAcc.oosDefects.toFixed(0)}` : 'N/A'}
          subHeading={'WoW Comparison'}
          miniHeading={`${wowTime}, since 00:00`}
          loading={lastWeekStatsSameTimeLoading}
        />
      </PanelLayout>
      {chart}
      <WidgetPanel sx={{ width: '100%', p: 0, height: '100%', overflow: 'hidden' }}>
        <OrderListV2
          filters={orderFilters}
          onSelect={setOrder}
          onClose={() => setSelectedOrderId('')}
          onMinimize={() => setSelectedOrderId('')}
          selectedOrderId={selectedOrderId}
        />
      </WidgetPanel>
    </PageLayout>
  )
}

export default withAuthentication(React.memo(OOSDefects))
