import { Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  icon: string
  text: string
  route: string
}

export const IconLink: React.FC<Props> = ({ icon, text, route }) => (
  <Stack
    sx={{
      alignItems: 'center',
      ':hover': {
        filter:
          'brightness(0) saturate(100%) invert(53%) sepia(23%) saturate(3835%) hue-rotate(165deg) brightness(103%) contrast(108%);',
      },
    }}
  >
    <Link to={route}>
      <img
        src={icon}
        alt={text}
        width={36}
        height={36}
        style={{
          cursor: 'pointer',
        }}
      />
    </Link>
    <Typography sx={{ fontSize: 12, color: 'white', mt: '1px' }}>{text}</Typography>
  </Stack>
)
