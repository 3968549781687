import { Order } from '@quickcommerceltd/zappboard'
import React, { useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { useLocation, useNavigate } from 'react-router-dom'
import { getDoubleLineChartConfig } from '../../components/DoubleLineChart/getDoubleLineChartConfig'
import { ErrorAlertV2 } from '../../components/ErrorAlertV2/ErrorAlertV2'
import { NoDataAvailable } from '../../components/NoDataAvailable/NoDataAvailable'
import { OrderListV2 } from '../../components/OrderListV2/OrderListV2'
import { SingleMetricPanel } from '../../components/SingleMetricPanel/SingleMetricPanel'
import { WidgetPanel } from '../../components/WidgetPanel/WidgetPanel'
import { useCommonWarehouseStatsV2 } from '../../hooks/useCommonWarehouseStatsV2/useCommonWarehouseStatsV2'
import { useL2OrderFilters } from '../../hooks/useL2OrderFilters/useL2OrderFilters'
import { formatPercentString } from '../../utils/formatPercentString'
import { getStatsIdToLocalString, getStatsIdToLocalTime } from '../../utils/warehouseStatsV2/getCommonTimeData'
import { getDTPHourlyChartData, getDtpMetrics, getDTPMinMax } from '../../utils/warehouseStatsV2/getDtpMetrics'
import { PageLayout } from '../PageLayout'
import { PanelLayout } from '../PanelLayout'
import { withAuthentication } from '../withAuthentication'

const DTP: React.FC = () => {
  const searchQuery = new URLSearchParams(useLocation().search)
  const navigate = useNavigate()
  const [selectedOrderId, setSelectedOrderId] = useState(searchQuery.get('orderId') ?? '')

  const {
    originFilter,
    warehouseFilter,
    latestAcc,
    latestHourly,
    lastWeekHourly,
    lastWeekSameTimeAcc,
    yesterdaySameTimeAcc,
    errorLatest,
    errorHourly,
    lastWeekError,
    lastWeekEndOfDayError,
    lastWeekStatsSameTimeError,
    yesterdayStatsSameTimeError,
    totalManagedWarehouses,
    loadingLatest,
    loadingHourly,
    lastWeekLoading,
    lastWeekStatsSameTimeLoading,
    yesterdayStatsSameTimeLoading,
  } = useCommonWarehouseStatsV2(24)

  // generate dtp metrics for each time line
  const dtpMetricsLatest = useMemo(() => getDtpMetrics(latestAcc, originFilter), [latestAcc, originFilter])
  const dtpMetricsHourly = useMemo(() => getDtpMetrics(latestHourly, originFilter), [latestHourly, originFilter])
  const dtpMetricsLastWeekHourly = useMemo(
    () => getDtpMetrics(lastWeekHourly, originFilter),
    [lastWeekHourly, originFilter]
  )
  const dtpMetricsLastWeekSameTime = useMemo(
    () => getDtpMetrics(lastWeekSameTimeAcc, originFilter),
    [lastWeekSameTimeAcc, originFilter]
  )
  const dtpMetricsYesterdaySameTime = useMemo(
    () => getDtpMetrics(yesterdaySameTimeAcc, originFilter),
    [yesterdaySameTimeAcc, originFilter]
  )

  // hourly metrics for charting
  const hourlyDtpMetrics = useMemo(() => getDTPHourlyChartData(dtpMetricsHourly), [dtpMetricsHourly])
  const hourlyDtpMetricsLastWeek = useMemo(
    () => getDTPHourlyChartData(dtpMetricsLastWeekHourly),
    [dtpMetricsLastWeekHourly]
  )

  const orderFilters = useL2OrderFilters({
    originFilter,
    warehouseFilter,
    totalManagedWarehouses,
    customFilters: [
      ['dtpMet', '==', true],
      ['fulfillmentStatus', '==', 'fulfilled'],
    ],
  })

  const setOrder = (order: Order) => {
    setSelectedOrderId(order.id)
    navigate(`?orderId=${order.id}`, { replace: true })
  }

  const error =
    errorLatest ||
    errorHourly ||
    lastWeekError ||
    lastWeekEndOfDayError ||
    lastWeekStatsSameTimeError ||
    yesterdayStatsSameTimeError

  if (error) {
    return <ErrorAlertV2 error={error} />
  }

  const dtpChartProps = {
    solidLabel: 'Today',
    solidData: hourlyDtpMetrics.map((metric) => metric.chartData),
    dottedLabel: 'Last Week',
    dottedData: hourlyDtpMetricsLastWeek.map((metric) => metric.chartData),
  }

  const { data, options } = getDoubleLineChartConfig({
    dottedLabel: dtpChartProps.dottedLabel,
    dottedData: dtpChartProps.dottedData,
    solidLabel: dtpChartProps.solidLabel,
    solidData: dtpChartProps.solidData,
    maintainAspectRatio: false,
    borderWidth: 3,
  })

  const hasNoChartData =
    dtpChartProps.solidData.length &&
    dtpChartProps.dottedData.length &&
    dtpChartProps.solidData.every((item) => item.y === 0) &&
    dtpChartProps.dottedData.every((item) => item.y === 0)

  const chart = hasNoChartData ? (
    <NoDataAvailable title="Timeline" />
  ) : (
    <WidgetPanel
      title="Timeline"
      sx={{ width: '100%' }}
      loading={loadingHourly || lastWeekLoading || loadingLatest || lastWeekStatsSameTimeLoading}
    >
      <Line data={data} options={options} style={{ maxHeight: 240 }} />
    </WidgetPanel>
  )

  const dtpLatestAcc = dtpMetricsLatest[0]
  const dtpLastWeekAcc = dtpMetricsLastWeekSameTime[0]
  const dtpYesterdayAcc = dtpMetricsYesterdaySameTime[0]
  const dtpLatestHourly = dtpMetricsHourly[dtpMetricsHourly.length - 1]

  const hourlyTime = latestHourly.length ? getStatsIdToLocalTime(latestHourly?.[latestHourly.length - 1]?.id) : null
  const dodTime = getStatsIdToLocalString(yesterdaySameTimeAcc?.[0]?.id)
  const wowTime = getStatsIdToLocalString(lastWeekSameTimeAcc?.[0]?.id)

  const tooltipLatest = getDTPMinMax(latestAcc?.[0], originFilter)
  const tooltipHourly = getDTPMinMax(latestHourly?.[latestHourly.length - 1], originFilter)
  const tooltipYesterday = getDTPMinMax(yesterdaySameTimeAcc?.[0], originFilter)
  const tooltipLastWeek = getDTPMinMax(lastWeekSameTimeAcc?.[0], originFilter)

  return (
    <PageLayout title="Delivered to Promise">
      <PanelLayout>
        <SingleMetricPanel
          heading={
            dtpLatestAcc?.dtpMetrics
              ? formatPercentString(dtpLatestAcc.dtpMetrics.completedTasksWithinPromisePercent)
              : 'N/A'
          }
          subHeading="Today"
          miniHeading={`Now, since 00:00`}
          loading={loadingLatest}
          tooltip={tooltipLatest}
        />
        <SingleMetricPanel
          heading={
            dtpLatestHourly?.dtpMetrics
              ? formatPercentString(dtpLatestHourly.dtpMetrics.completedTasksWithinPromisePercent)
              : 'N/A'
          }
          subHeading="Today, Hourly Average"
          miniHeading={`Now, since ${hourlyTime ? hourlyTime.toFormat('HH:mm') : 'N/A'}`}
          loading={loadingHourly}
          tooltip={tooltipHourly}
        />
        <SingleMetricPanel
          heading={
            dtpYesterdayAcc?.dtpMetrics
              ? formatPercentString(dtpYesterdayAcc.dtpMetrics.completedTasksWithinPromisePercent)
              : 'N/A'
          }
          subHeading={'DoD Comparison'}
          miniHeading={`${dodTime}, since 00:00`}
          loading={yesterdayStatsSameTimeLoading}
          tooltip={tooltipYesterday}
        />
        <SingleMetricPanel
          heading={
            dtpLastWeekAcc?.dtpMetrics
              ? formatPercentString(dtpLastWeekAcc.dtpMetrics.completedTasksWithinPromisePercent)
              : 'N/A'
          }
          subHeading={'WoW Comparison'}
          miniHeading={`${wowTime}, since 00:00`}
          loading={lastWeekStatsSameTimeLoading}
          tooltip={tooltipLastWeek}
        />
      </PanelLayout>
      {chart}
      <WidgetPanel sx={{ width: '100%', p: 0, height: '100%', overflow: 'hidden' }}>
        <OrderListV2
          filters={orderFilters}
          onSelect={setOrder}
          onClose={() => setSelectedOrderId('')}
          onMinimize={() => setSelectedOrderId('')}
          selectedOrderId={selectedOrderId}
        />
      </WidgetPanel>
    </PageLayout>
  )
}

export default withAuthentication(React.memo(DTP))
