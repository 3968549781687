import { arrayUnion, collection, doc, orderBy, query, setDoc, where, Timestamp, getDoc } from 'firebase/firestore'
import uniqBy from 'lodash/uniqBy'
import { DateTime } from 'luxon'
import { useCallback, useMemo, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import toast from 'react-hot-toast'
import { useRecoilState } from 'recoil'
import { db } from '../../firebase'
import { GenericDataConverter } from '../../firebase/genericDataConverter/genericDataConverter'
import { sessionState } from '../../state'
import { ZappMessage } from '../../types/ZappMessage'

const zappMessagingConverter = new GenericDataConverter<ZappMessage>()

export const useMessaging = () => {
  const now = DateTime.now()
  const [session] = useRecoilState(sessionState)
  const last24Hours = useMemo(() => now.minus({ hours: 24 }), [now.hour]) // eslint-disable-line react-hooks/exhaustive-deps
  const [updating, setUpdating] = useState(false)

  const [messages, loading] = useCollectionData<ZappMessage>(
    query(
      collection(db, 'messaging'),
      orderBy('createdAt', 'desc'),
      where('createdAt', '>', last24Hours.toJSDate())
    ).withConverter(zappMessagingConverter)
  )

  const managedWarehouses = useMemo(
    () => (session.user?.manager ? session.user?.managedWarehouses : []),
    [session.user]
  )

  const myMessages = useMemo(
    () =>
      messages?.filter(
        (message) =>
          session.user?.admin ||
          session.user?.supervisor ||
          managedWarehouses?.some((w) => message.toWarehouse.includes(w))
      ) ?? [],
    [managedWarehouses, messages, session.user]
  )

  const newMessages = useMemo(() => {
    if (myMessages?.length) {
      const newMessages = myMessages.filter(
        (message) =>
          session?.user?.email &&
          !message.receivedBy.some((action) => action.user === session.user?.email) &&
          message.user !== session.user.email
      )
      return newMessages.length
    }
    return 0
  }, [myMessages, session.user?.email])

  const requiresAction = useMemo(() => {
    if (session.user?.admin || session.user?.supervisor) return false
    if (myMessages?.length) {
      const newMessages = myMessages.filter(
        (message) =>
          session?.user?.email &&
          message.isAction &&
          !message.actionedBy.some((action) => action.user === session.user?.email)
      )
      return newMessages.length > 0
    }
  }, [myMessages, session.user])

  const successToast = (message: string) => {
    toast.success(message, {
      icon: '✅',
      style: {
        fontFamily: 'Inter, sans-serif',
        background: '#00805C',
        color: '#fff',
      },
    })
  }

  const errorToast = (message: string) => {
    toast.error(message, {
      icon: '⚠️',
      style: {
        fontFamily: 'Inter, sans-serif',
        background: '#D00000',
        color: '#fff',
      },
    })
  }

  const receivedMessage = useCallback(
    async (message: ZappMessage) => {
      if (updating) return
      try {
        setUpdating(true)
        const ref = doc(db, 'messaging', message.id)
        await setDoc(
          ref,
          {
            receivedBy: arrayUnion({ user: session?.user?.email, timestamp: Timestamp.now() }),
          },
          { merge: true }
        )
        const updatedMsg = await getDoc(ref)
        const receivedBy = updatedMsg.data()?.receivedBy
        const uniqueReceivedBy = uniqBy(receivedBy, 'user')
        if (uniqueReceivedBy.length < receivedBy.length) {
          await setDoc(
            ref,
            {
              receivedBy: uniqueReceivedBy,
            },
            { merge: true }
          )
        }
        successToast('Message marked as received')
        setUpdating(false)
      } catch (error) {
        errorToast((error as Error)?.message || 'Could not mark message as received')
      }
    },
    [session.user?.email, updating]
  )

  const actionMessage = useCallback(
    async (message: ZappMessage) => {
      try {
        await setDoc(
          doc(db, 'messaging', message.id),
          {
            actionedBy: arrayUnion({ user: session?.user?.email, timestamp: Timestamp.now() }),
          },
          { merge: true }
        )
        successToast('Message marked as actioned')
      } catch (error) {
        errorToast((error as Error)?.message || 'Could not mark message as actioned')
      }
    },
    [session.user?.email]
  )

  return {
    requiresAction,
    newMessages,
    messages: myMessages,
    loading,
    receivedMessage,
    actionMessage,
  }
}
