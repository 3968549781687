import { Timestamp } from 'firebase/firestore'
import { DateTime } from 'luxon'

export const toFirestoreTimestamp = (value?: unknown): Timestamp | undefined => {
  if (!value) return undefined

  if (typeof value === 'string' && !isNaN(new Date(value).getTime())) {
    return Timestamp.fromMillis(DateTime.fromISO(value).toMillis())
  }

  if (typeof value === 'number') {
    return Timestamp.fromMillis(value)
  }

  if (value instanceof Date) {
    return Timestamp.fromDate(value)
  }

  if (value instanceof DateTime) {
    return Timestamp.fromMillis(value.toMillis())
  }

  return undefined
}
