import { createContext, FC, useEffect, useState } from 'react'

export const TimeNowContext = createContext(new Date().getTime())

export const TimeNowProvider: FC = ({ children }) => {
  const [now, setNow] = useState(new Date().getTime())

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date().getTime()), 1000)
    return () => clearInterval(interval)
  }, [])

  return <TimeNowContext.Provider value={now}>{children}</TimeNowContext.Provider>
}
