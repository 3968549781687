import { Box, CircularProgress } from '@mui/material'
import { User, onAuthStateChanged, signInWithCustomToken } from 'firebase/auth'
import { doc, onSnapshot } from 'firebase/firestore'
import isEqual from 'lodash/isEqual'
import parser from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { H4 } from '../components/Text'
import { auth, db } from '../firebase'
import { sessionState } from '../state'
import routes from './routes'

export function withAuthentication<P>(Component: React.ComponentType<P>) {
  return (props: P) => {
    const [session, setSession] = useRecoilState(sessionState)
    const [error, setError] = useState<string>()
    const navigate = useNavigate()
    const location = useLocation()
    const secret = parser.parse(location.search).secret

    function wait(milliseconds: number) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds))
    }

    async function tryAuthenticateWithSecret() {
      const response = await fetch(`${process.env.REACT_APP_FIREBASE_SECRET_AUTH_URL}?secret=${secret}`)

      const body = await response.json()
      const credentials = await signInWithCustomToken(auth, body.token)

      console.log(`Logged in as ${credentials.user?.email}`)
      setClaimsToSession(credentials.user)
      createUserClaimChangeListener(credentials.user)
    }

    async function setClaimsToSession(user: User | null) {
      const claims = (await user?.getIdTokenResult())?.claims
      setSession({
        ...session,
        isAuthenticating: false,
        user: user && {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          admin: !!claims?.admin,
          supervisor: !!claims?.supervisor,
          manager: !!claims?.manager,
          managedWarehouses: (claims?.managedWarehouses as string[]) ?? [],
        },
      })
    }

    async function createUserClaimChangeListener(user: User | null) {
      if (!user?.email) return

      let prev: any = null
      const claimDoc = doc(db, `user_claims/${user.email}`)
      onSnapshot(claimDoc, async (curr) => {
        if (!prev) {
          prev = curr
          return
        }

        if (isEqual(curr.data(), prev.data())) {
          return
        }

        // force token reset
        await user.getIdToken(true)
        setClaimsToSession(user)
      })
    }

    // Subscribe to firebase auth
    useEffect(() => {
      return onAuthStateChanged(auth, async (resultUser) => {
        if (!resultUser) {
          if (secret) {
            let attempts = 0
            while (attempts++ < 50) {
              try {
                setError(undefined)
                tryAuthenticateWithSecret()
                break
              } catch (e) {
                console.error(`Failed to log in using secret. Attempt ${attempts}`)
                setError(`Failed to do the token login. Attempt: ${attempts} Error: ${JSON.stringify(e)}`)
                await wait(5000)
              }
            }
          } else {
            navigate(routes.signin)
            setSession({
              ...session,
              isAuthenticating: false,
              user: null,
            })
          }
        } else {
          console.log(`Logged in as ${resultUser?.email}`)
          setClaimsToSession(resultUser)
          createUserClaimChangeListener(resultUser)
        }
      })
    }, [setSession]) // eslint-disable-line react-hooks/exhaustive-deps

    return !session.isAuthenticating ? (
      <Component {...(props as any)} />
    ) : (
      <>
        <Box display="flex" justifyContent="center" padding={4}>
          {error && <H4>{error}</H4>}
          {!error && (
            <Box>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </>
    )
  }
}
