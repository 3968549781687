import { LinearProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import { ProgressBarChartData } from '../../types/ProgressBarChartData'
import { WidgetPanel } from '../WidgetPanel/WidgetPanel'

interface Props {
  title: string
  subtitle?: string
  leftHeading: string
  rightHeading: string
  limit: number
  data: ProgressBarChartData[]
  link?: string
  loading?: boolean
}

const SEVERE_THRESHOLD = 0.7
const WARNING_THRESHOLD = 0.5

const normalise = (value: number, min: number, max: number) => Math.min(100, ((value - min) * 100) / (max - min))
const getColor = (value: number, max: number) => {
  if (value >= max * SEVERE_THRESHOLD) {
    return 'error'
  }
  if (value >= max * WARNING_THRESHOLD) {
    return 'warning'
  }
  return 'primary'
}

const renderTitle = (title: string) => (
  <Typography sx={{ fontSize: '16px', lineHeight: '19px', color: 'text.secondary', fontWeight: 500 }}>
    {title}
  </Typography>
)

export const ProgressBarChart: React.FC<Props> = ({
  title,
  subtitle,
  leftHeading,
  rightHeading,
  data,
  limit,
  link,
  loading,
}) => (
  <WidgetPanel title={title} link={link} loading={loading}>
    {subtitle && (
      <Typography sx={{ color: 'text.secondary', fontSize: '12px' }} gutterBottom>
        {subtitle}
      </Typography>
    )}
    <Stack direction="row" justifyContent="space-between" sx={{ my: 1 }}>
      {renderTitle(leftHeading)}
      {renderTitle(rightHeading)}
    </Stack>
    {data
      .sort((a, b) => {
        if (a.value === b.value) {
          return a.name.localeCompare(b.name)
        }
        return b.value - a.value
      })
      .slice(0, limit ?? data.length)
      .map((item) => (
        <Stack
          key={item.name}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ borderBottom: '1px solid', borderColor: 'divider', mb: 1 }}
        >
          <Typography>{item.name}</Typography>
          <LinearProgress
            sx={{
              minWidth: '70%',
              borderRadius: 5,
              height: 7,
              backgroundColor: '#404258',
              mb: 1,
            }}
            variant="determinate"
            color={getColor(item.value, item.valueMax)}
            value={normalise(item.value, 0, item.valueMax)}
          />
          <Typography sx={{ textAlign: 'right' }}>{item.value}</Typography>
        </Stack>
      ))}
  </WidgetPanel>
)
