import { Order, TaskState } from '@quickcommerceltd/zappboard'
import { ETA_TYPE } from '../_consts/ETA_TYPE'

export const getEtaType = (order: Order) => {
  const isStacked = !!order.stackedWithTaskIds?.length

  switch (true) {
    case [TaskState.ASSIGNED].includes(order.deliveryStatus) && !order.deliveryRiderArrivedForPickupAt:
      return ETA_TYPE.PICKUP
    case [TaskState.ASSIGNED].includes(order.deliveryStatus) && !!order.deliveryRiderArrivedForPickupAt:
      return ETA_TYPE.ARRIVED_AT_STORE
    case [TaskState.PICKED_UP].includes(order.deliveryStatus):
      return isStacked ? ETA_TYPE.DELIVERY : ETA_TYPE.ARRIVED_AT_STORE
    case [TaskState.ACTIVE].includes(order.deliveryStatus) &&
      !!order.deliveryStartedAt &&
      !order.deliveryRiderArrivedAtCustomerAt:
      return ETA_TYPE.DELIVERY
    case [TaskState.ACTIVE].includes(order.deliveryStatus) &&
      !!order.deliveryStartedAt &&
      !!order.deliveryRiderArrivedAtCustomerAt:
      return ETA_TYPE.ARRIVED_AT_CUSTOMER
    case [TaskState.RETURNING].includes(order.deliveryStatus):
      return ETA_TYPE.RETURNING
    default:
      return ETA_TYPE.PICKUP
  }
}
