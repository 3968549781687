import { OrderStatsV2, WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { filterStatsByKey } from './filterStatsByKey'

const DEFAULT_STATS: OrderStatsV2 = {
  cancelledOrdersNum: 0,
  fulfilledOrdersNum: 0,
  unfulfilledOrdersNum: 0,
  unfulfilledNoPickupOrdersNum: 0,
  unfulfilledAndFailedNum: 0,
  ordersWith01RatingNum: 0,
  ordersWithOOSItemsNum: 0,
  ordersWithCustomerReportedIssues: 0,
  ordersWithPickerDefectsNum: 0,
  ordersWithPackerDefectsNum: 0,
  ordersWithCustomerDefectsNum: 0,
  ordersWithRiderDefectsNum: 0,
}

const sumStats = (acc: OrderStatsV2, current: OrderStatsV2) => {
  acc.fulfilledOrdersNum += current.fulfilledOrdersNum
  acc.cancelledOrdersNum += current.cancelledOrdersNum
  acc.unfulfilledOrdersNum += current.unfulfilledOrdersNum
  acc.unfulfilledNoPickupOrdersNum += current.unfulfilledNoPickupOrdersNum
  acc.unfulfilledAndFailedNum += current.unfulfilledAndFailedNum
  acc.ordersWith01RatingNum += current.ordersWith01RatingNum
  acc.ordersWithOOSItemsNum += current.ordersWithOOSItemsNum
  acc.ordersWithCustomerReportedIssues += current.ordersWithCustomerReportedIssues
  acc.ordersWithPickerDefectsNum += current.ordersWithPickerDefectsNum
  acc.ordersWithPackerDefectsNum += current.ordersWithPackerDefectsNum
  acc.ordersWithCustomerDefectsNum += current.ordersWithCustomerDefectsNum
  acc.ordersWithRiderDefectsNum += current.ordersWithRiderDefectsNum
}

export const getOrderStats = (warehouseStats?: WarehouseStatsV2, originFilter: string[] = []): OrderStatsV2 | null => {
  if (!warehouseStats) return null

  const warehouses = Object.values(warehouseStats.statsByWarehouse)
  return warehouses
    .map((stat) => {
      const filteredOrders = filterStatsByKey<OrderStatsV2>(stat.orders, originFilter)
      return filteredOrders.reduce(
        (acc, current) => {
          sumStats(acc, current)
          return acc
        },
        { ...DEFAULT_STATS }
      )
    })
    .reduce(
      (acc, current) => {
        sumStats(acc, current)
        return acc
      },
      { ...DEFAULT_STATS }
    )
}
