import ShoppingBagOutlined from '@mui/icons-material/ShoppingBagOutlined'
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined'
import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { OrderOrigin } from '@quickcommerceltd/zappboard'
import React, { useEffect, useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { useWarehouseStats } from '../../hooks'
import { useStoresToHide } from '../../hooks/useStoresToHide'
import { sessionState } from '../../state'

const originList = [
  { id: OrderOrigin.ZAPP, name: 'Zapp' },
  { id: OrderOrigin.UBER, name: 'Uber Eats' },
  { id: OrderOrigin.DELIVEROO, name: 'Deliveroo' },
  { id: OrderOrigin.JET, name: 'Just Eat' },
]

const MenuProps = {
  PaperProps: {
    style: {
      borderRadius: '8px',
      backgroundColor: 'white',
      color: 'black',
      fontWeight: 700,
      marginTop: '0.5em',
    },
  },
}

const SelectActionText = styled(Typography)(({ theme }) => ({
  zIndex: 1,
  pointerEvents: 'auto',
  fontWeight: 700,
  color: theme.palette.background.default,
  textDecoration: 'underline',
  textDecorationColor: theme.palette.primary.main,
  fontSize: 12,
  cursor: 'pointer',
}))

interface Props {
  singleSelectWarehouse?: boolean
  hideOriginFilter?: boolean
}
export const WarehouseV2Filters: React.FC<Props> = ({ singleSelectWarehouse = false, hideOriginFilter = false }) => {
  const [session, setSession] = useRecoilState(sessionState)
  const [isWarehouseFilterOpen, setIsWarehouseFilterOpen] = React.useState(false)
  const [isOriginFilterOpen, setIsOriginFilterOpen] = React.useState(false)
  const [warehouseStats = []] = useWarehouseStats({
    once: true,
    warehouseIds: session.user?.manager ? session.user?.managedWarehouses : [],
  })
  const storesToHide = useStoresToHide()

  const warehouseList = useMemo(() => {
    if (!warehouseStats) return []

    return warehouseStats
      .filter((warehouse) => !warehouse.isDistributionCenter && !warehouse.isDraft)
      .filter((warehouse) => !storesToHide.includes(warehouse.shortName))
      .map((warehouse) => ({
        name: warehouse.name,
        shortName: warehouse.shortName,
        id: warehouse.id,
      }))
      .sort((a, b) => a.shortName.localeCompare(b.shortName))
  }, [warehouseStats, session?.user])

  const onWarehouseChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event
    setSession({
      ...session,
      warehouseFilter: singleSelectWarehouse ? [value as string] : (value as string[]).filter((v) => !!v),
    })
  }

  useEffect(() => {
    if (warehouseList.length && !session.warehouseFilter) {
      setSession((session) => ({
        ...session,
        warehouseFilter: warehouseList.map(({ id }) => id),
      }))
    }
  }, [warehouseList, setSession, session.warehouseFilter])

  const onOriginChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event
    setSession({
      ...session,
      originFilter: value as string[],
    })
  }

  const selectAllStores = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    setSession({
      ...session,
      warehouseFilter: warehouseList.map(({ id }) => id),
    })
  }

  const clearAllStores = (ev: React.MouseEvent) => {
    ev.stopPropagation()

    setSession({
      ...session,
      warehouseFilter: [],
    })
  }

  const selectAllOrigins = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    setSession({
      ...session,
      originFilter: originList.map(({ id }) => id),
    })
  }

  const clearAllOrigins = (ev: React.MouseEvent) => {
    ev.stopPropagation()

    setSession({
      ...session,
      originFilter: [],
    })
  }

  return (
    <Stack direction="row" width="100%" spacing={1}>
      <FormControl sx={{ maxWidth: 200, flex: 1 }}>
        <Select
          multiple={!singleSelectWarehouse}
          displayEmpty
          value={session.warehouseFilter ?? []}
          onChange={onWarehouseChange}
          renderValue={(selected) =>
            singleSelectWarehouse
              ? warehouseList.find((w) => w.id === selected[0])?.shortName
              : `${selected.length} Selected`
          }
          MenuProps={MenuProps}
          open={isWarehouseFilterOpen}
          onOpen={() => setIsWarehouseFilterOpen(true)}
          onClose={() => setIsWarehouseFilterOpen(false)}
          input={
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start" onClick={() => setIsWarehouseFilterOpen(true)}>
                  <StoreOutlinedIcon sx={{ fill: '#002533', cursor: 'pointer' }} />
                </InputAdornment>
              }
              size="small"
              sx={{
                '& .MuiOutlinedInput-inputAdornedStart': {
                  color: '#E5F8FF',
                },
                backgroundColor: '#E5F8FF',
                color: '#002533',
                borderRadius: '20px',
                fontWeight: 'bold',
              }}
            />
          }
        >
          {!singleSelectWarehouse && (
            <MenuItem disabled value="" sx={{ opacity: '1 !important' }}>
              <Box display="flex" justifyContent="space-between" width="100%" px={1} my={0.5}>
                <SelectActionText onClick={selectAllStores}>Select all</SelectActionText>
                <SelectActionText onClick={clearAllStores}>Clear all</SelectActionText>
              </Box>
            </MenuItem>
          )}
          {warehouseList.map((warehouse) => (
            <MenuItem key={warehouse.shortName} value={warehouse.id} sx={{ p: 0.2 }}>
              {!singleSelectWarehouse && (
                <Checkbox checked={(session.warehouseFilter ?? [])?.indexOf(warehouse.id) > -1} />
              )}
              <ListItemText
                primary={
                  <strong>
                    {warehouse.shortName}{' '}
                    <Box sx={{ ml: 1, display: 'inline', color: 'divider', fontWeight: 600 }}>
                      <small>{warehouse.name}</small>
                    </Box>
                  </strong>
                }
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {!hideOriginFilter && (
        <FormControl sx={{ maxWidth: 200, flex: 1 }}>
          <Select
            multiple
            displayEmpty
            value={session.originFilter}
            onChange={onOriginChange}
            renderValue={(selected) => `${selected.length} Selected`}
            MenuProps={MenuProps}
            open={isOriginFilterOpen}
            onOpen={() => setIsOriginFilterOpen(true)}
            onClose={() => setIsOriginFilterOpen(false)}
            input={
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start" onClick={() => setIsOriginFilterOpen(true)}>
                    <ShoppingBagOutlined sx={{ fill: '#002533', cursor: 'pointer' }} />
                  </InputAdornment>
                }
                size="small"
                sx={{
                  '& .MuiOutlinedInput-inputAdornedStart': {
                    color: '#E5F8FF',
                  },
                  backgroundColor: '#E5F8FF',
                  color: '#002533',
                  borderRadius: '20px',
                  fontWeight: 'bold',
                }}
              />
            }
          >
            <MenuItem disabled value="" sx={{ opacity: '1 !important' }}>
              <Box display="flex" justifyContent="space-between" width="100%" px={1} my={0.5}>
                <SelectActionText onClick={selectAllOrigins}>Select all</SelectActionText>
                <SelectActionText onClick={clearAllOrigins}>Clear all</SelectActionText>
              </Box>
            </MenuItem>
            {originList.map((origin) => (
              <MenuItem key={origin.id} value={origin.id} sx={{ p: 0.2 }}>
                <Checkbox checked={session.originFilter.indexOf(origin.id) > -1} />
                <ListItemText primary={<strong>{origin.name}</strong>} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Stack>
  )
}
