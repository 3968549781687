import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import { NumberChangeChip } from '../NumberChangeChip/NumberChangeChip'
import { WidgetPanel } from '../WidgetPanel/WidgetPanel'

interface Props {
  ordersPerQuarter: number
  ordersPerQuarterLastWeek: number
  loading: boolean
}
export const MomentumPanel: React.FC<Props> = ({ ordersPerQuarter, ordersPerQuarterLastWeek, loading }) => {
  return (
    <WidgetPanel title="Momentum" sx={{ justifyContent: 'center', alignItems: 'center', p: 1 }} loading={loading}>
      <Typography variant="overline">Total orders processed in the last 15 minutes</Typography>
      <Stack alignItems="center">
        <Box display="flex" alignItems="center">
          <Typography variant="h1" sx={{ mr: 1 }}>
            {ordersPerQuarter === -1 ? <CircularProgress size={20} /> : ordersPerQuarter}
          </Typography>
          {ordersPerQuarter !== -1 && (
            <NumberChangeChip currentValue={ordersPerQuarter} compareValue={ordersPerQuarterLastWeek} />
          )}
        </Box>
        <Typography variant="h6">
          {ordersPerQuarterLastWeek === -1 ? <CircularProgress size={20} /> : ordersPerQuarterLastWeek}
        </Typography>
        <Typography sx={{ fontSize: '12px', color: '#9496AC' }}>Last Week</Typography>
      </Stack>
    </WidgetPanel>
  )
}
