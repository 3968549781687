import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { WarehouseV2Filters } from '../components/WarehouseV2Filters/warehouseV2Filters'
import { Layout } from '../layout'

interface Props {
  title: string
}
export const PageLayout: React.FC<Props> = ({ title, children }) => {
  return (
    <Layout>
      <Stack
        spacing={{ xs: 1, md: 2 }}
        sx={{
          m: 1,
          height: '88vh',
          '@media (max-width: 600px)': {
            mx: 0,
            width: '100%',
            height: 'unset',
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            '@media (max-width: 600px)': {
              flexDirection: 'column',
            },
          }}
        >
          <Typography variant="h3" sx={{ mr: 3 }}>
            {title}
          </Typography>
          <WarehouseV2Filters />
        </Box>
        {children}
      </Stack>
    </Layout>
  )
}
