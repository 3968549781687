import { WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { collection, limit, query, where } from 'firebase/firestore'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useCollectionData, useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { db } from '../../firebase'
import { GenericDataConverter } from '../../firebase/genericDataConverter/genericDataConverter'
import { dateTimeToSnapshotId } from '../../utils/warehouseStatsV2/dateTimeToSnapshotId'

const warehouseStatsConverter = new GenericDataConverter<WarehouseStatsV2>()

interface Props {
  startDate?: DateTime // start date of the first stat to fetch and quantity will determine how many stats to fetch working back (in time)
  quantity?: number // how many stats to fetch working back (each stat entry corresponds to a different time)
  hourly?: boolean // get entries by their hourly ids e.g. 2021-10-01_13-00
  id?: string
  once?: boolean
}

export const useWarehouseStatsV2 = ({ quantity = 8, once, id, startDate }: Props = {}) => {
  const useCollection = useMemo(() => {
    return once ? useCollectionDataOnce : useCollectionData
  }, [once])

  const snapshotsIds = useMemo(() => {
    const start = startDate ?? DateTime.utc()
    const now = start.minus({ minute: 1 })
    const result = [dateTimeToSnapshotId(now)]
    while (result.length < quantity) {
      const newDate = now.minus({ hour: result.length }).endOf('hour')
      result.push(dateTimeToSnapshotId(newDate))
    }
    return result
  }, [quantity, startDate])

  return useCollection<WarehouseStatsV2>(
    query(
      collection(db, 'statistics'),
      ...(id ? [where('id', '==', id)] : []),
      ...(!id && snapshotsIds.length ? [where('id', 'in', snapshotsIds)] : []),
      limit(quantity)
    ).withConverter(warehouseStatsConverter)
  )
}
