import { Stack, styled, Typography } from '@mui/material'
import { WarehouseStatus } from '@quickcommerceltd/zappboard'
import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import ActiveTableCell from '../../../components/ActiveTableCell'
import FormatDuration from '../../../components/FormatDuration'
import { StoreRevenueStats } from '../../../components/RevenueAndWorkloadStats/StoreRevenueStats'
import { TableCellCompact as TableCell, TableRow } from '../../../components/Table'
import { sessionState } from '../../../state'
import { WarehouseTableStats } from '../../../types/WarehouseTableStats'
import { formatPercentString } from '../../../utils/formatPercentString'
import { WarehouseStatusIndicator } from './WarehouseStatusIndicator'

const NECESSARY_OFFSET = 1 //Needed to use the FormatDuration component

interface Props {
  item: WarehouseTableStats
}

export const WarehouseStatsTableRowV2: FC<Props> = ({ item }) => {
  const navigate = useNavigate()
  const [session] = useRecoilState(sessionState)
  const isAdmin = !!session?.user?.admin

  const handleNavigate = useCallback(() => {
    navigate(`/v2/stores/${item.warehouse.id}`)
  }, [navigate, item.warehouse.id])

  return (
    <StyledTableRow key={item.warehouse.shortName} status={item.warehouse.status} onClick={handleNavigate}>
      <TableCell align="right">
        <Stack justifyContent="center" alignItems="center" spacing={0.5}>
          <Typography noWrap variant="caption" fontWeight="600">
            {item.warehouse.shortName}
          </Typography>
          <WarehouseStatusIndicator warehouse={item.warehouse} />
        </Stack>
      </TableCell>
      {isAdmin && (
        <TableCell align="right" style={{ padding: '8px 6px' }}>
          <StoreRevenueStats revenueNow={item.revenueNow} revenueLastWeek={item.revenueLastWeek} />
        </TableCell>
      )}
      <ActiveTableCell align="right">{item.fulfilled}</ActiveTableCell>
      <ActiveTableCell align="right">{item.cancelled}</ActiveTableCell>
      <ActiveTableCell align="right">{item.oosOrders}</ActiveTableCell>
      <ActiveTableCell align="right">{item.unfulfilled}</ActiveTableCell>
      <ActiveTableCell align="right">{item.unassigned}</ActiveTableCell>
      <ActiveTableCell align="right">{item.waiting}</ActiveTableCell>
      <ActiveTableCell align="right">{item.picking}</ActiveTableCell>
      <ActiveTableCell align="right">{item.packing}</ActiveTableCell>
      <ActiveTableCell align="right">{item.pending}</ActiveTableCell>
      <ActiveTableCell align="right">{item.assigned}</ActiveTableCell>
      <ActiveTableCell align="right">{item.inDelivery}</ActiveTableCell>
      <TableCell align="right">
        <FormatDuration from={NECESSARY_OFFSET} to={item.avgTimeInStore ?? 0} />
      </TableCell>
      <TableCell align="right">
        <FormatDuration from={NECESSARY_OFFSET} to={item.avgDeliveryTime ?? 0} />
      </TableCell>
      <TableCell align="right">
        <Typography>{formatPercentString(item.dtp)}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>{formatPercentString(item.d20)}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>{formatPercentString(item.d30)}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>{formatPercentString(item.d45)}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>{formatPercentString(item.d60)}</Typography>
      </TableCell>
    </StyledTableRow>
  )
}

const StyledTableRow = styled(TableRow)<{ status: WarehouseStatus }>(({ theme, status }) => ({
  ...([WarehouseStatus.CLOSED, WarehouseStatus.TEMPORARILY_CLOSED, WarehouseStatus.DRAFT].includes(status) && {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  }),
}))
