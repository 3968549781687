import { makeStyles } from 'tss-react/mui'

export const useCustomerSupportDialogStyles = makeStyles()((theme) => ({
  paper: {
    width: 'min(100%, 600px)',
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: '24px',
  },
  divider: {
    borderTopWidth: '1px',
    borderWidth: '1px',
    borderStyle: 'solid',
    opacity: 0.4,
    marginTop: '-8px',
    marginBottom: '4px',
    minWidth: '600px',
  },
  closeIcon: {
    position: 'absolute',
    top: '4px',
    right: '4px',
    color: 'inherit',
  },
  task: {
    fontWeight: 700,
    fontSize: '38px',
    marginLeft: '12px',
    marginBottom: '16px',
  },
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
    '& .MuiFormLabel-root': {
      color: '#DADADA',
      marginLeft: '12px',
    },
    '& .MuiInputBase-input': {
      color: 'white',
    },
  },
  select: {
    height: '57px',
    fontSize: '20px',
    borderRadius: '6px',
    marginBottom: '4px',
  },
  menuItem: {
    fontSize: '20px',
    borderRadius: '6px',
  },
  button: {
    height: '57px',
    fontWeight: 'bold',
    fontSize: '18px',
    borderRadius: '6px',
    marginTop: '28px',
    marginBottom: '8px',
  },
  successIcon: {
    display: 'block',
    position: 'relative',
    margin: '32px auto',
    fontSize: '72px',
    color: theme.palette.success.main,
    padding: '8px',
    boxSizing: 'border-box',
    border: `4px solid ${theme.palette.success.main}`,
    borderRadius: '100%',
  },
  successText: {
    fontSize: '20px',
    textAlign: 'center',
    marginTop: '24px',
    marginBottom: '8px',
  },
  successHeadline: {
    fontSize: '24px',
    textAlign: 'center',
    fontWeight: 600,
  },
  oosText: {
    fontSize: '20px',
    textAlign: 'center',
    marginBottom: '8px',
  },
  otherBox: {
    marginTop: '28px',
  },
  otherTextfield: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
    '& .MuiFormLabel-root': {
      color: '#DADADA',
    },
    '& .MuiInputBase-input': {
      color: 'white',
      fontSize: '20px',
      lineHeight: '28px',
    },
    marginBottom: '4px',
  },
  radioRoot: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  radioIcon: {
    marginLeft: '12px',
    borderRadius: '50%',
    width: 18,
    height: 18,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  radioCheckedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage: 'radial-gradient(#fff,#fff 0%,transparent 20%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  tableContainerOOS: {
    maxHeight: '20em',
    marginTop: '32px',
    marginBottom: '12px',
  },
  tableHeaderOOS: {
    fontSize: '20px',
    fontWeight: 600,
  },
  tableRowOOS: {
    fontSize: '20px',
  },
  productImage: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  productImageCell: {
    paddingRight: 0,
    width: theme.spacing(4),
  },
  productImageBig: {
    width: 255,
    height: 255,
    margin: '0 auto',
  },
  productImageLarge: {
    maxWidth: '100%',
  },
  inputOOS: {
    maxWidth: '40px',
    '& .MuiInputBase-input': {
      color: 'white',
      fontSize: '20px',
    },
  },
  markItemLineOOS: {
    backgroundColor: theme.status.warning.secondary,
    '&:hover': {
      background: `${theme.status.warning.primary} !important`,
    },
  },
  topRadio: {
    marginTop: '8px',
  },
  subtitleBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  reasonHeadline: {
    fontSize: '20px',
    textAlign: 'center',
    marginTop: '24px',
    marginBottom: '8px',
    fontWeight: 700,
  },
  radioText: {
    fontSize: '20px',
    padding: '12px',
  },
}))
