import { CircularProgress, Stack, Typography } from '@mui/material'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { Layout } from '../../layout'
import { sessionState } from '../../state'
import routes from '../routes'
import { withAuthentication } from '../withAuthentication'

const DEFAULT_WAREHOUSE_ID = 'uk_london_old-brompton-road'

const StoresPage: FC = () => {
  const navigate = useNavigate()
  const [{ user }] = useRecoilState(sessionState)

  useEffect(() => {
    if (!user) return
    const warehouseId = user.managedWarehouses?.[0] ?? DEFAULT_WAREHOUSE_ID
    navigate(routes.store.replace(':id', warehouseId), { replace: true })
  }, [user, navigate])

  return (
    <Layout>
      <Stack width="inherit" height="inherit" justifyContent="center" alignItems="center" spacing={2}>
        <CircularProgress />
        <Typography variant="body1" fontWeight="bold">
          Redirecting ...
        </Typography>
      </Stack>
    </Layout>
  )
}

export default withAuthentication(StoresPage)
