import { env } from '../../../config'
import { OrderEventsEnum } from '../../../types/OrderEventsEnum'

export interface OrderHistoryEvent {
  date: string
  link: string | undefined
  message: string
}

export const parseOrderHistoryEvent = (event: any): OrderHistoryEvent => {
  return {
    date: event?.date || '',
    link: event?.ticketUrl ?? createZephyrTaskLink(event.taskId),
    message: parseOrderHistoryMessage(event),
  }
}

const createZephyrTaskLink = (taskId: string | null): string | undefined => {
  if (taskId) {
    return `https://${env}.zephyr.quickcommerce.org/tasks/${taskId}`
  }
  return undefined
}

const parseOrderHistoryMessage = (event: any): string => {
  // from Saleor-Dashboard
  switch (event.type) {
    case OrderEventsEnum.CANCELED:
      const [rawReason, rawFee] = event.message?.split(', ') || ''
      const reason = translateCancellationReason(rawReason?.split('reason: ')?.[1] || '')
      const fee = rawFee?.split('fee: ')?.[1] || ''
      return ['ZC: Order was cancelled', reason && `- Reason: ${reason}`, fee && `- Fee: ${fee}`]
        .filter(Boolean)
        .join(' ')
    // eslint-disable-next-line
    case OrderEventsEnum.FULFILLMENT_CANCELED:
      return 'ZC: Fulfillment was cancelled'
    case OrderEventsEnum.INVOICE_REQUESTED:
      return 'ZC: Invoice was requested'
    case OrderEventsEnum.INVOICE_GENERATED:
      const requesterInvoiceGenerated = event.invoiceNumber
        ? `ZC: Invoice no. ${event.invoiceNumber ?? ''} was generated`
        : 'ZC: Invoice was generated'
      return requesterInvoiceGenerated
    case OrderEventsEnum.INVOICE_UPDATED:
      const requesterInvoiceUpdated = event.invoiceNumber
        ? `ZC: Invoice no. ${event.invoiceNumber ?? ''} was updated`
        : 'ZC: Invoice was updated'
      return requesterInvoiceUpdated
    case OrderEventsEnum.FULFILLMENT_FULFILLED_ITEMS:
      return `ZC: Fulfilled ${event.quantity ?? ''} items`
    case OrderEventsEnum.FULFILLMENT_RESTOCKED_ITEMS:
      return `ZC: Restocked ${event.quantity ?? ''} items`
    case OrderEventsEnum.NOTE_ADDED:
      const message = event.message ?? 'ZC: Note was added to the order'
      return message?.includes('href') ? message.split('>')[1].split('<')[0] : message
    case OrderEventsEnum.ORDER_FULLY_PAID:
      return 'ZC: Order was fully paid'
    case OrderEventsEnum.OTHER:
      return event.message ? `ZC: ${event.message}` : 'ZC: Undefined message'
    case OrderEventsEnum.PAYMENT_CAPTURED:
      return 'ZC: Payment was captured'
    case OrderEventsEnum.PAYMENT_FAILED:
      return 'ZC: Payment failed'
    case OrderEventsEnum.PAYMENT_REFUNDED:
      return 'ZC: Payment was refunded'
    case OrderEventsEnum.PAYMENT_VOIDED:
      return 'ZC: Payment was voided'
    case OrderEventsEnum.PLACED:
      return 'ZC: Order was placed'
    case OrderEventsEnum.STATUS_CHANGED:
      return `ZC: Order Status updated`
    case OrderEventsEnum.PAYMENT_AUTHORIZED:
      return 'ZC: Payment was authorized'
    case OrderEventsEnum.EXTERNAL_SERVICE_NOTIFICATION:
      return event.message ?? 'Undefined external service notification'
    case OrderEventsEnum.TASK_CREATED:
      return 'ZEP: Task was created.'
    case OrderEventsEnum.TASK_DELIVERY_STARTED:
      return 'ZEP: Task was started.'
    case OrderEventsEnum.TASK_ASSIGNED_RIDER:
      if (event.taskRiderName) {
        return `ZEP: Task is assigned to ${event.taskProvider ?? 'our'} rider ${event.taskRiderName} (${
          event.taskRiderPhone
        }).`
      }
      if (event.taskStatus === 'PENDING_RIDER_ASSIGNMENT') {
        const provider = event.taskProvider ? ' ' + event.taskProvider : ''

        return `ZEP: Task is waiting for a${provider} rider.`
      }
      if (event.taskFailureNotes && event.taskFailureReasons !== 'ORDER_CANCELLED') {
        return `ZEP: Task is unassigned. ${event.taskFailureNotes}`
      }
      return 'ZEP: Task is unassigned.'
    case OrderEventsEnum.TASK_ARRIVED_AT_CUSTOMER:
      return `ZEP: ${event.taskProvider ?? 'Our'} rider has arrived at destination.`
    case OrderEventsEnum.TASK_DELIVERED:
      return 'ZEP: Task was successfully delivered.'
    case OrderEventsEnum.TASK_FAILED:
      return 'ZEP: Task was failed.'
    case OrderEventsEnum.TASK_ARRIVED_AT_STORE:
      return `ZEP: ${event.taskProvider ?? 'Our'} rider arrived back at store.`
    case OrderEventsEnum.TASK_DELETED:
      return 'ZEP: Task was deleted.'
    case OrderEventsEnum.TASK_ARRIVED_FOR_PICKUP:
      return `ZEP: ${event.taskProvider ?? 'Our'} rider has arrived to pick up the task.`
    case OrderEventsEnum.TASK_RETURNING_TO_STORE:
      const provider = event.taskProvider ?? 'Our'

      return `ZEP: ${provider} rider is returning the task back to store.`
    case OrderEventsEnum.TASK_RETURNED:
      return `ZEP: Task was marked as returned to store.`
    case OrderEventsEnum.TASK_NOT_RETURNED:
      return `ZEP: Task was marked as not returned to store.`
    case OrderEventsEnum.PACKED:
      const linesPacked = event.lines?.map((line: any) => `${line.orderLine.productSku}: ${line.quantity}`).join(',')
      return linesPacked?.length > 0 ? `ZIPP: Order packed ${linesPacked}` : 'ZIPP: Order packed'
    case OrderEventsEnum.CREDIT_CREATED:
      return `ZC: Credit ${event.amount ?? ''} to customer`
    case OrderEventsEnum.LINES_CANCELLED:
      const linesCancelled = event.lines?.map((line: any) => `${line.orderLine.productSku}: ${line.quantity}`).join(',')
      return linesCancelled?.length > 0
        ? `ZC: Cancelled SKUs ${linesCancelled} ${event.source ?? ''}`
        : `ZC: Cancelled SKUs ${event.source ?? ''}`
    case OrderEventsEnum.TICKET_CREATED:
      const createdText = event.ticketId ? `: ${event.ticketId}` : ''
      return 'ZC: Ticket created' + createdText
    case OrderEventsEnum.EXTERNAL_CANCELLED_ERROR:
      return '3P: Error cancelling external Order'
    case OrderEventsEnum.EXTERNAL_CANCELLED:
      return '3P: Cancelled external Order'
    case OrderEventsEnum.EXTERNAL_ACCEPTED:
      return '3P: Accepted external Order'
    case OrderEventsEnum.EXTERNAL_ACCEPTED_ERROR:
      return '3P: Error accepting external Order'
    case OrderEventsEnum.EXTERNAL_DENIED:
      return '3P: Denied external Order.'
    case OrderEventsEnum.EXTERNAL_DENIED_ERROR:
      return '3P: Error denying external Order'
    case OrderEventsEnum.EXTERNAL_PATCHED_ERROR:
      return '3P: Error patching external Order'
    case OrderEventsEnum.EXTERNAL_PATCHED:
      return '3P: Patched external Order'
    default:
      return 'Unknown event type ' + event.type
  }
}

const translateCancellationReason = (cancellationReason: string): string => {
  switch (cancellationReason) {
    case 'order_taking_too_long_under_promise_time':
      return 'Order taking too long, under promise time'
    case 'order_taking_too_long_customer_wants_to_cancel_over_time_promise':
      return 'Order taking too long, over promise time'
    case 'oos_item':
      return 'OOS item'
    case 'no_riders':
      return 'No riders'
    case 'rider_accident':
      return 'Rider accident'
    case 'outside_of_polygons':
      return 'Destination outside polygons'
    case 'customer_gave_wrong_address':
      return 'Customer gave wrong address'
    case 'order_cannot_be_delivered_customer_unresponsive':
      return 'Order cannot be delivered, customer unresponsive'
    case 'order_placed_by_mistake_wants_to_add_or_remove_items':
      return 'Order placed by mistake, customer wants to add or remove items'
    case 'adyen_requested':
      return 'Adyen requested cancellation'
    case 'challenge_25_fail':
      return 'Challenge 25 fail'
    case 'store_closure':
      return 'Store closure'
    case 'weather_conditions':
      return 'Weather conditions'
    case 'order_duplicated':
      return 'Order duplicated'
    case 'third_party_requested':
      return 'Third party requested'
    default:
      return 'Other'
  }
}
