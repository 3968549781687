import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Box, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { SendMessageModal } from '../SendMessageModal/SendMessageModal'

export const SendMessageAction = () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <Box>
      <IconButton color="primary" onClick={() => setShowModal(true)}>
        <AddCircleIcon fontSize="large" />
      </IconButton>
      <SendMessageModal showModal={showModal} setShowModal={setShowModal} />
    </Box>
  )
}
