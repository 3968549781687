import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { WidgetPanel } from '../WidgetPanel/WidgetPanel'

interface Props {
  title: string
}

export const NoDataAvailable: React.FC<Props> = ({ title }) => (
  <WidgetPanel title={title}>
    <Stack direction="row" justifyContent="center" alignItems="center" height="100%" spacing={1}>
      <InfoOutlinedIcon fontSize="large" />
      <Typography>No data available</Typography>
    </Stack>
  </WidgetPanel>
)
