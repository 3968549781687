import { WarehouseStats } from '@quickcommerceltd/zappboard'
import { collection, query, where } from 'firebase/firestore'
import { useMemo } from 'react'
import { useCollectionData, useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { db } from '../../firebase'
import { GenericDataConverter } from '../../firebase/genericDataConverter/genericDataConverter'

const warehouseStatsConverter = new GenericDataConverter<WarehouseStats>()

interface Props {
  warehouseIds?: string[] | null
  once?: boolean
}

export const useWarehouseStats = ({ warehouseIds = [], once }: Props = {}) => {
  const useCollection = useMemo(() => {
    return once ? useCollectionDataOnce : useCollectionData
  }, [once])

  return useCollection<WarehouseStats>(
    query(
      collection(db, 'warehouseStats'),
      ...(warehouseIds?.length ? [where('id', 'in', warehouseIds)] : [])
    ).withConverter(warehouseStatsConverter)
  )
}
