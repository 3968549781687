import { orange, red } from '@mui/material/colors'
import cx from 'classnames'
import { Duration } from 'luxon'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  root: {
    fontSize: '.95em',
  },
  warn: {
    color: orange[500],
    fontWeight: 600,
  },
  danger: {
    color: red[500],
    fontWeight: 700,
  },
}))

const FormatDuration = React.memo(
  ({
    from,
    to,
    warnAfter,
    dangerAfter,
    dontshow,
  }: {
    from?: number
    to: number
    warnAfter?: number
    dangerAfter?: number
    dontshow?: boolean
  }) => {
    const { classes } = useStyles()
    if (dontshow || !from || !to || to < from) {
      return <span>-</span>
    }

    const value = to - from
    const duration = Duration.fromMillis(value).toFormat('hh:mm:ss')

    return (
      <span
        className={cx(classes.root, {
          [classes.warn]: warnAfter && value > warnAfter,
          [classes.danger]: dangerAfter && value > dangerAfter,
        })}
      >
        {duration}
      </span>
    )
  }
)

export default FormatDuration
