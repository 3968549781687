import { Button, CircularProgress, Stack, styled, Typography } from '@mui/material'
import React, { FC, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { TileHandingOver } from '../../components/TileHandingOver'
import { TileLowStockT0Skus } from '../../components/TileLowStockT0Skus'
import { TileOrderFunnel } from '../../components/TileOrderFunnel'
import { TileOrderList } from '../../components/TileOrderList'
import { TilePickingAndPackingTime } from '../../components/TilePickingAndPackingTime'
import { TilePickupAndHandoffTime } from '../../components/TilePickupAndHandoffTime'
import { TileUnassignedOrdersAndPickerLevels } from '../../components/TileUnassignedOrdersAndPickerLevels'
import { WarehouseSelector } from '../../components/WarehouseSelector/WarehouseSelector'
import { useCommonWarehouseStatsV2 } from '../../hooks/useCommonWarehouseStatsV2/useCommonWarehouseStatsV2'
import { useStoresToHide } from '../../hooks/useStoresToHide'
import { useStoreViewData } from '../../hooks/useStoreViewData/useStoreViewData'
import { useWarehouse } from '../../hooks/useWarehouse/useWarehouse'
import { Layout } from '../../layout'
import { sessionState } from '../../state'
import { filterStatsByWarehouse } from '../../utils/warehouseStatsV2/filterStatsByWarehouse'
import { getPickerLevels } from '../../utils/warehouseStatsV2/getPickerLevels'
import { getPickingMetrics } from '../../utils/warehouseStatsV2/getPickingMetrics'
import { getPickUpMetrics } from '../../utils/warehouseStatsV2/getPickUpMetrics'
import { getStoreViewMetrics } from '../../utils/warehouseStatsV2/getStoreViewMetrics'
import { WarehouseStatusIndicator } from '../Home/components/WarehouseStatusIndicator'
import routes from '../routes'
import { withAuthentication } from '../withAuthentication'

const StorePage: FC = () => {
  const navigate = useNavigate()
  const [session] = useRecoilState(sessionState)
  const { id: warehouseId } = useParams<keyof { id: string }>() as { id: string }
  const [warehouse, isLoadingWarehouse, warehouseError] = useWarehouse({ warehouseId })
  const storesToHide = useStoresToHide()

  const orders = useStoreViewData(warehouseId)
  const {
    latestAcc,
    errorLatest,
    loadingLatest,
    lastWeekSameTimeAcc,
    lastWeekStatsSameTimeError,
    lastWeekStatsSameTimeLoading,
  } = useCommonWarehouseStatsV2(1)

  const filteredWarehouseStats = useMemo(
    () => filterStatsByWarehouse(latestAcc, storesToHide, [warehouseId]),
    [latestAcc, warehouseId]
  )
  const filteredWarehouseStatsLastWeek = useMemo(
    () => filterStatsByWarehouse(lastWeekSameTimeAcc, storesToHide, [warehouseId]),
    [lastWeekSameTimeAcc, warehouseId]
  )

  const pickingMetrics = useMemo(
    () => getPickingMetrics(filteredWarehouseStats, session.originFilter),
    [filteredWarehouseStats, session.originFilter]
  )
  const pickupMetrics = useMemo(
    () => getPickUpMetrics(filteredWarehouseStats, session.originFilter),
    [filteredWarehouseStats, session.originFilter]
  )
  const pickingMetricsLastWeek = useMemo(
    () => getPickingMetrics(filteredWarehouseStatsLastWeek, session.originFilter),
    [filteredWarehouseStatsLastWeek, session.originFilter]
  )
  const pickupMetricsLastWeek = useMemo(
    () => getPickUpMetrics(filteredWarehouseStatsLastWeek, session.originFilter),
    [filteredWarehouseStatsLastWeek, session.originFilter]
  )
  const storeMetrics = useMemo(() => getStoreViewMetrics(orders), [orders])
  const pickerLevelMetrics = useMemo(() => getPickerLevels(filteredWarehouseStats?.[0]), [filteredWarehouseStats])

  const warehouseStatistics = useMemo(() => {
    if (!warehouseId || !filteredWarehouseStats?.[0]?.statsByWarehouse) return undefined
    return filteredWarehouseStats?.[0]?.statsByWarehouse?.[warehouseId]
  }, [warehouseId, filteredWarehouseStats])

  const onWarehouseChange = useCallback(
    (warehouseId: string) => {
      if (!warehouseId) return
      navigate(routes.store.replace(':id', warehouseId))
    },
    [navigate]
  )

  const isLoading = !warehouse || isLoadingWarehouse || loadingLatest || lastWeekStatsSameTimeLoading
  const error = warehouseError || errorLatest || lastWeekStatsSameTimeError

  if (error) {
    return (
      <Layout>
        <Stack width="inherit" height="inherit" justifyContent="center" alignItems="center">
          {error && <Typography variant="h2">Error: {error.message}</Typography>}
        </Stack>
      </Layout>
    )
  }

  return (
    <Layout>
      {/* Headline */}
      <Stack direction="row" mb={2} spacing={2}>
        <WarehouseSelector warehouseId={warehouseId} onChange={onWarehouseChange} />
        {!isLoading && <WarehouseStatusIndicator warehouse={warehouse} />}
        {isLoading && <CircularProgress />}
        <Button variant="outlined" onClick={() => navigate(routes.storeExternal.replace(':id', warehouseId))}>
          External Rider View
        </Button>
      </Stack>

      <Stack position="relative" direction={{ xs: 'column', md: 'row' }} flexGrow={1} spacing={2}>
        {/* Left Column */}
        <Stack width="100%" spacing={2} flex="1 1 50%">
          <StyledPlaceholder height={250}>
            <TileOrderFunnel
              columns={[
                {
                  label: 'Waiting',
                  value: storeMetrics?.waiting,
                  warningAt: 3,
                  dangerAt: 5,
                },
                {
                  label: 'Picking',
                  value: storeMetrics?.picking,
                  warningAt: 5,
                  dangerAt: 10,
                },
                {
                  label: 'Packing',
                  value: storeMetrics?.packing,
                  warningAt: 3,
                  dangerAt: 5,
                },
                { label: 'Racking', value: storeMetrics?.racking, warningAt: 5, dangerAt: 10 },
              ]}
            />
          </StyledPlaceholder>

          <Stack direction={{ xs: 'column', md: 'row' }} width="inherit" spacing={2} useFlexGap>
            <StyledPlaceholder height={350}>
              <TilePickingAndPackingTime
                pickingStatistics={pickingMetrics?.[0]}
                pickingStatisticsPast={pickingMetricsLastWeek?.[0]}
              />
            </StyledPlaceholder>

            <StyledPlaceholder height={350}>
              <TilePickupAndHandoffTime
                deliveryStatistics={pickupMetrics?.[0]}
                deliveryStatisticsPast={pickupMetricsLastWeek?.[0]}
              />
            </StyledPlaceholder>
          </Stack>

          <Stack direction={{ xs: 'column', md: 'row' }} width="inherit" spacing={2}>
            <StyledPlaceholder height={350}>
              <TileUnassignedOrdersAndPickerLevels
                unassignedOrders={storeMetrics?.unassigned}
                pendingOrders={storeMetrics?.pending}
                pickerLevels={[pickerLevelMetrics?.[0]?.activePickers, pickerLevelMetrics?.[0]?.expectedPickers]}
              />
            </StyledPlaceholder>

            <StyledPlaceholder height={350}>
              <TileLowStockT0Skus tier0LowStock={warehouseStatistics?.tier0LowStock} />
            </StyledPlaceholder>
          </Stack>
        </Stack>

        {/* Right Column */}
        <Stack width="100%" flex="1 1 50%" height="100%" bgcolor="background.paper" overflow="hidden">
          <StyledPlaceholder height={200}>
            <TileHandingOver warehouseId={warehouseId} />
          </StyledPlaceholder>
          <Stack height={782}>
            <TileOrderList warehouseId={warehouseId} />
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  )
}

const StyledPlaceholder = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 4,
  background: theme.palette.background.paper,
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
}))

export default withAuthentication(React.memo(StorePage))
