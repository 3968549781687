import { SupportInquiryCode } from '@quickcommerceltd/zappboard'

export const getReasonCodeTranslation = (reasonCode: SupportInquiryCode) => {
  switch (true) {
    case reasonCode === SupportInquiryCode.CANCEL_ORDER:
      return 'Cancel Order'
    case reasonCode === SupportInquiryCode.DELIVERY_DELAY:
      return 'Delivery Delay'
    case reasonCode === SupportInquiryCode.DELIVERY_FAILED:
      return 'Delivery Failed'
    case reasonCode === SupportInquiryCode.ITEM_OOS:
      return 'Item Out of Stock'
    default:
      return 'Other'
  }
}
