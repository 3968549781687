import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { MinMaxWarehouseMetric } from '../../types/MinMaxValue'

const TooltipContent: React.FC<{ minMax: MinMaxWarehouseMetric }> = ({ minMax }) => (
  <Box
    sx={{
      backgroundColor: '#E5F8FF',
      m: 0,
      p: 0.5,
    }}
  >
    <Typography sx={{ fontSize: '14px', lineHeight: '19px', color: 'divider', fontWeight: 600 }}>
      {minMax.max.warehouse}: {minMax.max.value}
    </Typography>
    <Typography sx={{ fontSize: '14px', lineHeight: '19px', color: 'divider', fontWeight: 600 }}>
      {minMax.min.warehouse}: {minMax.min.value}
    </Typography>
  </Box>
)

export const MinMaxTooltip: React.FC<{ minMax: MinMaxWarehouseMetric; children: React.ReactElement<any, any> }> = ({
  minMax,
  children,
}) => (
  <Box width="fit-content">
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#E5F8FF',
          },
        },
      }}
      title={<TooltipContent minMax={minMax} />}
      placement="right-start"
    >
      {children}
    </Tooltip>
  </Box>
)
