import { Order } from '@quickcommerceltd/zappboard'
import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  Timestamp,
  WithFieldValue,
} from 'firebase/firestore'

export class FirestoreTimestampConverter<T> implements FirestoreDataConverter<T> {
  toFirestore(object: WithFieldValue<T>): DocumentData {
    return recursivelyConvertMillisToTimestamps(object) as DocumentData
  }

  fromFirestore(snapshot: QueryDocumentSnapshot) {
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      exists: snapshot.exists,
      ...(recursivelyConvertTimestampsToMillis(snapshot.data()) as T),
    }
  }
}

export const orderTimestampConverter = new FirestoreTimestampConverter<Order>()

const recursivelyConvertTimestampsToMillis = function (value: unknown): unknown {
  if (Array.isArray(value)) {
    return value.map(recursivelyConvertTimestampsToMillis)
  }

  if (typeof value === 'object') {
    return Object.entries(value ?? {}).reduce((acc, [key, v]: [string, unknown]) => {
      const mappedValue = v instanceof Timestamp ? v.toMillis() : recursivelyConvertTimestampsToMillis(v)

      return { ...acc, [key]: mappedValue }
    }, {})
  }

  return value
}

const recursivelyConvertMillisToTimestamps = function (value: unknown): unknown {
  if (Array.isArray(value)) {
    return value.map(recursivelyConvertMillisToTimestamps)
  }

  if (typeof value === 'object') {
    return Object.entries(value ?? {}).reduce((acc, [key, v]: [string, unknown]) => {
      const mappedValue =
        key.endsWith('At') && typeof v === 'number' ? Timestamp.fromMillis(v) : recursivelyConvertMillisToTimestamps(v)

      return { ...acc, [key]: mappedValue }
    }, {})
  }

  return value
}
