import { Typography } from '@mui/material'
import cx from 'classnames'
import { FC } from 'react'
import { makeStyles } from 'tss-react/mui'

export const H1: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <Typography variant="h1" className={className}>
      {children}
    </Typography>
  )
}
export const H2: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <Typography variant="h2" className={className}>
      {children}
    </Typography>
  )
}
export const H3: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <Typography variant="h3" className={className}>
      {children}
    </Typography>
  )
}
export const H4: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <Typography variant="h4" className={className}>
      {children}
    </Typography>
  )
}

export const H5: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <Typography variant="h5" className={className}>
      {children}
    </Typography>
  )
}

export const H6: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <Typography variant="h6" className={className}>
      {children}
    </Typography>
  )
}

export const Text: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <Typography variant="body1" className={className}>
      {children}
    </Typography>
  )
}

const titleStyles = makeStyles()((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: '1em',
    marginBottom: '.5em',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2em',
    },
  },
}))

export const Title: FC<{}> = ({ children }) => {
  const { classes } = titleStyles()

  return (
    <Typography variant="h4" className={classes.root}>
      {children}
    </Typography>
  )
}

const subtitleStyles = makeStyles()((theme) => ({
  root: {
    // color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    opacity: 0.8,
    fontSize: '.8em',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1em',
    },
  },
}))

export const Subtitle: FC<{}> = ({ children }) => {
  const { classes } = subtitleStyles()

  return <Text className={classes.root}>{children}</Text>
}

const descriptionStyles = makeStyles()((theme) => ({
  root: {
    // color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    opacity: 0.8,
    fontSize: '.6em',
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.7em',
    },
  },
}))

export const Description: FC<{}> = ({ children }) => {
  const { classes } = descriptionStyles()

  return <Text className={classes.root}>{children}</Text>
}

const descriptionCenteredStyles = makeStyles()((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    opacity: 0.8,
    textAlign: 'center',
  },
}))

export const DescriptionCentered: FC<{}> = ({ children }) => {
  const { classes } = descriptionCenteredStyles()

  return <Text className={classes.root}>{children}</Text>
}

const numberStyles = makeStyles()(() => ({
  root: {
    lineHeight: '1em',
    marginBottom: '.1em',
    fontWeight: 400,
  },
  tiny: {
    fontSize: '1.5rem',
  },
  small: {
    fontSize: '1.8rem',
  },
  medium: {
    fontSize: '4.2rem',
  },
}))

export const Number: FC<{ size?: string }> = ({ children, size = 'medium' }) => {
  const { classes } = numberStyles()

  return (
    <H4
      className={cx(classes.root, {
        [classes.tiny]: size === 'tiny',
        [classes.small]: size === 'small',
        [classes.medium]: size === 'medium',
      })}
    >
      {children}
    </H4>
  )
}

export const Time: FC<{ size?: string }> = ({ children, size = 'small' }) => {
  const { classes } = numberStyles()

  return (
    <H4
      className={cx(classes.root, {
        [classes.tiny]: size === 'tiny',
        [classes.small]: size === 'small',
      })}
    >
      {children}
    </H4>
  )
}
