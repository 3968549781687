import { alpha } from '@mui/material'
import cx from 'classnames'
import debounce from 'lodash/debounce'
import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { H4 } from '../Text'

const useStyles = makeStyles()((theme) => ({
  root: {
    fontWeight: 400,
    transition: 'all 0.15s ease-in-out',
  },
  highlight: {
    background: alpha(theme.palette.primary.light, 0.5),
    '& > $value': {
      transition: 'all 0s ease-in-out',
      transform: 'scale(1.2)',
    },
  },
  value: {
    display: 'block',
    transformOrigin: 'right',
    transition: 'all 0.15s ease-in-out',
  },
  empty: {
    opacity: 0.5,
  },
  tiny: {
    fontSize: '1rem',
  },
  small: {
    fontSize: '2rem',
  },
  medium: {
    fontSize: '2.2rem',
  },
}))

interface Props {
  size?: string
  className?: string
}

export const ActiveNumber: React.FC<Props> = ({ children, size, className, ...props }) => {
  const { classes } = useStyles()
  const [isChanged, setIsChanged] = useState(false)
  const wasUpdated = useMemo(() => {
    return debounce(() => {
      setIsChanged(false)
    }, 500)
  }, [])

  useEffect(() => {
    setIsChanged(true)
    wasUpdated()
    return () => wasUpdated.cancel()
  }, [children, wasUpdated])

  return (
    <H4
      {...props}
      className={cx(classes.root, className, {
        [classes.highlight]: isChanged,
        [classes.tiny]: size === 'tiny',
        [classes.small]: size === 'small',
        [classes.medium]: size === 'medium',
      })}
    >
      {children}
    </H4>
  )
}

export default ActiveNumber
