import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableSortLabel as MuiTableSortLabel,
  styled,
} from '@mui/material'

export const TableHead = MuiTableHead
export const TableBody = MuiTableBody

export const Table = styled(MuiTable)(({ stickyHeader }) => ({
  ...(stickyHeader && {
    '& thead tr th': {
      backgroundColor: '#404258',
      fontSize: '1em',
      lineHeight: '1.2em',
      fontWeight: 600,
    },
  }),
}))

export const TableContainer = styled(MuiTableContainer)(({ theme }) => ({
  backgroundColor: 'rgba(255,255,255, 0.01)',
  border: `1px solid ${theme.palette.background.paper}`,
  borderRadius: '14px',
}))

export const TableCell = styled(MuiTableCell)(() => ({
  borderBottom: 'solid 1px #404258',
  '&.MuiTableCell-head': {
    padding: '8px 12px',
    fontSize: '0.8em',
  },
  '&.MuiTableCell-body': {
    fontSize: '1em',
    padding: '12px 12px',
  },
}))

export const TableCellCompact = styled(MuiTableCell)(({ theme }) => ({
  borderBottom: 'solid 1px #404258',
  color: 'inherit',

  '&.MuiTableCell-head': {
    padding: '8px 6px',
    fontSize: '.9em',
    '&:first-of-type': { paddingLeft: 8 },
    [theme.breakpoints.up('xl')]: {
      padding: '8px 12px',
      '&:first-of-type': { paddingLeft: 12 },
    },
  },
  '&.MuiTableCell-body': {
    fontSize: '1em',
    padding: '12px 6px',
    '&:first-of-type': { paddingLeft: 8 },
    [theme.breakpoints.up('xl')]: {
      padding: 12,
      '&:first-of-type': { paddingLeft: 12 },
    },
  },
}))

export const TableRow = styled(MuiTableRow)(() => ({
  '&:hover': {
    cursor: 'pointer',
  },
}))

export const HeaderRow = styled(MuiTableRow)(() => ({
  backgroundColor: '#404258',
  '& th': {
    fontSize: '1em',
    lineHeight: '1.2em',
    fontWeight: 600,
  },
}))

export const TableSortLabel = styled(MuiTableSortLabel)(({ theme }) => ({
  '&:hover': {
    color: '#fff',
  },
  '& .MuiTableSortLabel-icon': {
    marginLeft: -8,
    marginRight: -2,
    fill: '#fff',
    [theme.breakpoints.up('xl')]: {
      marginRight: 2,
    },
  },
}))
