import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import { TableRow, Tooltip } from '@mui/material'
import {
  FulfillmentStatus,
  Order,
  OrderDefectType,
  PickingStatus,
  ShippingMethod,
  TaskState,
} from '@quickcommerceltd/zappboard'
import { OrderOrigin } from '@quickcommerceltd/zappboard/lib/types/order-origin'
import cx from 'classnames'
import { DateTime } from 'luxon'
import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { useFeatureFlags, useRemoteConfigs } from '../../../hooks'
import { formatNumberRange } from '../../../utils/formatNumberRange'
import OrderNumber from '../../OrderNumber'
import { Rating } from '../../Rating/Rating'
import { TableCell } from '../../Table'
import { EtaTimestamp } from '../EtaTimestamp'
import { StatusDuration } from '../StatusDuration'

const MINUTE_IN_MS = 60000
const WAITING_WARNING = 1 //minutes
const WAITING_DANGER = 2
const PICKING_WARNING = 3
const PICKING_DANGER = 5
const RIDING_WARNING = 9
const RIDING_DANGER = 10
const RACKTIME_WARNING = 2
const RACKTIME_DANGER = 3
const RACKTIME_ETA_WARNING = 1.2 // racktime > 20% eta
const RACKTIME_ETA_DANGER = 1.3 // racktime > 30% eta

const useStyles = makeStyles()((theme) => ({
  done: { opacity: 0.6 },
  selected: {
    '&:hover': {
      backgroundColor: `${theme.palette.background.default} !important`,
    },
  },
  unassigned: {
    backgroundColor: theme.status.danger.primary,
    '&:hover': {
      backgroundColor: `#8c1b30 !important`,
    },
  },
  unassignedSelected: {
    backgroundColor: `${theme.status.danger.secondary} !important`,
  },
  new_customer_badge: {
    marginRight: '12px',
    padding: '2px 4px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px',
    fontSize: '10px',
    fontWeight: 'bold',
  },
  stackIcon: {
    padding: '0px',
    fontSize: '21px',
    marginBottom: '-5px',
    marginRight: '3px',
  },
  orderDefect: {
    backgroundColor: 'rgba(200, 97, 2, 0.3)',
    '&:hover': {
      backgroundColor: `rgba(153, 77, 6, 0.2) !important`,
    },
  },
  orderDefectSelected: {
    backgroundColor: `rgba(255, 137, 28, 0.2) !important`,
  },
  orderTaskReturn: {
    backgroundColor: 'rgba(2, 186, 255, 0.2)',
    '&:hover': {
      backgroundColor: `rgba(2, 186, 255, 0.3) !important`,
    },
  },
  orderTaskReturnSelected: {
    backgroundColor: `rgba(2, 186, 255, 0.5) !important`,
  },
  riderWaitingAtPickupAlert: {
    backgroundColor: `rgba(54,109,90, 1) !important`,
    '&.Mui-selected': {
      backgroundColor: `rgb(75,162,118, 1) !important`,
    },
  },
}))

interface Props {
  order: Order
  selectedOrderId?: string
  interactive?: boolean
}

export const OrderListItem: FC<Props> = ({ order, selectedOrderId, interactive }) => {
  const navigate = useNavigate()
  const { classes: styles } = useStyles()

  const { isConfirmReturningTaskEnabled, isRiderWaitingAtPickupAlertEnabled } = useFeatureFlags(order.warehouseId, [
    'isConfirmReturningTaskEnabled',
    'isRiderWaitingAtPickupAlertEnabled',
  ])

  const isRiderWaitingAtPickupThresholdMinutes = useRemoteConfigs<number>('isRiderWaitingAtPickupThresholdMinutes') ?? 1
  const now = Date.now()
  const isRiderWaitingAtPickup =
    isRiderWaitingAtPickupAlertEnabled &&
    order.pickingStatus === PickingStatus.PACKED &&
    order.deliveryStatus === TaskState.ASSIGNED &&
    order.deliveryRiderArrivedForPickupAt &&
    now + isRiderWaitingAtPickupThresholdMinutes * MINUTE_IN_MS > order.deliveryRiderArrivedForPickupAt

  const isOrderWithReturningTask = useMemo(() => {
    if (!isConfirmReturningTaskEnabled) return false
    return order.deliveryStatus === TaskState.RETURNING
  }, [order.deliveryStatus, isConfirmReturningTaskEnabled])

  const isSelected = useMemo(() => {
    return selectedOrderId === order.id
  }, [selectedOrderId, order.id])

  const isShowEta =
    order.fulfillmentStatus === FulfillmentStatus.UNFULFILLED &&
    ![TaskState.FAILED, TaskState.COMPLETED, TaskState.RETURNED, TaskState.NOT_RETURNED].includes(
      order.deliveryStatus
    ) &&
    order.rider?.name &&
    !order.rider?.name?.includes('Waiting for')

  const hasOOSDefect = useMemo(() => {
    return !!order.orderDefects?.filter(({ type }) => [OrderDefectType.ITEM_OOS].includes(type)).length
  }, [order.orderDefects])

  return (
    <TableRow
      hover={!!interactive}
      key={order.id}
      selected={isSelected}
      onClick={() => {
        if (interactive) {
          navigate(`?orderId=${order.id}`, { replace: true })
        }
      }}
      className={cx({
        [styles.done]: [FulfillmentStatus.FULFILLED, FulfillmentStatus.CANCELLED].includes(order.fulfillmentStatus),
        [styles.unassigned]:
          order.deliveryStatus === TaskState.UNASSIGNED && order.fulfillmentStatus !== FulfillmentStatus.CANCELLED,
        [styles.unassignedSelected]:
          order.deliveryStatus === TaskState.UNASSIGNED &&
          order.fulfillmentStatus !== FulfillmentStatus.CANCELLED &&
          isSelected,
        [styles.selected]: isSelected,
        [styles.orderDefect]: hasOOSDefect,
        [styles.orderDefectSelected]: hasOOSDefect && isSelected,
        [styles.orderTaskReturn]: isOrderWithReturningTask,
        [styles.orderTaskReturnSelected]: isOrderWithReturningTask && isSelected,
        [styles.riderWaitingAtPickupAlert]: isRiderWaitingAtPickup,
      })}
    >
      <TableCell scope="row" align="right">
        <Tooltip title={order.warehouseId}>
          <div>
            {order.isNewCustomer && <span className={styles.new_customer_badge}>new</span>}
            {order.externalId || <OrderNumber value={order.number} />}
          </div>
        </Tooltip>
      </TableCell>
      <TableCell scope="row" align="center">
        <Tooltip
          title={
            order.createdAt
              ? DateTime.fromMillis(order.createdAt).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
              : '-'
          }
        >
          <div>{order.createdAt ? DateTime.fromMillis(order.createdAt).toFormat('M/d HH:mm') : '—'}</div>
        </Tooltip>
      </TableCell>
      <TableCell scope="row" align="center" title={order.fulfillmentStatus}>
        {order.fulfillmentStatus}
      </TableCell>
      <TableCell scope="row" align="center">
        {!order.deliveryStatus ? (
          order.shippingMethod
        ) : order.deliveryStatus === TaskState.COMPLETED && !!order.customerRating?.rating ? (
          <Rating rating={order.customerRating.rating} note={order.customerRating?.note} size="small" />
        ) : (
          order.deliveryStatus
        )}
      </TableCell>
      <TableCell scope="row" align="center" title={order.pickingStatus}>
        {order.pickingStatus || '—'}
      </TableCell>
      <TableCell>{order.picker?.name || '—'}</TableCell>
      <TableCell>
        {!!order.stackedWithTaskIds?.length && (
          <Tooltip title="Stacked task">
            <LayersOutlinedIcon color="primary" className={styles.stackIcon} />
          </Tooltip>
        )}
        {order.rider?.name || '—'}
        {isShowEta && (
          <EtaTimestamp
            order={order}
            orderDeliveryStatus={order.deliveryStatus}
            orderDeliveryStartedAt={order.deliveryStartedAt}
            orderEstimatedDeliveryAt={order.estimatedDeliveryAt}
            orderEstimatedArrivalAtStoreAt={order.estimatedArrivalAtStoreAt}
            isStacked={!!order.stackedWithTaskIds?.length}
          />
        )}
      </TableCell>
      <TableCell align="center">
        <StatusDuration
          order={order}
          from={order.createdAt}
          to={order.pickingStartedAt}
          warnAfter={WAITING_WARNING * MINUTE_IN_MS}
          dangerAfter={WAITING_DANGER * MINUTE_IN_MS}
        />
      </TableCell>
      <TableCell align="center">
        <StatusDuration
          order={order}
          from={order.pickingStartedAt}
          to={order.pickingEndedAt}
          warnAfter={PICKING_WARNING * MINUTE_IN_MS}
          dangerAfter={PICKING_DANGER * MINUTE_IN_MS}
        />
      </TableCell>
      <TableCell align="center">
        <StatusDuration
          order={order}
          from={order.packingStartedAt}
          to={order.packingEndedAt}
          warnAfter={PICKING_WARNING * MINUTE_IN_MS}
          dangerAfter={PICKING_DANGER * MINUTE_IN_MS}
        />
      </TableCell>
      <TableCell align="center">
        <StatusDuration
          dontshow={
            order.shippingMethod === ShippingMethod.PICKUP ||
            ![OrderOrigin.ZAPP, OrderOrigin.UBER, undefined].includes(order.orderOrigin)
          }
          order={order}
          from={order.packingEndedAt}
          to={order.deliveryPickedUpAt ?? order.deliveryStartedAt}
          warnAfter={
            order.estimatedArrivalAtStoreAt && order.packingEndedAt
              ? Math.ceil(RACKTIME_ETA_WARNING * (order.estimatedArrivalAtStoreAt - order.packingEndedAt))
              : RACKTIME_WARNING * MINUTE_IN_MS
          }
          dangerAfter={
            order.estimatedArrivalAtStoreAt && order.packingEndedAt
              ? Math.ceil(RACKTIME_ETA_DANGER * (order.estimatedArrivalAtStoreAt - order.packingEndedAt))
              : RACKTIME_DANGER * MINUTE_IN_MS
          }
        />
      </TableCell>
      <TableCell align="center">
        <StatusDuration
          dontshow={
            order.shippingMethod === ShippingMethod.PICKUP ||
            ![OrderOrigin.ZAPP, OrderOrigin.UBER, undefined].includes(order.orderOrigin) ||
            [TaskState.NOT_RETURNED, TaskState.RETURNED, TaskState.FAILED].includes(order.deliveryStatus)
          }
          order={order}
          from={order.deliveryStartedAt}
          to={order.deliveredAt}
          warnAfter={RIDING_WARNING * MINUTE_IN_MS}
          dangerAfter={RIDING_DANGER * MINUTE_IN_MS}
        />
      </TableCell>
      <TableCell align="center">
        <StatusDuration
          dontshow={order.shippingMethod === ShippingMethod.PICKUP}
          order={order}
          from={order.createdAt}
          to={order.fulfilledAt}
          warnAfter={0.9 * order.promisedDeliveryTime + 10 * MINUTE_IN_MS}
          dangerAfter={order.promisedDeliveryTime + 10 * MINUTE_IN_MS}
        />
      </TableCell>
      <TableCell align="center">
        {order.externalId
          ? '-'
          : formatNumberRange(
              order.promisedDeliveryTime / MINUTE_IN_MS,
              order.promisedDeliveryTime / MINUTE_IN_MS + 10,
              '-',
              'min'
            )}
      </TableCell>
    </TableRow>
  )
}
