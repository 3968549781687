import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'

const findIndexOfDocument = (doc: QueryDocumentSnapshot, items: DocumentData[]): number =>
  items.findIndex((item) => {
    return item.id === doc.id
  })

export const updateItem = (doc: QueryDocumentSnapshot, items: DocumentData[]): void => {
  const i = findIndexOfDocument(doc, items)
  items[i] = doc
}

export const deleteItem = (doc: QueryDocumentSnapshot, items: DocumentData[]): void => {
  const i = findIndexOfDocument(doc, items)
  items.splice(i, 1)
}

export const addItem = (doc: QueryDocumentSnapshot, items: DocumentData[], method: 'append' | 'prepend'): void => {
  const i = findIndexOfDocument(doc, items)
  if (i >= 0) return

  if (method === 'append') {
    items.push(doc)
  }

  if (method === 'prepend') {
    items.unshift(doc)
  }
}
