import { PickingStatsV2, WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { OrderOrigin } from '@quickcommerceltd/zappboard/lib/types/order-origin'
import { ChartData } from '../../types/ChartData'
import { MinMaxWarehouseMetric } from '../../types/MinMaxValue'
import { PickingMetrics } from '../../types/PickingMetrics'
import { filterStatsByKey } from './filterStatsByKey'
import { getCommonTimeData, getStatsIdToLocalTime } from './getCommonTimeData'
import { getMillisToMinutes } from './getMillisToMinutes'
import { getSafeAverage } from './getSafeAverage'
import { millisFormatter } from './minutesFormatter'

interface ReducerStats {
  avgWaitingTimeMs: number
  avgPickingTimeMs: number
  avgPackingTimeMs: number
  avgRackTimeMs: number
  avgTimeInWarehouseMs: number
  waitingOrdersNum: number
  pickingOrdersNum: number
  packingOrdersNum: number
  packedOrdersNum: number
  rackedOrdersNum: number
}

const defaultStats: ReducerStats = {
  avgWaitingTimeMs: 0,
  avgPickingTimeMs: 0,
  avgPackingTimeMs: 0,
  avgRackTimeMs: 0,
  avgTimeInWarehouseMs: 0,
  waitingOrdersNum: 0,
  pickingOrdersNum: 0,
  packingOrdersNum: 0,
  packedOrdersNum: 0,
  rackedOrdersNum: 0,
}

const marketplace = [OrderOrigin.DELIVEROO, OrderOrigin.UBER, OrderOrigin.JET] as string[]

const avgPickAcrossAllOrigins = (pickStats: PickingStatsV2[]) => {
  const result = pickStats.reduce(
    (acc, curr) => ({
      avgPickingTimeMs: acc.avgPickingTimeMs + curr.avgPickingTimeMs,
      avgWaitingTimeMs: acc.avgWaitingTimeMs + curr.avgWaitingTimeMs,
      avgPackingTimeMs: acc.avgPackingTimeMs + curr.avgPackingTimeMs,
      avgRackTimeMs: acc.avgRackTimeMs + curr.avgRackTimeMs,
      avgTimeInWarehouseMs: acc.avgTimeInWarehouseMs + curr.avgTimeInWarehouseMs,
      waitingOrdersNum: acc.waitingOrdersNum + curr.waitingOrdersNum,
      pickingOrdersNum: acc.pickingOrdersNum + curr.pickingOrdersNum,
      packingOrdersNum: acc.packingOrdersNum + curr.packingOrdersNum,
      packedOrdersNum: acc.packedOrdersNum + curr.packedOrdersNum,
      rackedOrdersNum: acc.rackedOrdersNum + curr.rackedOrdersNum,
    }),
    { ...defaultStats }
  )

  result.avgPickingTimeMs = getSafeAverage(result.avgPickingTimeMs, pickStats.length)
  result.avgWaitingTimeMs = getSafeAverage(result.avgWaitingTimeMs, pickStats.length)
  result.avgPackingTimeMs = getSafeAverage(result.avgPackingTimeMs, pickStats.length)
  result.avgRackTimeMs = getSafeAverage(result.avgRackTimeMs, pickStats.length)
  result.avgTimeInWarehouseMs = getSafeAverage(result.avgTimeInWarehouseMs, pickStats.length)

  return result
}

const pickingStatsReducer = (acc: PickingStatsV2, curr: PickingStatsV2) => ({
  avgPickingTimeMs: acc.avgPickingTimeMs + curr.avgPickingTimeMs,
  avgWaitingTimeMs: acc.avgWaitingTimeMs + curr.avgWaitingTimeMs,
  avgPackingTimeMs: acc.avgPackingTimeMs + curr.avgPackingTimeMs,
  avgRackTimeMs: acc.avgRackTimeMs + curr.avgRackTimeMs,
  avgTimeInWarehouseMs: acc.avgTimeInWarehouseMs + curr.avgTimeInWarehouseMs,
  waitingOrdersNum: acc.waitingOrdersNum + curr.waitingOrdersNum,
  pickingOrdersNum: acc.pickingOrdersNum + curr.pickingOrdersNum,
  packingOrdersNum: acc.packingOrdersNum + curr.packingOrdersNum,
  packedOrdersNum: acc.packedOrdersNum + curr.packedOrdersNum,
  rackedOrdersNum: acc.rackedOrdersNum + curr.rackedOrdersNum,
})

export const getPickingMetrics = (stats?: WarehouseStatsV2[], originFilter: string[] = []): PickingMetrics[] => {
  if (!stats?.length) return []

  return stats
    ?.map((stat) => {
      const warehouses = Object.values(stat.statsByWarehouse)
      const pickMetricsAllWarehouse = warehouses
        .map((warehouse) => avgPickAcrossAllOrigins(filterStatsByKey<PickingStatsV2>(warehouse.picking, originFilter)))
        .reduce(pickingStatsReducer, { ...defaultStats })

      const pickMetricsWithoutMarketplace = warehouses
        .map((warehouse) =>
          avgPickAcrossAllOrigins(
            filterStatsByKey<PickingStatsV2>(
              warehouse.picking,
              originFilter.filter((origin) => !marketplace.includes(origin))
            )
          )
        )
        .reduce(pickingStatsReducer, { ...defaultStats })

      pickMetricsAllWarehouse.avgPickingTimeMs = getSafeAverage(
        pickMetricsAllWarehouse.avgPickingTimeMs,
        warehouses.length
      )
      pickMetricsAllWarehouse.avgWaitingTimeMs = getSafeAverage(
        pickMetricsAllWarehouse.avgWaitingTimeMs,
        warehouses.length
      )
      pickMetricsAllWarehouse.avgPackingTimeMs = getSafeAverage(
        pickMetricsAllWarehouse.avgPackingTimeMs,
        warehouses.length
      )
      pickMetricsWithoutMarketplace.avgRackTimeMs = getSafeAverage(
        pickMetricsWithoutMarketplace.avgRackTimeMs,
        warehouses.length
      )
      pickMetricsWithoutMarketplace.avgTimeInWarehouseMs = getSafeAverage(
        pickMetricsWithoutMarketplace.avgTimeInWarehouseMs,
        warehouses.length
      )

      const pickMetricsResult = {
        ...pickMetricsAllWarehouse,
        avgRackTimeMs: pickMetricsWithoutMarketplace.avgRackTimeMs,
        avgTimeInWarehouseMs: pickMetricsWithoutMarketplace.avgTimeInWarehouseMs,
      }

      const localTime = getStatsIdToLocalTime(stat.id)
      return {
        pickMetrics: pickMetricsResult,
        ...getCommonTimeData(localTime),
        sortId: localTime.toMillis(),
      }
    })
    .sort((a, b) => a.sortId - b.sortId)
}

export const getHourlyPickingTimeChartData = (metrics: PickingMetrics[]): ChartData[] =>
  metrics.map((metric, idx) => ({
    chartData: {
      x: idx === metrics.length - 1 ? 'Now' : metric.minute,
      y: getMillisToMinutes(metric.pickMetrics.avgPickingTimeMs),
      time: metric.time,
    },
    tooltip: `Time: ${metric.time}`,
  }))

export const getHourlyPackingTimeChartData = (metrics: PickingMetrics[]): ChartData[] =>
  metrics.map((metric, idx) => ({
    chartData: {
      x: idx === metrics.length - 1 ? 'Now' : metric.minute,
      y: getMillisToMinutes(metric.pickMetrics.avgPackingTimeMs),
      time: metric.time,
    },
    tooltip: `Time: ${metric.time}`,
  }))

export const getHourlyRackTimeChartData = (metrics: PickingMetrics[]): ChartData[] =>
  metrics.map((metric, idx) => ({
    chartData: {
      x: idx === metrics.length - 1 ? 'Now' : metric.minute,
      y: getMillisToMinutes(metric.pickMetrics.avgRackTimeMs),
      time: metric.time,
    },
    tooltip: `Time: ${metric.time}`,
  }))

export const getPickingMetricsMinMax = (
  warehouseStats?: WarehouseStatsV2,
  originFilter: string[] = []
): {
  avgPackingTime: MinMaxWarehouseMetric
  avgPickingTime: MinMaxWarehouseMetric
  avgRackingTime: MinMaxWarehouseMetric
} | null => {
  if (!warehouseStats) return null

  const warehouses = Object.values(warehouseStats.statsByWarehouse)
  const pickMetricsAllWarehouse = warehouses
    .map((warehouse) => ({
      avgPickAcrossAllOrigins: avgPickAcrossAllOrigins(
        filterStatsByKey<PickingStatsV2>(warehouse.picking, originFilter)
      ),
      warehouse: warehouse.warehouse.shortName,
    }))
    .reduce((acc, curr) => {
      if (!(curr.warehouse in acc)) {
        acc[curr.warehouse] = { ...defaultStats, warehouse: curr.warehouse }
      }
      acc[curr.warehouse] = {
        ...pickingStatsReducer(acc[curr.warehouse], curr.avgPickAcrossAllOrigins),
        warehouse: curr.warehouse,
      }

      return acc
    }, {} as Record<string, ReducerStats & { warehouse: string }>)

  const pickMetricsAllWarehouseNoMarketplace = warehouses
    .map((warehouse) => ({
      avgPickAcrossAllOrigins: avgPickAcrossAllOrigins(
        filterStatsByKey<PickingStatsV2>(
          warehouse.picking,
          originFilter.filter((origin) => !marketplace.includes(origin))
        )
      ),
      warehouse: warehouse.warehouse.shortName,
    }))
    .reduce((acc, curr) => {
      if (!(curr.warehouse in acc)) {
        acc[curr.warehouse] = { ...defaultStats, warehouse: curr.warehouse }
      }
      acc[curr.warehouse] = {
        ...pickingStatsReducer(acc[curr.warehouse], curr.avgPickAcrossAllOrigins),
        warehouse: curr.warehouse,
      }

      return acc
    }, {} as Record<string, ReducerStats & { warehouse: string }>)

  const pickUpMetricValues = Object.values(pickMetricsAllWarehouse)
  const pickUpMetricNoMarketplaceValues = Object.values(pickMetricsAllWarehouseNoMarketplace)

  if (!pickUpMetricValues.length) return null

  const sortedPickingTime = pickUpMetricValues.sort((a, b) => a.avgPickingTimeMs - b.avgPickingTimeMs)
  const sortedPackingTime = pickUpMetricValues.sort((a, b) => a.avgPackingTimeMs - b.avgPackingTimeMs)
  const sortedRackingTime = pickUpMetricNoMarketplaceValues.sort((a, b) => a.avgRackTimeMs - b.avgRackTimeMs)

  return {
    avgPickingTime: {
      min: {
        value: millisFormatter(sortedPickingTime[0].avgPickingTimeMs),
        warehouse: sortedPickingTime[0].warehouse,
      },
      max: {
        value: millisFormatter(sortedPickingTime[sortedPickingTime.length - 1].avgPickingTimeMs),
        warehouse: sortedPickingTime[sortedPickingTime.length - 1].warehouse,
      },
    },
    avgPackingTime: {
      min: {
        value: millisFormatter(sortedPackingTime[0].avgPackingTimeMs),
        warehouse: sortedPackingTime[0].warehouse,
      },
      max: {
        value: millisFormatter(sortedPackingTime[sortedPackingTime.length - 1].avgPackingTimeMs),
        warehouse: sortedPackingTime[sortedPackingTime.length - 1].warehouse,
      },
    },
    avgRackingTime: {
      min: {
        value: millisFormatter(sortedRackingTime[0].avgRackTimeMs),
        warehouse: sortedRackingTime[0].warehouse,
      },
      max: {
        value: millisFormatter(sortedRackingTime[sortedRackingTime.length - 1].avgRackTimeMs),
        warehouse: sortedRackingTime[sortedRackingTime.length - 1].warehouse,
      },
    },
  }
}
