import { Box } from '@mui/material'
import { GoogleAuthProvider } from 'firebase/auth'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import { useEffect } from 'react'
import AppBar from '../components/AppBar'
import Wrapper from '../components/Wrapper'
import { auth } from '../firebase'
import routes from './routes'

const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth) //

const Signin = () => {
  useEffect(() => {
    ui.start('#firebaseui-auth-container', {
      // Firebase UI config options
      signInSuccessUrl: '/',
      signInOptions: [GoogleAuthProvider.PROVIDER_ID],
    })
  }, [])

  return (
    <>
      <AppBar title="Signin" backTo={routes.home} />
      <Wrapper>
        {/**
         * firebaseui hooks into the following div to display
         * the google signin
         */}
        <Box id="firebaseui-auth-container" marginTop={4} />
      </Wrapper>
    </>
  )
}

export default Signin
