import { RevenueStatsV2, WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { ChartData } from '../../types/ChartData'
import { MinMaxWarehouseMetric } from '../../types/MinMaxValue'
import { RevenueBreakdown, RevenueMetrics } from '../../types/RevenueMetrics'
import { shortCurrencyFmt } from '../formatCurrencyShort'
import { filterStatsByKey } from './filterStatsByKey'
import { getCommonTimeData, getStatsIdToLocalTime } from './getCommonTimeData'

export const getRevenueTotal = (warehouseStats?: WarehouseStatsV2, originFilter: string[] = []) => {
  if (!warehouseStats) return null

  const warehouses = Object.values(warehouseStats.statsByWarehouse)
  return warehouses
    .map((stat) => {
      const filteredOrders = filterStatsByKey<RevenueStatsV2>(stat.revenue, originFilter)
      return filteredOrders.reduce(
        (acc, current) => {
          acc.total += current.total
          acc.projectedTotal += current.projectedTotal
          return acc
        },
        { total: 0, projectedTotal: 0 }
      )
    })
    .reduce(
      (acc, curr) => {
        acc.total += curr.total
        acc.projectedTotal += curr.projectedTotal
        return acc
      },
      { total: 0, projectedTotal: 0 }
    )
}

export const getRevenueMetrics = (stats?: WarehouseStatsV2[], originFilter: string[] = []): RevenueMetrics[] => {
  if (!stats?.length) return []

  return stats
    ?.map((stat) => {
      const warehouses = Object.values(stat.statsByWarehouse)

      const revenueAllWarehouse = warehouses.map((stat) => {
        const filteredOrderStats = filterStatsByKey<RevenueStatsV2>(stat.revenue, originFilter)
        return filteredOrderStats.reduce((acc, current) => acc + (current.total ?? 0), 0)
      })

      const allWarehouseTotal = revenueAllWarehouse.reduce((acc, current) => acc + current, 0)
      const localTime = getStatsIdToLocalTime(stat.id)

      return {
        revenue: allWarehouseTotal,
        ...getCommonTimeData(localTime),
        sortId: localTime.toMillis(),
      }
    })
    .sort((a, b) => a.sortId - b.sortId)
}

export const getRevenueBreakdown = (
  warehouseStats?: WarehouseStatsV2,
  originFilter: string[] = []
): RevenueBreakdown[] => {
  if (!warehouseStats) return []

  const warehouses = Object.values(warehouseStats.statsByWarehouse)
  return warehouses.map((stat) => {
    const filteredOrders = filterStatsByKey<RevenueStatsV2>(stat.revenue, originFilter)
    return filteredOrders.reduce(
      (acc, current) => {
        acc.total += current.total
        acc.projectedTotal += current.projectedTotal
        return acc
      },
      { total: 0, projectedTotal: 0, warehouse: stat.warehouse.shortName } as RevenueBreakdown
    )
  }) as RevenueBreakdown[]
}

export const getRevenueHourlyChartData = (metrics: RevenueMetrics[]): ChartData[] =>
  metrics.map((metric, idx) => ({
    chartData: {
      x: idx === metrics.length - 1 ? 'Now' : metric.minute,
      y: metric.revenue,
      time: metric.time,
    },
    tooltip: `Time: ${metric.time}`,
  }))

export const getRevenueMinMax = (
  warehouseStats?: WarehouseStatsV2,
  originFilter: string[] = []
): { actual: MinMaxWarehouseMetric; projected: MinMaxWarehouseMetric } | null => {
  if (!warehouseStats) return null

  const warehouses = Object.values(warehouseStats.statsByWarehouse)
  const revenueAllWarehouse = warehouses.map((stat) => {
    const filteredOrderStats = filterStatsByKey<RevenueStatsV2>(stat.revenue, originFilter)
    return filteredOrderStats.reduce(
      (acc, current) => ({
        total: acc.total + (current.total ?? 0),
        projectedTotal: acc.projectedTotal + (current.projectedTotal ?? 0),
        warehouse: stat.warehouse.shortName,
      }),
      { total: 0, projectedTotal: 0, warehouse: '' } as RevenueBreakdown
    ) as RevenueBreakdown
  })

  const revenueValues = Object.values(revenueAllWarehouse)
  const sortedActual = revenueValues.sort((a, b) => a.total - b.total)
  const sortedProjected = revenueValues.sort((a, b) => a.projectedTotal - b.projectedTotal)

  if (!sortedProjected.length) return null

  return {
    actual: {
      min: {
        value: shortCurrencyFmt.format(sortedActual[0].total),
        warehouse: sortedActual[0].warehouse,
      },
      max: {
        value: shortCurrencyFmt.format(sortedActual[sortedActual.length - 1].total),
        warehouse: sortedActual[sortedActual.length - 1].warehouse,
      },
    },
    projected: {
      min: {
        value: shortCurrencyFmt.format(sortedProjected[0].projectedTotal),
        warehouse: sortedProjected[0].warehouse,
      },
      max: {
        value: shortCurrencyFmt.format(sortedProjected[sortedProjected.length - 1].projectedTotal),
        warehouse: sortedProjected[sortedProjected.length - 1].warehouse,
      },
    },
  }
}
