import { WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { doc } from 'firebase/firestore'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { db } from '../../firebase'
import { GenericDataConverter } from '../../firebase/genericDataConverter/genericDataConverter'

const warehouseStatsConverter = new GenericDataConverter<WarehouseStatsV2>()

export const useWarehouseStatsLatestV2 = () => {
  return useDocumentData<WarehouseStatsV2>(doc(db, 'statistics', 'latest').withConverter(warehouseStatsConverter))
}
