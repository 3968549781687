import React from 'react'
import { Line } from 'react-chartjs-2'
import { MinMaxWarehouseMetric } from '../../types/MinMaxValue'
import { TimeChartData } from '../../types/TimeChartData'
import { formatPercentString } from '../../utils/formatPercentString'
import { ChartHeadingComparisonText } from '../ChartHeadingComparisonText/ChartHeadingComparisonText'
import { NoDataAvailable } from '../NoDataAvailable/NoDataAvailable'
import { WidgetPanel } from '../WidgetPanel/WidgetPanel'
import { getDoubleLineChartConfig } from './getDoubleLineChartConfig'

interface Props {
  title: string
  dottedLabel: string
  dottedData: TimeChartData[]
  solidLabel: string
  solidData: TimeChartData[]
  percentage?: boolean
  formatter?: (value: number) => string
  accumulatedNow?: TimeChartData
  accumulatedPast?: TimeChartData
  link?: string
  loading?: boolean
  todayTooltip?: MinMaxWarehouseMetric | null
  wowTooltip?: MinMaxWarehouseMetric | null
}
export const DoubleLineChart: React.FC<Props> = ({
  title,
  dottedLabel,
  dottedData,
  solidLabel,
  solidData,
  percentage,
  formatter,
  accumulatedNow,
  accumulatedPast,
  link,
  loading,
  todayTooltip,
  wowTooltip,
}) => {
  const { data, options } = getDoubleLineChartConfig({ dottedLabel, dottedData, solidLabel, solidData })

  if (
    (!solidData.length && !dottedData.length) ||
    (solidData.length &&
      dottedData.length &&
      solidData.every((item) => item.y === 0) &&
      dottedData.every((item) => item.y === 0))
  ) {
    return <NoDataAvailable title={title} />
  }

  const solidLatest = accumulatedNow?.y ?? 0
  const solidDate = accumulatedNow?.x ?? 'N/A'
  const dottedLatest = accumulatedPast?.y ?? 0
  const dottedDate = accumulatedPast?.time ?? 'N/A'

  return (
    <WidgetPanel title={title} link={link} loading={loading}>
      <ChartHeadingComparisonText
        todayValue={
          percentage ? formatPercentString(solidLatest) : formatter ? formatter(solidLatest) : solidLatest.toFixed(2)
        }
        wowValue={
          percentage ? formatPercentString(dottedLatest) : formatter ? formatter(dottedLatest) : dottedLatest.toFixed(2)
        }
        todayDate={solidDate}
        wowDate={dottedDate}
        todayTooltip={todayTooltip}
        wowTooltip={wowTooltip}
      />
      <Line data={data} options={options} height="130px" />
    </WidgetPanel>
  )
}
