import { Order } from '@quickcommerceltd/zappboard'
import isEqual from 'lodash/isEqual'
import uniqBy from 'lodash/uniqBy'
import React, { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { useInView, usePrevious } from '../../hooks'
import { HeaderRow, Table, TableBody, TableCell, TableHead } from '../Table'
import { DEFAULT_ORDER_TABLE_COLUMNS } from './consts/DEFAULT_ORDER_TABLE_COLUMNS'
import { OrderTableRow } from './OrderTableRow'
import { OrderTableUnseenOrdersIndicator } from './OrderTableUnseenOrdersIndicator'
import { OrderTableColumn } from './types/OrderTableColumn'

interface Props {
  orders: Order[]
  columns?: OrderTableColumn[]
  top?: number // sticky header offset
  selectedOrderId?: string
  onSelect?: (order: Order) => void
}

export const OrderTable: FC<Props> = ({
  orders,
  top = 0,
  columns = DEFAULT_ORDER_TABLE_COLUMNS,
  selectedOrderId,
  onSelect,
}) => {
  const latestOrderIdRef = useRef<string>()
  const lastSeenOrderIdRef = useRef<string>()
  const [unseenOrders, setUnseenOrders] = useState<number>(0)
  const [showScrollTop, setShowScrollTop] = useState(false)
  const [inViewRef, { inView }] = useInView({})

  const renderedOrdersMap = useRef(new Map<string, [React.ReactElement, Order]>())
  const prevSelectedOrderId = usePrevious(selectedOrderId)
  const memorizedOrders = uniqBy(orders, 'id').map((order) => {
    let [item, storedOrder] = renderedOrdersMap.current.get(order.id) ?? []

    if (!item || !isEqual(storedOrder, order) || [selectedOrderId, prevSelectedOrderId].includes(order.id)) {
      item = (
        <OrderTableRow
          key={order.id}
          order={order}
          columns={columns}
          selected={selectedOrderId === order.id}
          onClick={onSelect}
        />
      )
      renderedOrdersMap.current.set(order.id, [item, order])
    }

    return item
  })

  useEffect(() => {
    const sortedOrders = [...orders].sort((a, b) => b.createdAt - a.createdAt)
    const latestOrder = sortedOrders[0]

    if (!latestOrder) return

    // on initial load set latest order id
    if (!latestOrderIdRef.current) {
      latestOrderIdRef.current = latestOrder.id
      return
    }

    // update last seen order when in view
    if (inView) {
      lastSeenOrderIdRef.current = latestOrder.id
      setUnseenOrders(0)
      setShowScrollTop(false)
    }

    // show notification on unseen order
    if (!inView && latestOrderIdRef.current !== latestOrder.id) {
      const lastSeenOrderIndex = sortedOrders.findIndex((order) => order.id === lastSeenOrderIdRef.current)
      const unseenOrders = Math.max(0, lastSeenOrderIndex)
      setUnseenOrders(unseenOrders)
      setShowScrollTop(!!unseenOrders)
    }

    // update latest order id
    latestOrderIdRef.current = latestOrder.id
  }, [orders, inView])

  const scrollTop = useCallback(() => {
    if (!inViewRef.current) return
    ;(inViewRef.current as HTMLTableRowElement).scrollIntoView({ block: 'end', behavior: 'smooth' })
    setShowScrollTop(false)
  }, [inViewRef])

  return (
    <Table stickyHeader size="small">
      <TableHead>
        {/* sx needed to show the OrderTableUnseenOrdersIndicator properly*/}
        <HeaderRow sx={{ position: 'sticky', top, zIndex: 3 }}>
          {columns.map(({ id, align, label }) => (
            <TableCell key={id} align={align}>
              {label}
            </TableCell>
          ))}

          {showScrollTop && <OrderTableUnseenOrdersIndicator unseenOrders={unseenOrders} onClick={scrollTop} />}
        </HeaderRow>
      </TableHead>
      <TableBody>
        <tr ref={inViewRef as RefObject<HTMLTableRowElement>} />
        {memorizedOrders.map((order) => order)}
      </TableBody>
    </Table>
  )
}
