import { Order } from '@quickcommerceltd/zappboard'
import React, { useMemo, useRef, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { useLocation, useNavigate } from 'react-router-dom'
import { ErrorAlertV2 } from '../../components/ErrorAlertV2/ErrorAlertV2'
import { NoDataAvailable } from '../../components/NoDataAvailable/NoDataAvailable'
import { OrderListV2 } from '../../components/OrderListV2/OrderListV2'
import { FirebaseFilter } from '../../components/OrderListV2/types/FirebaseFilter'
import { SingleMetricPanel } from '../../components/SingleMetricPanel/SingleMetricPanel'
import { WidgetPanel } from '../../components/WidgetPanel/WidgetPanel'
import { useCommonWarehouseStatsV2 } from '../../hooks/useCommonWarehouseStatsV2/useCommonWarehouseStatsV2'
import { useL2OrderFilters } from '../../hooks/useL2OrderFilters/useL2OrderFilters'
import { ChartData } from '../../types/ChartData'
import { TimeChartData } from '../../types/TimeChartData'
import { formatPercentString } from '../../utils/formatPercentString'
import { getDOTHourlyChartData, getDotMetrics, getDOTMinMax } from '../../utils/warehouseStatsV2/getDotMetrics'
import { PageLayout } from '../PageLayout'
import { PanelLayout } from '../PanelLayout'
import { withAuthentication } from '../withAuthentication'

type ChartLineProps = {
  data: ChartData[]
  label: string
  colour: string
  dashed?: boolean
  hidden?: boolean
}
const getChartLine = ({ data, label, colour, dashed, hidden }: ChartLineProps) => ({
  label,
  hidden,
  data: data.map((d) => d.chartData),
  fill: false,
  borderColor: colour,
  backgroundColor: dashed ? 'rgba(0, 0, 0, 0)' : colour,
  tension: 0.3,
  borderWidth: 2,
  borderDash: dashed ? [5, 5] : undefined,
  borderJoinStyle: 'round' as const,
  borderCapStyle: 'round' as const,
})
const DOT: React.FC = () => {
  const searchQuery = new URLSearchParams(useLocation().search)
  const navigate = useNavigate()
  const [selectedOrderId, setSelectedOrderId] = useState(searchQuery.get('orderId') ?? '')

  const chartRef = useRef<ChartJSOrUndefined<'line', TimeChartData[]> | undefined>()
  const {
    originFilter,
    warehouseFilter,
    latestAcc,
    latestHourly,
    lastWeekHourly,
    errorLatest,
    errorHourly,
    lastWeekError,
    loadingLatest,
    loadingHourly,
    lastWeekLoading,
    lastWeekStatsSameTimeLoading,
    totalManagedWarehouses,
  } = useCommonWarehouseStatsV2(24)

  // generate dot metrics for each time line
  const dotMetricsLatest = useMemo(() => getDotMetrics(latestAcc, originFilter), [latestAcc, originFilter])
  const dotMetricsHourly = useMemo(() => getDotMetrics(latestHourly, originFilter), [latestHourly, originFilter])
  const dotMetricsLastWeekHourly = useMemo(
    () => getDotMetrics(lastWeekHourly, originFilter),
    [lastWeekHourly, originFilter]
  )

  // hourly metrics for charting
  const [dot20Hourly, dot30Hourly, dot45Hourly, dot60Hourly] = useMemo(
    () => getDOTHourlyChartData(dotMetricsHourly),
    [dotMetricsHourly]
  )
  const [dot20HourlyLastWeek, dot30HourlyLastWeek, dot45HourlyLastWeek, dot60HourlyLastWeek] = useMemo(
    () => getDOTHourlyChartData(dotMetricsLastWeekHourly),
    [dotMetricsLastWeekHourly]
  )
  const [dot20HourlyAcc, dot30HourlyAcc, dot45HourlyAcc, dot60HourlyAcc] = useMemo(
    () => getDOTHourlyChartData(dotMetricsLatest),
    [dotMetricsLatest]
  )

  const orFilters = useMemo(
    () =>
      [
        ['dot20Met', '==', true],
        ['dot30Met', '==', true],
        ['dot45Met', '==', true],
        ['dot60Met', '==', true],
      ] as FirebaseFilter<Order>[],
    []
  )

  const orderFilters = useL2OrderFilters({
    originFilter,
    warehouseFilter,
    totalManagedWarehouses,
    customFilters: [['fulfillmentStatus', '==', 'fulfilled']],
  })

  const setOrder = (order: Order) => {
    setSelectedOrderId(order.id)
    navigate(`?orderId=${order.id}`, { replace: true })
  }

  const error = errorLatest || errorHourly || lastWeekError
  if (error) {
    return <ErrorAlertV2 error={error} />
  }

  const d20Line = getChartLine({ data: dot20Hourly, label: 'D20', colour: '#FFE000' })
  const d30Line = getChartLine({ data: dot30Hourly, label: 'D30', colour: '#02BAFF' })
  const d45Line = getChartLine({ data: dot45Hourly, label: 'D45', colour: '#BB6BD9' })
  const d60Line = getChartLine({ data: dot60Hourly, label: 'D60', colour: '#00CC93' })

  const d20LastWeekLine = getChartLine({
    data: dot20HourlyLastWeek,
    label: 'D20 LastWeek',
    colour: '#FFE000',
    dashed: true,
    hidden: true,
  })
  const d30LastWeekLine = getChartLine({
    data: dot30HourlyLastWeek,
    label: 'D30 LastWeek',
    colour: '#02BAFF',
    dashed: true,
    hidden: true,
  })
  const d45LastWeekLine = getChartLine({
    data: dot45HourlyLastWeek,
    label: 'D45 LastWeek',
    colour: '#BB6BD9',
    dashed: true,
    hidden: true,
  })
  const d60LastWeekLine = getChartLine({
    data: dot60HourlyLastWeek,
    label: 'D60 LastWeek',
    colour: '#00CC93',
    dashed: true,
    hidden: true,
  })

  const data = {
    datasets: [d20Line, d30Line, d45Line, d60Line, d20LastWeekLine, d30LastWeekLine, d45LastWeekLine, d60LastWeekLine],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: 'white',
        },
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const label = context.dataset.label || ''
            const getLabel = (value: number) => {
              return formatPercentString(value)
            }
            if (context.parsed.y !== null) {
              return `${label}: ${getLabel(context.parsed.y)}`
            }
            return label
          },
        },
      },
    },
    scales: {
      y: {
        offset: true,
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
          font: {
            size: 10,
          },
        },
      },
      x: {
        border: {
          color: 'white',
        },
        ticks: {
          color: 'white',
          source: 'auto' as const,
          font: {
            size: 10,
          },
        },
        grid: {
          display: false,
        },
      },
    },
  }

  const hasNoChartData =
    dot20Hourly.length === 0 && dot30Hourly.length === 0 && dot45Hourly.length === 0 && dot60Hourly.length === 0

  const chart = hasNoChartData ? (
    <NoDataAvailable title="Timeline" />
  ) : (
    <WidgetPanel
      title="Timeline"
      sx={{ width: '100%' }}
      loading={loadingHourly || lastWeekLoading || loadingLatest || lastWeekStatsSameTimeLoading}
    >
      <Line ref={chartRef} data={data} options={options} style={{ maxHeight: 240 }} />
    </WidgetPanel>
  )

  const dotMinMax = getDOTMinMax(latestAcc?.[0], originFilter)

  return (
    <PageLayout title="DOT">
      <PanelLayout>
        <SingleMetricPanel
          heading={dot20HourlyAcc.length ? formatPercentString(dot20HourlyAcc[0].chartData.y) : 'N/A'}
          subHeading="D20"
          miniHeading={`Now, since 00:00`}
          loading={loadingLatest}
          tooltip={dotMinMax?.d20}
        />
        <SingleMetricPanel
          heading={dot30HourlyAcc.length ? formatPercentString(dot30HourlyAcc[0].chartData.y) : 'N/A'}
          subHeading="D30"
          miniHeading={`Now, since 00:00`}
          loading={loadingLatest}
          tooltip={dotMinMax?.d30}
        />
        <SingleMetricPanel
          heading={dot45HourlyAcc.length ? formatPercentString(dot45HourlyAcc[0].chartData.y) : 'N/A'}
          subHeading={'D45'}
          miniHeading={`Now, since 00:00`}
          loading={loadingLatest}
          tooltip={dotMinMax?.d45}
        />
        <SingleMetricPanel
          heading={dot60HourlyAcc.length ? formatPercentString(dot60HourlyAcc[0].chartData.y) : 'N/A'}
          subHeading={'D60'}
          miniHeading={`Now, since 00:00`}
          loading={loadingLatest}
          tooltip={dotMinMax?.d60}
        />
      </PanelLayout>
      {chart}
      <WidgetPanel sx={{ width: '100%', p: 0, height: '100%', overflow: 'hidden' }}>
        <OrderListV2
          filters={orderFilters}
          orFilters={orFilters}
          onSelect={setOrder}
          onClose={() => setSelectedOrderId('')}
          onMinimize={() => setSelectedOrderId('')}
          selectedOrderId={selectedOrderId}
        />
      </WidgetPanel>
    </PageLayout>
  )
}

export default withAuthentication(React.memo(DOT))
