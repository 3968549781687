import { Stack, Typography, styled } from '@mui/material'
import { FC } from 'react'
import { TileOrderFunnelColumn } from './types/TileOrderFunnelColumn'

interface Props {
  columns: TileOrderFunnelColumn[]
}

export const TileOrderFunnel: FC<Props> = ({ columns }) => {
  return (
    <Stack width="inherit" height="inherit">
      <Stack p={2} mb={2}>
        <Typography variant="h3">Order Funnel</Typography>
      </Stack>
      <Stack
        pt={2}
        direction="row"
        width="100%"
        height="inherit"
        justifyContent="space-around"
        alignItems="center"
        textAlign="center"
        flex={1}
      >
        {columns.map((column, index) => {
          const value = column.value || 0
          const isLastColumn = index === columns.length - 1
          const percentage = Math.min(100, (value / column.dangerAt) * 100)
          const color = value >= column.dangerAt ? 'error' : value >= column.warningAt ? 'warning' : undefined

          return (
            <Stack key={index} height="100%" flex={1} borderRight={Number(!isLastColumn)} borderColor="divider">
              <Stack flex={1} justifyContent="space-between" alignItems="center" borderBottom={1} borderColor="divider">
                <Typography variant="h1" mt={-4} mb={2} color={color && `${color}.main`}>
                  {value}
                </Typography>
                <Bar value={percentage} color={color} />
              </Stack>
              <Typography variant="h6" p={1}>
                {column.label}
              </Typography>
            </Stack>
          )
        })}
      </Stack>
    </Stack>
  )
}

const Bar = styled(Stack)<{ value: number; color?: 'primary' | 'warning' | 'error' }>(({ theme, value, color }) => ({
  width: '32px',
  height: `${value}%`,
  borderRadius: '4px 4px 0 0',
  transition: 'height 0.5s ease-in-out',

  ...([undefined, 'primary'].includes(color) && {
    background: theme.palette.primary.main,
  }),

  ...(color === 'warning' && {
    background: theme.palette.warning.main,
  }),

  ...(color === 'error' && {
    background: theme.palette.error.main,
  }),
}))
