import { WarehouseStatsV2 } from '@quickcommerceltd/zappboard'

export const filterStatsByWarehouse = (
  stats: WarehouseStatsV2[] | undefined,
  storesToHide: string[],
  warehouseIds?: string[] | null,
  secondaryFilter?: string[]
) => {
  if (!stats) return []

  return stats
    .map((stat) => {
      const statsByWarehouse = Object.fromEntries(
        Object.entries(stat.statsByWarehouse).filter(([warehouseId, stats]) => {
          const inWarehouseIds = warehouseIds?.length ? warehouseIds.includes(warehouseId) : true
          const inSecondaryFilter = secondaryFilter ? secondaryFilter.includes(warehouseId) : true
          const isHidden = storesToHide.includes(stats.warehouse.shortName)

          return !isHidden && inWarehouseIds && inSecondaryFilter
        })
      )
      return {
        ...stat,
        statsByWarehouse,
      }
    })
    .sort((a, b) => a.snapshotTimeMs - b.snapshotTimeMs)
}
