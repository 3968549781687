import { DateTime } from 'luxon'

// if the minute is 59, then it a full hourly data point, so we want to show the hour instead of the minute
export const getCommonTimeData = (time: DateTime) => ({
  time: time.toFormat('dd LLL yy HH:mm'),
  day: time.toFormat('dd LLL'),
  hour: time.plus({ hour: 1 }).toFormat('HH:00'),
  minute: time.plus({ hour: 1 }).toFormat('HH:mm'),
})

export const getStatsIdToLocalTime = (statsId: string) =>
  DateTime.fromFormat(statsId, 'yyyy-MM-dd_HH-mm', { zone: 'UTC' }).toLocal()

export const getStatsIdToLocalString = (statsId: string) =>
  statsId
    ? DateTime.fromFormat(statsId, 'yyyy-MM-dd_HH-mm', { zone: 'UTC' }).toLocal().toFormat('dd LLL yy HH:mm')
    : 'N/A'
