import { makeStyles } from 'tss-react/mui'

export const useProofDeliveryDialogStyles = makeStyles()((theme) => ({
  paper: {
    width: 'min(100%, 600px)',
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: '24px',
  },
  divider: {
    borderTopWidth: '1px',
    borderWidth: '1px',
    borderStyle: 'solid',
    opacity: 0.4,
    marginTop: '-8px',
    marginBottom: '4px',
    minWidth: '600px',
  },
  closeIcon: {
    position: 'absolute',
    top: '4px',
    right: '4px',
    color: 'inherit',
  },
  text: {
    marginBottom: '16px',
  },
  image: {
    width: '100%',
    maxHeight: '700px',
    objectFit: 'contain',
    marginBottom: '4px',
  },
}))
