import { Box, Button, CircularProgress, FormControl, FormControlLabel, RadioGroup, Typography } from '@mui/material'
import { DelayReason, SupportInquiry, SupportInquiryCode } from '@quickcommerceltd/zappboard'
import { FC, useState } from 'react'
import { useCustomerSupportDialogStyles } from '../CustomerSupportDialog.style'
import { StyledRadio } from '.'

interface Props {
  onButtonClick: () => void
  loading: boolean
  setRequest: (inquiry: Partial<SupportInquiry>) => void
}

export const CustomerSupportDelayed: FC<Props> = ({ onButtonClick, loading, setRequest }) => {
  const { classes } = useCustomerSupportDialogStyles()
  const [delayReason, setDelayReason] = useState<DelayReason | undefined>(undefined)

  const handelDelayReasonChange = (event: any) => {
    const reason = event.target.value
    if (!Object.values(DelayReason).includes(reason)) {
      return
    }
    setDelayReason(reason)
    setRequest({
      code: SupportInquiryCode.DELIVERY_DELAY,
      delayReason: reason,
      cancelReason: undefined,
      message: undefined,
      outOfStockItems: undefined,
    })
  }

  return (
    <Box>
      <Typography className={classes.reasonHeadline}>Please provide the reason for the expected delay*</Typography>

      <FormControl component="fieldset" required>
        <RadioGroup aria-label="delayReason" name="delayReason" value={undefined} onChange={handelDelayReasonChange}>
          <FormControlLabel
            className={classes.topRadio}
            value="no_riders_available"
            control={<StyledRadio />}
            label={<div className={classes.radioText}>No Riders Available</div>}
          />
          <FormControlLabel
            value="multiple_orders"
            control={<StyledRadio />}
            label={<div className={classes.radioText}>Multiple Orders</div>}
          />
          <FormControlLabel
            value="weather_conditions"
            control={<StyledRadio />}
            label={<div className={classes.radioText}>Weather Conditions</div>}
          />
          <FormControlLabel
            value="other"
            control={<StyledRadio />}
            label={<div className={classes.radioText}>Other</div>}
          />
        </RadioGroup>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        onClick={onButtonClick}
        fullWidth
        disabled={!delayReason || loading}
        className={classes.button}
        children={loading ? <CircularProgress size={20} color="inherit" /> : 'Create Zendesk Ticket'}
      />
    </Box>
  )
}
