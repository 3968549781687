import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { Order, SupportInquiry, SupportInquiryCode } from '@quickcommerceltd/zappboard'
import { httpsCallable } from 'firebase/functions'
import { FC, useCallback, useState } from 'react'
import { functions } from '../../firebase'
import {
  CustomerSupportCancelled,
  CustomerSupportDelayed,
  CustomerSupportOOS,
  CustomerSupportOther,
} from './_components'
import { getReasonCodeTranslation } from './_helpers/getReasonCodeTranslation'
import { useCustomerSupportDialogStyles } from './CustomerSupportDialog.style'

const DEFAULT_REQUEST = { code: undefined, message: '' }

interface Props {
  open: boolean
  order: Order
  userEmail: string
  onClose: () => void
  isOptionOOSForContactSupportEnabled: boolean
}

export const CustomerSupportDialog: FC<Props> = ({
  open,
  order,
  userEmail,
  onClose,
  isOptionOOSForContactSupportEnabled,
}) => {
  const { classes } = useCustomerSupportDialogStyles()
  const [loading, setLoading] = useState(false)
  const [ticketId, setTicketId] = useState('')
  const [request, setRequest] = useState<Partial<SupportInquiry>>(DEFAULT_REQUEST)
  const [reason, setReason] = useState('')
  const [error, setError] = useState('')

  const reset = () => {
    setRequest(DEFAULT_REQUEST)
    setTicketId('')
    setReason('')
    setLoading(false)
    setError('')
  }

  const handleOnClose = () => {
    reset()
    onClose()
  }

  const handleCodeChange = (event: any) => {
    reset()
    const code: SupportInquiryCode = event.target.value
    setReason(code)
    setRequest((inquiry: Partial<SupportInquiry>) => ({
      ...inquiry,
      code,
    }))
  }

  const submitSupportRequest = useCallback(async () => {
    if (!request.code || loading) return
    setLoading(true)
    setError('')

    const createSupportInquiry = httpsCallable<any, any>(functions, 'api-createSupportInquiry')

    await createSupportInquiry({
      userEmail,
      warehouseId: order.warehouseId,
      orderId: order.id,
      orderNumber: order.number,
      orderLinesLength: order.lines?.length || 0,
      taskId: order.deliveryTaskId,
      ...request,
    })
      .then((response) => {
        setTicketId(response.data ?? 'undefined')
        setLoading(false)
      })
      .catch((error: any) => {
        console.error(error)
        setLoading(false)
        setError(`Could not create a support inquiry for ${order?.id} with error: ${error?.message}`)
      })
  }, [request, loading, order, userEmail])

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      PaperProps={{
        style: { borderRadius: '20px' },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>Contact Customer Support</DialogTitle>
      <IconButton onClick={handleOnClose} className={classes.closeIcon}>
        <CloseIcon />
      </IconButton>
      <div className={classes.divider} />

      <DialogContent className={classes.paper}>
        <Box marginLeft={1}>
          <Typography className={classes.task}>Order #{order.number}</Typography>
        </Box>
        {!ticketId ? (
          <>
            {/* ISSUE CREATION SCREEN */}

            <Box padding={2}>
              <FormControl variant="outlined" className={classes.formControl} required>
                <InputLabel>Select the ticket reason</InputLabel>
                <Select
                  id="code"
                  label="Select the ticket reason"
                  value={request.code || ''}
                  onChange={handleCodeChange}
                  className={classes.select}
                  disabled={loading}
                >
                  <MenuItem value="" disabled className={classes.menuItem}>
                    Select the ticket reason
                  </MenuItem>
                  {Object.keys(SupportInquiryCode)
                    .sort()
                    .filter((key) => {
                      if (isOptionOOSForContactSupportEnabled && key === 'ITEM_OOS') {
                        return true
                      }
                      return ['DELIVERY_DELAY', 'OTHER', 'CANCEL_ORDER'].includes(key)
                    })
                    .map((code) => (
                      <MenuItem key={code} value={code} className={classes.menuItem}>
                        {getReasonCodeTranslation(code as SupportInquiryCode)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              {reason === SupportInquiryCode.ITEM_OOS ? (
                <CustomerSupportOOS
                  onButtonClick={submitSupportRequest}
                  loading={loading}
                  order={order}
                  setRequest={(v) => setRequest(v)}
                />
              ) : reason === SupportInquiryCode.DELIVERY_DELAY ? (
                <CustomerSupportDelayed
                  onButtonClick={submitSupportRequest}
                  loading={loading}
                  setRequest={(v) => setRequest(v)}
                />
              ) : reason === SupportInquiryCode.CANCEL_ORDER ? (
                <CustomerSupportCancelled
                  onButtonClick={submitSupportRequest}
                  loading={loading}
                  setRequest={(v) => setRequest(v)}
                />
              ) : reason === SupportInquiryCode.OTHER ? (
                <CustomerSupportOther
                  onButtonClick={submitSupportRequest}
                  loading={loading}
                  setRequest={(v) => setRequest(v)}
                />
              ) : (
                ''
              )}
            </Box>
          </>
        ) : (
          <>
            {/* SUCCESS SCREEN */}
            <Box padding={2}>
              <DoneIcon className={classes.successIcon} />
              <Typography className={classes.successHeadline}>Thank you!</Typography>
              <Typography className={classes.successText}>
                A ticket with id {`#${ticketId || 'Unknown'}`} has been created.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOnClose}
                fullWidth
                disabled={!!loading}
                className={classes.button}
                children="Close"
              />
            </Box>
          </>
        )}

        {error && (
          <Typography variant="body2" color="error" style={{ textAlign: 'center' }}>
            {error}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  )
}
