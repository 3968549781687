import { WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { useMemo } from 'react'
import { getLowTier0Metrics } from '../../utils/warehouseStatsV2/getLowTier0Metrics'
import { getPickerLevels } from '../../utils/warehouseStatsV2/getPickerLevels'
import { getPickQueueMetrics } from '../../utils/warehouseStatsV2/getPickQueueMetrics'
import { getRevenueTotal } from '../../utils/warehouseStatsV2/getRevenueMetrics'
import { getWarehouseStatusMetrics } from '../../utils/warehouseStatsV2/getWarehouseStatusMetrics'

export const useHomeRealTimeMetrics = (warehouseStats: WarehouseStatsV2[], originFilter: string[]) => {
  const warehouseStatusMetrics = useMemo(() => getWarehouseStatusMetrics(warehouseStats[0]), [warehouseStats])
  const lowTier0Metrics = useMemo(() => getLowTier0Metrics(warehouseStats[0]), [warehouseStats])
  const pickQueueMetrics = useMemo(
    () => getPickQueueMetrics(warehouseStats[0], originFilter),
    [warehouseStats, originFilter]
  )
  const revenueMetrics = useMemo(() => getRevenueTotal(warehouseStats[0], originFilter), [warehouseStats, originFilter])

  const pickerLevelMetrics = useMemo(() => getPickerLevels(warehouseStats[0]), [warehouseStats])

  return { warehouseStatusMetrics, lowTier0Metrics, pickQueueMetrics, revenueMetrics, pickerLevelMetrics }
}
