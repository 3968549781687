import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined'
import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  styled,
  Typography,
} from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { useStoresToHide } from '../../hooks/useStoresToHide'
import { useWarehouseStatsV2 } from '../../hooks/useWarehouseStatsV2'
import { sessionState } from '../../state'
import { filterStatsByWarehouse } from '../../utils/warehouseStatsV2/filterStatsByWarehouse'

const MenuProps = {
  PaperProps: {
    style: {
      borderRadius: '8px',
      backgroundColor: 'white',
      color: 'black',
      fontWeight: 700,
      marginTop: '0.5em',
    },
  },
}

interface Props {
  onWarehouseChange: (warehouses: string[]) => void
}

const SelectActionText = styled(Typography)(({ theme }) => ({
  zIndex: 1,
  pointerEvents: 'auto',
  fontWeight: 700,
  color: theme.palette.background.default,
  textDecoration: 'underline',
  textDecorationColor: theme.palette.primary.main,
  fontSize: 12,
  cursor: 'pointer',
}))

export const ToWarehouseSelector: React.FC<Props> = ({ onWarehouseChange }) => {
  const [session] = useRecoilState(sessionState)
  const [selectedWarehouse, setSelectedWarehouse] = React.useState<string[]>([])
  const [isSelectorOpen, setIsSelectorOpen] = React.useState(false)
  const [warehouseStats] = useWarehouseStatsV2({ quantity: 1, once: true })
  const storesToHide = useStoresToHide()

  const filteredWarehouses = useMemo(() => {
    const manager = session.user?.manager
    const managedWarehouses = manager ? session.user?.managedWarehouses : []
    return filterStatsByWarehouse(warehouseStats, storesToHide, managedWarehouses)
  }, [warehouseStats, session?.user])

  const warehouseList = useMemo(() => {
    if (!filteredWarehouses.length) return []
    const stats = filteredWarehouses[0]
    return Object.values(stats.statsByWarehouse)
      .map(({ warehouse }) => ({
        name: warehouse.name,
        shortName: warehouse.shortName,
        id: warehouse.id,
      }))
      .sort((a, b) => a.shortName.localeCompare(b.shortName))
  }, [filteredWarehouses])

  useEffect(() => {
    const newSelected = warehouseList.map(({ id }) => id)
    setSelectedWarehouse(newSelected)
    onWarehouseChange(newSelected)
  }, [warehouseList, onWarehouseChange])

  const onChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event
    const newSelected = (value as string[]).filter((v) => !!v)
    setSelectedWarehouse(newSelected)
    onWarehouseChange(newSelected)
  }

  const selectAllStores = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    const newSelected = warehouseList.map(({ id }) => id)
    setSelectedWarehouse(newSelected)
    onWarehouseChange(newSelected)
  }

  const clearAllStores = (ev: React.MouseEvent) => {
    ev.stopPropagation()
    setSelectedWarehouse([])
    onWarehouseChange([])
  }

  return (
    <FormControl sx={{ maxWidth: 300, flex: 1 }}>
      <Select
        multiple
        displayEmpty
        value={selectedWarehouse}
        onChange={onChange}
        renderValue={(selected) => `${selected.length} Selected`}
        MenuProps={MenuProps}
        open={isSelectorOpen}
        onOpen={() => setIsSelectorOpen(true)}
        onClose={() => setIsSelectorOpen(false)}
        input={
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start" onClick={() => setIsSelectorOpen(true)}>
                <StoreOutlinedIcon sx={{ fill: '#002533', cursor: 'pointer' }} />
              </InputAdornment>
            }
            size="small"
            sx={{
              '& .MuiOutlinedInput-inputAdornedStart': {
                color: '#E5F8FF',
              },
              backgroundColor: '#E5F8FF',
              color: '#002533',
              borderRadius: '20px',
              fontWeight: 'bold',
            }}
          />
        }
      >
        <MenuItem disabled value="" sx={{ opacity: '1 !important' }}>
          <Box display="flex" justifyContent="space-between" width="100%" px={1} my={0.5}>
            <SelectActionText onClick={selectAllStores}>Select all</SelectActionText>
            <SelectActionText onClick={clearAllStores}>Clear all</SelectActionText>
          </Box>
        </MenuItem>
        {warehouseList.map((warehouse) => (
          <MenuItem key={warehouse.shortName} value={warehouse.id} sx={{ p: 0.2 }}>
            <Checkbox checked={selectedWarehouse.indexOf(warehouse.id) > -1} />
            <ListItemText
              primary={
                <strong>
                  {warehouse.shortName}{' '}
                  <Box sx={{ ml: 1, display: 'inline', color: 'divider', fontWeight: 600 }}>
                    <small>{warehouse.name}</small>
                  </Box>
                </strong>
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
