import { fetchAndActivate, getAll } from 'firebase/remote-config'
import { useEffect, useMemo, useState } from 'react'
import { remoteConfig } from '../../firebase'

interface RemoteConfig {
  [flag: string]: {
    [warehouseId: string]: boolean
  }
}

export const useFeatureFlags = (warehouseId: string, flags: string[]) => {
  const [remoteConfigs, setRemoteConfigs] = useState<RemoteConfig>({})

  useEffect(() => {
    try {
      fetchAndActivate(remoteConfig)
      const config = Object.entries(getAll(remoteConfig)).reduce<RemoteConfig>((result, [key, value]) => {
        try {
          const flagValue = JSON.parse(value.asString())
          result[key] = typeof flagValue === 'object' ? flagValue : { all: !!flagValue }
        } catch (error) {
          result[key] = { all: false }
        }

        return result
      }, {})

      setRemoteConfigs(config)
    } catch {}
  }, [])

  return useMemo(
    () =>
      flags.reduce<Record<string, boolean>>((result, flag) => {
        const config = remoteConfigs[flag] || {}
        result[flag] = config[warehouseId] ?? config['all'] ?? false
        return result
      }, {}),
    [warehouseId, flags, remoteConfigs]
  )
}
