import { makeStyles } from 'tss-react/mui'

export const useConfirmReturningTaskDialogStyles = makeStyles()((theme) => ({
  paper: {
    width: 'min(100%, 600px)',
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: '24px',
  },
  divider: {
    borderTopWidth: '1px',
    borderWidth: '1px',
    borderStyle: 'solid',
    opacity: 0.4,
    marginTop: '-8px',
    marginBottom: '4px',
    minWidth: '600px',
  },
  closeIcon: {
    position: 'absolute',
    top: '4px',
    right: '4px',
    color: 'inherit',
  },
  task: {
    fontWeight: 700,
    fontSize: '38px',
    marginLeft: '12px',
  },
  table: {
    fontSize: '20px',
    borderSpacing: '4px 8px',
  },
  icon: {
    marginRight: '10px',
    marginLeft: '12px',
    marginTop: '5px',
  },
  returnButton: {
    height: '57px',
    fontWeight: 'bold',
    fontSize: '18px',
    borderRadius: '6px',
    minWidth: '400px',
  },
  notReturnButton: {
    color: theme.palette.error.main,
    borderWidth: '0px',
    marginTop: '28px',
    marginBottom: '4px',
    height: '25px',
    fontWeight: 'bold',
    fontSize: '18px',
    borderRadius: '6px',
  },
}))
