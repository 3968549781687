import { Box, Divider, Stack, styled, Typography } from '@mui/material'
import { WarehouseStatus, WarehouseStoreStatusV2 } from '@quickcommerceltd/zappboard'
import keyBy from 'lodash/keyBy'
import React, { useMemo } from 'react'
import { ProgressBarChartData } from '../../types/ProgressBarChartData'
import { WidgetPanel } from '../WidgetPanel/WidgetPanel'

const StoreStatus = styled(Box)(() => ({
  minWidth: 27,
  minHeight: 27,
  borderRadius: 3,
  fontSize: 14,
  fontWeight: 600,
  padding: '2px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  img: {
    width: 20,
  },
}))

const getStatusColour = (status: WarehouseStatus, inContingency?: boolean) => {
  if (inContingency && status === 'OPEN') {
    return 'warning.main'
  }
  if (status !== 'OPEN') {
    return 'error.main'
  }
  return 'success.main'
}

interface Props {
  storeStatusList: WarehouseStoreStatusV2[]
  pickQueue: ProgressBarChartData[]
  loading?: boolean
}

export const StoreStatusPanel: React.FC<Props> = ({ storeStatusList, pickQueue, loading }) => {
  const orderedList = useMemo(() => {
    const pickQueueMap = keyBy(pickQueue, 'name')
    const sortedList = storeStatusList.sort((a, b) => {
      if (a.status !== 'OPEN' || a.isInContingencyMode || Object.values(a.storefronts ?? {}).some((a) => a !== 'OPEN'))
        return -1
      if (b.status !== 'OPEN' || b.isInContingencyMode || Object.values(b.storefronts ?? {}).some((a) => a !== 'OPEN'))
        return 1
      const aPickQueue = pickQueueMap[a.shortName]?.value ?? 0
      const bPickQueue = pickQueueMap[b.shortName]?.value ?? 0

      if (aPickQueue !== bPickQueue) {
        return bPickQueue - aPickQueue
      }

      return a.shortName.localeCompare(b.shortName)
    })
    return sortedList
  }, [storeStatusList, pickQueue])

  const allGreen = orderedList.every(
    (store) =>
      store.status === 'OPEN' &&
      !store.isInContingencyMode &&
      Object.values(store.storefronts ?? {}).every((a) => a === 'OPEN')
  )

  if (allGreen) {
    return (
      <WidgetPanel title="Store Status" loading={loading}>
        <Typography
          sx={{ fontSize: '16px', lineHeight: '19px', color: 'text.secondary', fontWeight: 500 }}
          gutterBottom
        >
          <small>Everything is open and running smoothly</small>
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
          <Typography variant="h4">No Issues</Typography>
          <Stack spacing={1} direction="row" my={1}>
            {['STORE', 'UBER', 'DELIVEROO', 'JET'].map((name) => (
              <StoreStatus
                key={name}
                sx={{
                  backgroundColor: getStatusColour(WarehouseStatus.OPEN, false),
                  img: {
                    width: 30,
                  },
                }}
              >
                <img src={`/icons/${name}.svg`} alt={`all ${name}`} />
              </StoreStatus>
            ))}
          </Stack>
        </Box>
      </WidgetPanel>
    )
  }
  return (
    <WidgetPanel title="Store Status" loading={loading}>
      <Typography sx={{ fontSize: '16px', lineHeight: '19px', color: 'text.secondary', fontWeight: 500 }} gutterBottom>
        Store
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Stack rowGap={1} divider={<Divider orientation="horizontal" flexItem />}>
        {orderedList.slice(0, 5).map((store) => (
          <Box data-testid="store-status-row" key={store.id} display="flex" justifyContent="space-between">
            <Typography sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: 500 }}>{store.shortName}</Typography>
            <Stack direction="row" spacing={1}>
              {store.isInContingencyMode && store.isInContingencyModeFor && (
                <StoreStatus
                  data-testid="contingency-status"
                  sx={{ backgroundColor: getStatusColour(store.status, store.isInContingencyMode) }}
                >
                  <Box display="flex">
                    <img
                      style={{ marginRight: '8px', padding: '2px' }}
                      src="/icons/umbrella.svg"
                      alt="contingency mode"
                    />
                    <Typography sx={{ fontSize: 'inherit' }}>
                      {storeStatusList.find((s) => s.id === store.isInContingencyModeFor)?.shortName}
                    </Typography>
                  </Box>
                </StoreStatus>
              )}
              <StoreStatus
                data-testid="store-status"
                sx={{ backgroundColor: getStatusColour(store.status, store.isInContingencyMode) }}
              >
                <img src="/icons/STORE.svg" alt="store" />
              </StoreStatus>
              {store.storefronts?.UBER && (
                <StoreStatus
                  data-testid="storefront-status"
                  sx={{
                    backgroundColor: getStatusColour(
                      store.storefronts.UBER as WarehouseStatus,
                      store.isInContingencyMode
                    ),
                  }}
                >
                  <img src="/icons/UBER.svg" alt="uber storefront" />
                </StoreStatus>
              )}
              {store.storefronts?.DELIVEROO && (
                <StoreStatus
                  data-testid="storefront-status"
                  sx={{
                    backgroundColor: getStatusColour(
                      store.storefronts.DELIVEROO as WarehouseStatus,
                      store.isInContingencyMode
                    ),
                  }}
                >
                  <img src="/icons/DELIVEROO.svg" alt="deliveroo storefront" />
                </StoreStatus>
              )}
              {store.storefronts?.JET && (
                <StoreStatus
                  data-testid="storefront-status"
                  sx={{
                    backgroundColor: getStatusColour(
                      store.storefronts.JET as WarehouseStatus,
                      store.isInContingencyMode
                    ),
                  }}
                >
                  <img src="/icons/JET.svg" alt="JET storefront" />
                </StoreStatus>
              )}
            </Stack>
          </Box>
        ))}
      </Stack>
    </WidgetPanel>
  )
}
