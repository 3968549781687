import { WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { useMemo } from 'react'
import { get01RatingMetrics } from '../../utils/warehouseStatsV2/get01RatingMetrics'
import { getDotMetrics } from '../../utils/warehouseStatsV2/getDotMetrics'
import { getDtpMetrics } from '../../utils/warehouseStatsV2/getDtpMetrics'
import { getOOSDefectMetrics } from '../../utils/warehouseStatsV2/getOOSDefectMetrics'
import { getPickingMetrics } from '../../utils/warehouseStatsV2/getPickingMetrics'
import { getPickUpMetrics } from '../../utils/warehouseStatsV2/getPickUpMetrics'

export const useHomeHistoricalMetrics = (warehouseStats: WarehouseStatsV2[], originFilter: string[]) => {
  const dtpMetrics = useMemo(() => getDtpMetrics(warehouseStats, originFilter), [warehouseStats, originFilter])
  const dotMetrics = useMemo(() => getDotMetrics(warehouseStats, originFilter), [warehouseStats, originFilter])
  const pickingMetrics = useMemo(() => getPickingMetrics(warehouseStats, originFilter), [warehouseStats, originFilter])
  const pickupMetrics = useMemo(() => getPickUpMetrics(warehouseStats, originFilter), [warehouseStats, originFilter])
  const oosDefectMetrics = useMemo(
    () => getOOSDefectMetrics(warehouseStats, originFilter),
    [warehouseStats, originFilter]
  )
  const lowRatingMetrics = useMemo(
    () => get01RatingMetrics(warehouseStats, originFilter),
    [warehouseStats, originFilter]
  )

  return { dtpMetrics, dotMetrics, pickingMetrics, pickupMetrics, oosDefectMetrics, lowRatingMetrics }
}
