import { Stack, TableBody, Typography } from '@mui/material'
import { Tier0SkuLowStockStats } from '@quickcommerceltd/zappboard'
import { useCallback, useMemo } from 'react'
import { Table, TableCell, TableHead, TableRow } from '../Table'

const WARNING_AT = 7
const DANGER_AT = 5

interface Props {
  tier0LowStock?: Tier0SkuLowStockStats[]
}

export const TileLowStockT0Skus: React.FC<Props> = (props) => {
  const tier0LowStock = useMemo(() => {
    return (props.tier0LowStock || [])
      .filter(({ stockLevel }) => stockLevel)
      .sort((a, b) => a.stockLevel - b.stockLevel)
      .slice(0, 5)
  }, [props.tier0LowStock])

  const getColor = useCallback((stockLevel: number) => {
    return stockLevel <= DANGER_AT ? 'error.main' : stockLevel <= WARNING_AT ? 'warning.main' : 'initial'
  }, [])

  return (
    <Stack width="inherit" height="inherit">
      <Typography variant="h3" p={2}>
        Low Tier0 SKUs
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: 'text.secondary' }}>SKU</TableCell>
            <TableCell sx={{ color: 'text.secondary' }}>Product Name</TableCell>
            <TableCell align="right" sx={{ color: 'text.secondary' }}>
              Available
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tier0LowStock.map((row) => (
            <TableRow key={row.sku}>
              <TableCell style={{ whiteSpace: 'nowrap' }}>{row.sku}</TableCell>
              <TableCell
                style={{
                  maxWidth: '200px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: getColor(row.stockLevel),
                  fontWeight: 'bold',
                }}
              >
                {row.stockLevel}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  )
}
