import { Box, CircularProgress, Stack, SxProps, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  title?: string
  link?: string
  padding?: number
  sx?: SxProps
  loading?: boolean
}
export const WidgetPanel: React.FC<Props> = ({ title, children, link, loading, sx = {} }) => {
  const navigate = useNavigate()

  return (
    <Stack
      onClick={() => (link ? navigate(link) : null)}
      sx={{
        position: 'relative',
        width: 368,
        height: 307,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: '8px',
        backgroundColor: 'background.paper',
        p: 2,
        cursor: link ? 'pointer' : 'default',

        '@media (max-width: 600px)': {
          width: '100%',
          height: 'unset',
        },
        ...sx,
      }}
    >
      {title && (
        <Typography sx={{ fontSize: '24px', lineHeight: '29px' }} gutterBottom>
          {title}
        </Typography>
      )}
      <Box sx={{ position: 'absolute', right: 5 }}>{loading && <CircularProgress size={20} sx={{ p: '1px' }} />}</Box>

      {children}
    </Stack>
  )
}
