import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, IconButton, styled, Tab, Tabs } from '@mui/material'
import { Order } from '@quickcommerceltd/zappboard'
import keyBy from 'lodash/keyBy'
import union from 'lodash/union'
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import useLocalStorage from 'react-use-localstorage'
import { theme } from '../../theme'
import { OrderDetailsV2 } from '../OrderDetailsV2/OrderDetailsV2'

const TabWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderTop: 'solid 1px #666873',
  position: 'fixed',
  bottom: 0,
  left: '80px',
  width: 'calc(100% - 80px)',
  height: 64,
  zIndex: 10,
  display: 'flex',
  justifyContent: 'space-between',
}))

const StyledTabs = styled(Tabs)(() => ({
  marginTop: -1,
  color: 'white',
  '& .Mui-selected': {
    backgroundColor: theme.palette.background.paper,
  },
}))

const StyledTab = styled(Tab)(() => ({
  border: 'solid 1px #666873',
  borderBottom: 'solid 1px transparent',
  marginRight: -1,
  lineHeight: '1em',
  textAlign: 'left',
  textTransform: 'none',
  height: 64,
  minWidth: 212,
}))

const statusColors: Record<string, string> = {
  open: theme.palette.warning.main,
  unassigned: theme.palette.error.main,
  cancelled: theme.palette.warning.main,
  fulfilled: theme.palette.success.main,
  unfulfilled: theme.palette.primary.main,
}

const StatusIndicator = styled('span', {
  shouldForwardProp: (prop) => prop !== 'status',
})<{ status: string }>(({ theme, status }) => {
  return {
    width: 12,
    height: 12,
    display: 'inline-block',
    borderRadius: '50%',
    verticalAlign: 'middle',
    marginRight: 6,
    position: 'relative',
    top: -1,
    backgroundColor: statusColors[status] || theme.palette.warning.main,
  }
})

interface OrderTabsProps {
  activeOrderId: string | undefined
  orders: Order[]
  onClose: (id: string) => void
  onMinimize: (id: string) => void
  onSelect: (order: Order) => void
}

export const OrderTabsV2: FC<OrderTabsProps> = ({ activeOrderId: orderId, orders, onClose, onMinimize, onSelect }) => {
  const [tabs, setTabs] = useLocalStorage('tabs', undefined)
  const [order, setOrder] = useState<Order | undefined>()

  // get active order by id
  useEffect(() => {
    const selectedOrder = orders?.find((o) => o.id === orderId)
    setOrder(selectedOrder)
  }, [orders, orderId, setOrder])

  // add tab on change
  useEffect(() => {
    if (!orderId) return
    setTabs(union(tabs.split(','), [orderId]).filter(Boolean).join())
  }, [tabs, orderId, setTabs])

  const removeTab = (id: string) => {
    onClose(id)
    const filteredTabs = tabs
      ?.split(',')
      .filter((tabId) => !!tabId && tabId !== id)
      .join()
    setTabs(filteredTabs ?? [])
  }

  const ordersSet = useMemo(() => keyBy(orders, 'id'), [orders])

  const ids = useMemo(() => (tabs?.split(',') ?? []).filter((tabId) => ordersSet[tabId]), [tabs, ordersSet])

  if (!ids?.length) {
    return null
  }

  const stackedOrder = order?.stackedWithTaskIds?.length
    ? orders.find((o) => o.deliveryTaskId === order.stackedWithTaskIds?.[0])
    : undefined

  return (
    <TabWrapper sx={{ display: { xs: 'none', sm: 'inherit' } }}>
      {order && (
        <OrderDetailsV2
          order={order}
          onClose={removeTab}
          onMinimize={onMinimize}
          stackedOrder={stackedOrder}
          onSelect={onSelect}
        />
      )}
      <StyledTabs
        scrollButtons="auto"
        variant="scrollable"
        value={orderId || false}
        onChange={(_e, value) => {
          const selectedOrder = orders?.find((o) => o.id === value)
          if (selectedOrder) {
            onSelect(selectedOrder)
          }
        }}
      >
        {ids
          .filter((id) => ordersSet[id])
          .map((id) => {
            const order = ordersSet[id]
            return (
              <StyledTab
                value={id}
                key={id}
                label={
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ color: 'common.white', ml: 2, mr: 6 }}
                  >
                    <Box>
                      <Box
                        sx={{
                          marginTop: 0,
                          fontWeight: 700,
                          fontSize: '1.1em',
                        }}
                      >
                        Order #{order.number}
                      </Box>
                      <Box
                        sx={{
                          marginTop: 1,
                          fontSize: '.9em',
                          textTransform: 'capitalize',
                        }}
                      >
                        <StatusIndicator status={order.fulfillmentStatus} />
                        {order.fulfillmentStatus}
                      </Box>
                    </Box>
                    <IconButton
                      color="inherit"
                      sx={{
                        position: 'absolute',
                        right: 0,
                        width: 40,
                        height: '100%',
                        borderRadius: 0,
                      }}
                      component="span"
                      onClick={(e: SyntheticEvent) => {
                        e.stopPropagation()
                        removeTab(id)
                      }}
                    >
                      <CloseIcon
                        sx={{
                          width: 20,
                          height: 20,
                        }}
                      />
                    </IconButton>
                  </Box>
                }
              />
            )
          })}
      </StyledTabs>
      <Button
        onClick={() => {
          setOrder(undefined)
          setTabs('')
          if (order) {
            onClose(order?.id || '')
          }
        }}
      >
        Clear
      </Button>
    </TabWrapper>
  )
}
