import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { Stack, styled } from '@mui/material'
import { green, red } from '@mui/material/colors'
import React, { useEffect, useMemo, useState } from 'react'
import FormatDuration from '../FormatDuration'

const NECESSARY_OFFSET = 1 //Needed to use the FormatDuration component

interface Props {
  value: number
  inverted?: boolean
  suffix?: string
  duration?: boolean
  size?: 'small' | 'medium'
}

export const ActiveBadgePercentage: React.FC<Props> = ({
  value = 0,
  inverted = false,
  suffix = '%',
  duration = false,
  size = 'medium',
}) => {
  const [hasChanged, setHasChanged] = useState(false)

  const { badgeColor, arrowDirection } = useMemo((): {
    badgeColor: 'red' | 'green'
    arrowDirection: 'up' | 'down'
  } => {
    if (!inverted && value < 0) return { badgeColor: 'red', arrowDirection: 'down' }
    if (inverted && value > 0) return { badgeColor: 'red', arrowDirection: 'up' }
    if (inverted && value < 0) return { badgeColor: 'green', arrowDirection: 'down' }

    return { badgeColor: 'green', arrowDirection: 'up' }
  }, [value, inverted])

  // animate the badge when the value changes
  useEffect(() => {
    setHasChanged(true)
    const timer = setTimeout(() => setHasChanged(false), 500)
    return () => clearTimeout(timer)
  }, [value])

  return (
    <StyledStack direction="row" alignItems="center" size={size} badgeColor={badgeColor} highlight={Number(hasChanged)}>
      <StyledArrowDropUpIcon size={size} arrowDirection={arrowDirection} />
      {duration ? (
        <>
          {value < 0 && <span>-</span>}
          <FormatDuration from={NECESSARY_OFFSET} to={Math.abs(value) + NECESSARY_OFFSET} />
        </>
      ) : (
        <span>
          {value.toFixed(2)}
          {suffix}
        </span>
      )}
    </StyledStack>
  )
}

const StyledStack = styled(Stack)<{
  size: 'small' | 'medium'
  badgeColor: 'red' | 'green'
  highlight: number
}>(({ size, badgeColor, highlight }) => ({
  padding: '2px 8px 2px 2px',
  borderRadius: '4px',
  fontSize: '12px',
  fontWeight: 'bold',
  transition: 'all 0.15s ease-in-out',
  justifyContent: 'center',

  ...(size === 'small' && {
    padding: '1px',
    fontSize: '10px',
  }),

  ...(badgeColor === 'red' && {
    color: red[900],
    background: red[200],
  }),

  ...(badgeColor === 'green' && {
    color: green[900],
    background: green[200],
  }),

  ...(highlight && {
    filter: 'brightness(70%)',
    '& > span': {
      filter: 'unset',
      transform: 'scale(1.1)',
    },
  }),
}))

const StyledArrowDropUpIcon = styled(ArrowDropUpIcon)<{
  size: 'small' | 'medium'
  arrowDirection: 'up' | 'down'
}>(({ size, arrowDirection }) => ({
  fontSize: '18px',

  ...(size === 'small' && {
    fontSize: '14px',
    marginLeft: '-6px',
  }),

  ...(arrowDirection === 'down' && {
    transform: 'rotate(180deg)',
  }),
}))

export default ActiveBadgePercentage
