import { keyframes, Stack, styled, Typography } from '@mui/material'
import { Order, OrderOrigin } from '@quickcommerceltd/zappboard'
import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useStoreViewData } from '../../hooks/useStoreViewData/useStoreViewData'
import { useStoreViewDataDeliveroo } from '../../hooks/useStoreViewData/useStoreViewDataDeliveroo'
import { withAuthentication } from '../withAuthentication'

const StyledSection = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '8px',
  flex: '1 1 50%',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  height: '90vh',
  overflow: 'hidden',
}))

const OrderSection = styled(Stack)(() => ({
  flexFlow: 'column wrap',
  height: '100%',
  overflow: 'hidden',
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  textDecoration: 'underline',
  fontSize: '50px',
  textAlign: 'center',
  textTransform: 'uppercase',
  fontWeight: 700,
  marginBottom: theme.spacing(1),
}))

const blink = keyframes`
  50% {
    opacity: 0.3;
  }
`

const ExternalStorePage: FC = () => {
  const { id: warehouseId } = useParams<keyof { id: string }>() as { id: string }

  const orders = useStoreViewData(warehouseId)
  const [deliverooOrders = []] = useStoreViewDataDeliveroo(warehouseId)
  const ordersAssigned = useMemo(
    () => orders.filter((order) => !order.pickingStartedAt).sort((a, b) => b.createdAt - a.createdAt),
    [orders]
  )
  const ordersPicking = useMemo(
    () =>
      orders
        .filter((order) => !!order.pickingStartedAt && !order.pickingEndedAt)
        .sort((a, b) => b.createdAt - a.createdAt),
    [orders]
  )
  const ordersPacking = useMemo(
    () =>
      orders
        .filter((order) => !!order.packingStartedAt && !order.packingEndedAt)
        .sort((a, b) => b.createdAt - a.createdAt),
    [orders]
  )
  const ordersReady = useMemo(
    () =>
      orders
        .filter((order) => !!order.packingEndedAt && !order.deliveryStartedAt && !order.fulfilledAt)
        .sort((a, b) => b.createdAt - a.createdAt),
    [orders]
  )

  const getOrderIcon = (orderOrigin: OrderOrigin | undefined): string => {
    switch (orderOrigin) {
      case 'UBER':
        return '/icons/external-store/UBEREATS.svg'
      case 'DELIVEROO':
        return '/icons/external-store/DELIVEROO.svg'
      case 'ZAPP':
        return '/icons/external-store/ZAPP.svg'
      default:
        return ''
    }
  }

  const renderOrder = (order: Order) => {
    const icon = getOrderIcon(order.orderOrigin)

    const isProcessing = (order.packingStartedAt || order.pickingStartedAt) && !order.packingEndedAt
    const orderContent = (
      <Stack direction="row" alignItems="center" justifyContent="flex-end" maxWidth={240} minWidth={240} key={order.id}>
        {order.orderOrigin === 'ZAPP' ? (
          <Stack direction="row" alignItems="baseline" justifyContent="flex-end">
            <Typography
              fontSize="32px"
              fontWeight={500}
              color={isProcessing ? 'success.light' : undefined}
              sx={isProcessing ? { animation: `${blink} 1.5s ease-in-out infinite` } : undefined}
            >
              {order.number.toString().slice(0, 4)}
            </Typography>
            <Typography
              fontSize="56px"
              fontWeight={700}
              color={isProcessing ? 'success.light' : undefined}
              sx={isProcessing ? { animation: `${blink} 1.5s ease-in-out infinite` } : undefined}
            >
              {order.number.toString().slice(4)}
            </Typography>
          </Stack>
        ) : (
          <Typography
            fontSize="56px"
            fontWeight={700}
            color={isProcessing ? 'success.light' : undefined}
            sx={isProcessing ? { animation: `${blink} 1.5s ease-in-out infinite` } : undefined}
          >
            {order.externalId}
          </Typography>
        )}
        {icon && (
          <Stack
            justifyContent="center"
            sx={isProcessing ? { animation: `${blink} 1.5s ease-in-out infinite`, fill: 'success.light' } : undefined}
            color={isProcessing ? 'success.light' : undefined}
            pl={order.orderOrigin === 'UBER' ? 2 : 3}
          >
            <img src={icon} alt={`${order.orderOrigin} storefront`} />
          </Stack>
        )}
      </Stack>
    )

    return orderContent
  }

  return (
    <Stack p={1}>
      {/* Headline */}
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
        <img src="/zapp-logo.svg" alt="Zapp Logo" height={50} />
      </Stack>

      <Stack position="relative" direction={{ xs: 'column', md: 'row' }} flexGrow={1} spacing={2} mt={2}>
        {/* In progress column */}
        <StyledSection>
          <StyledTitle>In Progress</StyledTitle>
          <OrderSection spacing={1} key={`${ordersPicking.map((o) => o.id)}-${ordersPacking.map((o) => o.id)}`}>
            {[...ordersPicking, ...ordersPacking].map((order) => renderOrder(order))}
            {ordersAssigned.map((order) => renderOrder(order))}
          </OrderSection>
        </StyledSection>

        {/* Ready column */}
        <StyledSection>
          <StyledTitle>Ready</StyledTitle>
          <OrderSection spacing={1}>
            {ordersReady.map((order) => renderOrder(order))}
            {deliverooOrders.map((order) => renderOrder(order))}
          </OrderSection>
        </StyledSection>
      </Stack>
    </Stack>
  )
}

export default withAuthentication(React.memo(ExternalStorePage))
