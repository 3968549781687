export const AVG_DAYS_IN_MONTH = 30.437

export function getDiffTranslation(seconds: number): string {
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(seconds / 60 / 60)
  const days = Math.floor(seconds / 60 / 60 / 24)
  const months = Math.floor(seconds / 60 / 60 / 24 / AVG_DAYS_IN_MONTH)

  switch (true) {
    case months > 1:
      return `${months} months`
    case months === 1:
      return `1 month`
    case days > 1:
      return `${days} days`
    case days === 1:
      return `1 day`
    case hours > 1:
      return `${hours} hrs`
    case hours === 1:
      return `1 hr`
    case minutes > 1:
      return `${minutes} mins`
    case minutes === 1:
      return `1 min`
    case seconds > 1:
      return `${seconds} secs`
    case seconds === 1:
      return `1 sec`
    default:
      return ''
  }
}
