import CloseIcon from '@mui/icons-material/Close'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import MinimizeIcon from '@mui/icons-material/Minimize'
import { Box, Button, Chip, Divider, Grid, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import {
  FulfillmentStatus,
  Order,
  OrderDefect,
  OrderDefectType,
  OrderOrigin,
  PickingStatus,
  TaskState,
} from '@quickcommerceltd/zappboard'
import cx from 'classnames'
import { FC, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'
import { makeStyles } from 'tss-react/mui'
import { env } from '../../config'
import { useFeatureFlags } from '../../hooks'
import { sessionState } from '../../state'
import { ReactComponent as FraudCheck } from '../../svg/fraud.svg'
import { formatAddress } from '../../utils/formatContacts'
import { formatNumberRange } from '../../utils/formatNumberRange'
import { ConfirmReturningTaskDialog } from '../ConfirmReturningTaskDialog/ConfirmReturningTaskDialog'
import { CustomerSupportDialog } from '../CustomerSupportDialog/CustomerSupportDialog'
import { OrderHistory } from '../OrderHistory/OrderHistory'
import { OrderLines } from '../OrderLines/OrderLines'
import { PickingDeviceAssignmentDialog } from '../PickingDeviceAssignmentDialog/PickingDeviceAssignmentDialog'
import { Rating } from '../Rating/Rating'

const MINUTE_IN_MS = 60000

const useStyles = makeStyles()((theme) => ({
  root: {
    background: theme.palette.background.paper,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
    position: 'fixed',
    bottom: 64,
    left: 0,
    width: '100%',
    height: '42vh',
    borderTop: 'solid 2px #fff',
    maxHeight: 400,
  },
  badge: { borderRadius: 4, textTransform: 'uppercase', fontWeight: 600 },
  fraudCheck: { background: theme.palette.success.main },
  disabledAssignButton: {
    '&.Mui-disabled': {
      color: grey[700],
      border: '1px solid #616161',
    },
  },
}))

export const OrderDetails: FC<{
  order: Order
  onClose: (id: string) => void
  onMinimize: (id: string) => void
}> = ({ order, onClose, onMinimize }) => {
  const [session] = useRecoilState(sessionState)
  const { classes: styles } = useStyles()
  const [showCustomerSupportDialog, setShowCustomerSupportDialog] = useState(false)
  const [showPickingDeviceDialog, setShowPickingDeviceDialog] = useState(false)
  const [showConfirmReturningTaskDialog, setShowConfirmReturningTaskDialog] = useState(false)

  const close = () => {
    onClose(order.id)
  }

  const minimize = () => {
    onMinimize(order.id)
  }

  const toggleCustomerSupportDialog = () => {
    setShowCustomerSupportDialog(!showCustomerSupportDialog)
  }
  const togglePickingDeviceDialog = () => {
    setShowPickingDeviceDialog(!showPickingDeviceDialog)
  }
  const toggleConfirmReturningTaskDialog = () => {
    setShowConfirmReturningTaskDialog(!showConfirmReturningTaskDialog)
  }

  const isOrderWithReturningTask = useMemo(() => {
    const isReturning = order.deliveryStatus === TaskState.RETURNING

    if (isReturning) setShowConfirmReturningTaskDialog((prev) => !prev)

    return isReturning
  }, [order.deliveryStatus])

  const {
    isOrderHistoryEnabled,
    isContactCustomerSupportEnabled,
    isPickingDeviceAssignmentEnabled,
    isOptionOOSForContactSupportEnabled,
    isConfirmReturningTaskEnabled,
  } = useFeatureFlags(order.warehouseId, [
    'isOrderHistoryEnabled',
    'isContactCustomerSupportEnabled',
    'isPickingDeviceAssignmentEnabled',
    'isOptionOOSForContactSupportEnabled',
    'isConfirmReturningTaskEnabled',
  ])

  const linkToZCommerceOrder = `https://${env}.zcommerce.quickcommerce.org/dashboard/orders/${order.id ?? ''}`

  const isAssignToPickerDisabled =
    [PickingStatus.PICKED, PickingStatus.PACKED].includes(order.pickingStatus) ||
    order.fulfillmentStatus !== FulfillmentStatus.UNFULFILLED

  const metadataStackedTasksValue = order.stackedWithTaskIds?.map((taskId, index) => {
    const linkToZephyrTask = `https://${env}.zephyr.quickcommerce.org/tasks/${taskId}`
    return (
      <span key={taskId}>
        <Link href={linkToZephyrTask} underline="none" color="primary" rel="noopener" target="_blank">
          {`Task ${index + 1}`}
        </Link>
        {order.stackedWithTaskIds && order.stackedWithTaskIds.length > index + 1 ? ', ' : ''}
      </span>
    )
  })

  const metadataOrderDefectValue = useMemo(() => {
    const translateDefect = (defect: OrderDefect): string => {
      switch (defect.type) {
        case OrderDefectType.ITEM_OOS:
          return `Item OOS: ${defect.value}`
        default:
          return `${defect.type}: ${defect.value}`
      }
    }

    return order.orderDefects?.map((defect, index) => <div key={index}>{translateDefect(defect)}</div>)
  }, [order.orderDefects])

  const marketplace = [OrderOrigin.DELIVEROO, OrderOrigin.UBER, OrderOrigin.JET] as string[]
  const isMarketplaceOrder = !!(order.orderOrigin && marketplace.includes(order.orderOrigin))

  return (
    <>
      <Stack
        className={styles.root}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.code === 'Escape') {
            close()
          }
        }}
      >
        <Stack p={3} pt={2} overflow="auto" height="1" spacing={2}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Typography variant="h2" fontWeight={400}>
              Order{' '}
              <Link href={linkToZCommerceOrder} underline="none" color="inherit" rel="noopener" target="_blank">
                #{order.number} {order.externalId && `(${order.externalId})`}
              </Link>
            </Typography>

            <Button
              style={{
                minWidth: '28px',
                maxWidth: '28px',
              }}
              onClick={() => navigator.clipboard.writeText(`${order.number}`)}
            >
              <Tooltip title={'Copy order number to clipboard'}>
                <FileCopyOutlinedIcon color="primary"></FileCopyOutlinedIcon>
              </Tooltip>
            </Button>

            {order.isNewCustomer && <Chip color="primary" className={cx(styles.badge)} label="new customer" />}

            <Chip
              color="primary"
              className={styles.badge}
              sx={{ backgroundColor: 'primary.main' }}
              label={order.fulfillmentStatus}
            />

            {order.isFraud && (
              <Chip
                color="primary"
                className={styles.badge}
                sx={{ backgroundColor: 'success.main' }}
                icon={<FraudCheck />}
                label="Fraud check"
              />
            )}

            {!!order.customerRating?.rating && (
              <Rating rating={order.customerRating.rating} note={order.customerRating?.note} />
            )}

            <Stack direction="row" flexGrow="1" justifyContent="flex-end" alignItems="center" spacing={1}>
              {isConfirmReturningTaskEnabled && isOrderWithReturningTask && (
                <Button
                  variant="outlined"
                  color="primary"
                  children="Confirm Returning Task"
                  onClick={toggleConfirmReturningTaskDialog}
                />
              )}

              {isPickingDeviceAssignmentEnabled && (
                <Tooltip
                  title={isAssignToPickerDisabled ? 'Order fulfilled, cancelled or packed' : ''}
                  placement="left"
                >
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      children="Assign To Picker"
                      onClick={togglePickingDeviceDialog}
                      disabled={isAssignToPickerDisabled}
                    />
                  </div>
                </Tooltip>
              )}

              {!session.user?.supervisor && isContactCustomerSupportEnabled && (
                <Tooltip placement="top" title={!!order.externalId ? 'Not available for external orders' : ''}>
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      children="Contact Support"
                      onClick={toggleCustomerSupportDialog}
                      disabled={!!order.externalId}
                    />
                  </div>
                </Tooltip>
              )}

              <Stack direction="row">
                <IconButton color="inherit" onClick={minimize} size="small">
                  <MinimizeIcon />
                </IconButton>

                <IconButton color="inherit" onClick={close} size="small">
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>

          <Stack>
            <Grid container columnSpacing={6}>
              <Grid
                item
                xs={12}
                sm={isOrderHistoryEnabled ? 12 : 8}
                md={isOrderHistoryEnabled ? 4 : 8}
                lg={isOrderHistoryEnabled ? 4 : 8}
              >
                <Box ml={-1}>
                  <OrderLines order={order} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={isOrderHistoryEnabled ? 12 : 4} md={4} lg={4}>
                <Typography variant="h3" pt={1}>
                  Metadata
                </Typography>
                {[
                  {
                    label: 'Promised delivery time',
                    value: formatNumberRange(
                      order.promisedDeliveryTime / MINUTE_IN_MS,
                      order.promisedDeliveryTime / MINUTE_IN_MS + 10,
                      '-',
                      'min'
                    ),
                  },
                  {
                    label: 'Delivery Address',
                    value: formatAddress(order.shippingAddress),
                  },
                  {
                    label: 'Delivery option',
                    value: order.metadata.addressDeliveryOption,
                  },
                  {
                    label: 'Rider note',
                    value: order.metadata.riderNote,
                  },
                  {
                    label: 'Shipping method',
                    value: order.shippingMethod,
                  },
                  {
                    label: 'Contains 18+ Items',
                    value: [
                      `Alcohol: ${order.containsAlcohol ? 'yes' : 'no'}`,
                      `Tobacco: ${order.containsTobacco ? 'yes' : 'no'}`,
                    ].join(', '),
                  },
                  {
                    label: 'Weight',
                    value: order.weight,
                  },
                  {
                    label: 'Stacked tasks',
                    value: metadataStackedTasksValue,
                  },
                  {
                    label: 'Order defects',
                    value: metadataOrderDefectValue,
                  },
                ]
                  .filter((metadata) => {
                    return Array.isArray(metadata.value) ? !!metadata.value.length : !!metadata.value
                  })
                  .map(({ label, value }) => (
                    <div key={label}>
                      <Box my={1}>
                        <Divider />
                      </Box>
                      <Typography>
                        <b>{label}</b>
                      </Typography>
                      <Typography variant="body2">{value || '—'}</Typography>
                    </div>
                  ))}
              </Grid>
              {!!isOrderHistoryEnabled && (
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <OrderHistory
                    orderId={order.id}
                    origin={!order.externalId ? 'ZAPP' : 'EXTERNAL'}
                    pictureOfDelivery={order.thirdPartyProviderPictureOfDelivery}
                  />
                </Grid>
              )}
            </Grid>
          </Stack>
        </Stack>
      </Stack>

      <ConfirmReturningTaskDialog
        open={showConfirmReturningTaskDialog}
        taskId={order.deliveryTaskId}
        userEmail={session.user?.email?.split('@')?.[0] || ''}
        riderName={order.rider?.name || ''}
        estimatedDeliveryAt={order.estimatedDeliveryAt || 0}
        deliveryStartedAt={order.deliveryStartedAt || 0}
        streetAddress={order.shippingAddress?.streetAddress1 || ''}
        orderNumber={`${order.number}`}
        orderId={order.id}
        warehouseId={order.warehouseId}
        onClose={toggleConfirmReturningTaskDialog}
        isMarketplaceOrder={isMarketplaceOrder}
      />
      <PickingDeviceAssignmentDialog
        open={showPickingDeviceDialog}
        orderId={order.id}
        orderNumber={order.number}
        warehouseId={order.warehouseId}
        onClose={togglePickingDeviceDialog}
      />
      <CustomerSupportDialog
        open={showCustomerSupportDialog}
        order={order}
        userEmail={session.user?.email || ''}
        onClose={toggleCustomerSupportDialog}
        isOptionOOSForContactSupportEnabled={isOptionOOSForContactSupportEnabled}
      />
    </>
  )
}
