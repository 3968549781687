import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { Order, OutOfStockItem, SupportInquiry, SupportInquiryCode } from '@quickcommerceltd/zappboard'
import { FC, useCallback, useState } from 'react'
import { useCustomerSupportDialogStyles } from '../CustomerSupportDialog.style'
import { OrderLinesOOS } from '.'

interface Props {
  onButtonClick: () => void
  loading: boolean
  order: Order
  setRequest: (inquiry: Partial<SupportInquiry>) => void
}

export const CustomerSupportOOS: FC<Props> = ({ onButtonClick, loading, order, setRequest }) => {
  const { classes } = useCustomerSupportDialogStyles()
  const [itemsOOS, setItemsOOS] = useState<OutOfStockItem[] | undefined>(undefined)

  const handleUpdate = useCallback(
    (value) => {
      setItemsOOS(value)
      setRequest({
        code: SupportInquiryCode.ITEM_OOS,
        delayReason: undefined,
        cancelReason: undefined,
        message: undefined,
        outOfStockItems: value,
      })
    },
    [setRequest]
  )

  return (
    <Box>
      <Typography className={classes.reasonHeadline}>Which Items are OOS?</Typography>
      <Typography className={classes.oosText}>You can set the missing amout for multiple items</Typography>
      <Box>
        <OrderLinesOOS order={order} setItemsOOS={handleUpdate} itemsOOS={itemsOOS} />
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={onButtonClick}
        fullWidth
        disabled={!itemsOOS || loading}
        className={classes.button}
        children={loading ? <CircularProgress size={20} color="inherit" /> : 'Create Zendesk Ticket'}
      />
    </Box>
  )
}
