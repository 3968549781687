import { TaskStatsV2, WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { OrderOrigin } from '@quickcommerceltd/zappboard/lib/types/order-origin'
import { ChartData } from '../../types/ChartData'
import { DOTMetrics } from '../../types/DOTMetrics'
import { MinMaxWarehouseMetric } from '../../types/MinMaxValue'
import { formatPercentString } from '../formatPercentString'
import { filterStatsByKey } from './filterStatsByKey'
import { getCommonTimeData, getStatsIdToLocalTime } from './getCommonTimeData'
import { getSafeAverage } from './getSafeAverage'
import { getSafePercent } from './getSafePercent'

const marketplace = [OrderOrigin.DELIVEROO, OrderOrigin.UBER, OrderOrigin.JET] as string[]

interface DotReducerStats {
  completedTasksNum: number
  in20MinNum: number
  in30MinNum: number
  in45MinNum: number
  in60MinNum: number
  in20MinPercent: number
  in30MinPercent: number
  in45MinPercent: number
  in60MinPercent: number
  count20: number
  count30: number
  count45: number
  count60: number
}

const defaultStats: DotReducerStats = {
  completedTasksNum: 0,
  in20MinNum: 0,
  in30MinNum: 0,
  in45MinNum: 0,
  in60MinNum: 0,
  in20MinPercent: 0,
  in30MinPercent: 0,
  in45MinPercent: 0,
  in60MinPercent: 0,
  count20: 0,
  count30: 0,
  count45: 0,
  count60: 0,
}

const avgDotAcrossAllOriginAndPartners = (taskStats: TaskStatsV2[]) => {
  const result = taskStats.reduce(
    (acc, curr) => ({
      completedTasksNum: acc.completedTasksNum + curr.completedTasksNum,
      in20MinNum: acc.in20MinNum + curr.completedTasksWithinTargets.in20MinNum,
      in30MinNum: acc.in30MinNum + curr.completedTasksWithinTargets.in30MinNum,
      in45MinNum: acc.in45MinNum + curr.completedTasksWithinTargets.in45MinNum,
      in60MinNum: acc.in60MinNum + curr.completedTasksWithinTargets.in60MinNum,
      in20MinPercent: acc.in20MinPercent + curr.completedTasksWithinTargets.in20MinPercent,
      in30MinPercent: acc.in30MinPercent + curr.completedTasksWithinTargets.in30MinPercent,
      in45MinPercent: acc.in45MinPercent + curr.completedTasksWithinTargets.in45MinPercent,
      in60MinPercent: acc.in60MinPercent + curr.completedTasksWithinTargets.in60MinPercent,
      count20: acc.count20 + (curr.completedTasksWithinTargets.in20MinNum > 0 ? 1 : 0),
      count30: acc.count30 + (curr.completedTasksWithinTargets.in30MinNum > 0 ? 1 : 0),
      count45: acc.count45 + (curr.completedTasksWithinTargets.in45MinNum > 0 ? 1 : 0),
      count60: acc.count60 + (curr.completedTasksWithinTargets.in60MinNum > 0 ? 1 : 0),
    }),
    { ...defaultStats }
  )

  result.in20MinPercent = getSafeAverage(result.in20MinPercent, result.count20)
  result.in30MinPercent = getSafeAverage(result.in30MinPercent, result.count30)
  result.in45MinPercent = getSafeAverage(result.in45MinPercent, result.count45)
  result.in60MinPercent = getSafeAverage(result.in60MinPercent, result.count60)

  return result
}

const aggReducer = (acc: DotReducerStats, curr: DotReducerStats) => ({
  completedTasksNum: acc.completedTasksNum + curr.completedTasksNum,
  in20MinNum: acc.in20MinNum + curr.in20MinNum,
  in30MinNum: acc.in30MinNum + curr.in30MinNum,
  in45MinNum: acc.in45MinNum + curr.in45MinNum,
  in60MinNum: acc.in60MinNum + curr.in60MinNum,
  in20MinPercent: acc.in20MinPercent + curr.in20MinPercent,
  in30MinPercent: acc.in30MinPercent + curr.in30MinPercent,
  in45MinPercent: acc.in45MinPercent + curr.in45MinPercent,
  in60MinPercent: acc.in60MinPercent + curr.in60MinPercent,
  count20: acc.count20 + (curr.in20MinNum > 0 ? 1 : 0),
  count30: acc.count30 + (curr.in30MinNum > 0 ? 1 : 0),
  count45: acc.count45 + (curr.in45MinNum > 0 ? 1 : 0),
  count60: acc.count60 + (curr.in60MinNum > 0 ? 1 : 0),
})

export const getDotMetrics = (stats?: WarehouseStatsV2[], originFilter: string[] = []): DOTMetrics[] => {
  if (!stats?.length) return []

  return stats
    ?.map((stat) => {
      const warehouses = Object.values(stat.statsByWarehouse)
      const dotAllWarehouse = warehouses
        .map((warehouse) =>
          avgDotAcrossAllOriginAndPartners(
            filterStatsByKey<TaskStatsV2>(
              warehouse.delivery,
              originFilter.filter((origin) => !marketplace.includes(origin))
            )
          )
        )
        .reduce(aggReducer, { ...defaultStats })

      dotAllWarehouse.in20MinPercent = getSafePercent(dotAllWarehouse.in20MinNum, dotAllWarehouse.completedTasksNum)
      dotAllWarehouse.in30MinPercent = getSafePercent(dotAllWarehouse.in30MinNum, dotAllWarehouse.completedTasksNum)
      dotAllWarehouse.in45MinPercent = getSafePercent(dotAllWarehouse.in45MinNum, dotAllWarehouse.completedTasksNum)
      dotAllWarehouse.in60MinPercent = getSafePercent(dotAllWarehouse.in60MinNum, dotAllWarehouse.completedTasksNum)
      const localTime = getStatsIdToLocalTime(stat.id)
      return {
        dotMetrics: dotAllWarehouse,
        ...getCommonTimeData(localTime),
        sortId: localTime.toMillis(),
      }
    })
    .sort((a, b) => a.sortId - b.sortId)
}

export const getDOTHourlyChartData = (metrics: DOTMetrics[]): [ChartData[], ChartData[], ChartData[], ChartData[]] => [
  metrics.map((metric, idx) => ({
    chartData: {
      x: idx === metrics.length - 1 ? 'Now' : metric.minute,
      y: Math.round(metric.dotMetrics.in20MinPercent),
      time: metric.time,
    },
    tooltip: `Time: ${metric.time}, Orders: ${metric.dotMetrics.in20MinNum}`,
  })),
  metrics.map((metric, idx) => ({
    chartData: {
      x: idx === metrics.length - 1 ? 'Now' : metric.minute,
      y: Math.round(metric.dotMetrics.in20MinPercent + metric.dotMetrics.in30MinPercent),
      time: metric.time,
    },
    tooltip: `Time: ${metric.time} - Orders: ${metric.dotMetrics.in20MinNum + metric.dotMetrics.in30MinNum}`,
  })),
  metrics.map((metric, idx) => ({
    chartData: {
      x: idx === metrics.length - 1 ? 'Now' : metric.minute,
      y: Math.round(
        metric.dotMetrics.in20MinPercent + metric.dotMetrics.in30MinPercent + metric.dotMetrics.in45MinPercent
      ),
      time: metric.time,
    },
    tooltip: `Time: ${metric.time} - Orders: ${
      metric.dotMetrics.in20MinNum + metric.dotMetrics.in30MinNum + metric.dotMetrics.in45MinNum
    }`,
  })),
  metrics.map((metric, idx) => ({
    chartData: {
      x: idx === metrics.length - 1 ? 'Now' : metric.minute,
      y: Math.round(
        metric.dotMetrics.in20MinPercent +
          metric.dotMetrics.in30MinPercent +
          metric.dotMetrics.in45MinPercent +
          metric.dotMetrics.in60MinPercent
      ),
      time: metric.time,
    },
    tooltip: `Time: ${metric.time} - Orders: ${
      metric.dotMetrics.in20MinNum +
      metric.dotMetrics.in30MinNum +
      metric.dotMetrics.in45MinNum +
      metric.dotMetrics.in60MinNum
    }`,
  })),
]

export const getDOTMinMax = (
  warehouseStats?: WarehouseStatsV2,
  originFilter: string[] = []
): {
  d20: MinMaxWarehouseMetric
  d30: MinMaxWarehouseMetric
  d45: MinMaxWarehouseMetric
  d60: MinMaxWarehouseMetric
} | null => {
  if (!warehouseStats) return null

  const warehouses = Object.values(warehouseStats.statsByWarehouse)
  const dotAllWarehouse = warehouses
    .map((warehouse) => ({
      avgDotAcrossAllOriginAndPartners: avgDotAcrossAllOriginAndPartners(
        filterStatsByKey<TaskStatsV2>(
          warehouse.delivery,
          originFilter.filter((origin) => !marketplace.includes(origin))
        )
      ),
      warehouse: warehouse.warehouse.shortName,
    }))
    .reduce((acc, curr) => {
      if (!(curr.warehouse in acc)) {
        acc[curr.warehouse] = { ...defaultStats, warehouse: curr.warehouse }
      }
      acc[curr.warehouse] = {
        ...aggReducer(acc[curr.warehouse], curr.avgDotAcrossAllOriginAndPartners),
        warehouse: curr.warehouse,
      }
      return acc
    }, {} as Record<string, DotReducerStats & { warehouse: string }>)

  const dotAllWarehouseValues = Object.values(dotAllWarehouse)
  const sortedStats = dotAllWarehouseValues.map((warehouseStat) => {
    warehouseStat.in20MinPercent = getSafePercent(warehouseStat.in20MinNum, warehouseStat.completedTasksNum)
    warehouseStat.in30MinPercent = getSafePercent(
      warehouseStat.in30MinNum + warehouseStat.in20MinNum,
      warehouseStat.completedTasksNum
    )
    warehouseStat.in45MinPercent = getSafePercent(
      warehouseStat.in45MinNum + warehouseStat.in30MinNum + warehouseStat.in20MinNum,
      warehouseStat.completedTasksNum
    )
    warehouseStat.in60MinPercent = getSafePercent(
      warehouseStat.in60MinNum + warehouseStat.in45MinNum + warehouseStat.in30MinNum + warehouseStat.in20MinNum,
      warehouseStat.completedTasksNum
    )
    return warehouseStat
  })

  const sortedD20 = sortedStats.sort((a, b) => a.in20MinPercent - b.in20MinPercent)
  const sortedD30 = sortedStats.sort((a, b) => a.in30MinPercent - b.in30MinPercent)
  const sortedD45 = sortedStats.sort((a, b) => a.in45MinPercent - b.in45MinPercent)
  const sortedD60 = sortedStats.sort((a, b) => a.in60MinPercent - b.in60MinPercent)

  if (!sortedStats.length) {
    return null
  }

  return {
    d20: {
      min: {
        value: formatPercentString(sortedD20[0].in20MinPercent),
        warehouse: sortedD20[0].warehouse,
      },
      max: {
        value: formatPercentString(sortedD20[sortedD20.length - 1].in20MinPercent),
        warehouse: sortedD20[sortedD20.length - 1].warehouse,
      },
    },
    d30: {
      min: {
        value: formatPercentString(sortedD30[0].in30MinPercent),
        warehouse: sortedD30[0].warehouse,
      },
      max: {
        value: formatPercentString(sortedD30[sortedD30.length - 1].in30MinPercent),
        warehouse: sortedD30[sortedD30.length - 1].warehouse,
      },
    },
    d45: {
      min: {
        value: formatPercentString(sortedD45[0].in45MinPercent),
        warehouse: sortedD45[0].warehouse,
      },
      max: {
        value: formatPercentString(sortedD45[sortedD45.length - 1].in45MinPercent),
        warehouse: sortedD45[sortedD45.length - 1].warehouse,
      },
    },
    d60: {
      min: {
        value: formatPercentString(sortedD60[0].in60MinPercent),
        warehouse: sortedD60[0].warehouse,
      },
      max: {
        value: formatPercentString(sortedD60[sortedD60.length - 1].in60MinPercent),
        warehouse: sortedD60[sortedD60.length - 1].warehouse,
      },
    },
  }
}
