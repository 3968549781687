import { TaskStatsV2, WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { OrderOrigin } from '@quickcommerceltd/zappboard/lib/types/order-origin'
import { ChartData } from '../../types/ChartData'
import { DTPMetrics } from '../../types/DTPMetrics'
import { MinMaxWarehouseMetric } from '../../types/MinMaxValue'
import { formatPercentString } from '../formatPercentString'
import { filterStatsByKey } from './filterStatsByKey'
import { getCommonTimeData, getStatsIdToLocalTime } from './getCommonTimeData'
import { getSafeAverage } from './getSafeAverage'
import { getSafePercent } from './getSafePercent'

interface ReduceTaskStats {
  completedTasksWithinPromiseNum: number
  completedTasksWithinPromisePercent: number
  completedTasksNum: number
}

const defaultReducerValues = {
  completedTasksWithinPromiseNum: 0,
  completedTasksWithinPromisePercent: 0,
  completedTasksNum: 0,
}

const reduceTaskStats = (acc: ReduceTaskStats, curr: TaskStatsV2 | ReduceTaskStats) => ({
  completedTasksWithinPromiseNum: acc.completedTasksWithinPromiseNum + curr.completedTasksWithinPromiseNum,
  completedTasksWithinPromisePercent: acc.completedTasksWithinPromisePercent + curr.completedTasksWithinPromisePercent,
  completedTasksNum: acc.completedTasksNum + curr.completedTasksNum,
})

const avgDeliveryTimeAcrossAllOriginAndPartners = (taskStats: TaskStatsV2[]) => {
  const result = taskStats.reduce(reduceTaskStats, {
    ...defaultReducerValues,
  })

  result.completedTasksWithinPromisePercent = getSafeAverage(
    result.completedTasksWithinPromisePercent,
    taskStats.length
  )

  return result
}

const marketplace = [OrderOrigin.DELIVEROO, OrderOrigin.UBER, OrderOrigin.JET] as string[]

export const getDtpMetrics = (stats?: WarehouseStatsV2[], originFilter: string[] = []): DTPMetrics[] => {
  if (!stats?.length) return []

  return stats
    ?.map((stat) => {
      const warehouses = Object.values(stat.statsByWarehouse)
      const dtpAllWarehouse = warehouses
        .map((warehouse) =>
          avgDeliveryTimeAcrossAllOriginAndPartners(
            filterStatsByKey<TaskStatsV2>(
              warehouse.delivery,
              originFilter.filter((origin) => !marketplace.includes(origin))
            )
          )
        )
        .reduce(reduceTaskStats, { ...defaultReducerValues })

      dtpAllWarehouse.completedTasksWithinPromisePercent = getSafePercent(
        dtpAllWarehouse.completedTasksWithinPromiseNum,
        dtpAllWarehouse.completedTasksNum
      )
      const localTime = getStatsIdToLocalTime(stat.id)
      return {
        dtpMetrics: dtpAllWarehouse,
        ...getCommonTimeData(localTime),
        sortId: localTime.toMillis(),
      }
    })
    .sort((a, b) => a.sortId - b.sortId)
}

export const getDTPHourlyChartData = (metrics: DTPMetrics[]): ChartData[] =>
  metrics.map((metric, idx) => ({
    chartData: {
      x: idx === metrics.length - 1 ? 'Now' : metric.minute,
      y: Math.round(metric.dtpMetrics.completedTasksWithinPromisePercent),
      time: metric.time,
    },
    tooltip: `Time: ${metric.time}`,
  }))

export const getDTPMinMax = (
  warehouseStats?: WarehouseStatsV2,
  originFilter: string[] = []
): MinMaxWarehouseMetric | null => {
  if (!warehouseStats) return null

  const warehouses = Object.values(warehouseStats.statsByWarehouse)
  const dtpAllWarehouse = warehouses
    .map((warehouse) => ({
      avgDeliveryTimeAcrossAllOriginAndPartners: avgDeliveryTimeAcrossAllOriginAndPartners(
        filterStatsByKey<TaskStatsV2>(
          warehouse.delivery,
          originFilter.filter((origin) => !marketplace.includes(origin))
        )
      ),
      warehouse: warehouse.warehouse.shortName,
    }))
    .reduce((acc, curr) => {
      if (!(curr.warehouse in acc)) {
        acc[curr.warehouse] = { ...defaultReducerValues, warehouse: curr.warehouse }
      }
      acc[curr.warehouse] = {
        ...reduceTaskStats(acc[curr.warehouse], curr.avgDeliveryTimeAcrossAllOriginAndPartners),
        warehouse: curr.warehouse,
      }
      return acc
    }, {} as Record<string, ReduceTaskStats & { warehouse: string }>)

  const dtpAllWarehouseValues = Object.values(dtpAllWarehouse)
  const sortedStats = dtpAllWarehouseValues
    .map((warehouseStat) => {
      warehouseStat.completedTasksWithinPromisePercent = getSafePercent(
        warehouseStat.completedTasksWithinPromiseNum,
        warehouseStat.completedTasksNum
      )
      return warehouseStat
    })
    .sort((a, b) => a.completedTasksWithinPromisePercent - b.completedTasksWithinPromisePercent)

  if (sortedStats.length) {
    return {
      min: {
        value: formatPercentString(sortedStats[0].completedTasksWithinPromisePercent),
        warehouse: sortedStats[0].warehouse,
      },
      max: {
        value: formatPercentString(sortedStats[sortedStats.length - 1].completedTasksWithinPromisePercent),
        warehouse: sortedStats[sortedStats.length - 1].warehouse,
      },
    }
  }

  return null
}
