import { Box, Container, ContainerProps } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  marginTop?: number
  marginBottom?: number
} & Pick<ContainerProps, 'maxWidth'>

const Wrapper = ({ children, maxWidth = 'lg', marginTop = 3, marginBottom = 5 }: Props) => {
  return (
    <Container maxWidth={maxWidth}>
      <Box mt={marginTop} mb={marginBottom}>
        {children}
      </Box>
    </Container>
  )
}

export default Wrapper
