import { Box } from '@mui/material'
import { Order } from '@quickcommerceltd/zappboard'
import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ErrorAlertV2 } from '../../components/ErrorAlertV2/ErrorAlertV2'
import { OrderListV2 } from '../../components/OrderListV2/OrderListV2'
import { OrderSearchV2 } from '../../components/OrderSearchV2/OrderSearchV2'
import { SingleMetricPanel } from '../../components/SingleMetricPanel/SingleMetricPanel'
import { WidgetPanel } from '../../components/WidgetPanel/WidgetPanel'
import { useCommonWarehouseStatsV2 } from '../../hooks/useCommonWarehouseStatsV2/useCommonWarehouseStatsV2'
import { useL2OrderFilters } from '../../hooks/useL2OrderFilters/useL2OrderFilters'
import { getOrderStats } from '../../utils/warehouseStatsV2/getOrderStats'
import { PageLayout } from '../PageLayout'
import { PanelLayout } from '../PanelLayout'
import { withAuthentication } from '../withAuthentication'

const Orders: React.FC = () => {
  const searchQuery = new URLSearchParams(useLocation().search)
  const [selectedOrderId, setSelectedOrderId] = useState(searchQuery.get('orderId') ?? '')
  const [filter, setFilter] = useState({ searchTerm: '' })
  const [paused, setPaused] = useState(false)

  const { originFilter, warehouseFilter, totalManagedWarehouses, latestAcc, errorLatest, loadingLatest } =
    useCommonWarehouseStatsV2(1)

  const orderStats = useMemo(() => getOrderStats(latestAcc?.[0], originFilter), [latestAcc, originFilter])

  const orderFilters = useL2OrderFilters({
    originFilter,
    warehouseFilter,
    totalManagedWarehouses,
  })

  const setOrder = (order: Order) => {
    setSelectedOrderId(order.id)
  }

  if (errorLatest) {
    return <ErrorAlertV2 error={errorLatest} />
  }

  return (
    <PageLayout title="Orders">
      <PanelLayout>
        <SingleMetricPanel
          heading={Number(orderStats?.fulfilledOrdersNum ?? 0).toString()}
          subHeading="Fulfilled Orders"
          miniHeading={'Now, since 00:00'}
          loading={loadingLatest}
        />
        <SingleMetricPanel
          heading={Number(orderStats?.cancelledOrdersNum ?? 0).toString()}
          subHeading={'Cancelled Orders'}
          miniHeading={'Now, since 00:00'}
          loading={loadingLatest}
        />
        <SingleMetricPanel
          heading={Number(orderStats?.unfulfilledOrdersNum ?? 0).toString()}
          subHeading={'Unfulfilled Orders'}
          miniHeading={'Now'}
          loading={loadingLatest}
        />
      </PanelLayout>
      <WidgetPanel sx={{ width: '100%', p: 0, height: '100%', overflow: 'hidden' }} loading={loadingLatest}>
        <Box px={1} py={2} display="flex" justifyContent="flex-end" alignItems="center">
          {paused && (
            <Box
              sx={{
                mr: 5,
                fontSize: '14px',
                lineHeight: '140%',
                fontWeight: 600,
                color: 'warning.light',
              }}
            >
              Zappboard refreshes have been paused, please scroll to the top or hit refresh for the latest data
            </Box>
          )}
          <OrderSearchV2 onFilterChange={setFilter} selectedOrderId={selectedOrderId} />
        </Box>
        <OrderListV2
          filters={orderFilters}
          onSelect={setOrder}
          onClose={() => setSelectedOrderId('')}
          onMinimize={() => setSelectedOrderId('')}
          selectedOrderId={selectedOrderId}
          searchTerm={filter.searchTerm}
          onPause={setPaused}
          refresh
        />
      </WidgetPanel>
    </PageLayout>
  )
}

export default withAuthentication(React.memo(Orders))
