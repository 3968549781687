import { Order } from '@quickcommerceltd/zappboard'
import React, { useMemo, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import { useLocation, useNavigate } from 'react-router-dom'
import { getBarLineChartConfig } from '../../components/BarLineChart/getBarLineChartConfig'
import { ErrorAlertV2 } from '../../components/ErrorAlertV2/ErrorAlertV2'
import { NoDataAvailable } from '../../components/NoDataAvailable/NoDataAvailable'
import { OrderListV2 } from '../../components/OrderListV2/OrderListV2'
import { SingleMetricPanel } from '../../components/SingleMetricPanel/SingleMetricPanel'
import { WidgetPanel } from '../../components/WidgetPanel/WidgetPanel'
import { useCommonWarehouseStatsV2 } from '../../hooks/useCommonWarehouseStatsV2/useCommonWarehouseStatsV2'
import { useL2OrderFilters } from '../../hooks/useL2OrderFilters/useL2OrderFilters'
import { get01RatingMetrics, getLowRatingHourlyChartData } from '../../utils/warehouseStatsV2/get01RatingMetrics'
import { getStatsIdToLocalString, getStatsIdToLocalTime } from '../../utils/warehouseStatsV2/getCommonTimeData'
import { PageLayout } from '../PageLayout'
import { PanelLayout } from '../PanelLayout'
import { withAuthentication } from '../withAuthentication'

const LowRatings: React.FC = () => {
  const searchQuery = new URLSearchParams(useLocation().search)
  const navigate = useNavigate()
  const [selectedOrderId, setSelectedOrderId] = useState(searchQuery.get('orderId') ?? '')

  const {
    originFilter,
    warehouseFilter,
    latestAcc,
    latestHourly,
    lastWeekHourly,
    lastWeekSameTimeAcc,
    yesterdaySameTimeAcc,
    errorLatest,
    errorHourly,
    lastWeekError,
    lastWeekEndOfDayError,
    lastWeekStatsSameTimeError,
    yesterdayStatsSameTimeError,
    totalManagedWarehouses,
    loadingLatest,
    loadingHourly,
    lastWeekLoading,
    lastWeekStatsSameTimeLoading,
    yesterdayStatsSameTimeLoading,
  } = useCommonWarehouseStatsV2(24)

  // generate oos metrics for each time line
  const lowRatingsMetricsLatest = useMemo(() => get01RatingMetrics(latestAcc, originFilter), [latestAcc, originFilter])
  const lowRatingsMetricsHourly = useMemo(
    () => get01RatingMetrics(latestHourly, originFilter),
    [latestHourly, originFilter]
  )
  const lowRatingsMetricsLastWeekHourly = useMemo(
    () => get01RatingMetrics(lastWeekHourly, originFilter),
    [lastWeekHourly, originFilter]
  )
  const lowRatingsMetricsLastWeekSameTime = useMemo(
    () => get01RatingMetrics(lastWeekSameTimeAcc, originFilter),
    [lastWeekSameTimeAcc, originFilter]
  )
  const lowRatingsMetricsYesterdaySameTime = useMemo(
    () => get01RatingMetrics(yesterdaySameTimeAcc, originFilter),
    [yesterdaySameTimeAcc, originFilter]
  )

  // hourly metrics for charting
  const hourlyLowRatingsMetrics = useMemo(
    () => getLowRatingHourlyChartData(lowRatingsMetricsHourly),
    [lowRatingsMetricsHourly]
  )
  const hourlyLowRatingsMetricsLastWeek = useMemo(
    () => getLowRatingHourlyChartData(lowRatingsMetricsLastWeekHourly),
    [lowRatingsMetricsLastWeekHourly]
  )

  const orderFilters = useL2OrderFilters({
    originFilter,
    warehouseFilter,
    totalManagedWarehouses,
    customFilters: [['customerRating.rating', 'in', [0, 1]]],
  })

  const setOrder = (order: Order) => {
    setSelectedOrderId(order.id)
    navigate(`?orderId=${order.id}`, { replace: true })
  }

  const error =
    errorLatest ||
    errorHourly ||
    lastWeekError ||
    lastWeekEndOfDayError ||
    lastWeekStatsSameTimeError ||
    yesterdayStatsSameTimeError

  if (error) {
    return <ErrorAlertV2 error={error} />
  }

  const lowRatingsChartProps = {
    barLabel: 'Today',
    barData: hourlyLowRatingsMetrics.map((metric) => metric.chartData),
    lineLabel: 'Last Week',
    lineData: hourlyLowRatingsMetricsLastWeek.map((metric) => metric.chartData),
  }

  const { data, options } = getBarLineChartConfig({
    lineLabel: lowRatingsChartProps.lineLabel,
    lineData: lowRatingsChartProps.lineData,
    barLabel: lowRatingsChartProps.barLabel,
    barData: lowRatingsChartProps.barData,
    maintainAspectRatio: false,
    borderWidth: 3,
  })

  const hasNoChartData = !lowRatingsChartProps.lineData.length && !lowRatingsChartProps.barData.length

  const chart = hasNoChartData ? (
    <NoDataAvailable title="Timeline" />
  ) : (
    <WidgetPanel
      title="Timeline"
      sx={{ width: '100%', height: '300px' }}
      loading={loadingHourly || lastWeekLoading || loadingLatest || lastWeekStatsSameTimeLoading}
    >
      <Chart type="bar" data={data} options={options} style={{ paddingBottom: 22, maxHeight: 240 }} />
    </WidgetPanel>
  )

  const lowRatingsLatestAcc = lowRatingsMetricsLatest[0]
  const lowRatingsLastWeekAcc = lowRatingsMetricsLastWeekSameTime[0]
  const lowRatingsYesterdayAcc = lowRatingsMetricsYesterdaySameTime[0]
  const lowRatingsLatestHourly = lowRatingsMetricsHourly[lowRatingsMetricsHourly.length - 1]

  const hourlyTime = latestHourly.length ? getStatsIdToLocalTime(latestHourly?.[latestHourly.length - 1]?.id) : null
  const dodTime = getStatsIdToLocalString(yesterdaySameTimeAcc?.[0]?.id)
  const wowTime = getStatsIdToLocalString(lastWeekSameTimeAcc?.[0]?.id)

  return (
    <PageLayout title="0/1 Ratings">
      <PanelLayout>
        <SingleMetricPanel
          heading={lowRatingsLatestAcc?.lowRatings >= 0 ? `${lowRatingsLatestAcc.lowRatings.toFixed(0)}` : 'N/A'}
          subHeading="Today"
          miniHeading={`Now, since 00:00`}
          loading={loadingLatest}
        />
        <SingleMetricPanel
          heading={lowRatingsLatestHourly?.lowRatings >= 0 ? `${lowRatingsLatestHourly.lowRatings.toFixed(0)}` : 'N/A'}
          subHeading="Today, This Hour"
          miniHeading={`Now, since ${hourlyTime ? hourlyTime.toFormat('HH:mm') : 'N/A'}`}
          loading={loadingHourly}
        />
        <SingleMetricPanel
          heading={lowRatingsYesterdayAcc?.lowRatings >= 0 ? `${lowRatingsYesterdayAcc.lowRatings.toFixed(0)}` : 'N/A'}
          subHeading={'DoD Comparison'}
          miniHeading={`${dodTime}, since 00:00`}
          loading={yesterdayStatsSameTimeLoading}
        />
        <SingleMetricPanel
          heading={lowRatingsLastWeekAcc?.lowRatings >= 0 ? `${lowRatingsLastWeekAcc.lowRatings.toFixed(0)}` : 'N/A'}
          subHeading={'WoW Comparison'}
          miniHeading={`${wowTime}, since 00:00`}
          loading={lastWeekStatsSameTimeLoading}
        />
      </PanelLayout>
      {chart}
      <WidgetPanel sx={{ width: '100%', p: 0, height: '100%', overflow: 'hidden' }}>
        <OrderListV2
          filters={orderFilters}
          onSelect={setOrder}
          onClose={() => setSelectedOrderId('')}
          onMinimize={() => setSelectedOrderId('')}
          selectedOrderId={selectedOrderId}
        />
      </WidgetPanel>
    </PageLayout>
  )
}
export default withAuthentication(React.memo(LowRatings))
