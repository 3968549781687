import { WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'
import { sessionState } from '../../state'
import { dateTimeToSnapshotId } from '../../utils/warehouseStatsV2/dateTimeToSnapshotId'
import { filterStatsByWarehouse } from '../../utils/warehouseStatsV2/filterStatsByWarehouse'
import { useStoresToHide } from '../useStoresToHide'
import { useWarehouseStatsRollingV2, useWarehouseStatsV2 } from '../useWarehouseStatsV2'
import { useWarehouseStatsLatestV2 } from '../useWarehouseStatsV2/useWarehouseStatsLatestV2'

export const useCommonWarehouseStatsV2 = (quantity = 8) => {
  const [session] = useRecoilState(sessionState)
  const isAdmin = !!session.user?.admin
  const warehouseFilter = session.warehouseFilter
  const originFilter = session.originFilter
  const deliveryPartnerFilter = session.deliveryPartnerFilter

  const storesToHide = useStoresToHide()

  const managedWarehouses = useMemo(
    () => (session.user?.manager ? session.user?.managedWarehouses : undefined),
    [session.user]
  )

  const [latestStat, setLatestStat] = useState<WarehouseStatsV2[]>([])
  const [wowStat, setWowStat] = useState<WarehouseStatsV2[]>([])
  const [dodStat, setDodStat] = useState<WarehouseStatsV2[]>([])

  // get warehouse stats for now and last week same time
  const now = DateTime.now()

  // eslint is wrong here, we only want new DateTime returned on day, hour and minute changes
  /* eslint-disable react-hooks/exhaustive-deps */
  const eodLastWeek = useMemo(() => now.minus({ week: 1 }).endOf('day'), [now.day])
  const lastWeekSameHour = useMemo(() => now.minus({ week: 1 }), [now.hour])
  const lastWeekSameTime = useMemo(() => now.minus({ week: 1 }), [now.minute])
  const sameTimeYesterday = useMemo(() => now.minus({ day: 1 }), [now.minute])
  const lastQuarterHour = useMemo(() => now.minus({ minute: 15 }), [now.minute])
  const lastWeekLastQuarterHour = useMemo(() => now.minus({ minute: 15, week: 1 }), [now.minute])
  /* eslint-enable */

  const [warehouseStatsLatest, loadingLatest, errorLatest] = useWarehouseStatsLatestV2()
  const [warehouseStatsHourly, loadingHourly, errorHourly] = useWarehouseStatsRollingV2({ quantity })
  const [lastWeekStats, lastWeekLoading, lastWeekError] = useWarehouseStatsRollingV2({
    startDate: lastWeekSameHour,
    once: true,
    quantity,
  })
  const [lastWeekStatsEndOfDay, lastWeekEndOfDayLoading, lastWeekEndOfDayError] = useWarehouseStatsV2({
    id: dateTimeToSnapshotId(eodLastWeek),
    once: true,
  })
  const [lastWeekStatsSameTime, lastWeekStatsSameTimeLoading, lastWeekStatsSameTimeError] = useWarehouseStatsV2({
    id: dateTimeToSnapshotId(lastWeekSameTime),
    once: true,
  })
  const [yesterdayStatsSameTime, yesterdayStatsSameTimeLoading, yesterdayStatsSameTimeError] = useWarehouseStatsV2({
    id: dateTimeToSnapshotId(sameTimeYesterday),
    once: true,
  })

  const [lastQuarterHourStats, lastQuarterHourLoading, lastQuarterHourError] = useWarehouseStatsV2({
    id: dateTimeToSnapshotId(lastQuarterHour),
    once: true,
  })

  const [lastWeekLastQuarterHourStats, lastWeekLastQuarterHourLoading, lastWeekLastQuarterHourError] =
    useWarehouseStatsV2({
      id: dateTimeToSnapshotId(lastWeekLastQuarterHour),
      once: true,
    })

  // we filter all stats based on the user's managed warehouses
  const filteredWarehouses = useMemo(
    () => filterStatsByWarehouse(warehouseStatsHourly, storesToHide, managedWarehouses, warehouseFilter),
    [warehouseStatsHourly, managedWarehouses, warehouseFilter]
  )
  const filteredWarehousesLastWeek = useMemo(
    () => filterStatsByWarehouse(lastWeekStats, storesToHide, managedWarehouses, warehouseFilter),
    [lastWeekStats, managedWarehouses, warehouseFilter]
  )
  const filteredWarehousesLastWeekEndOfDay = useMemo(
    () => filterStatsByWarehouse(lastWeekStatsEndOfDay, storesToHide, managedWarehouses, warehouseFilter),
    [lastWeekStatsEndOfDay, managedWarehouses, warehouseFilter]
  )
  const filteredWarehousesLastQuarterHour = useMemo(
    () => filterStatsByWarehouse(lastQuarterHourStats, storesToHide, managedWarehouses, warehouseFilter),
    [lastQuarterHourStats, managedWarehouses, warehouseFilter]
  )
  const filteredWarehousesLastWeekLastQuarterHour = useMemo(
    () => filterStatsByWarehouse(lastWeekLastQuarterHourStats, storesToHide, managedWarehouses, warehouseFilter),
    [lastWeekLastQuarterHourStats, managedWarehouses, warehouseFilter]
  )

  // for smoother minute transitions we only update the latest, dod and last week minute by minute
  // when we have data, there occasions where firestore will return empty data for a brief moment while it switches snapshots
  const filteredWarehousesLatest = useMemo(() => {
    if (warehouseStatsLatest) {
      const stats = filterStatsByWarehouse([warehouseStatsLatest], storesToHide, managedWarehouses, warehouseFilter)
      setLatestStat(stats)
      return stats
    }
    return latestStat
  }, [warehouseStatsLatest, managedWarehouses, warehouseFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  const filteredWarehousesLastWeekSameTime = useMemo(() => {
    if (lastWeekStatsSameTime?.length) {
      const stats = filterStatsByWarehouse(lastWeekStatsSameTime, storesToHide, managedWarehouses, warehouseFilter)
      setWowStat(stats)
      return stats
    }
    return wowStat
  }, [lastWeekStatsSameTime, managedWarehouses, warehouseFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  const filteredWarehousesYesterdaySameTime = useMemo(() => {
    if (yesterdayStatsSameTime?.length) {
      const stats = filterStatsByWarehouse(yesterdayStatsSameTime, storesToHide, managedWarehouses, warehouseFilter)
      setDodStat(stats)
      return stats
    }
    return dodStat
  }, [yesterdayStatsSameTime, managedWarehouses, warehouseFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  const totalManagedWarehouses = useMemo(() => {
    if (!warehouseStatsLatest) return 0
    const stats = filterStatsByWarehouse([warehouseStatsLatest], storesToHide, managedWarehouses)
    return Object.keys(stats[0].statsByWarehouse).length
  }, [warehouseStatsLatest, managedWarehouses])

  const warehouseShortNameMap = useMemo(() => {
    if (!warehouseStatsLatest) return {}
    return Object.keys(warehouseStatsLatest.statsByWarehouse).reduce((acc, key) => {
      const stat = warehouseStatsLatest.statsByWarehouse[key]
      acc[key] = stat.warehouse.shortName
      return acc
    }, {} as Record<string, string>)
  }, [warehouseStatsLatest])

  return {
    isAdmin,
    warehouseFilter,
    originFilter,
    deliveryPartnerFilter,
    latestAcc: filteredWarehousesLatest,
    latestHourly: filteredWarehouses,
    lastWeekHourly: filteredWarehousesLastWeek,
    lastWeekEoD: filteredWarehousesLastWeekEndOfDay,
    lastWeekSameTimeAcc: filteredWarehousesLastWeekSameTime,
    yesterdaySameTimeAcc: filteredWarehousesYesterdaySameTime,
    lastQuarterHourStatsAcc: filteredWarehousesLastQuarterHour,
    lastWeekLastQuarterHourStatsAcc: filteredWarehousesLastWeekLastQuarterHour,
    errorLatest,
    errorHourly,
    lastWeekError,
    lastWeekEndOfDayError,
    lastWeekStatsSameTimeError,
    yesterdayStatsSameTimeError,
    lastWeekLastQuarterHourError,
    lastQuarterHourError,
    loadingLatest,
    loadingHourly,
    lastWeekLoading,
    lastWeekEndOfDayLoading,
    lastWeekStatsSameTimeLoading,
    yesterdayStatsSameTimeLoading,
    lastQuarterHourLoading,
    lastWeekLastQuarterHourLoading,
    totalManagedWarehouses,
    managedWarehouses,
    warehouseShortNameMap,
  }
}
