import { Order } from '@quickcommerceltd/zappboard'
import { calculateReturnArrivalAtStoreAt } from '../../_helpers/calculateReturnArrivalAtStoreAt'
import { ETA_TYPE } from '../_consts/ETA_TYPE'

export const getValidEta = (etaType: string, order: Order): number => {
  switch (true) {
    case etaType === ETA_TYPE.PICKUP:
      return order.estimatedArrivalAtStoreAt || 0
    case etaType === ETA_TYPE.DELIVERY:
      return order.estimatedDeliveryAt || 0
    case etaType === ETA_TYPE.RETURNING:
      const estimatedReturnArrivalAtStoreAt = calculateReturnArrivalAtStoreAt(
        order.estimatedDeliveryAt || 0,
        order.deliveryStartedAt || 0
      )
      return estimatedReturnArrivalAtStoreAt
    default:
      return 0
  }
}
