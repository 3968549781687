import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Tab, Tabs } from '@mui/material'
import { Order } from '@quickcommerceltd/zappboard'
import cx from 'classnames'
import keyBy from 'lodash/keyBy'
import union from 'lodash/union'
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useLocalStorage from 'react-use-localstorage'
import { makeStyles } from 'tss-react/mui'
import { useOrderStyles } from '../../utils/hooks/useOrderStyles'
import { OrderDetails } from '../OrderDetails/OrderDetails'

const useStyles = makeStyles()((theme) => {
  return {
    paper: {
      backgroundColor: theme.palette.background.default,
      borderTop: 'solid 1px #666873',
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 64,
    },
    orderStatus: {
      fontSize: '.8em',
    },
    close: {
      position: 'absolute',
      right: 0,
      width: 40,
      height: '100%',
      borderRadius: 0,
    },
    closeIcon: {
      width: 20,
      height: 20,
    },
    orderNumber: { marginTop: 2, fontWeight: 700, fontSize: '1.1em' },

    status: {
      marginTop: 8,
      fontSize: '.9em',
      textTransform: 'capitalize',
    },
    statusIndicator: {
      width: 12,
      height: 12,
      display: 'inline-block',
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 6,
      position: 'relative',
      top: -1,
    },
    defaultStatus: {
      backgroundColor: theme.palette.primary.main,
    },
  }
})

const useTabsStyles = makeStyles()(() => {
  return {
    indicator: {
      display: 'none',
    },
    root: {
      marginTop: -1,
      color: 'white',
    },
  }
})

const useTabStyles = makeStyles()((theme) => {
  return {
    root: {
      border: 'solid 1px #666873',
      borderBottom: 'solid 1px transparent',
      marginRight: -1,
      lineHeight: '1.2em',
      textAlign: 'left',
      textTransform: 'none',
      height: 64,
      minWidth: 212,
    },
    selected: {
      backgroundColor: theme.palette.background.paper,
      borderTopColor: theme.palette.background.paper,
    },
    wrapper: {
      alignItems: 'flex-start',
    },
  }
})

interface OrderTabsProps {
  activeOrderId: string | undefined
  orders: Order[]
}

export const OrderTabs: FC<OrderTabsProps> = ({ activeOrderId: orderId, orders }) => {
  const { classes: styles } = useStyles()
  const { classes: tabsStyles } = useTabsStyles()
  const { classes: tabStyles } = useTabStyles()
  const { classes: orderStyles } = useOrderStyles()
  const navigate = useNavigate()
  const [tabs, setTabs] = useLocalStorage('tabs', undefined)
  const [order, setOrder] = useState<Order | undefined>()

  // get active order by id
  useEffect(() => {
    const selectedOrder = orders?.find((o) => o.id === orderId)
    setOrder(selectedOrder)
  }, [orders, orderId, setOrder])

  // add tab on change
  useEffect(() => {
    if (!orderId) return
    setTabs(union(tabs.split(','), [orderId]).filter(Boolean).join())
  }, [tabs, orderId, setTabs])

  const removeTab = (id: string) => {
    if (id === orderId) {
      navigate('?', { replace: true })
    }

    const filteredTabs = tabs
      ?.split(',')
      .filter((tabId) => !!tabId && tabId !== id)
      .join()
    setTabs(filteredTabs ?? [])
  }

  const minimizeOrder = () => {
    navigate('?', { replace: true })
  }

  const ordersSet = useMemo(() => keyBy(orders, 'id'), [orders])

  const ids = useMemo(() => (tabs?.split(',') ?? []).filter((tabId) => ordersSet[tabId]), [tabs, ordersSet])

  if (!ids?.length) {
    return null
  }

  return (
    <Box className={styles.paper}>
      {order && <OrderDetails order={order} onClose={removeTab} onMinimize={minimizeOrder} />}
      <Tabs
        classes={tabsStyles}
        scrollButtons="auto"
        variant="scrollable"
        value={orderId || false}
        onChange={(_e, value) => {
          if (value === orderId) {
            return minimizeOrder()
          }
          navigate(`?orderId=${value}`, { replace: true })
        }}
      >
        {ids
          .filter((id) => ordersSet[id])
          .map((id) => {
            const order = ordersSet[id]
            return (
              <Tab
                classes={tabStyles}
                value={id}
                key={id}
                label={
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    ml={2}
                    mr={6}
                    sx={{ color: 'common.white' }}
                  >
                    <Box>
                      <Box className={styles.orderNumber}>Order #{order.number}</Box>
                      <Box className={styles.status}>
                        <span className={cx(styles.statusIndicator, orderStyles[order.fulfillmentStatus])} />
                        {order.fulfillmentStatus}
                      </Box>
                    </Box>
                    <IconButton
                      color="inherit"
                      className={styles.close}
                      component="span"
                      onClick={(e: SyntheticEvent) => {
                        e.stopPropagation()
                        removeTab(id)
                      }}
                    >
                      <CloseIcon className={styles.closeIcon} />
                    </IconButton>
                  </Box>
                }
              />
            )
          })}
      </Tabs>
    </Box>
  )
}
