import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { IconButton } from '@mui/material'

export const ZappboardSupport: React.FC = () => (
  <IconButton
    onClick={() => window.open('https://quick-commerce.slack.com/app_redirect?channel=zappboard-support', '_blank')}
  >
    <HelpOutlineIcon sx={{ color: 'white' }} fontSize={'large'} />
  </IconButton>
)
