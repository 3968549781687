import { OrderStatsV2, WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { ChartData } from '../../types/ChartData'
import { filterStatsByKey } from './filterStatsByKey'
import { getCommonTimeData, getStatsIdToLocalTime } from './getCommonTimeData'

interface LowRatingMetrics {
  lowRatings: number
  time: string
  day: string
  hour: string
  minute: string
  sortId: number
}

export const get01RatingMetrics = (stats?: WarehouseStatsV2[], originFilter: string[] = []): LowRatingMetrics[] => {
  if (!stats?.length) return []

  return stats
    ?.map((stat) => {
      const warehouses = Object.values(stat.statsByWarehouse)

      const lowRatingAllWarehouse = warehouses.map((stat) => {
        const filteredOrderStats = filterStatsByKey<OrderStatsV2>(stat.orders, originFilter)
        return filteredOrderStats.reduce((acc, current) => acc + (current.ordersWith01RatingNum ?? 0), 0)
      })

      const allWarehouseTotal = lowRatingAllWarehouse.reduce((acc, current) => acc + current ?? 0, 0)
      const localTime = getStatsIdToLocalTime(stat.id)

      return {
        lowRatings: allWarehouseTotal,
        ...getCommonTimeData(localTime),
        sortId: localTime.toMillis(),
      }
    })
    .sort((a, b) => a.sortId - b.sortId)
}

export const getLowRatingHourlyChartData = (metrics: LowRatingMetrics[]): ChartData[] =>
  metrics.map((metric, idx) => ({
    chartData: {
      x: idx === metrics.length - 1 ? 'Now' : metric.minute,
      y: metric.lowRatings,
      time: metric.time,
    },
    tooltip: `Time: ${metric.time}`,
  }))
