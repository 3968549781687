import SearchIcon from '@mui/icons-material/Search'
import { Box, InputAdornment, MenuItem, Select, SelectChangeEvent, styled, TextField } from '@mui/material'
import debounce from 'lodash/debounce'
import React, { useMemo } from 'react'

const DEBOUNCE_TIME = 500

interface Props {
  onFilterChange: (filter: { type: string; searchTerm: string }) => void
}

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-outlined': {
    color: 'white',
    borderColor: 'white',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },
  '& .MuiSelect-icon': {
    color: theme.palette.primary.main,
  },
}))

export const TableFilter: React.FC<Props> = ({ onFilterChange }) => {
  const [type, setType] = React.useState<string>('')
  const [searchTerm, setSearchTerm] = React.useState<string>('')

  const notifyFilterChange = useMemo(
    () =>
      debounce((type, searchTerm) => {
        !!onFilterChange &&
          onFilterChange({
            type,
            searchTerm,
          })
      }, DEBOUNCE_TIME),
    [onFilterChange]
  )

  const handleTypeChange = (event: SelectChangeEvent<unknown>) => {
    const type = event.target.value as string
    setType(type)
    notifyFilterChange(type, searchTerm)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event?.target?.value ?? ''
    setSearchTerm(searchTerm)
    notifyFilterChange(type, searchTerm)
  }

  return (
    <Box px={1} py={2} display="flex" justifyContent="flex-end" alignItems="center">
      <StyledSelect autoWidth displayEmpty variant="outlined" size="small" value={type} onChange={handleTypeChange}>
        <MenuItem value="">All Types</MenuItem>
        <MenuItem value="ambient">Ambient</MenuItem>
        <MenuItem value="frozen">Frozen</MenuItem>
        <MenuItem value="chilled">Chilled</MenuItem>
      </StyledSelect>
      <TextField
        size="small"
        variant="outlined"
        placeholder="Search by SKU or Name"
        sx={{ ml: 1, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: 'primary.main' }} />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}
