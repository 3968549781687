import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Button, Menu, MenuItem } from '@mui/material'
import { SyntheticEvent, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { auth } from '../../firebase'
import routes from '../../pages/routes'
import { sessionState } from '../../state'

export const UserDropdown = () => {
  const [session, setSession] = useRecoilState(sessionState)
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const logOut = () => {
    handleClose()
    setSession({
      ...session,
      user: null,
      isAuthenticating: false,
    })

    auth.signOut().then(() => {
      console.log("You've been logged out")
      navigate(routes.signin)
    })
  }

  return (
    <span style={{ position: 'relative' }}>
      {session?.user && (
        <Button
          sx={{ color: 'common.white' }}
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="text"
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClick}
        >
          {session.user.email?.split('@')[0]}
        </Button>
      )}
      <Menu
        id="customized-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        PaperProps={{ style: { minWidth: 220 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {session?.user?.admin && (
          <MenuItem component={Link} to={routes.authorize} onClick={handleClose}>
            Authorization
          </MenuItem>
        )}
        <MenuItem onClick={logOut}>Log out</MenuItem>
      </Menu>
    </span>
  )
}
