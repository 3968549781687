import { Box, styled, Typography } from '@mui/material'
import React from 'react'
import { MinMaxWarehouseMetric } from '../../types/MinMaxValue'
import { MinMaxTooltip } from '../MinMaxTooltip/MinMaxTooltip'
import { NumberChangeChip } from '../NumberChangeChip/NumberChangeChip'
import { WidgetPanel } from '../WidgetPanel/WidgetPanel'

const Heading = styled(Typography)(() => ({
  lineHeight: '54px',
  fontWeight: 600,
  fontSize: '45px',
  marginRight: '10px',
}))

const SubHeading = styled(Typography)(() => ({
  color: '#BABCD7',
  fontSize: '18px',
  lineHeight: '21px',
  fontWeight: 500,
}))

const MiniHeading = styled(Typography)(() => ({
  lineHeight: '14px',
  fontWeight: 500,
  fontSize: '12px',
  color: '#9496AC',
}))

interface Props {
  heading: string
  subHeading: string
  miniHeading: string
  dot?: boolean
  loading?: boolean
  compareNow?: number
  comparePast?: number
  reverseCompare?: boolean
  tooltip?: MinMaxWarehouseMetric | null
}
export const SingleMetricPanel: React.FC<Props> = ({
  heading,
  miniHeading,
  subHeading,
  loading,
  compareNow,
  comparePast,
  reverseCompare,
  tooltip,
}) => (
  <WidgetPanel sx={{ height: 110, p: 1, width: 'auto', flex: 1 }} loading={loading}>
    <Box display="flex" alignItems="center">
      {!!tooltip && tooltip.min.warehouse !== tooltip.max.warehouse ? (
        <MinMaxTooltip minMax={tooltip}>
          <Heading>{heading}</Heading>
        </MinMaxTooltip>
      ) : (
        <Heading>{heading}</Heading>
      )}
      {!!compareNow && !!comparePast && (
        <NumberChangeChip currentValue={compareNow} compareValue={comparePast} inverted={reverseCompare} />
      )}
    </Box>
    <SubHeading>{subHeading}</SubHeading>
    <MiniHeading>{miniHeading}</MiniHeading>
  </WidgetPanel>
)
