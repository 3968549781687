import CloseIcon from '@mui/icons-material/Close'
import {
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { Line, Order } from '@quickcommerceltd/zappboard'
import { FC, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '../Table'
import { useOrderLinesStyles } from './OrderLines.style'

interface OrderPickedItems extends Order {
  pickedItems?: {
    [key: string]: number
  }
}

export const OrderLines: FC<{ order: OrderPickedItems }> = ({ order }) => {
  const [selectedLine, setSelectedLine] = useState<Line | null>(null)
  const { classes } = useOrderLinesStyles()

  return (
    <>
      <Dialog
        onClose={() => setSelectedLine(null)}
        open={!!selectedLine}
        PaperProps={{
          style: { borderRadius: '20px' },
        }}
      >
        <DialogTitle className={classes.dialogTitle}>Product Information</DialogTitle>
        <IconButton onClick={() => setSelectedLine(null)} className={classes.closeIcon}>
          <CloseIcon />
        </IconButton>
        <div className={classes.divider} />

        {selectedLine && (
          <DialogContent className={classes.paper}>
            <Box marginY={2}>
              <table className={classes.table}>
                <tbody>
                  <tr>
                    <td>Name:</td>
                    <td>{selectedLine?.productName || 'Unknown'}</td>
                  </tr>
                  <tr>
                    <td>SKU:</td>
                    <td>{selectedLine?.productSku || 'Unknown'}</td>
                  </tr>
                  <tr>
                    <td>Variant:</td>
                    <td>{selectedLine?.variantName || 'Unknown'}</td>
                  </tr>
                  <tr>
                    <td>Unit price:</td>
                    <td>{`${selectedLine?.unitPriceNet || 'Unknown'} ${selectedLine.currency || 'Unkown'}`}</td>
                  </tr>
                  <tr>
                    <td>Quantity:</td>
                    <td>{selectedLine?.quantity || 'Unknown'}</td>
                  </tr>
                </tbody>
              </table>
              <Box textAlign="center">
                <img
                  alt={selectedLine.productName}
                  className={classes.productImageLarge}
                  src={selectedLine.productImage?.image || selectedLine.productImage?.thumbnail}
                />
              </Box>
            </Box>
          </DialogContent>
        )}
      </Dialog>

      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="h3">Product-Name</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">SKU</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="h3">QTY</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {order.lines?.map((line) => (
            <Tooltip
              arrow
              key={line.id}
              placement={'top'}
              title={
                <Box padding={1}>
                  <Box
                    flexDirection="row"
                    display="flex"
                    justifyContent="space-between"
                    lineHeight="1.2em"
                    fontSize={16}
                    mb={1}
                  >
                    <Box>{line.productName}</Box>
                    <Box>{line.productSku}</Box>
                  </Box>
                  <Box textAlign="center">
                    <Avatar
                      variant="rounded"
                      className={classes.productImageBig}
                      src={line.productImage?.image || line.productImage?.thumbnail}
                    >
                      {false}
                    </Avatar>
                  </Box>
                </Box>
              }
            >
              <TableRow hover onClick={() => setSelectedLine(line)}>
                <TableCell className={classes.productImageCell}>
                  <Avatar
                    variant="rounded"
                    className={classes.productImage}
                    src={line.productImage?.thumbnail || line.productImage?.image}
                  >
                    {false}
                  </Avatar>
                </TableCell>
                <TableCell>{line.productName}</TableCell>
                <TableCell>
                  <Typography noWrap variant="body2">
                    {line.productSku}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {!line.hasOOSDefect && (
                    <Typography variant="body2">
                      {[order.pickedItems?.[line.productSku] ?? 0, line.quantity].join('/')}
                    </Typography>
                  )}

                  {line.hasOOSDefect && (
                    <Chip
                      color="error"
                      sx={{ borderRadius: 1 }}
                      label={
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                          <Typography variant="body2">
                            {[order.pickedItems?.[line.productSku] ?? 0, line.quantity].join('/')}
                          </Typography>
                          <Typography variant="body2" sx={{ opacity: 0.6 }}>
                            OOS
                          </Typography>
                        </Stack>
                      }
                    />
                  )}
                </TableCell>
              </TableRow>
            </Tooltip>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
