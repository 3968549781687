import { Box, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { Tier0SkuLowStockStatsWithWarehouse } from '../../types/Tier0SkuLowStockStatsWithWarehouse'
import { WidgetPanel } from '../WidgetPanel/WidgetPanel'
import { TableFilter } from './TableFilter'

interface Props {
  tier0List: Tier0SkuLowStockStatsWithWarehouse[]
  loading?: boolean
}

const StyledCell = styled(TableCell)(() => ({
  borderColor: 'rgb(54, 56, 75)',
}))

const COLUMNS = [
  { field: 'sku', headerName: 'SKU' },
  { field: 'name', headerName: 'Product Name' },
  { field: 'stockLevel', headerName: 'Stock Level', type: 'number' },
  { field: 'type', headerName: 'Type' },
  { field: 'warehouse', headerName: 'Store' },
  { field: 'lastStockUpdate', headerName: 'Last Updated' },
] as { field: keyof Tier0SkuLowStockStatsWithWarehouse; headerName: string; type?: 'number' | 'img' }[]

export const Tier0TableSkuTable: React.FC<Props> = ({ tier0List, loading }) => {
  const [filter, setFilter] = useState({ type: '', searchTerm: '' })
  const tableData = useMemo(() => {
    return tier0List.filter((row) => {
      let typeMatch = true
      let searchTermMatch = true

      if (filter.type) {
        typeMatch = row.type.toLowerCase() === filter.type.toLowerCase()
      }
      if (filter.searchTerm) {
        searchTermMatch =
          row.name.toLowerCase().includes(filter.searchTerm.toLowerCase()) ||
          row.sku.toLowerCase().includes(filter.searchTerm.toLowerCase())
      }
      return typeMatch && searchTermMatch
    })
  }, [tier0List, filter])

  return (
    <WidgetPanel sx={{ p: 0, width: '100%', height: '100%', overflow: 'hidden' }} loading={loading}>
      <TableFilter onFilterChange={setFilter} />
      <TableContainer sx={{ overflowY: 'scroll', maxHeight: '80vh', display: 'flex' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {COLUMNS.map((column) => (
                <StyledCell sx={{ backgroundColor: 'rgb(64, 66, 88)' }} key={column.field}>
                  {column.headerName}
                </StyledCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => (
              <TableRow key={`${row.sku}-${row.warehouse}`}>
                {COLUMNS.map((column) => (
                  <StyledCell key={column.field}>
                    <Tooltip
                      title={
                        <Box p={0} m={0} my={0.5} display="flex" justifyContent="center" alignItems="center">
                          <img height={100} src={row.image} alt={row.name} />
                        </Box>
                      }
                    >
                      <span>{row[column.field] ?? 'N/A'}</span>
                    </Tooltip>
                  </StyledCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </WidgetPanel>
  )
}
