import { Backdrop, Typography } from '@mui/material'
import { FC } from 'react'
import { useOnlineStatus } from '../../hooks/useOnlineStatus'
import { Alert } from './OnlineStatusNotificationbar.style'

export const OnlineStatusNotificationbar: FC = () => {
  const isOnline = useOnlineStatus()

  return (
    <Backdrop open={!isOnline} sx={{ zIndex: 1200 }}>
      <Alert severity="error" variant="filled">
        <Typography fontWeight="bold">You are OFFLINE!</Typography>
        <Typography variant="caption">Please check your internet connection.</Typography>
      </Alert>
    </Backdrop>
  )
}
