import { TableHead as MuiTableHead } from '@mui/material'
import { FC, useCallback } from 'react'
import { HeaderRow, TableCellCompact, TableSortLabel } from './Table'

export interface TableColumn {
  id: string
  label: string
  sortByProp?: string
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface SortSettings {
  id: string
  direction: SortDirection
  sortByProp?: string
}

interface Props {
  columns: TableColumn[]
  sortSettings: SortSettings | undefined
  onSortSettingsChange: ({ id, sortByProp, direction }: SortSettings) => void
}

export const TableHead: FC<Props> = ({ columns, sortSettings, onSortSettingsChange }) => {
  const handleSortBy = useCallback(
    (id: string, sortByProp: string) => {
      const direction =
        id !== sortSettings?.id || sortSettings?.direction !== SortDirection.DESC
          ? SortDirection.DESC
          : SortDirection.ASC
      onSortSettingsChange({ id, sortByProp, direction })
    },
    [sortSettings, onSortSettingsChange]
  )

  return (
    <MuiTableHead>
      <HeaderRow>
        {columns.map((item) => (
          <TableCellCompact
            key={item.id}
            align="right"
            sx={{
              padding: '0.3em',
              textAlign: 'right',
            }}
          >
            {item.sortByProp ? (
              <TableSortLabel
                active={item.id === sortSettings?.id}
                direction={item.id === sortSettings?.id ? sortSettings?.direction : SortDirection.DESC}
                onClick={() => handleSortBy(item.id, item.sortByProp!)}
              >
                {item.label}
              </TableSortLabel>
            ) : (
              item.label
            )}
          </TableCellCompact>
        ))}
      </HeaderRow>
    </MuiTableHead>
  )
}
