import { ETA_TYPE } from '../_consts/ETA_TYPE'

export function getStatusTranslation(diff: number, etaType: string): string {
  switch (true) {
    case diff === 0 && etaType === ETA_TYPE.DELIVERY:
      return 'Arrived at customer'
    case diff === 0 && [ETA_TYPE.PICKUP, ETA_TYPE.RETURNING].includes(etaType):
    case ETA_TYPE.ARRIVED_AT_STORE === etaType:
      return 'Arrived at store'
    case diff !== 0 && etaType === ETA_TYPE.DELIVERY:
      return 'Arrives in '
    case diff !== 0 && etaType === ETA_TYPE.PICKUP:
      return 'Pickup in '
    case diff !== 0 && etaType === ETA_TYPE.RETURNING:
      return 'Returns in '
    default:
      return ''
  }
}
