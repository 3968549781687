import { Box, Button, CircularProgress, TextField } from '@mui/material'
import { SupportInquiry, SupportInquiryCode } from '@quickcommerceltd/zappboard'
import { FC, useState } from 'react'
import { useCustomerSupportDialogStyles } from '../CustomerSupportDialog.style'

interface Props {
  onButtonClick: () => void
  loading: boolean
  setRequest: (inquiry: Partial<SupportInquiry>) => void
}

export const CustomerSupportOther: FC<Props> = ({ onButtonClick, loading, setRequest }) => {
  const { classes } = useCustomerSupportDialogStyles()
  const [message, setMessage] = useState(undefined)

  const handleMessageChange = (event: any) => {
    let text = event.target.value
    setMessage(text)
    setRequest({
      code: SupportInquiryCode.OTHER,
      delayReason: undefined,
      cancelReason: undefined,
      message: text,
      outOfStockItems: undefined,
    })
  }

  return (
    <Box className={classes.otherBox}>
      <TextField
        id="message"
        label="ZappMessage"
        variant="outlined"
        value={message}
        onChange={handleMessageChange}
        multiline={true}
        minRows={6}
        maxRows={6}
        className={classes.otherTextfield}
        required
      />

      <Button
        variant="contained"
        color="primary"
        onClick={onButtonClick}
        fullWidth
        disabled={!message || loading}
        className={classes.button}
        children={loading ? <CircularProgress size={20} color="inherit" /> : 'Create Zendesk Ticket'}
      />
    </Box>
  )
}
