import { FulfillmentStatus, Order } from '@quickcommerceltd/zappboard'
import { FC, useMemo } from 'react'
import { useTimeNow } from '../../hooks/useTimeNow'
import FormatDuration from '../FormatDuration'

interface Props {
  dontshow?: boolean
  order: Order
  from?: number
  to?: number
  warnAfter?: number
  dangerAfter?: number
}

export const StatusDuration: FC<Props> = (props) => {
  const now = useTimeNow()

  const to = useMemo(() => {
    return calculateToTimestamp(props.order, props.to, now)
  }, [props, now])

  return useMemo(
    () => (
      <FormatDuration
        dontshow={props.dontshow}
        from={props.from}
        to={to}
        warnAfter={props.warnAfter}
        dangerAfter={props.dangerAfter}
      />
    ),
    [props, to]
  )
}

const calculateToTimestamp = (order: Order, statusTimestamp: number | undefined, now: number): number => {
  const isCompleted =
    order.fulfillmentStatus === FulfillmentStatus.FULFILLED || order.fulfillmentStatus === FulfillmentStatus.CANCELLED

  if (isCompleted && !statusTimestamp) {
    return 0 // no time shown due to missing timestamp
  }

  return Math.min(now, statusTimestamp || order.deliveredAt || now)
}
