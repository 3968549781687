import { Paper, TableContainer } from '@mui/material'
import { FulfillmentStatus, Order, PickingStatus, TaskState } from '@quickcommerceltd/zappboard'
import isEqual from 'lodash/isEqual'
import uniqBy from 'lodash/uniqBy'
import React, { FC, useMemo, useRef } from 'react'
import { usePrevious } from '../../hooks'
import { HeaderRow, Table, TableBody, TableCell, TableHead } from '../Table'
import { OrderListItem } from './_components/OrderListItem'

const isOrderPackedAndRiderWaiting = (order: Order) =>
  order.pickingStatus === PickingStatus.PACKED &&
  order.deliveryStatus === TaskState.ASSIGNED &&
  order.deliveryRiderArrivedForPickupAt

const compareStatus = (a: Order, b: Order) => {
  const isUnfulfilled = (status: FulfillmentStatus) => status === FulfillmentStatus.UNFULFILLED
  return (
    Number(isUnfulfilled(b.fulfillmentStatus)) - Number(isUnfulfilled(a.fulfillmentStatus)) || b.createdAt - a.createdAt
  )
}

export const OrdersList: FC<{
  orders: Order[]
  selectedOrderId?: string
  interactive?: boolean
}> = ({ orders, selectedOrderId, interactive }) => {
  const prevSelectedOrderId = usePrevious(selectedOrderId)
  const renderedOrdersMap = useRef(new Map<string, [React.ReactElement, Order]>())

  // sort orders: unfulfilled first
  const sortedOrders = useMemo(() => {
    return orders.sort(compareStatus)
  }, [orders])

  const ordersWaitingAtPickup = useMemo(() => {
    return (
      orders
        .filter(isOrderPackedAndRiderWaiting)
        .sort((a, b) => (a.deliveryRiderArrivedForPickupAt ?? 0) - (b.deliveryRiderArrivedForPickupAt ?? 0)) ?? []
    )
  }, [orders])

  // (re)render only new and updated orders
  const memorizedOrders = uniqBy([...ordersWaitingAtPickup, ...sortedOrders], 'id').map((order) => {
    let [item, storedOrder] = renderedOrdersMap.current.get(order.id) ?? []

    if (!item || !isEqual(storedOrder, order) || [selectedOrderId, prevSelectedOrderId].includes(order.id)) {
      item = <OrderListItem key={order.id} order={order} selectedOrderId={selectedOrderId} interactive={interactive} />
      renderedOrdersMap.current.set(order.id, [item, order])
    }

    return item
  })

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="customized table">
          <TableHead>
            <HeaderRow>
              <TableCell align="right">#</TableCell>
              <TableCell align="center">Created</TableCell>
              <TableCell align="center">Fulfillment</TableCell>
              <TableCell align="center">Delivery</TableCell>
              <TableCell align="center">Picking</TableCell>
              <TableCell>Picker</TableCell>
              <TableCell>Rider</TableCell>
              <TableCell align="center">Waiting</TableCell>
              <TableCell align="center">Picking</TableCell>
              <TableCell align="center">Packing</TableCell>
              <TableCell align="center">Racktime</TableCell>
              <TableCell align="center">Riding</TableCell>
              <TableCell align="center">Delivery</TableCell>
              <TableCell align="center">Promised</TableCell>
            </HeaderRow>
          </TableHead>
          <TableBody>{memorizedOrders.map((order) => order)}</TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
