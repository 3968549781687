import { Stack, SxProps, Theme, styled, useMediaQuery } from '@mui/material'
import { FC, ReactNode } from 'react'
import { useRecoilState } from 'recoil'
import AppBar, { HEADER_HEIGHT } from './components/AppBar'
import { IconLink } from './components/IconLink/IconLink'
import { Tabbar } from './components/Tabbar/Tabbar'
import routes from './pages/routes'
import { sessionState } from './state'

const SIDEBAR_WIDTH = 80

interface Props {
  sx?: SxProps
  children?: ReactNode
}

export const Layout: FC<Props> = ({ children, sx }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [session] = useRecoilState(sessionState)
  const isAdmin = !!session.user?.admin
  const supervisor = session.user?.supervisor

  return (
    <Stack width="100%" minHeight="100vh" sx={{ overflowX: 'hidden' }}>
      <AppBar />

      {!isMobile && (
        <Sidebar py={2} px={1} spacing={2}>
          {(isAdmin || supervisor) && <IconLink icon="/icons/widgets.svg" text="Dashboard" route={routes.home} />}
          <IconLink icon="/icons/STORE.svg" text="Stores" route={routes.stores} />
          <IconLink icon="/icons/shopping_bag.svg" text="Orders" route={routes.v2Orders} />
          <IconLink icon="/icons/old-zb.svg" text="Old ZB" route={routes.old} />
        </Sidebar>
      )}

      <Content p={2} pl={isMobile ? 2 : 12} sx={sx} flexGrow={1}>
        {children}
      </Content>

      {isMobile && <Tabbar />}
    </Stack>
  )
}

const Sidebar = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  top: HEADER_HEIGHT,
  left: 0,
  bottom: 0,
  width: SIDEBAR_WIDTH,
  borderRight: '1px solid',
  borderColor: theme.palette.divider,
  background: theme.palette.background.paper,
  zIndex: 1,
}))

const Content = styled(Stack)(() => ({
  position: 'relative',
  width: '100%',
  height: 'inherit',
}))
