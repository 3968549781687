export enum OrderEventsEnum {
  // from Saleor-Dashboard
  CREDIT_CREATED = 'CREDIT_CREATED',
  PACKED = 'PACKED',
  PLACED = 'PLACED',
  TICKET_CREATED = 'TICKET_CREATED',
  CANCELED = 'CANCELED',
  LINES_CANCELLED = 'LINES_CANCELLED',
  ORDER_FULLY_PAID = 'ORDER_FULLY_PAID',
  PAYMENT_AUTHORIZED = 'PAYMENT_AUTHORIZED',
  PAYMENT_CAPTURED = 'PAYMENT_CAPTURED',
  EXTERNAL_SERVICE_NOTIFICATION = 'EXTERNAL_SERVICE_NOTIFICATION',
  PAYMENT_REFUNDED = 'PAYMENT_REFUNDED',
  PAYMENT_VOIDED = 'PAYMENT_VOIDED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  INVOICE_REQUESTED = 'INVOICE_REQUESTED',
  INVOICE_GENERATED = 'INVOICE_GENERATED',
  INVOICE_UPDATED = 'INVOICE_UPDATED',
  FULFILLMENT_CANCELED = 'FULFILLMENT_CANCELED',
  FULFILLMENT_RESTOCKED_ITEMS = 'FULFILLMENT_RESTOCKED_ITEMS',
  FULFILLMENT_FULFILLED_ITEMS = 'FULFILLMENT_FULFILLED_ITEMS',
  NOTE_ADDED = 'NOTE_ADDED',
  OTHER = 'OTHER',
  STATUS_CHANGED = 'STATUS_CHANGED',
  TASK_CREATED = 'TASK_CREATED',
  TASK_ASSIGNED_RIDER = 'TASK_ASSIGNED_RIDER',
  TASK_ARRIVED_AT_CUSTOMER = 'TASK_ARRIVED_AT_CUSTOMER',
  TASK_DELIVERY_STARTED = 'TASK_DELIVERY_STARTED',
  TASK_DELIVERED = 'TASK_DELIVERED',
  TASK_FAILED = 'TASK_FAILED',
  TASK_DELETED = 'TASK_DELETED',
  TASK_ARRIVED_AT_STORE = 'TASK_ARRIVED_AT_STORE',
  TASK_ARRIVED_FOR_PICKUP = 'TASK_ARRIVED_FOR_PICKUP',
  TASK_RETURNING_TO_STORE = 'TASK_RETURNING_TO_STORE',
  TASK_RETURNED = 'TASK_RETURNED',
  TASK_NOT_RETURNED = 'TASK_NOT_RETURNED',
  EXTERNAL_CANCELLED_ERROR = 'EXTERNAL_CANCELLED_ERROR',
  EXTERNAL_CANCELLED = 'EXTERNAL_CANCELLED',
  EXTERNAL_ACCEPTED = 'EXTERNAL_ACCEPTED',
  EXTERNAL_ACCEPTED_ERROR = 'EXTERNAL_ACCEPTED_ERROR',
  EXTERNAL_DENIED = 'EXTERNAL_DENIED',
  EXTERNAL_DENIED_ERROR = 'EXTERNAL_DENIED_ERROR',
  EXTERNAL_PATCHED_ERROR = 'EXTERNAL_PATCHED_ERROR',
  EXTERNAL_PATCHED = 'EXTERNAL_PATCHED',
}
