import NotificationsActive from '@mui/icons-material/NotificationsActive'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'

interface Props {
  onClick: () => void
}
export const ActionRequired: React.FC<Props> = ({ onClick }) => {
  return (
    <Button variant="text" onClick={() => onClick()}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: 'error.main',
          p: 1,
          color: '#fff',
          width: '300px',
          '&:hover': {
            backgroundColor: 'error.dark',
          },
          borderRadius: '5px',
        }}
      >
        <NotificationsActive sx={{ mr: 1 }} />
        <Typography variant="h4">Action Required</Typography>
      </Box>
    </Button>
  )
}
