import { makeStyles } from 'tss-react/mui'

export const usePickingDeviceAssignmentDialogStyles = makeStyles()((theme) => ({
  paper: {
    width: 'min(100%, 600px)',
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: '24px',
  },
  divider: {
    borderTopWidth: '1px',
    borderWidth: '1px',
    borderStyle: 'solid',
    opacity: 0.4,
    marginTop: '-8px',
    marginBottom: '4px',
    minWidth: '600px',
  },
  closeIcon: {
    position: 'absolute',
    top: '4px',
    right: '4px',
    color: 'inherit',
  },
  order: {
    fontWeight: 700,
    fontSize: '38px',
    marginLeft: '12px',
    marginBottom: '16px',
  },
  currentPicker: {
    height: '57px',
    borderRadius: '6px',
    fontSize: '16px',
    minWidth: '400px',
    marginBottom: '16px',
    alignItems: 'center',
    marginLeft: '16px',
    marginRight: '16px',
  },
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
    '& .MuiFormLabel-root': {
      color: '#DADADA',
      marginLeft: '4px',
    },
    '& .MuiInputBase-input': {
      color: 'white',
    },
  },
  select: {
    height: '57px',
    fontSize: '20px',
    borderRadius: '6px',
    minWidth: '400px',
  },
  menuItem: {
    fontSize: '20px',
    borderRadius: '6px',
  },
  button: {
    height: '57px',
    fontWeight: 'bold',
    fontSize: '18px',
    borderRadius: '6px',
    minWidth: '400px',
    marginTop: '28px',
    marginBottom: '4px',
  },
}))
