import { fetchAndActivate, getAll } from 'firebase/remote-config'
import { useEffect, useMemo, useState } from 'react'
import { remoteConfig } from '../../firebase'

interface RemoteConfig {
  [flag: string]: unknown
}

export const useRemoteConfigs = <T>(flagName: string): T => {
  const [remoteConfigs, setRemoteConfigs] = useState<RemoteConfig>({})

  useEffect(() => {
    try {
      fetchAndActivate(remoteConfig)
      const config = Object.entries(getAll(remoteConfig)).reduce<RemoteConfig>((result, [key, value]) => {
        try {
          const flagValue = JSON.parse(value.asString())
          result[key] = flagValue
        } catch (error) {
          result[key] = null
        }
        return result
      }, {})
      setRemoteConfigs(config)
    } catch {}
  }, [])

  return useMemo(() => {
    const config = remoteConfigs[flagName]
    return config as T
  }, [flagName, remoteConfigs])
}
