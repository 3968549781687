import { Box } from '@mui/material'
import { Order, ShippingMethod } from '@quickcommerceltd/zappboard'
import { collectionGroup, limit, orderBy, query, where } from 'firebase/firestore'
import includes from 'lodash/includes'
import startsWith from 'lodash/startsWith'
import { useMemo, useState } from 'react'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import AppBar from '../components/AppBar'
import { OrdersList } from '../components/OrdersList/OrdersList'
import { OrderTabs } from '../components/OrderTabs/OrderTabs'
import { Searchbar } from '../components/Searchbar/Searchbar'
import { db, orderTimestampConverter } from '../firebase'
import { usePaginationData } from '../hooks/usePagination'
import { sessionState } from '../state'
import routes from './routes'
import { withAuthentication } from './withAuthentication'

const AllOrders = () => {
  const searchQuery = new URLSearchParams(useLocation().search)
  const orderId = searchQuery.get('orderId') ?? undefined
  const [searchTerm, setSearchTerm] = useState('')
  const [session] = useRecoilState(sessionState)
  const statsQuery = useMemo(() => {
    return query(collectionGroup(db, 'orders'), orderBy('createdAt', 'desc')).withConverter(orderTimestampConverter)
  }, [])

  const [orderList, { loadMore }] = usePaginationData<Order>(statsQuery, {
    idField: 'id',
    limit: 100,
  })

  // search by order number or externalId using searchTerm
  const [internalQuery, externalQuery] = useMemo(() => {
    if (!searchTerm) return []

    const orderNumber = parseInt(searchTerm)
    const isInOrderList = orderList.find((order) => {
      return order.number === orderNumber || order.externalId === searchTerm
    })
    if (isInOrderList) return []

    const internalQuery = query(
      collectionGroup(db, 'orders'),
      where('number', '==', orderNumber),
      limit(1)
    ).withConverter(orderTimestampConverter)

    const externalQuery = query(
      collectionGroup(db, 'orders'),
      where('externalId', '==', searchTerm),
      limit(1)
    ).withConverter(orderTimestampConverter)

    return [internalQuery, externalQuery]
  }, [searchTerm, orderList])

  const [internalOrder = []] = useCollectionDataOnce<Order>(internalQuery)
  const [externalOrder = []] = useCollectionDataOnce<Order>(externalQuery)

  // combine orderList with single order from search
  const orders = useMemo(() => {
    return [...orderList, ...internalOrder, ...externalOrder]
  }, [orderList, internalOrder, externalOrder])

  const filteredOrders = useMemo(() => {
    if (!searchTerm) {
      return orders
    }

    const contains = (value: string = '', termList: string[], func: Function) => {
      return termList.some((term) => func(value.toLowerCase(), term.toLowerCase()))
    }

    return orders?.filter((order) => {
      const searchTermList = searchTerm.split(' ').filter(Boolean)
      const { streetAddress1 } = order.shippingAddress

      const containsOrderNumber = contains(String(order.number), searchTermList, includes)
      const containsAddress = contains(streetAddress1, searchTermList, includes)
      const containsPicker = contains(order.picker?.name, searchTermList, includes)
      const containsRider = contains(order.rider?.name, searchTermList, includes)
      const isFulfillmentStatus = contains(order.fulfillmentStatus, searchTermList, startsWith)
      const isDeliveryStatus = contains(order.deliveryStatus ?? ShippingMethod.PICKUP, searchTermList, startsWith)
      const isPickingStatus = contains(order.pickingStatus, searchTermList, startsWith)
      const containsExternalId = contains(order.externalId, searchTermList, includes)

      return [
        containsOrderNumber,
        containsAddress,
        containsPicker,
        containsRider,
        isFulfillmentStatus,
        isDeliveryStatus,
        isPickingStatus,
        containsExternalId,
      ].some(Boolean)
    })
  }, [orders, searchTerm])

  if (!session.user?.admin) {
    return null
  }

  return (
    <>
      <AppBar backTo={routes.home} searchbar={<Searchbar onSearch={setSearchTerm} />} />

      <InfiniteScroll
        dataLength={orders.length}
        next={loadMore}
        hasMore={true}
        loader={null}
        endMessage={null}
        refreshFunction={() => {}}
      >
        <Box paddingBottom="50vh">
          <Box padding={0}>
            <OrdersList interactive selectedOrderId={orderId} orders={filteredOrders ?? []} />
          </Box>
        </Box>
      </InfiniteScroll>

      <OrderTabs activeOrderId={orderId} orders={orders ?? []} />
    </>
  )
}

export default withAuthentication(AllOrders)
