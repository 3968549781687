import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  AppBar as MaterialAppBar,
  Box,
  IconButton,
  styled,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { fetchAndActivate, getBoolean } from 'firebase/remote-config'
import { ReactNode, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Snowfall from 'react-snowfall'
import { remoteConfig } from '../firebase'
import routes from '../pages/routes'
import useFullscreen from '../utils/hooks/useFullscreen'
import { Alerts } from './Alerts/Alerts'
import { UserDropdown } from './UserDropdown/UserDropdown'
import { ZappboardSupport } from './ZappboardSupport/ZappboardSupport'

export const HEADER_HEIGHT = 64

type Props = {
  title?: string | ReactNode
  backTo?: string
  actions?: ReactNode
  searchbar?: ReactNode
}

const AppBar = (props: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [isChristmas, setIsChristmas] = useState(false)
  const [isFullscreen] = useFullscreen()

  useEffect(() => {
    fetchAndActivate(remoteConfig)
    const enabled = getBoolean(remoteConfig, 'isChristmas')
    setIsChristmas(enabled)
  }, [])

  if (isFullscreen) {
    return null
  }

  const logoUrl = isChristmas ? '/zappboard-logo-xmas.svg' : '/zappboard-logo.svg'

  const getLogoWidth = () => {
    if (isMobile) {
      return isChristmas ? 250 : 150
    } else {
      return isChristmas ? 360 : 220
    }
  }

  return (
    <>
      <MaterialAppBar
        elevation={0}
        position="fixed"
        sx={{ top: 0, left: 0, background: '#212331', borderBottom: '1px solid', borderColor: 'divider' }}
      >
        {isChristmas && <Snowfall snowflakeCount={50} speed={[0.1, 0.5]} wind={[0.1, 0.5]} />}
        <Toolbar>
          {props.backTo && (
            <IconButton component={Link} to={props.backTo} color="inherit" edge="start">
              <ArrowBackIcon titleAccess="Navigate Back" />
            </IconButton>
          )}

          <Link to={routes.home}>
            <img
              src={logoUrl}
              alt="Zappboard"
              width={getLogoWidth()}
              style={{ position: 'relative', top: isChristmas ? -3 : 2, display: 'block' }}
            />
          </Link>

          <Box ml={3} flex={!props.searchbar ? 'auto' : 'none'}>
            {typeof props.title === 'string' ? (
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {props.title}
              </Typography>
            ) : (
              props.title
            )}
          </Box>

          {props.searchbar && (
            <Box ml={8} mr={8} flex="auto">
              {props.searchbar}
            </Box>
          )}

          {props.actions && <Box mr={2}>{props.actions}</Box>}
          <Alerts />
          <UserDropdown />
          <ZappboardSupport />
        </Toolbar>
      </MaterialAppBar>
      <AppBarSpacer />
    </>
  )
}

const AppBarSpacer = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: HEADER_HEIGHT,
}))

export default AppBar
