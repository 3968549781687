import { Box, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { getDoubleLineChartConfig } from '../../components/DoubleLineChart/getDoubleLineChartConfig'
import { ErrorAlertV2 } from '../../components/ErrorAlertV2/ErrorAlertV2'
import { NoDataAvailable } from '../../components/NoDataAvailable/NoDataAvailable'
import { NumberChangeChip } from '../../components/NumberChangeChip/NumberChangeChip'
import { SingleMetricPanel } from '../../components/SingleMetricPanel/SingleMetricPanel'
import { WidgetPanel } from '../../components/WidgetPanel/WidgetPanel'
import { useCommonWarehouseStatsV2 } from '../../hooks/useCommonWarehouseStatsV2/useCommonWarehouseStatsV2'
import { shortCurrencyFmt } from '../../utils/formatCurrencyShort'
import { getStatsIdToLocalString } from '../../utils/warehouseStatsV2/getCommonTimeData'
import {
  getRevenueBreakdown,
  getRevenueHourlyChartData,
  getRevenueMetrics,
  getRevenueMinMax,
  getRevenueTotal,
} from '../../utils/warehouseStatsV2/getRevenueMetrics'
import { PageLayout } from '../PageLayout'
import { PanelLayout } from '../PanelLayout'
import { withAuthentication } from '../withAuthentication'

const Revenue: React.FC = () => {
  const {
    originFilter,
    latestAcc,
    latestHourly,
    lastWeekHourly,
    lastWeekSameTimeAcc,
    yesterdaySameTimeAcc,
    errorLatest,
    errorHourly,
    lastWeekEndOfDayError,
    lastWeekStatsSameTimeError,
    yesterdayStatsSameTimeError,
    loadingLatest,
    loadingHourly,
    lastWeekLoading,
    lastWeekStatsSameTimeLoading,
    yesterdayStatsSameTimeLoading,
  } = useCommonWarehouseStatsV2(24)

  // generate dtp metrics for each time line
  const revenueLatestTotal = useMemo(() => getRevenueTotal(latestAcc?.[0], originFilter), [latestAcc, originFilter])
  const revenueTotalWoW = useMemo(
    () => getRevenueTotal(lastWeekSameTimeAcc?.[0], originFilter),
    [lastWeekSameTimeAcc, originFilter]
  )
  const revenueTotalDoD = useMemo(
    () => getRevenueTotal(yesterdaySameTimeAcc?.[0], originFilter),
    [yesterdaySameTimeAcc, originFilter]
  )

  // breakdown metrics
  const todayBreakdown = useMemo(() => getRevenueBreakdown(latestAcc?.[0], originFilter), [latestAcc, originFilter])
  const lastWeekBreakdown = useMemo(
    () => getRevenueBreakdown(lastWeekSameTimeAcc?.[0], originFilter),
    [lastWeekSameTimeAcc, originFilter]
  )
  const breakdownComparison = useMemo(() => {
    const result: {
      warehouse: string
      today: number
      lastWeek: number
    }[] = []

    todayBreakdown.forEach((item) => {
      const lastWeek = lastWeekBreakdown.find((lastWeekItem) => lastWeekItem.warehouse === item.warehouse)
      result.push({
        warehouse: item.warehouse,
        today: item.total,
        lastWeek: lastWeek?.total ?? 0,
      })
    })

    return result.sort((a, b) => a.warehouse.localeCompare(b.warehouse))
  }, [todayBreakdown, lastWeekBreakdown])

  // charting metrics
  const revenueMetrics = useMemo(() => getRevenueMetrics(latestHourly, originFilter), [latestHourly, originFilter])
  const revenueMetricsLastWeek = useMemo(
    () => getRevenueMetrics(lastWeekHourly, originFilter),
    [lastWeekHourly, originFilter]
  )
  const revenueHourlyChartData = useMemo(() => getRevenueHourlyChartData(revenueMetrics), [revenueMetrics])
  const revenueLastWeekHourlyChartData = useMemo(
    () => getRevenueHourlyChartData(revenueMetricsLastWeek),
    [revenueMetricsLastWeek]
  )

  const chartProps = {
    solidLabel: 'Today',
    solidData: revenueHourlyChartData.map((metric) => metric.chartData),
    dottedLabel: 'Last Week',
    dottedData: revenueLastWeekHourlyChartData.map((metric) => metric.chartData),
  }

  const { data, options } = getDoubleLineChartConfig({
    dottedLabel: chartProps.dottedLabel,
    dottedData: chartProps.dottedData,
    solidLabel: chartProps.solidLabel,
    solidData: chartProps.solidData,
    maintainAspectRatio: false,
    borderWidth: 3,
    formatter: (val: unknown) => shortCurrencyFmt.format(Number(val)),
  })

  const hasNoChartData =
    chartProps.solidData.length &&
    chartProps.dottedData.length &&
    chartProps.solidData.every((item) => item.y === 0) &&
    chartProps.dottedData.every((item) => item.y === 0)

  const chart = hasNoChartData ? (
    <NoDataAvailable title="Timeline" />
  ) : (
    <WidgetPanel
      title="Timeline"
      sx={{ width: '100%', height: '30vh' }}
      loading={loadingHourly || lastWeekLoading || loadingLatest || lastWeekStatsSameTimeLoading}
    >
      <Line data={data} options={options} style={{ paddingBottom: 22, maxHeight: 240 }} />
    </WidgetPanel>
  )

  const dodTime = getStatsIdToLocalString(yesterdaySameTimeAcc?.[0]?.id)
  const wowTime = getStatsIdToLocalString(lastWeekSameTimeAcc?.[0]?.id)

  const error =
    errorLatest || errorHourly || lastWeekEndOfDayError || lastWeekStatsSameTimeError || yesterdayStatsSameTimeError

  if (error) {
    return <ErrorAlertV2 error={error} />
  }

  const barData = {
    datasets: [
      {
        label: 'Last Week',
        borderColor: '#BABFEE',
        backgroundColor: '#BABFEE',
        data: breakdownComparison.map((item) => ({ x: item.warehouse, y: item.lastWeek })),
      },
      {
        label: 'Today',
        borderColor: '#02BAFF',
        backgroundColor: '#02BAFF',
        data: breakdownComparison.map((item) => ({ x: item.warehouse, y: item.today })),
      },
    ],
  }

  const tooltipLatest = getRevenueMinMax(latestAcc?.[0], originFilter)
  const tooltipLastWeek = getRevenueMinMax(lastWeekSameTimeAcc?.[0], originFilter)
  const tooltipYesterday = getRevenueMinMax(yesterdaySameTimeAcc?.[0], originFilter)

  const barOptions = {
    ...options,
    plugins: {
      ...options.plugins,
      legend: {
        ...options.plugins?.legend,
        display: true,
        labels: {
          color: 'white',
        },
      },
    },
    scales: {
      ...options.scales,
      x: {
        ...options.scales?.x,
        ticks: {
          ...options.scales?.x?.ticks,
          font: {
            ...options.scales?.x?.ticks?.font,
            size: 12,
          },
        },
      },
    },
  }

  return (
    <PageLayout title="Revenue">
      <PanelLayout>
        <SingleMetricPanel
          heading={shortCurrencyFmt.format(revenueLatestTotal?.total ?? 0)}
          subHeading="Today"
          miniHeading={'Now, since 00:00'}
          loading={loadingLatest}
          tooltip={tooltipLatest?.actual}
        />
        <SingleMetricPanel
          heading={shortCurrencyFmt.format(revenueLatestTotal?.projectedTotal ?? 0)}
          subHeading={'Projected Total'}
          miniHeading={'Today'}
          loading={loadingLatest}
          tooltip={tooltipLatest?.projected}
        />
        <SingleMetricPanel
          heading={shortCurrencyFmt.format(revenueTotalDoD?.total ?? 0)}
          subHeading={'DoD Comparison'}
          miniHeading={`${dodTime}, since 00:00`}
          loading={yesterdayStatsSameTimeLoading}
          compareNow={revenueLatestTotal?.total}
          comparePast={revenueTotalDoD?.total}
          tooltip={tooltipYesterday?.actual}
        />
        <SingleMetricPanel
          heading={shortCurrencyFmt.format(revenueTotalWoW?.total ?? 0)}
          subHeading={'WoW Comparison'}
          miniHeading={`${wowTime}, since 00:00`}
          loading={lastWeekStatsSameTimeLoading}
          compareNow={revenueLatestTotal?.total}
          comparePast={revenueTotalWoW?.total}
          tooltip={tooltipLastWeek?.actual}
        />
      </PanelLayout>
      {chart}
      <WidgetPanel
        sx={{ width: '100%', height: 'fit-content', p: 1 }}
        loading={loadingLatest || lastWeekStatsSameTimeLoading}
      >
        <Box display="flex" justifyContent="space-evenly" alignItems="center" flexWrap="wrap">
          {breakdownComparison.map((item) => (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" key={item.warehouse}>
              <Typography>{item.warehouse}</Typography>
              <NumberChangeChip currentValue={item.today} compareValue={item.lastWeek} percentage />
            </Box>
          ))}
        </Box>
      </WidgetPanel>
      <WidgetPanel
        title="Today's Breakdown"
        sx={{ width: '100%', height: '30vh' }}
        loading={loadingLatest || lastWeekStatsSameTimeLoading}
      >
        <Bar data={barData} options={barOptions} style={{ paddingBottom: 22, maxHeight: 240 }} />
      </WidgetPanel>
    </PageLayout>
  )
}

export default withAuthentication(React.memo(Revenue))
