import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import { Box, styled } from '@mui/material'
import React from 'react'
import { OrderFlowEvent } from './_helpers/getOrderFlowData'

interface Props {
  data: OrderFlowEvent[]
}

const EventContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '18px',
  lineHeight: '22px',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  padding: '8px 12px',
  border: `1px solid ${theme.palette.divider}`,
  '& span': {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    lineHeight: '19px',
  },
}))

const TIMELINE_MIN_SIZE = 50

export const OrderFlowTimeline: React.FC<Props> = ({ data }) => {
  const startingTime = data[0].timestamp
  const endingTime = data[data.length - 1].timestamp
  const totalSeconds = endingTime.diff(startingTime, 'seconds').seconds
  const getItemHeight = (event: OrderFlowEvent, event2: OrderFlowEvent | null) => {
    if (!event2) {
      return TIMELINE_MIN_SIZE
    }
    const diff = event2.timestamp.diff(event.timestamp, 'seconds').seconds
    const percentage = diff / totalSeconds
    return TIMELINE_MIN_SIZE * (1 + percentage * 3)
  }

  return (
    <Timeline>
      {data.map((event, idx) => (
        <TimelineItem
          key={`${event.type}-${event.event}-${event.timestamp.toMillis()}`}
          position={event.type === 'store' ? 'left' : 'right'}
          sx={{
            minHeight: TIMELINE_MIN_SIZE,
            height: getItemHeight(event, idx !== data.length - 1 ? data[idx + 1] : null),
          }}
        >
          <TimelineSeparator>
            <TimelineDot color="secondary" sx={{ backgroundColor: 'white', my: 0, zIndex: 1 }} />
            {idx !== data.length - 1 && (
              <TimelineConnector
                sx={{
                  width: '12px',
                  borderRadius: '10px',
                  backgroundColor: event.inHandOverTime ? 'warning.main' : 'primary.main',
                  position: 'relative',
                  '&:before': {
                    content: '""',
                    position: 'absolute',
                    width: '10px',
                    height: '7px',
                    left: '1px',
                    borderRadius: '10px',
                    backgroundColor: 'background.paper',
                    bottom: '-1px',
                  },
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    width: '10px',
                    height: '7px',
                    left: '1px',
                    borderRadius: '10px',
                    backgroundColor: 'background.paper',
                    top: '-1px',
                  },
                }}
              />
            )}
          </TimelineSeparator>
          <TimelineContent sx={{ py: 0 }}>
            <EventContent>
              {event.event}
              <span>{event.timestamp.toFormat('HH:mm:ss')}</span>
            </EventContent>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}
