import { Divider, LinearProgress, Stack, Typography, useTheme } from '@mui/material'
import React, { useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'

const UNASSIGNED_WARNING_AT = 5
const UNASSIGNED_DANGER_AT = 10

interface Props {
  unassignedOrders?: number
  pendingOrders?: number
  pickerLevels?: [number, number]
}

export const TileUnassignedOrdersAndPickerLevels: React.FC<Props> = (props) => {
  const theme = useTheme()

  const [unassignedAndPending, unassignedPercentage, unassignedColor] = useMemo(() => {
    const unassigned = props.unassignedOrders || 0
    const pending = props.pendingOrders || 0
    const unassignedAndPending = unassigned + pending
    const percentage = Math.min(100, (unassignedAndPending / UNASSIGNED_DANGER_AT) * 100)
    const color =
      unassignedAndPending >= UNASSIGNED_DANGER_AT
        ? theme.palette.error.main
        : unassignedAndPending >= UNASSIGNED_WARNING_AT
        ? theme.palette.warning.main
        : undefined

    return [unassignedAndPending, percentage, color]
  }, [theme, props.unassignedOrders, props.pendingOrders])

  const pickerLevelsPercentage = useMemo(() => {
    const [actual = 0, expected = 0] = props.pickerLevels || []
    return Math.min(100, (actual / expected) * 100)
  }, [props.pickerLevels])

  return (
    <Stack width="inherit" height="inherit" p={2}>
      <Typography variant="h3">Unassigned Orders</Typography>

      <Stack position="relative" width={200} alignSelf="center" alignItems="center" mt={4}>
        <Typography variant="h1" color={unassignedColor} sx={{ position: 'absolute', bottom: -8 }}>
          {unassignedAndPending}
        </Typography>
        <Doughnut
          data={{
            datasets: [
              {
                data: [unassignedPercentage, 100 - unassignedPercentage],
                backgroundColor: [unassignedColor || theme.palette.primary.main, theme.palette.divider],
                borderWidth: 0,
              },
            ],
          }}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            cutout: 35,
            aspectRatio: 2 / 1,
            rotation: 270, // start angle in degrees
            circumference: 180, // sweep angle in degrees
          }}
        />
      </Stack>

      <Stack flex={1} justifyContent="center">
        <Divider />
      </Stack>

      <Stack spacing={1}>
        <Typography variant="h3">Picker Levels</Typography>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3" fontWeight="bold">
            {props.pickerLevels?.[0] || 0}
          </Typography>
          <Typography variant="h3" fontWeight="bold">
            {props.pickerLevels?.[1] || 0}
          </Typography>
        </Stack>
        <LinearProgress variant="determinate" value={pickerLevelsPercentage} sx={{ height: 6 }} />
        <Stack direction="row" justifyContent="space-between">
          <Typography color="text.secondary">Actual</Typography>
          <Typography color="text.secondary">Expected</Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
