import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined'
import CloseIcon from '@mui/icons-material/Close'
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import MinimizeIcon from '@mui/icons-material/Minimize'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBagOutlined'
import VapingRoomsIcon from '@mui/icons-material/VapingRooms'
import WineBarIcon from '@mui/icons-material/WineBar'
import { Box, Button, Chip, Divider, Grid, IconButton, Link, Stack, styled, Tooltip, Typography } from '@mui/material'
import { FulfillmentStatus, Order, OrderOrigin, PickingStatus, TaskState } from '@quickcommerceltd/zappboard'
import { ZendeskTicketStatus } from '@quickcommerceltd/zappboard/lib/types/zendeskTicketStatus'
import { DateTime } from 'luxon'
import { FC, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'
import { env } from '../../config'
import { useFeatureFlags } from '../../hooks'
import { sessionState } from '../../state'
import { ReactComponent as FraudCheck } from '../../svg/fraud.svg'
import { formatNumberRange } from '../../utils/formatNumberRange'
import { ConfirmReturningTaskDialog } from '../ConfirmReturningTaskDialog/ConfirmReturningTaskDialog'
import { CustomerSupportDialog } from '../CustomerSupportDialog/CustomerSupportDialog'
import { OrderFlow } from '../OrderFlow/OrderFlow'
import { OrderLinesV2 } from '../OrderLinesV2/OrderLinesV2'
import { PickingDeviceAssignmentDialog } from '../PickingDeviceAssignmentDialog/PickingDeviceAssignmentDialog'
import { Rating } from '../Rating/Rating'

const MINUTE_IN_MS = 60000
const ZENDESK_LINK = 'https://zappsupport.zendesk.com'

const StyledStack = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
  position: 'fixed',
  bottom: 64,
  left: '80px',
  width: 'calc(100% - 80px)',
  height: '42vh',
  borderTop: 'solid 2px #fff',
  maxHeight: 400,
  zIndex: 10,
}))

const StyledChip = styled(Chip)(() => ({
  borderRadius: 4,
  textTransform: 'uppercase',
  fontWeight: 600,
}))

const IssueTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '19px',
  letterSpacing: 0,
}))

const IssueText = styled(Typography)(() => ({
  fontSize: '21px',
  fontWeight: 600,
  lineHeight: '25px',
  letterSpacing: 0,
}))

export const OrderDetailsV2: FC<{
  order: Order
  onClose: (id: string) => void
  onMinimize: (id: string) => void
  onSelect: (order: Order) => void
  stackedOrder?: Order
}> = ({ order, onClose, onMinimize, onSelect, stackedOrder }) => {
  const [session] = useRecoilState(sessionState)
  const [showCustomerSupportDialog, setShowCustomerSupportDialog] = useState(false)
  const [showPickingDeviceDialog, setShowPickingDeviceDialog] = useState(false)
  const [showConfirmReturningTaskDialog, setShowConfirmReturningTaskDialog] = useState(false)

  const close = () => {
    onClose(order.id)
  }

  const minimize = () => {
    onMinimize(order.id)
  }

  const toggleCustomerSupportDialog = () => {
    setShowCustomerSupportDialog(!showCustomerSupportDialog)
  }
  const togglePickingDeviceDialog = () => {
    setShowPickingDeviceDialog(!showPickingDeviceDialog)
  }
  const toggleConfirmReturningTaskDialog = () => {
    setShowConfirmReturningTaskDialog(!showConfirmReturningTaskDialog)
  }

  const navigateToStackedOrder = () => {
    if (!stackedOrder) return
    onSelect(stackedOrder)
  }

  const isOrderWithReturningTask = useMemo(() => {
    const isReturning = order.deliveryStatus === TaskState.RETURNING

    if (isReturning) setShowConfirmReturningTaskDialog((prev) => !prev)

    return isReturning
  }, [order.deliveryStatus])

  const {
    isOrderHistoryEnabled,
    isContactCustomerSupportEnabled,
    isPickingDeviceAssignmentEnabled,
    isOptionOOSForContactSupportEnabled,
    isConfirmReturningTaskEnabled,
  } = useFeatureFlags(order.warehouseId, [
    'isOrderHistoryEnabled',
    'isContactCustomerSupportEnabled',
    'isPickingDeviceAssignmentEnabled',
    'isOptionOOSForContactSupportEnabled',
    'isConfirmReturningTaskEnabled',
  ])

  const linkToZCommerceOrder = `https://${env}.zcommerce.quickcommerce.org/dashboard/orders/${order.id ?? ''}`

  const isAssignToPickerDisabled =
    [PickingStatus.PICKED, PickingStatus.PACKED].includes(order.pickingStatus) ||
    order.fulfillmentStatus !== FulfillmentStatus.UNFULFILLED

  const containsValuableItems = false
  const containsFrozenItems = false
  const fullDateCreated = DateTime.fromMillis(order.createdAt).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)

  const marketplace = [OrderOrigin.DELIVEROO, OrderOrigin.UBER, OrderOrigin.JET] as string[]
  const isMarketplaceOrder = !!(order.orderOrigin && marketplace.includes(order.orderOrigin))
  const showReturnTaskButton =
    (isConfirmReturningTaskEnabled && isOrderWithReturningTask) ||
    (isMarketplaceOrder &&
      !order.hasBeenMovedToPutaway &&
      (order.fulfillmentStatus === FulfillmentStatus.CANCELLED || order.deliveryStatus === TaskState.FAILED))

  return (
    <StyledStack
      sx={{ display: { xs: 'none', sm: 'inherit' } }}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.code === 'Escape') {
          close()
        }
      }}
    >
      <Stack p={3} px={0} pt={2} overflow="hidden" height="1" spacing={1}>
        <Stack alignItems="flex-start" direction="row" spacing={1} px={1}>
          <Stack alignItems="center">
            <Typography sx={{ fontSize: '27px', fontWeight: 700, lineHeight: '33px', px: 1.5 }}>
              Order{' '}
              <Link href={linkToZCommerceOrder} underline="none" color="inherit" rel="noopener" target="_blank">
                #{order.number} {order.externalId && `(${order.externalId})`}
              </Link>
            </Typography>
            <Typography sx={{ fontSize: '12px' }}>{fullDateCreated}</Typography>
          </Stack>
          <Button
            style={{
              minWidth: '28px',
              maxWidth: '28px',
            }}
            onClick={() => navigator.clipboard.writeText(`${order.number}`)}
          >
            <Tooltip title={'Copy order number to clipboard'}>
              <FileCopyOutlinedIcon color="primary"></FileCopyOutlinedIcon>
            </Tooltip>
          </Button>

          {order.isNewCustomer && <StyledChip color="primary" label="new customer" />}

          <StyledChip color="primary" sx={{ backgroundColor: 'primary.main' }} label={order.fulfillmentStatus} />
          <Tooltip title="Shipping method">
            <StyledChip color="success" label={order.shippingMethod.replace('_', ' ')} />
          </Tooltip>
          <StyledChip color="success" label={order.warehouseId.split('_')[2].toUpperCase()} />

          <Stack direction="row" spacing={1} pl={2}>
            {containsValuableItems && <DiamondOutlinedIcon />}
            {containsFrozenItems && <AcUnitOutlinedIcon />}
            {order.containsAlcohol && (
              <Tooltip title={'Contains alcohol'}>
                <WineBarIcon />
              </Tooltip>
            )}
            {order.containsTobacco && (
              <Tooltip title={'Contains tobacco'}>
                <VapingRoomsIcon />
              </Tooltip>
            )}
          </Stack>

          <Stack alignItems="center" pl={1}>
            <Tooltip title="Promised delivery time">
              <AccessTimeIcon />
            </Tooltip>
            <Typography sx={{ fontSize: '12px', mt: '5px' }}>
              {formatNumberRange(
                order.promisedDeliveryTime / MINUTE_IN_MS,
                order.deliveryPromiseUpper
                  ? order.deliveryPromiseUpper
                  : order.promisedDeliveryTime / MINUTE_IN_MS + 15,
                '-',
                'min'
              )}
            </Typography>
          </Stack>

          {order.packageCount && (
            <Stack alignItems="center" pl={1}>
              <Tooltip title="Number of Packages">
                <ShoppingBagIcon />
              </Tooltip>
              <Typography sx={{ fontSize: '12px', mt: '5px' }}>{order.packageCount}</Typography>
            </Stack>
          )}

          {stackedOrder && (
            <Button variant="outlined" color="primary" onClick={navigateToStackedOrder} sx={{ ml: 1 }}>
              Stacked with #{stackedOrder.number}
            </Button>
          )}

          {order.isFraud && (
            <StyledChip
              color="primary"
              sx={{ backgroundColor: 'success.main' }}
              icon={<FraudCheck />}
              label="Fraud check"
            />
          )}

          {!!order.customerRating?.rating && (
            <Rating rating={order.customerRating.rating} note={order.customerRating?.note} />
          )}

          <Stack direction="row" flexGrow="1" justifyContent="flex-end" alignItems="center" spacing={1}>
            {showReturnTaskButton && (
              <Button
                variant="outlined"
                color="primary"
                children="Confirm Returning Task"
                onClick={toggleConfirmReturningTaskDialog}
              />
            )}

            {isPickingDeviceAssignmentEnabled && (
              <Tooltip title={isAssignToPickerDisabled ? 'Order fulfilled, cancelled or packed' : ''} placement="left">
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    children="Assign To Picker"
                    onClick={togglePickingDeviceDialog}
                    disabled={isAssignToPickerDisabled}
                  />
                </div>
              </Tooltip>
            )}

            {!session.user?.supervisor && isContactCustomerSupportEnabled && (
              <Tooltip placement="top" title={!!order.externalId ? 'Not available for external orders' : ''}>
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    children="Contact Support"
                    onClick={toggleCustomerSupportDialog}
                    disabled={!!order.externalId}
                  />
                </div>
              </Tooltip>
            )}

            <Stack direction="row">
              <IconButton color="inherit" onClick={minimize} size="small">
                <MinimizeIcon />
              </IconButton>

              <IconButton color="inherit" onClick={close} size="small">
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
        <Divider />

        <Stack>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={isOrderHistoryEnabled ? 12 : 8}
              md={isOrderHistoryEnabled ? 4 : 8}
              lg={isOrderHistoryEnabled ? 4 : 8}
            >
              <Box>
                <OrderLinesV2 order={order} />
              </Box>
            </Grid>
            {!!isOrderHistoryEnabled && (
              <Grid item xs={12} sm={12} md={4} lg={4} sx={{ borderLeft: '1px solid', borderLeftColor: 'divider' }}>
                <OrderFlow order={order} />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={isOrderHistoryEnabled ? 12 : 4}
              md={4}
              lg={4}
              sx={{ borderLeft: '1px solid', borderLeftColor: 'divider' }}
            >
              <Typography sx={{ fontSize: '27px', fontWeight: 700, lineHeight: '33px', px: 2, mb: 3 }}>
                Issues / Action
              </Typography>
              <Stack px={2} sx={{ overflowY: 'auto', maxHeight: '250px' }}>
                <Box>
                  {order.zendeskTicketId ? (
                    <StyledChip
                      sx={{
                        backgroundColor:
                          order.zendeskTicketStatus === ZendeskTicketStatus.SOLVED ? 'success.light' : 'warning',
                      }}
                      label={order.zendeskTicketStatus?.toUpperCase()}
                    />
                  ) : (
                    <StyledChip sx={{ backgroundColor: 'success.light' }} label="No Issues" />
                  )}
                </Box>

                <Box mt={3}>
                  <IssueTitle>Initial Reason Code</IssueTitle>
                  <IssueText>{order.customerReportedReasons?.join(', ') ?? 'N/A'}</IssueText>
                </Box>

                <Divider sx={{ my: 1.5 }} />

                <Box>
                  <IssueTitle>Final Reason Code</IssueTitle>
                  <IssueText>{order.customerReportedResolution ?? 'N/A'}</IssueText>
                </Box>

                <Divider sx={{ my: 1.5 }} />

                <Box>
                  <IssueTitle>Zendesk</IssueTitle>
                  {order.zendeskTicketId ? (
                    <Box display="flex" alignItems="flex-start">
                      <IssueText>#{order.zendeskTicketId}</IssueText>
                      <Button
                        sx={{
                          minWidth: '28px',
                          maxWidth: '28px',
                          padding: 0,
                          ml: 1,
                        }}
                        onClick={() => navigator.clipboard.writeText(`${order.zendeskTicketId}`)}
                      >
                        <Tooltip title={'Copy zendesk ticket number to clipboard'}>
                          <FileCopyOutlinedIcon color="primary"></FileCopyOutlinedIcon>
                        </Tooltip>
                      </Button>
                      <Link
                        href={`${ZENDESK_LINK}/agent/tickets/${order.zendeskTicketId}`}
                        underline="none"
                        color="primary"
                        rel="noopener"
                        target="_blank"
                      >
                        <OpenInNewIcon sx={{ fontSize: '25px', ml: 0.5 }} />
                      </Link>
                    </Box>
                  ) : (
                    <IssueText>None</IssueText>
                  )}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <ConfirmReturningTaskDialog
        open={showConfirmReturningTaskDialog}
        taskId={order.deliveryTaskId}
        userEmail={session.user?.email?.split('@')?.[0] || ''}
        riderName={order.rider?.name || ''}
        estimatedDeliveryAt={order.estimatedDeliveryAt || 0}
        deliveryStartedAt={order.deliveryStartedAt || 0}
        streetAddress={order.shippingAddress?.streetAddress1 || ''}
        orderNumber={`${order.number}`}
        orderId={order.id}
        warehouseId={order.warehouseId}
        onClose={toggleConfirmReturningTaskDialog}
        isMarketplaceOrder={isMarketplaceOrder}
      />
      <PickingDeviceAssignmentDialog
        open={showPickingDeviceDialog}
        orderId={order.id}
        orderNumber={order.number}
        warehouseId={order.warehouseId}
        onClose={togglePickingDeviceDialog}
      />
      <CustomerSupportDialog
        open={showCustomerSupportDialog}
        order={order}
        userEmail={session.user?.email || ''}
        onClose={toggleCustomerSupportDialog}
        isOptionOOSForContactSupportEnabled={isOptionOOSForContactSupportEnabled}
      />
    </StyledStack>
  )
}
