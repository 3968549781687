import { Stack } from '@mui/material'
import { DateTime } from 'luxon'
import React, { FC, useMemo } from 'react'
import AppBar from '../../components/AppBar'
import { MomentumPanel } from '../../components/MomentumPanel/MomentumPanel'
import { WidgetPanel } from '../../components/WidgetPanel/WidgetPanel'
import { useCommonWarehouseStatsV2 } from '../../hooks/useCommonWarehouseStatsV2/useCommonWarehouseStatsV2'
import { useHomeMomentumMetric } from '../../hooks/useHomePageMetrics/useHomeMomentumMetric'
import { Layout } from '../../layout'
import { withAuthentication } from '../withAuthentication'
import { WarehouseStatsTableV2 } from './components/WarehouseStatsTableV2'

const HomePage: FC = () => {
  const now = DateTime.now()

  const {
    originFilter,
    latestAcc,
    errorLatest,
    loadingLatest,
    lastWeekSameTimeAcc,
    lastWeekStatsSameTimeError,
    lastWeekStatsSameTimeLoading,
    lastQuarterHourStatsAcc,
    lastWeekLastQuarterHourStatsAcc,
    latestHourly,
    loadingHourly,
    lastQuarterHourLoading,
    lastWeekLastQuarterHourLoading,
    errorHourly,
    lastQuarterHourError,
    lastWeekLastQuarterHourError,
  } = useCommonWarehouseStatsV2(2)

  const { ordersPerQuarter, ordersPerQuarterLastWeek } = useHomeMomentumMetric({
    lastQuarterHourStatsAcc,
    lastWeekLastQuarterHourStatsAcc,
    latestAcc,
    lastWeekSameTimeAcc,
    originFilter,
  })

  const hourlyStats = useMemo(
    () => (now.minute >= 20 ? latestHourly?.[1] : latestHourly?.[0]),
    [latestHourly, now.minute]
  )

  const error =
    errorLatest || lastWeekStatsSameTimeError || errorHourly || lastQuarterHourError || lastWeekLastQuarterHourError

  if (error) {
    return (
      <>
        <AppBar />
        <Stack p={4}>{error.message}</Stack>
      </>
    )
  }

  return (
    <Layout>
      <Stack spacing={2}>
        <MomentumPanel
          ordersPerQuarter={ordersPerQuarter}
          ordersPerQuarterLastWeek={ordersPerQuarterLastWeek}
          loading={lastQuarterHourLoading || lastWeekLastQuarterHourLoading}
        />
        <WidgetPanel
          sx={{ width: 'auto', height: 'auto', p: 0 }}
          loading={loadingHourly || loadingLatest || lastWeekStatsSameTimeLoading}
        >
          <WarehouseStatsTableV2
            warehouseStats={latestAcc?.[0]}
            lastWeekSameTimeStats={lastWeekSameTimeAcc?.[0]}
            hourlyStats={hourlyStats}
          />
        </WidgetPanel>
      </Stack>
    </Layout>
  )
}

export default withAuthentication(HomePage)
