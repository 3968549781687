import { WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { ProgressBarChartData } from '../../types/ProgressBarChartData'

export const getLowTier0Metrics = (warehouseStats?: WarehouseStatsV2, threshold = 5): ProgressBarChartData[] => {
  if (!warehouseStats) return []

  const warehouses = Object.values(warehouseStats.statsByWarehouse)
  return warehouses.map((stat) => {
    const belowThreshold = stat.tier0LowStock.filter((item) => item.stockLevel < threshold)
    return {
      value: belowThreshold.length,
      valueMax: 100,
      name: stat.warehouse.shortName,
    } as ProgressBarChartData
  })
}

export const getLowTier0UniqueSkuMetrics = (warehouseStats?: WarehouseStatsV2) => {
  if (!warehouseStats) return []
  const skuMap: Record<string, number> = {}

  const warehouses = Object.values(warehouseStats.statsByWarehouse)
  warehouses.forEach((stat) => {
    stat.tier0LowStock.forEach((item) => {
      if (!skuMap[item.sku]) {
        skuMap[item.sku] = 1
        return
      }
      skuMap[item.sku] += 1
    })
  })

  return skuMap
}
