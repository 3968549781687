import CloseIcon from '@mui/icons-material/Close'
import DirectionsBikeOutlinedIcon from '@mui/icons-material/DirectionsBikeOutlined'
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { TaskState } from '@quickcommerceltd/zappboard'
import { Timestamp } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { FC, useState } from 'react'
import { functions } from '../../firebase'
import { calculateReturnArrivalAtStoreAt } from '../_helpers/calculateReturnArrivalAtStoreAt'
import { useConfirmReturningTaskDialogStyles } from './ConfirmReturningTaskDialog.style'

interface Props {
  open: boolean
  taskId: string
  userEmail: string
  riderName: string
  estimatedDeliveryAt: number
  deliveryStartedAt: number
  streetAddress: string
  orderNumber: string
  orderId: string
  warehouseId: string
  onClose: () => void
  isMarketplaceOrder: boolean
}

export const ConfirmReturningTaskDialog: FC<Props> = ({
  open,
  taskId,
  userEmail,
  riderName,
  estimatedDeliveryAt,
  deliveryStartedAt,
  streetAddress,
  orderNumber,
  orderId,
  warehouseId,
  onClose,
  isMarketplaceOrder,
}) => {
  const { classes } = useConfirmReturningTaskDialogStyles()
  const [loading, setLoading] = useState<TaskState | undefined>(undefined)
  const [error, setError] = useState('')

  const handleOnClose = () => {
    onClose()
    setError('')
  }

  const submitMarketplaceReturn = () => {
    if (loading) return

    setLoading(TaskState.RETURNED)
    setError('')

    const confirmReturningMarketplaceOrder = httpsCallable(functions, 'api-confirmReturningMarketplaceOrder')

    confirmReturningMarketplaceOrder({
      warehouseId,
      orderNumber,
      orderId,
    })
      .then(() => {
        setLoading(undefined)
        onClose()
      })
      .catch((error) => {
        console.error(error)
        setError(`Error: Could not confirm returning order: ${error?.message}`)
      })
      .finally(() => {
        setLoading(undefined)
      })
  }

  const submitReturnTaskStatus = (taskStatus: TaskState) => {
    if (loading) return

    setLoading(taskStatus)
    setError('')

    const confirmReturningTask = httpsCallable(functions, 'api-confirmReturningTask')

    confirmReturningTask({
      taskId,
      taskStatus: taskStatus === TaskState.RETURNED ? 'RETURNED' : 'NOT_RETURNED',
      zappboardUser: userEmail,
      orderId,
      orderNumber,
      warehouseId,
    })
      .then(() => {
        setLoading(undefined)
        onClose()
      })
      .catch((error) => {
        console.error(error)
        setError(`Error: Could not confirm returning task: ${error?.message}`)
      })
      .finally(() => {
        setLoading(undefined)
      })
  }

  const estimatedReturnArrivalAtStoreAt = calculateReturnArrivalAtStoreAt(estimatedDeliveryAt, deliveryStartedAt)

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      PaperProps={{
        style: { borderRadius: '20px' },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>Confirm Returning Task</DialogTitle>
      <IconButton onClick={handleOnClose} className={classes.closeIcon}>
        <CloseIcon />
      </IconButton>
      <div className={classes.divider} />
      <DialogContent className={classes.paper}>
        <Box marginLeft={1}>
          <Typography className={classes.task}>Task #{orderNumber}</Typography>
        </Box>
        <Box marginY={2}>
          <table className={classes.table}>
            <tbody>
              <tr>
                <td>
                  <DirectionsBikeOutlinedIcon className={classes.icon} />
                </td>
                <td>Rider:</td>
                <td>{riderName || 'Unknown'}</td>
              </tr>

              <tr>
                <td>
                  <RoomOutlinedIcon className={classes.icon} />
                </td>
                <td>From:</td>
                <td>{streetAddress || 'Unknown'}</td>
              </tr>
              {!!estimatedReturnArrivalAtStoreAt && (
                <tr>
                  <td>
                    <RestoreOutlinedIcon className={classes.icon} />
                  </td>
                  <td colSpan={2}>
                    Estimated arrival at store at:{' '}
                    {Timestamp.fromMillis(estimatedReturnArrivalAtStoreAt).toDate().toLocaleTimeString()}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Box>
        <Box padding={2} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              isMarketplaceOrder ? submitMarketplaceReturn() : submitReturnTaskStatus(TaskState.RETURNED)
            }
            fullWidth
            className={classes.returnButton}
            disabled={!!loading}
            children={
              loading === TaskState.RETURNED ? <CircularProgress size={20} color="inherit" /> : 'Confirm returned bag'
            }
          />
          {!isMarketplaceOrder && (
            <Button
              variant="outlined"
              onClick={() => submitReturnTaskStatus(TaskState.NOT_RETURNED)}
              className={classes.notReturnButton}
              disabled={!!loading}
              children={
                loading === TaskState.NOT_RETURNED ? <CircularProgress size={20} color="inherit" /> : 'Bag not returned'
              }
            />
          )}
        </Box>

        {error && (
          <Typography variant="body2" color="error" style={{ textAlign: 'center' }}>
            {error}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  )
}
