import { PickingStatsV2, WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { ProgressBarChartData } from '../../types/ProgressBarChartData'
import { filterStatsByKey } from './filterStatsByKey'

export const getPickQueueMetrics = (
  warehouseStats?: WarehouseStatsV2,
  originFilter: string[] = []
): ProgressBarChartData[] => {
  if (!warehouseStats) return []

  const warehouses = Object.values(warehouseStats.statsByWarehouse)
  return warehouses.map((stat) => {
    const filteredPicking = filterStatsByKey<PickingStatsV2>(stat.picking, originFilter)
    const resultTotal = filteredPicking.reduce(
      (acc, current) => {
        acc.value += current.waitingOrdersNum
        return acc
      },
      { value: 0, valueMax: 20 }
    )

    return {
      ...resultTotal,
      name: stat.warehouse.shortName,
    } as ProgressBarChartData
  })
}
