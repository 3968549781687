import { TimeChartData } from '../../types/TimeChartData'
import { formatPercentString } from '../../utils/formatPercentString'

interface Params {
  metric1Label: string
  metric1Data: TimeChartData[]
  metric2Label: string
  metric2Data: TimeChartData[]
  metric3Label: string
  metric3Data: TimeChartData[]
}

export const getTripleLineChartConfig = ({
  metric1Label,
  metric2Label,
  metric3Label,
  metric1Data,
  metric2Data,
  metric3Data,
}: Params) => {
  const data = {
    datasets: [
      {
        label: metric1Label,
        data: metric1Data,
        fill: false,
        borderColor: '#FFE000',
        backgroundColor: '#FFE000',
        tension: 0.3,
        borderWidth: 2,
        borderJoinStyle: 'round' as const,
        borderCapStyle: 'round' as const,
      },
      {
        label: metric2Label,
        data: metric2Data,
        fill: false,
        borderColor: '#02BAFF',
        backgroundColor: '#02BAFF',
        tension: 0.3,
        borderJoinStyle: 'round' as const,
        borderCapStyle: 'round' as const,
        borderWidth: 2,
      },
      {
        label: metric3Label,
        data: metric3Data,
        fill: false,
        borderColor: '#BB6BD9',
        backgroundColor: '#BB6BD9',
        tension: 0.3,
        borderJoinStyle: 'round' as const,
        borderCapStyle: 'round' as const,
        borderWidth: 2,
      },
    ],
  }

  const options = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const label = context.dataset.label || ''
            const getLabel = (value: number) => {
              return formatPercentString(value)
            }
            if (context.parsed.y !== null) {
              return `${label}: ${getLabel(context.parsed.y)}`
            }
            return label
          },
        },
      },
    },
    scales: {
      y: {
        offset: true,
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
          font: {
            size: 10,
          },
        },
      },
      x: {
        border: {
          color: 'white',
        },
        ticks: {
          color: 'white',
          source: 'auto' as const,
          font: {
            size: 10,
          },
        },
        grid: {
          display: false,
        },
      },
    },
  }

  return { data, options }
}
