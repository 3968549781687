import { Order, OrderOrigin } from '@quickcommerceltd/zappboard'
import { useMemo } from 'react'
import { FirebaseFilter } from '../../components/OrderListV2/types/FirebaseFilter'

interface Params {
  originFilter: string[]
  warehouseFilter?: string[]
  totalManagedWarehouses: number
  customFilters?: FirebaseFilter<Order>[]
}
export const useL2OrderFilters = ({
  warehouseFilter = [],
  originFilter,
  totalManagedWarehouses,
  customFilters,
}: Params) => {
  const orderFilters = useMemo(() => {
    const orderFilters: FirebaseFilter<Order>[] = customFilters ?? []
    if (warehouseFilter.length && totalManagedWarehouses > 0 && warehouseFilter.length < totalManagedWarehouses) {
      orderFilters.push(['warehouseId', 'in', warehouseFilter])
    }
    if (originFilter.length && originFilter.length < Object.keys(OrderOrigin).length) {
      orderFilters.push(['orderOrigin', 'in', originFilter])
    }
    if (warehouseFilter.length && totalManagedWarehouses === 1) {
      orderFilters.push(['warehouseId', '==', warehouseFilter[0]])
    }
    return orderFilters.length ? orderFilters : undefined
  }, [warehouseFilter, originFilter, totalManagedWarehouses, customFilters])

  return orderFilters
}
