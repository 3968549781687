import { OrderOrigin } from '@quickcommerceltd/zappboard'
import { filterStatsByKey } from './filterStatsByKey'

export const sumMetricByOrigin = <T = Record<string, number>>(
  stats: Record<string, T>,
  metric: keyof T,
  originFilter: string[] = [OrderOrigin.UBER, OrderOrigin.DELIVEROO, OrderOrigin.ZAPP, OrderOrigin.JET]
): number => {
  if (!stats) return 0

  const filteredStats = filterStatsByKey<T>(stats, originFilter)
  return filteredStats.reduce((acc, current) => acc + ((current[metric] as number) ?? 0), 0)
}
