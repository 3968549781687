import { WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { useCallback, useMemo } from 'react'
import { getOrderStats } from '../../utils/warehouseStatsV2/getOrderStats'

interface Params {
  latestAcc: WarehouseStatsV2[]
  lastWeekSameTimeAcc: WarehouseStatsV2[]
  lastQuarterHourStatsAcc: WarehouseStatsV2[]
  lastWeekLastQuarterHourStatsAcc: WarehouseStatsV2[]
  originFilter: string[]
}
export const useHomeMomentumMetric = ({
  lastQuarterHourStatsAcc,
  latestAcc,
  lastWeekLastQuarterHourStatsAcc,
  lastWeekSameTimeAcc,
  originFilter,
}: Params): { ordersPerQuarter: number; ordersPerQuarterLastWeek: number } => {
  const calcOrdersPerQuarter = useCallback(
    (lastQuarterStats?: WarehouseStatsV2[], latestStats?: WarehouseStatsV2[]) => {
      if (!lastQuarterStats?.length || !latestStats?.length) {
        return -1
      }

      const orderMetricsLastQuarter = getOrderStats(lastQuarterStats?.[0], originFilter)
      const orderMetricsNow = getOrderStats(latestStats?.[0], originFilter)

      const totalNow =
        (orderMetricsNow?.fulfilledOrdersNum ?? 0) +
        (orderMetricsNow?.cancelledOrdersNum ?? 0) +
        (orderMetricsNow?.unfulfilledOrdersNum ?? 0)

      const totalLastQuarter =
        (orderMetricsLastQuarter?.fulfilledOrdersNum ?? 0) +
        (orderMetricsLastQuarter?.cancelledOrdersNum ?? 0) +
        (orderMetricsLastQuarter?.unfulfilledOrdersNum ?? 0)

      return totalNow - totalLastQuarter
    },
    [originFilter]
  )

  const ordersPerQuarter = useMemo(
    () => calcOrdersPerQuarter(lastQuarterHourStatsAcc, latestAcc),
    [lastQuarterHourStatsAcc, latestAcc, calcOrdersPerQuarter]
  )

  const ordersPerQuarterLastWeek = useMemo(
    () => calcOrdersPerQuarter(lastWeekLastQuarterHourStatsAcc, lastWeekSameTimeAcc),
    [lastWeekLastQuarterHourStatsAcc, lastWeekSameTimeAcc, calcOrdersPerQuarter]
  )

  return { ordersPerQuarter, ordersPerQuarterLastWeek }
}
