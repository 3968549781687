import { MutableRefObject, useEffect, useRef, useState } from 'react'

interface InViewOptions {
  root?: HTMLElement | null
  rootMargin?: string
  threshold?: number
}

const DEFAULT_OPTIONS: InViewOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 1.0,
}

interface Props {
  options?: InViewOptions
  onInView?: () => void
  onOutOfView?: () => void
}

export const useInView = ({
  options = DEFAULT_OPTIONS,
  onInView = () => {},
  onOutOfView = () => {},
}: Props): [MutableRefObject<unknown>, { inView: boolean; rootRef: MutableRefObject<unknown> }] => {
  const ref = useRef(null)
  const rootRef = useRef(null)
  const [inView, setInView] = useState(false)

  useEffect(() => {
    const elementRef = ref.current
    if (!elementRef) return

    // function to handle intersection changes
    const observerFn = ([entry]: IntersectionObserverEntry[]) => {
      entry.isIntersecting ? onInView() : onOutOfView()
      setInView(entry.isIntersecting)
    }

    // create observer
    const observer = new IntersectionObserver(observerFn, { ...options, root: rootRef.current })
    observer.observe(elementRef)

    // cleanup on unmount
    return () => {
      if (elementRef) observer.unobserve(elementRef)
    }
  }, [options, onInView, onOutOfView])

  return [ref, { inView, rootRef }]
}
