const routes = {
  home: '/',
  warehouse: '/warehouse/:id',
  statistics: '/statistics/:id',
  manager: '/manager/:id',
  riders: '/warehouse/:id/riders',
  signin: '/signin',
  authorize: '/authorize',
  orders: '/orders',
  old: '/old',

  // V2 routes
  stores: '/v2/stores',
  store: '/v2/stores/:id',
  storeExternal: '/v2/stores/:id/external',
  dtp: '/v2/dtp',
  dot: '/v2/dot',
  tier0: '/v2/tier0',
  revenue: '/v2/revenue',
  oosDefects: '/v2/oos-defects',
  lowRatings: '/v2/low-ratings',
  v2Orders: '/v2/orders',
}

export default routes
