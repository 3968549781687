import { Box } from '@mui/material'
import { FC, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { NumberChangeChip } from '../NumberChangeChip/NumberChangeChip'
import { calculatePercent } from './_helpers/calculatePercent'
import { convertToCurrency } from './_helpers/convertToCurrency'
import ActiveNumber from './ActiveNumber'

const useStyles = makeStyles()(() => ({
  revenue: {
    justifyContent: 'center',
    textAlign: 'center',
    whitespace: 'nowrap',
  },
}))

export const StoreRevenueStats: FC<{
  revenueNow: number
  revenueLastWeek: number
}> = ({ revenueNow, revenueLastWeek }) => {
  const { classes } = useStyles()
  const stats = useMemo(() => {
    const revenueToday = revenueNow ?? 0
    const revenueLastWeekSameTime = revenueLastWeek ?? 0
    const allTodayTotalGrowthPercent = calculatePercent(revenueToday, revenueLastWeekSameTime)

    return {
      revenueToday,
      allTodayTotalGrowthPercent,
    }
  }, [revenueNow, revenueLastWeek])

  return (
    <Box>
      <Box>
        <ActiveNumber size="tiny" className={classes.revenue}>
          {convertToCurrency(stats.revenueToday)}
        </ActiveNumber>
      </Box>
      <Box mt={'2px'}>
        <NumberChangeChip currentValue={revenueNow} compareValue={revenueLastWeek} small />
      </Box>
    </Box>
  )
}
