import ChatIcon from '@mui/icons-material/Chat'
import { Rating as MuiRating, Tooltip } from '@mui/material'
import { FC } from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<{ size: string }>()((_, { size }) => ({
  customerRatingNote: {
    marginLeft: '4px',
    fontSize: size === 'small' ? '16px' : '20px',
    color: 'darkgrey',
    cursor: 'pointer',
  },
}))

interface Props {
  rating: number
  note?: string
  size?: 'small' | 'medium' | 'large'
}

export const Rating: FC<Props> = ({ rating, note = '', size = 'medium' }) => {
  const { classes } = useStyles({ size })

  return (
    <Tooltip title={note}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <MuiRating name="customer-rating" value={rating} size={size} readOnly />
        {note && <ChatIcon className={classes.customerRatingNote} />}
      </div>
    </Tooltip>
  )
}
