import { Order, ShippingMethod } from '@quickcommerceltd/zappboard'
import includes from 'lodash/includes'
import startsWith from 'lodash/startsWith'

export const searchOrderList = (orderList: Order[], searchTerm: string): Order[] => {
  if (!searchTerm) return orderList

  const contains = (value: string = '', termList: string[], func: Function) => {
    return termList.some((term) => func(value.toLowerCase(), term.toLowerCase()))
  }

  const searchTermList = searchTerm.split(' ').filter(Boolean)
  return orderList.filter((order) =>
    [
      contains(String(order.number), searchTermList, includes),
      contains(order.externalId, searchTermList, includes),
      contains(order.picker?.name, searchTermList, includes),
      contains(order.rider?.name, searchTermList, includes),
      contains(order.fulfillmentStatus, searchTermList, startsWith),
      contains(order.deliveryStatus ?? ShippingMethod.PICKUP, searchTermList, startsWith),
      contains(order.pickingStatus, searchTermList, startsWith),
      order.skuIds?.some((skuId) => contains(skuId, searchTermList, includes)),
      order.skuNames?.some((skuName) => contains(skuName, searchTermList, includes)),
    ].some(Boolean)
  )
}
