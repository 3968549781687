import { collection, collectionGroup, DocumentData, or, orderBy, Query, query, where } from 'firebase/firestore'
import { db, orderTimestampConverter } from '../../../firebase'
import { FirebaseFilter } from '../types/FirebaseFilter'

export const constructOrdersByFiltersQuery = <T extends DocumentData>(
  filters?: FirebaseFilter<T>[],
  orConstraints?: FirebaseFilter<T>[],
  singleWarehouseId?: string
): Query<T> => {
  const queryBuilder = query(
    singleWarehouseId ? collection(db, `warehouseStats/${singleWarehouseId}/orders`) : collectionGroup(db, 'orders'),
    ...(filters ? filters.map((filter) => where(...filter)) : []),
    orderBy('createdAt', 'desc')
  )

  if (orConstraints) {
    query(queryBuilder, or(...orConstraints.map((filter) => where(...filter))))
  }

  return queryBuilder.withConverter(orderTimestampConverter) as Query<T>
}
