import { Stack } from '@mui/material'
import React, { FC, useMemo } from 'react'
import { BarLineChart } from '../../components/BarLineChart/BarLineChart'
import { DoubleLineChart } from '../../components/DoubleLineChart/DoubleLineChart'
import { ErrorAlertV2 } from '../../components/ErrorAlertV2/ErrorAlertV2'
import { MomentumPanel } from '../../components/MomentumPanel/MomentumPanel'
import { ProgressBarChart } from '../../components/ProgressBarChart/ProgressBarChart'
import { ProgressTable } from '../../components/ProgressTable/ProgressTable'
import { RevenuePanel } from '../../components/RevenuePanel/RevenuePanel'
import { StoreStatusPanel } from '../../components/StoreStatusPanel/StoreStatusPanel'
import { TripleLineChart } from '../../components/TripleLineChart/TripleLineChart'
import { WarehouseV2Filters } from '../../components/WarehouseV2Filters/warehouseV2Filters'
import { useCommonWarehouseStatsV2 } from '../../hooks/useCommonWarehouseStatsV2/useCommonWarehouseStatsV2'
import { useCycleCountProgress } from '../../hooks/useCycleCountProgress/useCycleCountProgress'
import { useHomeChartData } from '../../hooks/useHomePageMetrics/useHomeChartData'
import { useHomeHistoricalMetrics } from '../../hooks/useHomePageMetrics/useHomeHistoricalMetrics'
import { useHomeMomentumMetric } from '../../hooks/useHomePageMetrics/useHomeMomentumMetric'
import { useHomeRealTimeMetrics } from '../../hooks/useHomePageMetrics/useHomeRealTimeMetrics'
import { Layout } from '../../layout'
import { getStatsIdToLocalString, getStatsIdToLocalTime } from '../../utils/warehouseStatsV2/getCommonTimeData'
import { getDOTMinMax } from '../../utils/warehouseStatsV2/getDotMetrics'
import { getDTPMinMax } from '../../utils/warehouseStatsV2/getDtpMetrics'
import { getPickingMetricsMinMax } from '../../utils/warehouseStatsV2/getPickingMetrics'
import { getPickUpMetricsMinMax } from '../../utils/warehouseStatsV2/getPickUpMetrics'
import { getRevenueMinMax, getRevenueTotal } from '../../utils/warehouseStatsV2/getRevenueMetrics'
import { minutesFormatter } from '../../utils/warehouseStatsV2/minutesFormatter'
import routes from '../routes'
import { withAuthentication } from '../withAuthentication'

const HomePage: FC = () => {
  const {
    isAdmin,
    originFilter,
    latestAcc,
    latestHourly,
    lastWeekHourly,
    lastWeekEoD,
    lastWeekSameTimeAcc,
    lastQuarterHourStatsAcc,
    lastWeekLastQuarterHourStatsAcc,
    errorLatest,
    errorHourly,
    lastWeekError,
    lastWeekEndOfDayError,
    lastWeekStatsSameTimeError,
    yesterdayStatsSameTimeError,
    loadingLatest,
    loadingHourly,
    lastWeekLoading,
    lastWeekEndOfDayLoading,
    lastWeekStatsSameTimeLoading,
    lastQuarterHourLoading,
    lastWeekLastQuarterHourLoading,
    warehouseFilter,
    warehouseShortNameMap,
  } = useCommonWarehouseStatsV2()

  const { loading: ccLoading, ccProgress } = useCycleCountProgress(warehouseFilter ?? [], warehouseShortNameMap)

  // generate latest real time only metrics
  const { warehouseStatusMetrics, lowTier0Metrics, pickQueueMetrics, revenueMetrics } = useHomeRealTimeMetrics(
    latestAcc,
    originFilter
  )

  // generate latest historical metrics
  const { dtpMetrics, dotMetrics, pickingMetrics, pickupMetrics, oosDefectMetrics, lowRatingMetrics } =
    useHomeHistoricalMetrics(latestHourly, originFilter)

  // get the latest accumulated metrics (all orders from midnight till now)
  const {
    dtpMetrics: dtpMetricsAcc,
    dotMetrics: dotMetricsAcc,
    pickingMetrics: pickingMetricsAcc,
    pickupMetrics: pickupMetricsAcc,
    oosDefectMetrics: oosDefectMetricsAcc,
    lowRatingMetrics: lowRatingMetricsAcc,
  } = useHomeHistoricalMetrics(latestAcc, originFilter)

  // generate hourly metrics for charting
  const {
    dot20Hourly,
    dot30Hourly,
    dot45Hourly,
    hourlyDtpMetrics,
    hourlyPickingTime,
    hourlyPackingTime,
    hourlyRackTime,
    hourlyHandOverTime,
    hourlyOOSDefects,
    hourly01Ratings,
  } = useHomeChartData({
    dtpMetrics,
    dotMetrics,
    pickingMetrics,
    pickupMetrics,
    oosDefectMetrics,
    lowRatingMetrics,
  })

  // generate accumulated metrics for charting
  const {
    dot20Hourly: dot20HourlyAcc,
    dot30Hourly: dot30HourlyAcc,
    dot45Hourly: dot45HourlyAcc,
    hourlyDtpMetrics: hourlyDtpMetricsAcc,
    hourlyPickingTime: hourlyPickingTimeAcc,
    hourlyPackingTime: hourlyPackingTimeAcc,
    hourlyRackTime: hourlyRackTimeAcc,
    hourlyHandOverTime: hourlyHandOverTimeAcc,
    hourlyOOSDefects: hourlyOOSDefectsAcc,
    hourly01Ratings: hourly01RatingsAcc,
  } = useHomeChartData({
    dtpMetrics: dtpMetricsAcc,
    dotMetrics: dotMetricsAcc,
    pickingMetrics: pickingMetricsAcc,
    pickupMetrics: pickupMetricsAcc,
    oosDefectMetrics: oosDefectMetricsAcc,
    lowRatingMetrics: lowRatingMetricsAcc,
  })

  // generate revenue metrics last week
  const revenueMetricsLastWeek = useMemo(
    () => getRevenueTotal(lastWeekSameTimeAcc[0], originFilter),
    [lastWeekSameTimeAcc, originFilter]
  )
  const revenueMetricsLastWeekEoD = useMemo(
    () => getRevenueTotal(lastWeekEoD[0], originFilter),
    [lastWeekEoD, originFilter]
  )

  // generate last week historical metrics for WoW comparison
  const {
    dtpMetrics: dtpMetricsLastWeek,
    pickingMetrics: pickingMetricsLastWeek,
    pickupMetrics: pickupMetricsLastWeek,
    oosDefectMetrics: oosDefectMetricsLastWeek,
    lowRatingMetrics: lowRatingMetricsLastWeek,
  } = useHomeHistoricalMetrics(lastWeekHourly, originFilter)

  // generate last week accumulated metrics
  const {
    dtpMetrics: dtpMetricsLastWeekAcc,
    pickingMetrics: pickingMetricsLastWeekAcc,
    pickupMetrics: pickupMetricsLastWeekAcc,
    oosDefectMetrics: oosDefectMetricsLastWeekAcc,
    lowRatingMetrics: lowRatingMetricsLastWeekAcc,
  } = useHomeHistoricalMetrics(lastWeekSameTimeAcc, originFilter)

  // last week charting data
  const {
    hourlyDtpMetrics: hourlyDtpMetricsLastWeek,
    hourlyPickingTime: hourlyPickingTimeLastWeek,
    hourlyPackingTime: hourlyPackingTimeLastWeek,
    hourlyRackTime: hourlyRackTimeLastWeek,
    hourlyHandOverTime: hourlyHandOverTimeLastWeek,
    hourlyOOSDefects: hourlyOOSDefectsLastWeek,
    hourly01Ratings: hourly01RatingsLastWeek,
  } = useHomeChartData({
    dtpMetrics: dtpMetricsLastWeek,
    dotMetrics: [],
    pickingMetrics: pickingMetricsLastWeek,
    pickupMetrics: pickupMetricsLastWeek,
    oosDefectMetrics: oosDefectMetricsLastWeek,
    lowRatingMetrics: lowRatingMetricsLastWeek,
  })

  // last week charting data accumulated
  const {
    hourlyDtpMetrics: hourlyDtpMetricsLastWeekAcc,
    hourlyPickingTime: hourlyPickingTimeLastWeekAcc,
    hourlyPackingTime: hourlyPackingTimeLastWeekAcc,
    hourlyRackTime: hourlyRackTimeLastWeekAcc,
    hourlyHandOverTime: hourlyHandOverTimeLastWeekAcc,
    hourlyOOSDefects: hourlyOOSDefectsLastWeekAcc,
    hourly01Ratings: hourly01RatingsLastWeekAcc,
  } = useHomeChartData({
    dtpMetrics: dtpMetricsLastWeekAcc,
    dotMetrics: [],
    pickingMetrics: pickingMetricsLastWeekAcc,
    pickupMetrics: pickupMetricsLastWeekAcc,
    oosDefectMetrics: oosDefectMetricsLastWeekAcc,
    lowRatingMetrics: lowRatingMetricsLastWeekAcc,
  })

  const { ordersPerQuarter, ordersPerQuarterLastWeek } = useHomeMomentumMetric({
    lastQuarterHourStatsAcc,
    lastWeekLastQuarterHourStatsAcc,
    latestAcc,
    lastWeekSameTimeAcc,
    originFilter,
  })

  const error =
    errorLatest ||
    errorHourly ||
    lastWeekError ||
    lastWeekEndOfDayError ||
    lastWeekStatsSameTimeError ||
    yesterdayStatsSameTimeError

  if (error) {
    return <ErrorAlertV2 error={error} />
  }

  const latest = latestAcc[0]
  const latestLastWeek = lastWeekSameTimeAcc[0]

  const dtpChartProps = {
    title: 'DTP',
    solidLabel: 'Today',
    solidData: hourlyDtpMetrics.map((metric) => metric.chartData),
    dottedLabel: 'Last Week',
    dottedData: hourlyDtpMetricsLastWeek.map((metric) => metric.chartData),
    percentage: true,
    accumulatedNow: hourlyDtpMetricsAcc.map((metric) => metric.chartData).pop(),
    accumulatedPast: hourlyDtpMetricsLastWeekAcc.map((metric) => metric.chartData).pop(),
    link: routes.dtp,
    loading: loadingHourly || lastWeekLoading || loadingLatest || lastWeekStatsSameTimeLoading,
    todayTooltip: getDTPMinMax(latestAcc[0], originFilter),
    wowTooltip: getDTPMinMax(lastWeekSameTimeAcc[0], originFilter),
  }

  const dotTooltips = getDOTMinMax(latestAcc[0], originFilter)
  const dotChartProps = {
    title: 'DOT',
    metric1Label: 'D20',
    metric1Data: dot20Hourly.map((metric) => metric.chartData),
    metric2Label: 'D30',
    metric2Data: dot30Hourly.map((metric) => metric.chartData),
    metric3Label: 'D45',
    metric3Data: dot45Hourly.map((metric) => metric.chartData),
    metric1Accumulated: dot20HourlyAcc.map((metric) => metric.chartData).pop(),
    metric2Accumulated: dot30HourlyAcc.map((metric) => metric.chartData).pop(),
    metric3Accumulated: dot45HourlyAcc.map((metric) => metric.chartData).pop(),
    link: routes.dot,
    loading: loadingHourly || lastWeekLoading || loadingLatest || lastWeekStatsSameTimeLoading,
    d20Tooltip: dotTooltips?.d20,
    d30Tooltip: dotTooltips?.d30,
    d45Tooltip: dotTooltips?.d45,
  }

  const pickingMetricsMinMaxLatest = getPickingMetricsMinMax(latestAcc[0], originFilter)
  const pickingMetricsMinMaxLastWeek = getPickingMetricsMinMax(lastWeekSameTimeAcc[0], originFilter)

  const pickTimeChartProps = {
    title: 'Avg Picking',
    solidLabel: 'Today',
    solidData: hourlyPickingTime.map((metric) => metric.chartData),
    dottedLabel: 'Last Week',
    dottedData: hourlyPickingTimeLastWeek.map((metric) => metric.chartData),
    accumulatedNow: hourlyPickingTimeAcc.map((metric) => metric.chartData).pop(),
    accumulatedPast: hourlyPickingTimeLastWeekAcc.map((metric) => metric.chartData).pop(),
    formatter: minutesFormatter,
    loading: loadingHourly || lastWeekLoading || loadingLatest || lastWeekStatsSameTimeLoading,
    todayTooltip: pickingMetricsMinMaxLatest?.avgPickingTime,
    wowTooltip: pickingMetricsMinMaxLastWeek?.avgPickingTime,
  }

  const packTimeChartProps = {
    title: 'Avg Packing',
    solidLabel: 'Today',
    solidData: hourlyPackingTime.map((metric) => metric.chartData),
    dottedLabel: 'Last Week',
    dottedData: hourlyPackingTimeLastWeek.map((metric) => metric.chartData),
    accumulatedNow: hourlyPackingTimeAcc.map((metric) => metric.chartData).pop(),
    accumulatedPast: hourlyPackingTimeLastWeekAcc.map((metric) => metric.chartData).pop(),
    formatter: minutesFormatter,
    loading: loadingHourly || lastWeekLoading || loadingLatest || lastWeekStatsSameTimeLoading,
    todayTooltip: pickingMetricsMinMaxLatest?.avgPackingTime,
    wowTooltip: pickingMetricsMinMaxLastWeek?.avgPackingTime,
  }

  const rackTimeChartProps = {
    title: 'Avg Rack Time',
    solidLabel: 'Today',
    solidData: hourlyRackTime.map((metric) => metric.chartData),
    dottedLabel: 'Last Week',
    dottedData: hourlyRackTimeLastWeek.map((metric) => metric.chartData),
    accumulatedNow: hourlyRackTimeAcc.map((metric) => metric.chartData).pop(),
    accumulatedPast: hourlyRackTimeLastWeekAcc.map((metric) => metric.chartData).pop(),
    formatter: minutesFormatter,
    loading: loadingHourly || lastWeekLoading || loadingLatest || lastWeekStatsSameTimeLoading,
    todayTooltip: pickingMetricsMinMaxLatest?.avgRackingTime,
    wowTooltip: pickingMetricsMinMaxLastWeek?.avgRackingTime,
  }

  const handOverTimeChartProps = {
    title: 'Avg Handover at Pick Up',
    solidLabel: 'Today',
    solidData: hourlyHandOverTime.map((metric) => metric.chartData),
    dottedLabel: 'Last Week',
    dottedData: hourlyHandOverTimeLastWeek.map((metric) => metric.chartData),
    accumulatedNow: hourlyHandOverTimeAcc.map((metric) => metric.chartData).pop(),
    accumulatedPast: hourlyHandOverTimeLastWeekAcc.map((metric) => metric.chartData).pop(),
    formatter: minutesFormatter,
    loading: loadingHourly || lastWeekLoading || loadingLatest || lastWeekStatsSameTimeLoading,
    todayTooltip: getPickUpMetricsMinMax(latestAcc[0], originFilter)?.hapu,
    wowTooltip: getPickUpMetricsMinMax(lastWeekSameTimeAcc[0], originFilter)?.hapu,
  }

  const lowRatingChartProps = {
    title: '0/1 Ratings',
    lineLabel: 'Last Week',
    lineData: hourly01RatingsLastWeek.map((metric) => metric.chartData),
    barLabel: 'Today',
    barData: hourly01Ratings.map((metric) => metric.chartData),
    accumulatedNow: hourly01RatingsAcc.map((metric) => metric.chartData).pop(),
    accumulatedPast: hourly01RatingsLastWeekAcc.map((metric) => metric.chartData).pop(),
    loading: loadingHourly || lastWeekLoading || loadingLatest || lastWeekStatsSameTimeLoading,
    link: routes.lowRatings,
  }

  const oosDefectChartProps = {
    title: 'OOS Defects',
    lineLabel: 'Last Week',
    lineData: hourlyOOSDefectsLastWeek.map((metric) => metric.chartData),
    barLabel: 'Today',
    barData: hourlyOOSDefects.map((metric) => metric.chartData),
    accumulatedNow: hourlyOOSDefectsAcc.map((metric) => metric.chartData).pop(),
    accumulatedPast: hourlyOOSDefectsLastWeekAcc.map((metric) => metric.chartData).pop(),
    loading: loadingHourly || lastWeekLoading || loadingLatest || lastWeekStatsSameTimeLoading,
    link: routes.oosDefects,
  }

  const revenueMinMaxLatest = getRevenueMinMax(latestAcc[0], originFilter)
  const revenueMinMaxLastWeek = getRevenueMinMax(lastWeekSameTimeAcc[0], originFilter)
  const revenueMinMaxLastWeekEoD = getRevenueMinMax(lastWeekEoD[0], originFilter)

  return (
    <Layout>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} flexWrap="wrap" useFlexGap>
        <WarehouseV2Filters />
        {isAdmin && (
          <RevenuePanel
            currentDate={`Today ${latest?.id ? getStatsIdToLocalTime(latest.id).toFormat('HH:mm') : 'N/A'}`}
            pastDate={latestLastWeek?.id ? getStatsIdToLocalString(latestLastWeek.id) : 'N/A'}
            pastDateEoD={
              latestLastWeek?.id ? `${getStatsIdToLocalTime(latestLastWeek.id).toFormat('dd LLL yy')}, EOD` : 'N/A'
            }
            revenueSoFar={revenueMetrics?.total ?? 0}
            revenueSoFarPast={revenueMetricsLastWeek?.total ?? 0}
            projectedToday={revenueMetrics?.projectedTotal ?? 0}
            revenuePast={revenueMetricsLastWeekEoD?.total ?? 0}
            loading={loadingLatest || lastWeekEndOfDayLoading || lastWeekStatsSameTimeLoading}
            link={routes.revenue}
            todayTooltip={revenueMinMaxLatest?.actual}
            wowTooltip={revenueMinMaxLastWeek?.actual}
            projectedTooltip={revenueMinMaxLatest?.projected}
            wowEoDTooltip={revenueMinMaxLastWeekEoD?.actual}
          />
        )}
        <MomentumPanel
          ordersPerQuarter={ordersPerQuarter}
          ordersPerQuarterLastWeek={ordersPerQuarterLastWeek}
          loading={lastQuarterHourLoading || lastWeekLastQuarterHourLoading}
        />
        <DoubleLineChart {...dtpChartProps} />
        <TripleLineChart {...dotChartProps} />
        <BarLineChart {...lowRatingChartProps} />
        <BarLineChart {...oosDefectChartProps} />
        <ProgressBarChart
          title="Low Tier0 SKUs"
          subtitle="Total SKUs with less than 5 units"
          leftHeading="Store"
          rightHeading="SKUs"
          limit={6}
          data={lowTier0Metrics}
          link={routes.tier0}
          loading={loadingLatest}
        />
        <ProgressBarChart
          title="Waiting to Pick"
          leftHeading="Store"
          rightHeading="Orders"
          limit={6}
          data={pickQueueMetrics}
          loading={loadingLatest}
        />
        <ProgressTable
          title="Cycle Count"
          subtitle="Priority Locations. Resets at Midnight"
          leftHeading="Store"
          rightHeading="Locations"
          data={ccProgress}
          loading={ccLoading}
        />
        <StoreStatusPanel
          storeStatusList={warehouseStatusMetrics}
          pickQueue={pickQueueMetrics}
          loading={loadingLatest}
        />
        <DoubleLineChart {...pickTimeChartProps} />
        <DoubleLineChart {...packTimeChartProps} />
        <DoubleLineChart {...rackTimeChartProps} />
        <DoubleLineChart {...handOverTimeChartProps} />
      </Stack>
    </Layout>
  )
}

export default withAuthentication(React.memo(HomePage))
