import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { doc, serverTimestamp, setDoc } from 'firebase/firestore'
import hljs from 'highlight.js/lib/core'
import markdown from 'highlight.js/lib/languages/markdown'
import 'highlight.js/styles/dark.css'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import Markdown from 'react-markdown'
import Editor from 'react-simple-code-editor'
import { useRecoilState } from 'recoil'
import emoji from 'remark-emoji'
import { Pluggable } from 'unified'

import { db } from '../../firebase'
import { sessionState } from '../../state'
import { ToWarehouseSelector } from './ToWarehouseSelector'

hljs.registerLanguage('markdown', markdown)

interface Props {
  showModal: boolean
  setShowModal: (open: boolean) => void
}
export const SendMessageModal: React.FC<Props> = ({ showModal, setShowModal }) => {
  const session = useRecoilState(sessionState)
  const [markdown, setMarkdown] = useState('')
  const [orderNo, setOrderNo] = useState<string>('')
  const [isAction, setIsAction] = useState(false)
  const [toWarehouse, setToWarehouse] = useState<string[]>([])

  const sendMessage = async () => {
    try {
      const id = window.crypto.randomUUID()
      await setDoc(doc(db, 'messaging', id), {
        id,
        createdAt: serverTimestamp(),
        message: markdown,
        user: session[0].user?.email,
        orderNo,
        toWarehouse,
        isAction,
        receivedBy: [],
        actionedBy: [],
      })
      setMarkdown('')
      setOrderNo('')
      setIsAction(false)
      toast.success('Message sent', {
        icon: '✅',
        style: {
          fontFamily: 'Inter, sans-serif',
          background: '#00805C',
          color: '#fff',
        },
      })
      setShowModal(false)
    } catch (error) {
      toast.error((error as Error)?.message || 'Could not send message', {
        icon: '⚠️',
        style: {
          fontFamily: 'Inter, sans-serif',
          background: '#B5233E',
          color: '#fff',
        },
      })
    }
  }

  return (
    <Dialog open={showModal} onClose={() => setShowModal(false)}>
      <DialogTitle>Send Message</DialogTitle>
      <DialogContent>
        <Stack spacing={2} minWidth="500px">
          <Box display="flex" alignItems="center">
            <Typography variant="h3" sx={{ mr: 1 }}>
              To
            </Typography>
            <ToWarehouseSelector onWarehouseChange={setToWarehouse} />
          </Box>
          <TextField
            variant={'outlined'}
            placeholder="Order No (optional)"
            sx={{ border: '2px solid white', borderRadius: '7px', width: '30ch' }}
            value={orderNo}
            onChange={(e) => setOrderNo(e.target.value)}
          />
          <Editor
            value={markdown}
            onValueChange={(code) => setMarkdown(code)}
            highlight={(md) => hljs.highlight(md, { language: 'markdown' }).value}
            padding={10}
            style={{
              fontSize: 13,
              height: '250px',
              border: '2px solid white',
              borderRadius: '7px',
            }}
          />

          <Box>
            <FormControlLabel
              control={<Checkbox checked={isAction} onChange={(e) => setIsAction(e.target.checked)} />}
              label="Action Required"
            />
          </Box>
          <Box display={markdown.length ? 'block' : 'none'}>
            <Typography variant="overline">Preview</Typography>
            <Box sx={{ border: '1px solid #BABCD7', borderRadius: '7px', p: 1 }}>
              <Markdown remarkPlugins={[emoji as Pluggable]} children={markdown} />
            </Box>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Box justifyContent="flex-end" display="flex">
          <Button
            variant="contained"
            disabled={!markdown.length}
            onClick={sendMessage}
            sx={{ width: '10ch', mr: 1, fontWeight: '600' }}
          >
            Send
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowModal(false)}
            sx={{ width: '10ch', background: 'white', color: 'primary.main' }}
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
