import { TimeChartData } from '../../types/TimeChartData'
import { formatPercentString } from '../../utils/formatPercentString'

interface Params {
  dottedLabel: string
  dottedData: TimeChartData[]
  solidLabel: string
  solidData: TimeChartData[]
  percentage?: boolean
  formatter?: (value: number) => string
  maintainAspectRatio?: boolean
  borderWidth?: number
}
export const getDoubleLineChartConfig = ({
  dottedLabel,
  solidLabel,
  solidData,
  dottedData,
  percentage,
  formatter,
  maintainAspectRatio = true,
  borderWidth = 2,
}: Params) => {
  const data = {
    datasets: [
      {
        label: dottedLabel,
        data: dottedData,
        fill: false,
        borderColor: '#BABFEE',
        backgroundColor: '#BABFEE',
        tension: 0.3,
        borderDash: [5, 5],
        borderWidth,
        borderJoinStyle: 'round' as const,
        borderCapStyle: 'round' as const,
      },
      {
        label: solidLabel,
        data: solidData,
        fill: false,
        borderColor: '#02BAFF',
        backgroundColor: '#02BAFF',
        tension: 0.3,
        borderJoinStyle: 'round' as const,
        borderCapStyle: 'round' as const,
        borderWidth,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const label = context.dataset.label || ''
            const getLabel = (value: number) => {
              if (percentage) {
                return formatPercentString(value)
              }
              return formatter ? formatter(value) : value.toFixed(2)
            }
            if (context.parsed.y !== null) {
              return `${label}: ${getLabel(context.parsed.y)}`
            }
            return label
          },
        },
      },
    },
    scales: {
      y: {
        offset: true,
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
          font: {
            size: 10,
          },
        },
      },
      x: {
        border: {
          color: 'white',
        },
        ticks: {
          color: 'white',
          source: 'auto' as const,
          font: {
            size: 10,
          },
        },
        grid: {
          display: false,
        },
      },
    },
  }

  return { data, options }
}
