import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined'
import { FormControl, InputAdornment, ListItemText, MenuItem, OutlinedInput, Select, Stack } from '@mui/material'
import { FC, useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { useWarehouseStats } from '../../hooks'
import { useStoresToHide } from '../../hooks/useStoresToHide'
import { sessionState } from '../../state'

interface Props {
  warehouseId: string
  onChange: (warehouseId: string) => void
}

export const WarehouseSelector: FC<Props> = (props) => {
  const [session] = useRecoilState(sessionState)
  const [warehouses = []] = useWarehouseStats({ once: true })

  const storesToHide = useStoresToHide()

  const warehouseList = useMemo(() => {
    const user = session.user
    if (!user) return []
    const validWarehouses = warehouses.filter(
      (warehouse) => !storesToHide.includes(warehouse.shortName) && !warehouse.isDistributionCenter
    )

    if (user.manager) {
      return validWarehouses.filter((warehouse) => user.managedWarehouses?.includes(warehouse.id))
    }

    return validWarehouses
  }, [session, warehouses, storesToHide])

  if (warehouseList.length <= 1) return null

  return (
    <Stack alignItems="baseline">
      <FormControl sx={{ minWidth: 200 }}>
        <Select
          displayEmpty
          value={warehouseList.length ? props.warehouseId : ''}
          onChange={(e) => props.onChange(e.target.value as string)}
          MenuProps={{
            PaperProps: {
              sx: {
                borderRadius: '8px',
                backgroundColor: 'common.white',
                color: 'common.black',
                fontWeight: 700,
                marginTop: '0.5em',
              },
            },
          }}
          input={
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <StoreOutlinedIcon sx={{ fill: 'common.black', cursor: 'pointer' }} />
                </InputAdornment>
              }
              size="small"
              sx={{
                '& .MuiOutlinedInput-inputAdornedStart': {
                  color: '#E5F8FF',
                },
                height: '40px',
                backgroundColor: '#E5F8FF',
                color: 'background.paper',
                borderRadius: '20px',
              }}
            />
          }
        >
          {warehouseList.map((warehouse) => (
            <MenuItem key={warehouse.shortName} value={warehouse.id} sx={{ p: 1 }}>
              <ListItemText
                primary={
                  <strong>
                    {warehouse.name} ({warehouse.shortName})
                  </strong>
                }
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  )
}
