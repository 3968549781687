import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'

export const useOnlineStatus = () => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine)

  const checkConnection = useCallback(() => {
    axios(`/no-cache.png?v=${Date.now()}`, { timeout: 9500 })
      .then(() => setIsOffline(false))
      .catch(() => setIsOffline(true))
  }, [])

  useEffect(() => {
    const interval = setInterval(checkConnection, 10000)
    const onOffline = () => setIsOffline(true)
    const onOnline = () => checkConnection()

    checkConnection()
    window.addEventListener('offline', onOffline)
    window.addEventListener('online', onOnline)

    return () => {
      clearInterval(interval)
      window.removeEventListener('offline', onOffline)
      window.removeEventListener('online', onOnline)
    }
  }, [checkConnection])

  return !isOffline
}
