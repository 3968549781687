import { makeStyles } from 'tss-react/mui'

// TODO: migrate to emotion and styled
export const useOrderStyles = makeStyles()((theme) => {
  return {
    open: { backgroundColor: theme.palette.warning.main },
    unassigned: { backgroundColor: theme.palette.error.main },
    cancelled: { backgroundColor: theme.palette.warning.main },
    fulfilled: { backgroundColor: theme.palette.success.main },
    unfulfilled: { backgroundColor: theme.palette.primary.main },
  }
})
