import { Radio } from '@mui/material'
import cx from 'classnames'
import { useCustomerSupportDialogStyles } from '../CustomerSupportDialog.style'

export const StyledRadio = (props: any) => {
  const { classes } = useCustomerSupportDialogStyles()
  return (
    <Radio
      className={classes.radioRoot}
      disableRipple
      color="default"
      checkedIcon={<span className={cx(classes.radioIcon, classes.radioCheckedIcon)} />}
      icon={<span className={classes.radioIcon} />}
      {...props}
    />
  )
}
