import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { MinMaxWarehouseMetric } from '../../types/MinMaxValue'
import { MinMaxTooltip } from '../MinMaxTooltip/MinMaxTooltip'

interface Props {
  todayValue: string
  wowValue: string
  todayDate: string
  wowDate: string
  todayTooltip?: MinMaxWarehouseMetric | null
  wowTooltip?: MinMaxWarehouseMetric | null
}

const renderSmallText = (label: string, value: string) => (
  <>
    <Typography sx={{ fontSize: '16px', lineHeight: '19px', color: 'text.secondary' }}>{label}</Typography>
    <Typography sx={{ fontSize: '11px', lineHeight: '12px', color: 'text.secondary' }}>
      {value}, <span style={{ whiteSpace: 'nowrap' }}>since 00:00</span>
    </Typography>
  </>
)

export const ChartHeadingComparisonText: React.FC<Props> = ({
  todayValue,
  todayDate,
  wowValue,
  wowDate,
  todayTooltip,
  wowTooltip,
}) => (
  <Grid container spacing={1} alignItems="flex-end" sx={{ mb: 1 }}>
    <Grid item xs={6}>
      {!!todayTooltip && todayTooltip.min.warehouse !== todayTooltip.max.warehouse ? (
        <MinMaxTooltip minMax={todayTooltip}>
          <Typography sx={{ fontSize: '48px', lineHeight: '58px', fontWeight: 500 }}>{todayValue}</Typography>
        </MinMaxTooltip>
      ) : (
        <Typography sx={{ fontSize: '48px', lineHeight: '58px', fontWeight: 500 }}>{todayValue}</Typography>
      )}
    </Grid>
    <Grid item xs={6}>
      {!!wowTooltip && wowTooltip.min.warehouse !== wowTooltip.max.warehouse ? (
        <MinMaxTooltip minMax={wowTooltip}>
          <Typography
            sx={{ fontSize: '32px', lineHeight: '38px', fontWeight: 500, color: 'text.secondary', mr: '5rem' }}
          >
            {wowValue}
          </Typography>
        </MinMaxTooltip>
      ) : (
        <Typography sx={{ fontSize: '32px', lineHeight: '38px', fontWeight: 500, color: 'text.secondary', mr: '5rem' }}>
          {wowValue}
        </Typography>
      )}
    </Grid>
    <Grid item xs={6}>
      {renderSmallText('Today', todayDate)}
    </Grid>
    <Grid item xs={6}>
      <Stack>{renderSmallText('WoW', wowDate)}</Stack>
    </Grid>
  </Grid>
)
