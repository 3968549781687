import { Box, Typography } from '@mui/material'
import React from 'react'
import { shortCurrencyFmt } from '../../utils/formatCurrencyShort'

interface Props {
  currentValue: number
  compareValue: number
  percentage?: boolean
  inverted?: boolean
  fractionDigits?: number
  small?: boolean
}

const getValueChange = (
  currentValue: number,
  previousValue: number,
  percentage: boolean,
  inverted: boolean,
  fractionDigits: number
) => {
  if (!currentValue || !previousValue) return ['N/A', true]

  const change = currentValue - previousValue
  const isPositive = inverted ? change <= 0 : change > 0

  if (!percentage) {
    return [`${isPositive ? '+' : '-'} ${shortCurrencyFmt.format(Math.abs(change))}`, isPositive]
  }

  const percentageFmt = Intl.NumberFormat('en-GB', {
    style: 'percent',
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: fractionDigits,
  })

  if (inverted) {
    return [`${isPositive ? '↓' : '↑'} ${percentageFmt.format(Math.abs(change / previousValue))}`, isPositive]
  }

  return [`${isPositive ? '↑' : '↓'} ${percentageFmt.format(Math.abs(change / previousValue))}`, isPositive]
}

export const NumberChangeChip: React.FC<Props> = ({
  currentValue,
  compareValue,
  percentage = true,
  inverted = false,
  fractionDigits = 2,
  small = false,
}) => {
  const [text, isPositive] = getValueChange(currentValue, compareValue, percentage, inverted, fractionDigits)
  return (
    <Box
      data-testid="number-change-chip"
      sx={{
        backgroundColor: isPositive ? 'success.light' : 'error.main',
        height: 'fit-content',
        borderRadius: '4px',
        padding: '4px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
      }}
    >
      <Typography sx={{ fontSize: small ? '12px' : '16px', lineHeight: small ? '14px' : '19px', fontWeight: 600 }}>
        {text}
      </Typography>
    </Box>
  )
}
