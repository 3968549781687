import React from 'react'
import { Chart } from 'react-chartjs-2'
import { TimeChartData } from '../../types/TimeChartData'
import { ChartHeadingComparisonText } from '../ChartHeadingComparisonText/ChartHeadingComparisonText'
import { NoDataAvailable } from '../NoDataAvailable/NoDataAvailable'
import { WidgetPanel } from '../WidgetPanel/WidgetPanel'
import { getBarLineChartConfig } from './getBarLineChartConfig'

interface Props {
  title: string
  lineLabel: string
  lineData: TimeChartData[]
  barLabel: string
  barData: TimeChartData[]
  accumulatedNow?: TimeChartData
  accumulatedPast?: TimeChartData
  link?: string
  loading?: boolean
}

export const BarLineChart: React.FC<Props> = ({
  title,
  lineLabel,
  lineData,
  barLabel,
  barData,
  accumulatedNow,
  accumulatedPast,
  link,
  loading,
}) => {
  const { data, options } = getBarLineChartConfig({ lineLabel, lineData, barLabel, barData })

  if (!lineData.length && !barData.length) {
    return <NoDataAvailable title={title} />
  }

  const barLatest = accumulatedNow?.y ?? 0
  const barDate = accumulatedNow?.x ?? 'N/A'
  const lineLatest = accumulatedPast?.y ?? 0
  const lineDate = accumulatedPast?.time ?? 'N/A'

  return (
    <WidgetPanel title={title} link={link} loading={loading}>
      <ChartHeadingComparisonText
        todayValue={barLatest.toFixed(0)}
        wowValue={lineLatest.toFixed(0)}
        todayDate={barDate}
        wowDate={lineDate}
      />
      <Chart type="bar" data={data} options={options} height="130px" />
    </WidgetPanel>
  )
}
