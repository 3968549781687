import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { FulfillmentStatus, Order, PickingStatus, ShippingMethod, WarehouseStats } from '@quickcommerceltd/zappboard'
import { collection, doc, limit, orderBy, query, where } from 'firebase/firestore'
import includes from 'lodash/includes'
import startsWith from 'lodash/startsWith'
import { FC, useMemo, useState } from 'react'
import { useCollectionDataOnce, useDocumentData } from 'react-firebase-hooks/firestore'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link, useLocation, useParams } from 'react-router-dom'
import AppBar from '../components/AppBar'
import { OrdersList } from '../components/OrdersList/OrdersList'
import { OrderTabs } from '../components/OrderTabs/OrderTabs'
import { Searchbar } from '../components/Searchbar/Searchbar'
import { SoundEffect } from '../components/SoundEffect'
import { Title } from '../components/Text'
import { orderTimestampConverter } from '../firebase'
import { db } from '../firebase/firebase'
import { GenericDataConverter } from '../firebase/genericDataConverter/genericDataConverter'
import { usePaginationData } from '../hooks/usePagination'
import { WarehouseStatusIndicator } from './Home/components/WarehouseStatusIndicator'
import routes from './routes'
import { withAuthentication } from './withAuthentication'

const warehouseStatsConverter = new GenericDataConverter<WarehouseStats>()
// const orderConverter = new GenericDataConverter<Order>();

interface RouterProps {
  id: string
}

const Manager: FC = () => {
  const { id } = useParams<keyof RouterProps>() as RouterProps
  const [stats, loading] = useDocumentData<WarehouseStats>(
    doc(db, `warehouseStats/${id}`).withConverter(warehouseStatsConverter)
  )
  const searchQuery = new URLSearchParams(useLocation().search)
  const orderId = searchQuery.get('orderId') ?? undefined
  const [searchTerm, setSearchTerm] = useState('')

  // order list query
  const orderListQuery = useMemo(() => {
    return query(collection(db, `warehouseStats/${id}/orders`), orderBy('createdAt', 'desc')).withConverter(
      orderTimestampConverter
    )
  }, [id])

  const [orderList, { loadMore }] = usePaginationData<Order>(orderListQuery, {
    idField: 'id',
    limit: 50,
  })

  // single order query
  const singleOrderQuery = useMemo(() => {
    if (!searchTerm) return null

    const orderNumber = parseInt(searchTerm)
    if (isNaN(orderNumber)) return null
    if (orderList.find((order) => order.number === orderNumber)) return null

    return query(
      collection(db, `warehouseStats/${id}/orders`),
      where('number', '==', orderNumber),
      limit(1)
    ).withConverter(orderTimestampConverter)
  }, [id, searchTerm, orderList])

  const [singleOrder = []] = useCollectionDataOnce<Order>(singleOrderQuery)

  // single external order query
  const singleExternalOrderQuery = useMemo(() => {
    if (!searchTerm) return null
    if (orderList.find((order) => order.externalId === searchTerm)) return null

    return query(
      collection(db, `warehouseStats/${id}/orders`),
      where('externalId', '==', searchTerm),
      limit(1)
    ).withConverter(orderTimestampConverter)
  }, [id, searchTerm, orderList])

  const [singleExternalOrder = []] = useCollectionDataOnce<Order>(singleExternalOrderQuery)

  // combine orderList with single order from search
  const orders = useMemo(() => {
    return [...orderList, ...singleOrder, ...singleExternalOrder]
  }, [orderList, singleOrder, singleExternalOrder])

  const waitingOrders = useMemo(() => {
    return (
      orders?.filter(
        (o) => o.fulfillmentStatus === FulfillmentStatus.UNFULFILLED && o.pickingStatus === PickingStatus.OPEN
      )?.length ?? 0
    )
  }, [orders])

  const filteredOrders = useMemo(() => {
    if (!searchTerm) {
      return orders
    }

    const contains = (value: string = '', termList: string[], func: Function) => {
      return termList.some((term) => func(value.toLowerCase(), term.toLowerCase()))
    }

    return orders.filter((order) => {
      const searchTermList = searchTerm.split(' ').filter(Boolean)
      const { streetAddress1 } = order.shippingAddress

      const containsOrderNumber = contains(String(order.number), searchTermList, includes)
      const containsAddress = contains(streetAddress1, searchTermList, includes)
      const containsPicker = contains(order.picker?.name, searchTermList, includes)
      const containsRider = contains(order.rider?.name, searchTermList, includes)
      const isFulfillmentStatus = contains(order.fulfillmentStatus, searchTermList, startsWith)
      const isDeliveryStatus = contains(order.deliveryStatus ?? ShippingMethod.PICKUP, searchTermList, startsWith)
      const isPickingStatus = contains(order.pickingStatus, searchTermList, startsWith)
      const containsExternalId = contains(order.externalId, searchTermList, includes)

      return [
        containsOrderNumber,
        containsAddress,
        containsPicker,
        containsRider,
        isFulfillmentStatus,
        isDeliveryStatus,
        isPickingStatus,
        containsExternalId,
      ].some(Boolean)
    })
  }, [orders, searchTerm])

  if (!loading && !stats) {
    return (
      <>
        <Box display="flex" justifyContent="center" padding={4}>
          <Title>Not found</Title>
        </Box>
      </>
    )
  }

  if (!stats) {
    return (
      <>
        <Box display="flex" justifyContent="center" padding={4}>
          <CircularProgress />
        </Box>
      </>
    )
  }

  return (
    <>
      <SoundEffect value={waitingOrders} />
      <AppBar
        title={
          <Stack direction="row" spacing={3} alignItems="center">
            <Typography fontWeight="bold">
              {stats.shortName} • {stats.pickUpAddress?.city}
            </Typography>
            <WarehouseStatusIndicator warehouse={stats} />
          </Stack>
        }
        backTo={routes.home}
        searchbar={<Searchbar onSearch={setSearchTerm} />}
        actions={
          <Box display="none">
            <Button component={Link} variant="contained" to={routes.statistics.replace(':id', id)}>
              Stats
            </Button>
            <Box mx={1} />
            <Button component={Link} variant="contained" color="primary" to={routes.riders.replace(':id', id)}>
              Riders
            </Button>
          </Box>
        }
      />

      <InfiniteScroll
        dataLength={orders.length}
        next={loadMore}
        hasMore={true}
        loader={null}
        endMessage={null}
        refreshFunction={() => {}}
      >
        <Box paddingBottom="50vh">
          <Box padding={0}>
            <OrdersList interactive selectedOrderId={orderId} orders={filteredOrders ?? []} />
          </Box>
        </Box>
      </InfiniteScroll>

      <OrderTabs activeOrderId={orderId} orders={orders ?? []} />
    </>
  )
}

export default withAuthentication(Manager)
