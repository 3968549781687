import { WarehouseStats } from '@quickcommerceltd/zappboard'
import { doc } from 'firebase/firestore'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { db } from '../../firebase'
import { GenericDataConverter } from '../../firebase/genericDataConverter/genericDataConverter'

const warehouseStatsConverter = new GenericDataConverter<WarehouseStats>()

interface Props {
  warehouseId?: string
}

export const useWarehouse = ({ warehouseId }: Props) => {
  return useDocumentData<WarehouseStats>(
    warehouseId ? doc(db, 'warehouseStats', warehouseId).withConverter(warehouseStatsConverter) : undefined
  )
}
