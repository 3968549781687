import SearchIcon from '@mui/icons-material/Search'
import { InputAdornment, TextField } from '@mui/material'
import debounce from 'lodash/debounce'
import React, { useEffect, useMemo } from 'react'

const DEBOUNCE_TIME = 500

interface Props {
  onFilterChange: (filter: { searchTerm: string }) => void
  selectedOrderId: string
}

function getOrderNumber(orderId: string) {
  try {
    const orderNumber = window.atob(orderId).split(':')[1]
    return orderNumber
  } catch (e) {
    console.error('Error decoding orderId:', e)
    return null
  }
}

export const OrderSearchV2: React.FC<Props> = ({ onFilterChange, selectedOrderId }) => {
  const [searchTerm, setSearchTerm] = React.useState<string>('')

  const notifyFilterChange = useMemo(
    () =>
      debounce((searchTerm) => {
        !!onFilterChange &&
          onFilterChange({
            searchTerm,
          })
      }, DEBOUNCE_TIME),
    [onFilterChange]
  )

  useEffect(() => {
    if (selectedOrderId) {
      const orderNumber = getOrderNumber(selectedOrderId)
      if (!orderNumber) return

      setSearchTerm(orderNumber as string)
      notifyFilterChange(orderNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event?.target?.value ?? ''
    setSearchTerm(searchTerm)
    notifyFilterChange(searchTerm)
  }

  return (
    <TextField
      size="small"
      variant="outlined"
      placeholder="Search by Order ID, SKU ID or SKU Name"
      sx={{ width: '37ch', ml: 1, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
      value={searchTerm}
      onChange={handleSearchChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ color: 'primary.main' }} />
          </InputAdornment>
        ),
      }}
    />
  )
}
