import { Box, Button, CircularProgress, FormControl, FormControlLabel, RadioGroup, Typography } from '@mui/material'
import { CancelReason, SupportInquiry, SupportInquiryCode } from '@quickcommerceltd/zappboard'
import { FC, useState } from 'react'
import { useCustomerSupportDialogStyles } from '../CustomerSupportDialog.style'
import { StyledRadio } from '.'

interface Props {
  onButtonClick: () => void
  loading: boolean
  setRequest: (inquiry: Partial<SupportInquiry>) => void
}
export const CustomerSupportCancelled: FC<Props> = ({ onButtonClick, loading, setRequest }) => {
  const { classes } = useCustomerSupportDialogStyles()
  const [cancelReason, setCancelReason] = useState<CancelReason | undefined>(undefined)

  const handelCancelReasonChange = (event: any) => {
    const reason = event.target.value
    if (!Object.values(CancelReason).includes(reason)) {
      return
    }
    setCancelReason(reason)
    setRequest({
      code: SupportInquiryCode.CANCEL_ORDER,
      delayReason: undefined,
      cancelReason: reason,
      message: undefined,
      outOfStockItems: undefined,
    })
  }

  return (
    <Box>
      <Typography className={classes.reasonHeadline}>Please provide the reason for the cancellation*</Typography>

      <FormControl component="fieldset" required>
        <RadioGroup aria-label="cancelReason" name="cancelReason" value={undefined} onChange={handelCancelReasonChange}>
          <FormControlLabel
            className={classes.topRadio}
            value="no_riders_available"
            control={<StyledRadio />}
            label={<div className={classes.radioText}>No Riders Available</div>}
          />
          <FormControlLabel
            value="temp_store_closure"
            control={<StyledRadio />}
            label={<div className={classes.radioText}>Temp Store Closure</div>}
          />
          <FormControlLabel
            value="extreme_weather_conditions"
            control={<StyledRadio />}
            label={<div className={classes.radioText}>Extreme Weather Conditions</div>}
          />
          <FormControlLabel
            value="could_not_find_customer"
            control={<StyledRadio />}
            label={<div className={classes.radioText}>Could Not Find Customer</div>}
          />
          <FormControlLabel
            value="unresponsive_customer"
            control={<StyledRadio />}
            label={<div className={classes.radioText}>Unresponsive Customer</div>}
          />
          <FormControlLabel
            value="whole_order_oos"
            control={<StyledRadio />}
            label={<div className={classes.radioText}>Whole Order OOS</div>}
          />
        </RadioGroup>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        onClick={onButtonClick}
        fullWidth
        disabled={!cancelReason || loading}
        className={classes.button}
        children={loading ? <CircularProgress size={20} color="inherit" /> : 'Create Zendesk Ticket'}
      />
    </Box>
  )
}
