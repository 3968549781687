import { Divider, Stack, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { FC } from 'react'
import { PickingMetrics } from '../../types/PickingMetrics'
import { ChartHeadingComparisonText } from '../ChartHeadingComparisonText/ChartHeadingComparisonText'
import { NumberChangeChip } from '../NumberChangeChip/NumberChangeChip'
import { WidgetPanel } from '../WidgetPanel/WidgetPanel'

interface Props {
  pickingStatistics?: PickingMetrics
  pickingStatisticsPast?: PickingMetrics
}

export const TilePickingAndPackingTime: FC<Props> = (props) => {
  const lastWeekSameTime = DateTime.now().minus({ days: 7 })
  const toMinSeconds = (timestamp = 0) => DateTime.fromMillis(timestamp).toFormat('mm:ss')
  const isLoading = !props.pickingStatistics?.pickMetrics || !props.pickingStatisticsPast?.pickMetrics

  return (
    <WidgetPanel sx={{ width: 'inherit', height: 'inherit', p: 2 }} loading={isLoading}>
      <Stack spacing={1}>
        <Typography variant="h3">Avg Picking</Typography>
        <Stack direction="row" alignItems="baseline">
          <ChartHeadingComparisonText
            todayValue={toMinSeconds(props.pickingStatistics?.pickMetrics.avgPickingTimeMs)}
            todayDate={'Now'}
            wowValue={toMinSeconds(props.pickingStatisticsPast?.pickMetrics.avgPickingTimeMs)}
            wowDate={lastWeekSameTime.toFormat('dd LLL yy HH:mm')}
          />
          <NumberChangeChip
            currentValue={props.pickingStatistics?.pickMetrics.avgPickingTimeMs || 0}
            compareValue={props.pickingStatisticsPast?.pickMetrics.avgPickingTimeMs || 0}
            fractionDigits={0}
            inverted
          />
        </Stack>
      </Stack>
      <Stack flex={1} justifyContent="center">
        <Divider />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h3">Avg Packing</Typography>
        <Stack direction="row" alignItems="baseline">
          <ChartHeadingComparisonText
            todayValue={toMinSeconds(props.pickingStatistics?.pickMetrics.avgPackingTimeMs)}
            todayDate={'Now'}
            wowValue={toMinSeconds(props.pickingStatisticsPast?.pickMetrics.avgPackingTimeMs)}
            wowDate={lastWeekSameTime.toFormat('dd LLL yy HH:mm')}
          />
          <NumberChangeChip
            currentValue={props.pickingStatistics?.pickMetrics.avgPackingTimeMs || 0}
            compareValue={props.pickingStatisticsPast?.pickMetrics.avgPackingTimeMs || 0}
            fractionDigits={0}
            inverted
          />
        </Stack>
      </Stack>
    </WidgetPanel>
  )
}
