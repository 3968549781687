import { TimeChartData } from '../../types/TimeChartData'

interface Params {
  lineLabel: string
  lineData: TimeChartData[]
  barLabel: string
  barData: TimeChartData[]
  maintainAspectRatio?: boolean
  borderWidth?: number
}

export const getBarLineChartConfig = ({
  lineLabel,
  lineData,
  barLabel,
  barData,
  maintainAspectRatio,
  borderWidth,
}: Params) => {
  const data = {
    datasets: [
      {
        type: 'line' as const,
        label: lineLabel,
        borderColor: '#BABFEE',
        backgroundColor: '#BABFEE',
        tension: 0.5,
        data: lineData,
        borderDash: [5, 5],
        borderWidth: borderWidth ?? 2,
        borderJoinStyle: 'round' as const,
        borderCapStyle: 'round' as const,
      },
      {
        type: 'bar' as const,
        label: barLabel,
        borderColor: '#02BAFF',
        backgroundColor: '#02BAFF',
        data: barData,
        borderWidth: 0,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        offset: false,
        beginAtZero: true,
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
          stepSize: 1,
          font: {
            size: 10,
          },
        },
      },
      x: {
        border: {
          color: 'white',
        },
        ticks: {
          color: 'white',
          source: 'auto' as const,
          font: {
            size: 10,
          },
        },
        grid: {
          display: false,
        },
      },
    },
  }

  return { data, options }
}
