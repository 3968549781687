import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { OnlineStatusNotificationbar } from '../components/OnlineStatusNotificationbar/OnlineStatusNotificationbar'
import { TimeNowProvider } from '../providers/TimeNowProvider'
import AllOrders from './AllOrders'
import Authorize from './Authorize'
import Home from './Home/Home'
import HomeV2 from './HomeV2/HomeV2'
import Manager from './Manager'
import routes from './routes'
import Signin from './SignIn'
import DOT from './v2/DOT'
import DTP from './v2/DTP'
import ExternalStore from './v2/ExternalStore'
import LowRatings from './v2/LowRatings'
import OOSDefects from './v2/OOSDefects'
import Orders from './v2/Orders'
import Revenue from './v2/Revenue'
import Store from './v2/Store'
import Stores from './v2/Stores'
import Tier0 from './v2/Tier0'
import Warehouse from './Warehouse'

ChartJS.register(
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip
)

const App = () => {
  return (
    <TimeNowProvider>
      <OnlineStatusNotificationbar />
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path={routes.authorize} element={<Authorize />} />
          <Route path={routes.warehouse} element={<Warehouse />} />
          <Route path={routes.statistics} element={<Warehouse />} />
          <Route path={routes.manager} element={<Manager />} />
          <Route path={routes.orders} element={<AllOrders />} />
          <Route path={routes.signin} element={<Signin />} />
          <Route path={routes.old} element={<Home />} />

          {/* V2 */}
          <Route path={routes.home} element={<HomeV2 />} />
          <Route path={routes.stores} element={<Stores />} />
          <Route path={routes.store} element={<Store />} />
          <Route path={routes.storeExternal} element={<ExternalStore />} />
          <Route path={routes.dtp} element={<DTP />} />
          <Route path={routes.dot} element={<DOT />} />
          <Route path={routes.tier0} element={<Tier0 />} />
          <Route path={routes.revenue} element={<Revenue />} />
          <Route path={routes.oosDefects} element={<OOSDefects />} />
          <Route path={routes.lowRatings} element={<LowRatings />} />
          <Route path={routes.v2Orders} element={<Orders />} />

          {/* Fallback */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </TimeNowProvider>
  )
}

export default App
