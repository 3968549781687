import { Typography } from '@mui/material'
import { Order, TaskState } from '@quickcommerceltd/zappboard'
import { FC, useMemo } from 'react'
import { useTimeNow } from '../../hooks/useTimeNow'
import { ETA_TYPE } from './_consts/ETA_TYPE'
import { getDiffInSeconds } from './_helpers/getDiffInSeconds'
import { getDiffTranslation } from './_helpers/getDiffTranslation'
import { getEtaType } from './_helpers/getEtaType'
import { getStatusTranslation } from './_helpers/getStatusTranslation'
import { getValidEta } from './_helpers/getValidEta'

interface Props {
  order: Order
  orderDeliveryStatus: TaskState
  orderDeliveryStartedAt?: number
  orderEstimatedDeliveryAt?: number
  orderEstimatedArrivalAtStoreAt?: number
  isStacked: boolean
}

export const EtaTimestamp: FC<Props> = (props) => {
  const now = useTimeNow()

  const value = useMemo(() => {
    const etaType = getEtaType(props.order)
    const validEta = getValidEta(etaType, props.order)

    if (etaType !== ETA_TYPE.ARRIVED_AT_STORE && !validEta) return ''

    const diffInSeconds = getDiffInSeconds(now, validEta)
    const status = getStatusTranslation(diffInSeconds, etaType)
    const diff = getDiffTranslation(diffInSeconds)

    return `${status}${diff}` || ''
  }, [props, now])

  return useMemo(() => {
    if (!value) return null

    return (
      <Typography variant="caption" component={'div'}>
        {value}
      </Typography>
    )
  }, [value])
}
