import CircleIcon from '@mui/icons-material/Circle'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { Line } from 'react-chartjs-2'
import { MinMaxWarehouseMetric } from '../../types/MinMaxValue'
import { TimeChartData } from '../../types/TimeChartData'
import { formatPercentString } from '../../utils/formatPercentString'
import { MinMaxTooltip } from '../MinMaxTooltip/MinMaxTooltip'
import { NoDataAvailable } from '../NoDataAvailable/NoDataAvailable'
import { WidgetPanel } from '../WidgetPanel/WidgetPanel'
import { getTripleLineChartConfig } from './getTripleLineChartConfig'

interface Props {
  title: string
  metric1Label: string
  metric1Data: TimeChartData[]
  metric2Label: string
  metric2Data: TimeChartData[]
  metric3Label: string
  metric3Data: TimeChartData[]
  metric1Accumulated?: TimeChartData
  metric2Accumulated?: TimeChartData
  metric3Accumulated?: TimeChartData
  link?: string
  loading?: boolean
  d20Tooltip?: MinMaxWarehouseMetric | null
  d30Tooltip?: MinMaxWarehouseMetric | null
  d45Tooltip?: MinMaxWarehouseMetric | null
}
export const TripleLineChart: React.FC<Props> = ({
  title,
  metric1Label,
  metric2Label,
  metric3Label,
  metric1Data,
  metric2Data,
  metric3Data,
  metric1Accumulated,
  metric2Accumulated,
  metric3Accumulated,
  link,
  loading,
  d20Tooltip,
  d30Tooltip,
  d45Tooltip,
}) => {
  const { data, options } = getTripleLineChartConfig({
    metric1Label,
    metric1Data,
    metric2Label,
    metric2Data,
    metric3Label,
    metric3Data,
  })

  if (
    (!metric1Data.length && !metric2Data.length && !metric3Data.length) ||
    (metric1Data.length &&
      metric2Data.length &&
      metric3Data.length &&
      !metric1Accumulated?.y &&
      !metric2Accumulated?.y &&
      !metric3Accumulated?.y)
  ) {
    return <NoDataAvailable title={title} />
  }

  const iconText = (value: number, label: string, color: string, tooltip?: MinMaxWarehouseMetric | null) => (
    <Stack>
      {!!tooltip && tooltip.min.warehouse !== tooltip.max.warehouse ? (
        <MinMaxTooltip minMax={tooltip}>
          <Typography sx={{ fontSize: '32px', fontWeight: 500, lineHeight: '38px' }}>
            {formatPercentString(value)}
          </Typography>
        </MinMaxTooltip>
      ) : (
        <Typography sx={{ fontSize: '32px', fontWeight: 500, lineHeight: '38px' }}>
          {formatPercentString(value)}
        </Typography>
      )}
      <Stack direction="row" alignItems="center">
        <CircleIcon sx={{ color, fontSize: 16, mr: 0.5 }} />
        <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '19px', color: 'text.secondary' }}>
          {label}
        </Typography>
      </Stack>
    </Stack>
  )

  const latestMetric1 = metric1Accumulated?.y ?? 0
  const latestMetric2 = metric2Accumulated?.y ?? 0
  const latestMetric3 = metric3Accumulated?.y ?? 0

  return (
    <WidgetPanel title={title} link={link} loading={loading}>
      <Stack direction="row" spacing={5} my={1} mb={2}>
        <Stack>{iconText(latestMetric1, metric1Label, '#FFE000', d20Tooltip)}</Stack>
        <Stack>{iconText(latestMetric2, metric2Label, '#02BAFF', d30Tooltip)}</Stack>
        <Stack>{iconText(latestMetric3, metric3Label, '#BB6BD9', d45Tooltip)}</Stack>
      </Stack>
      <Line data={data} options={options} />
    </WidgetPanel>
  )
}
