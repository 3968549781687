import { Alert, Stack } from '@mui/material'
import React, { FC } from 'react'
import { Layout } from '../../layout'

interface Props {
  error?: Error
}
export const ErrorAlertV2: FC<Props> = ({ error }) => (
  <Layout>
    <Stack spacing={{ xs: 1, md: 2 }} sx={{ m: 1 }}>
      <Alert severity="error">Something went wrong: {error?.message}</Alert>
    </Stack>
  </Layout>
)
