import { FulfillmentStatus, Order, PickingStatus, TaskState } from '@quickcommerceltd/zappboard'
import uniqBy from 'lodash/uniqBy'

export const sortOrderList = (orderList: Order[]): Order[] => {
  const isUnfulfilled = (status: FulfillmentStatus) => status === FulfillmentStatus.UNFULFILLED
  const isWaitingForPickup = (order: Order) =>
    order.pickingStatus === PickingStatus.PACKED &&
    order.deliveryStatus === TaskState.ASSIGNED &&
    order.deliveryRiderArrivedForPickupAt

  const sortedByRiderWaitingAtPickup = [...orderList].filter(isWaitingForPickup).sort((a, b) => {
    return (a.deliveryRiderArrivedForPickupAt ?? 0) - (b.deliveryRiderArrivedForPickupAt ?? 0)
  })

  const sortedByFulfillmentStatus = [...orderList].sort((a, b) => {
    return (
      Number(isUnfulfilled(b.fulfillmentStatus)) - Number(isUnfulfilled(a.fulfillmentStatus)) ||
      b.createdAt - a.createdAt
    )
  })

  return uniqBy([...sortedByRiderWaitingAtPickup, ...sortedByFulfillmentStatus], 'id')
}
