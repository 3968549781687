import { WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { collection, limit, orderBy, query, where } from 'firebase/firestore'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useCollectionData, useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { db } from '../../firebase'
import { GenericDataConverter } from '../../firebase/genericDataConverter/genericDataConverter'

const warehouseStatsConverter = new GenericDataConverter<WarehouseStatsV2>()

interface Props {
  startDate?: DateTime // start date of the first stat to fetch and quantity will determine how many stats to fetch working back (in time)
  quantity?: number // how many stats to fetch working back (each stat entry corresponds to a different time)
  once?: boolean
}

export const useWarehouseStatsRollingV2 = ({ quantity = 8, once, startDate }: Props = {}) => {
  const useCollection = useMemo(() => {
    return once ? useCollectionDataOnce : useCollectionData
  }, [once])

  const snapshotMs = useMemo(() => (startDate ? startDate.endOf('hour').toMillis() : null), [startDate])

  return useCollection<WarehouseStatsV2>(
    query(
      collection(db, 'statistics-rolling'),
      ...(snapshotMs ? [where('snapshotTimeMs', '<=', snapshotMs)] : []),
      orderBy('snapshotTimeMs', 'desc'),
      limit(quantity)
    ).withConverter(warehouseStatsConverter)
  )
}
