/**
 * Define the different parts of your state here
 * using https://recoiljs.org/
 */
import { DeliveryPartner } from '@quickcommerceltd/zappboard'
import { OrderOrigin } from '@quickcommerceltd/zappboard/lib/types/order-origin'
import { atom } from 'recoil'

export const sessionState = atom<{
  user: {
    uid: string
    email: string | null
    displayName: string | null
    admin: boolean | null
    manager: boolean | null
    supervisor: boolean | null
    managedWarehouses: string[] | null
  } | null
  isAuthenticating: boolean
  warehouseFilter: string[] | undefined
  originFilter: string[]
  deliveryPartnerFilter: string[]
}>({
  key: 'session',
  default: {
    isAuthenticating: true,
    user: null,
    warehouseFilter: undefined,
    originFilter: [OrderOrigin.ZAPP, OrderOrigin.UBER, OrderOrigin.DELIVEROO, OrderOrigin.JET],
    deliveryPartnerFilter: [DeliveryPartner.UBER, DeliveryPartner.STUART],
  },
})
