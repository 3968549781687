import { useMemo } from 'react'
import { DOTMetrics } from '../../types/DOTMetrics'
import { DTPMetrics } from '../../types/DTPMetrics'
import { LowRatingMetrics } from '../../types/LowRatingMetrics'
import { OOSDefectMetrics } from '../../types/OOSDefectMetrics'
import { PickingMetrics } from '../../types/PickingMetrics'
import { PickUpMetrics } from '../../types/PickUpMetrics'
import { getLowRatingHourlyChartData } from '../../utils/warehouseStatsV2/get01RatingMetrics'
import { getDOTHourlyChartData } from '../../utils/warehouseStatsV2/getDotMetrics'
import { getDTPHourlyChartData } from '../../utils/warehouseStatsV2/getDtpMetrics'
import { getOOSDefectHourlyChartData } from '../../utils/warehouseStatsV2/getOOSDefectMetrics'
import {
  getHourlyPackingTimeChartData,
  getHourlyPickingTimeChartData,
  getHourlyRackTimeChartData,
} from '../../utils/warehouseStatsV2/getPickingMetrics'
import { getHourlyHandOverTimeChartData } from '../../utils/warehouseStatsV2/getPickUpMetrics'

interface Params {
  dtpMetrics: DTPMetrics[]
  dotMetrics: DOTMetrics[]
  pickupMetrics: PickUpMetrics[]
  pickingMetrics: PickingMetrics[]
  oosDefectMetrics: OOSDefectMetrics[]
  lowRatingMetrics: LowRatingMetrics[]
}

export const useHomeChartData = ({
  dtpMetrics,
  dotMetrics,
  pickupMetrics,
  pickingMetrics,
  oosDefectMetrics,
  lowRatingMetrics,
}: Params) => {
  const [dot20Hourly, dot30Hourly, dot45Hourly] = useMemo(() => getDOTHourlyChartData(dotMetrics), [dotMetrics])
  const hourlyDtpMetrics = useMemo(() => getDTPHourlyChartData(dtpMetrics), [dtpMetrics])
  const hourlyPickingTime = useMemo(() => getHourlyPickingTimeChartData(pickingMetrics), [pickingMetrics])
  const hourlyPackingTime = useMemo(() => getHourlyPackingTimeChartData(pickingMetrics), [pickingMetrics])
  const hourlyRackTime = useMemo(() => getHourlyRackTimeChartData(pickingMetrics), [pickingMetrics])
  const hourlyHandOverTime = useMemo(() => getHourlyHandOverTimeChartData(pickupMetrics), [pickupMetrics])
  const hourlyOOSDefects = useMemo(() => getOOSDefectHourlyChartData(oosDefectMetrics), [oosDefectMetrics])
  const hourly01Ratings = useMemo(() => getLowRatingHourlyChartData(lowRatingMetrics), [lowRatingMetrics])

  return {
    dot20Hourly,
    dot30Hourly,
    dot45Hourly,
    hourlyDtpMetrics,
    hourlyPickingTime,
    hourlyPackingTime,
    hourlyRackTime,
    hourlyHandOverTime,
    hourlyOOSDefects,
    hourly01Ratings,
  }
}
