import { CssBaseline } from '@mui/material'
import { grey } from '@mui/material/colors'
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  Theme,
  alpha,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles'
import '@mui/styles'
import { FC } from 'react'

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    status: {
      danger: {
        primary: React.CSSProperties['color']
        secondary: React.CSSProperties['color']
      }
      warning: {
        primary: React.CSSProperties['color']
        secondary: React.CSSProperties['color']
      }
      idle: {
        primary: React.CSSProperties['color']
        secondary: React.CSSProperties['color']
      }
    }
  }
  interface ThemeOptions {
    status: {
      danger: {
        primary: React.CSSProperties['color']
        secondary: React.CSSProperties['color']
      }
      warning: {
        primary: React.CSSProperties['color']
        secondary: React.CSSProperties['color']
      }
      idle: {
        primary: React.CSSProperties['color']
        secondary: React.CSSProperties['color']
      }
    }
  }
}

export const theme = createTheme({
  status: {
    danger: { primary: '#B5233E', secondary: '#E63555' },
    warning: { primary: '#C86102', secondary: '#FF891C' },
    idle: { primary: '#00805C', secondary: '#05CD85' },
  },
  palette: {
    background: { default: '#212331', paper: '#2B2D3D' },
    primary: { main: '#02BAFF', contrastText: '#FFFFFF' },
    secondary: { main: '#02BAFF', contrastText: '#FFFFFF' },
    text: { primary: '#FFFFFF', secondary: '#BABCD7' },
    success: { main: '#00805C', light: '#00CC93' },
    error: { main: '#D83232' },
    warning: { main: '#F59401' },
    divider: '#404258',
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: grey[50],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: { textTransform: 'none', fontWeight: 600 },
        textSizeSmall: { textTransform: 'uppercase' },
        root: {
          '&.Mui-disabled': {
            color: grey[700],
            border: '1px solid #616161',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: alpha('#404258', 0.9),
          fontSize: '1em',
        },
        arrow: { color: alpha('#404258', 0.9) },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 700,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#404258',
            opacity: 1,
            '&:hover': { backgroundColor: '#404258' },
          },

          '&:hover': {
            backgroundColor: 'rgba(0,0,0,.1)',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 28,
        },
        label: {
          fontWeight: 700,

          fontSize: '1.1em',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          backgroundColor: '#404258',
        },
      },
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h1: {
      fontSize: 40,
      lineHeight: 1.5,
      fontWeight: 'bold',
    },
    h2: {
      fontSize: 32,
      lineHeight: 44 / 32,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 20,
      lineHeight: 27 / 20,
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 16,
      lineHeight: 22 / 16,
      fontWeight: 'bold',
    },
    h5: {
      fontSize: 14,
      lineHeight: 19 / 14,
      fontWeight: 'bold',
    },
    caption: {
      fontSize: 12,
      lineHeight: 16 / 12,
      fontWeight: 400,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
})

export const ThemeProvider: FC = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={responsiveFontSizes(theme)}>
      <>
        <CssBaseline />
        {children}
      </>
    </MuiThemeProvider>
  </StyledEngineProvider>
)
