import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { FulfillmentStatus, Order, PickingStatus, TaskState } from '@quickcommerceltd/zappboard'
import cx from 'classnames'
import { Timestamp, collection, doc, orderBy, query, where } from 'firebase/firestore'
import { Duration } from 'luxon'
import { FC, useMemo } from 'react'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import AppBar from '../components/AppBar'
import { Card, cardStyles } from '../components/Card'
import { OrdersList } from '../components/OrdersList/OrdersList'
import {
  RevenueAndWorkloadStats,
  RevenueWarehouseStats,
} from '../components/RevenueAndWorkloadStats/RevenueAndWorkloadStats'
import { SoundEffect } from '../components/SoundEffect'
import { Description, Number, Subtitle, Time, Title } from '../components/Text'
import WarehouseCard from '../components/WarehouseCard/WarehouseCard'
import { ORDERS_PERIOD_MS } from '../config'
import { db, orderTimestampConverter } from '../firebase'
import { GenericDataConverter } from '../firebase/genericDataConverter/genericDataConverter'
import { sessionState } from '../state'
import { toFirestoreTimestamp } from '../utils/toFirestoreTimestamp'
import { WarehouseStatusIndicator } from './Home/components/WarehouseStatusIndicator'
import routes from './routes'
import { withAuthentication } from './withAuthentication'

const UNFULFILLED_DANGER = 5 //minutes
const CANCELLED_DANGER = 5
const UNASSIGNED_WARNING = 3
const PENDING_WARNING = 3
const UNASSIGNED_DANGER = 8
const PENDING_DANGER = 8
const WAITING_WARNING = 1
const WAITING_DANGER = 2
const PICKING_WARNING = 2
const PICKING_DANGER = 4
const ASSIGNED_WARNING = 3
const ASSIGNED_DANGER = 8
const ACTIVE_WARNING = 3
const ACTIVE_DANGER = 8

const MINUTE_IN_MS = 60000
const DELIVERY_10AVG_WARNING = MINUTE_IN_MS * 15
const DELIVERY_10AVG_DANGER = MINUTE_IN_MS * 18
const PROMISED_10AVG_WARNING = 20 * MINUTE_IN_MS
const PROMISED_10AVG_DANGER = 30 * MINUTE_IN_MS
const DELIVERED_ON_TIME_DANGER = 85 //percent
const DELIVERED_ON_TIME_WARNING = 90 //percent

const DELIVERED_TO_PROMISE_DANGER = 90 //percent
const DELIVERED_TO_PROMISE_WARNING = 95 //percent

const revenueStatsConverter = new GenericDataConverter<RevenueWarehouseStats>()

interface RouterProps {
  id: string
}

const Warehouse: FC = () => {
  const [session] = useRecoilState(sessionState)

  const { classes } = cardStyles()
  const { id } = useParams<keyof RouterProps>() as RouterProps
  const searchQuery = new URLSearchParams(useLocation().search)
  const orderId = searchQuery.get('orderId') ?? undefined

  const [stats, loading, error] = useDocumentData<RevenueWarehouseStats>(
    doc(db, `warehouseStats/${id}`).withConverter(revenueStatsConverter)
  )

  const now = useMemo(() => Timestamp.now().toMillis(), [])

  const [orders] = useCollectionData<Order>(
    query(
      collection(db, `warehouseStats/${id}/orders`),
      orderBy('createdAt', 'desc'),
      where('createdAt', '>', toFirestoreTimestamp(now - ORDERS_PERIOD_MS))
    ).withConverter(orderTimestampConverter)
  )

  const orderStats = useMemo(() => {
    const unfulfilledOrders = orders?.filter((o) => o.fulfillmentStatus === FulfillmentStatus.UNFULFILLED)
    return {
      waiting: unfulfilledOrders?.filter((o) => o.pickingStatus === PickingStatus.OPEN)?.length ?? 0,
      picking: unfulfilledOrders?.filter((o) => o.pickingStatus === PickingStatus.PICKING)?.length ?? 0,
      unassigned: unfulfilledOrders?.filter((o) => o.deliveryStatus === TaskState.UNASSIGNED)?.length ?? 0,
      pending: unfulfilledOrders?.filter((o) => o.deliveryStatus === TaskState.PENDING)?.length ?? 0,
      assigned:
        unfulfilledOrders?.filter((o) => [TaskState.ASSIGNED, TaskState.PENDING].includes(o.deliveryStatus))?.length ??
        0,
      active:
        unfulfilledOrders?.filter((o) =>
          [TaskState.ACTIVE, TaskState.PICKED_UP, TaskState.RETURNING].includes(o.deliveryStatus)
        )?.length ?? 0,
    }
  }, [orders])

  if (process.env.NODE_ENV === 'development') {
    console.log('warehouse', stats, orders)
  }

  if (error) {
    return <Box padding={4}>{error.message}</Box>
  }

  if (!loading && !stats) {
    return (
      <>
        <AppBar title={`${id}`} backTo={routes.home} />
        <Box display="flex" justifyContent="center" padding={4}>
          <Title>Not found</Title>
        </Box>
      </>
    )
  }

  if (!stats || !orders) {
    return (
      <>
        <AppBar title={`${id}`} backTo={routes.home} />
        <Box display="flex" justifyContent="center" padding={4}>
          <CircularProgress />
        </Box>
      </>
    )
  }

  const avgCompletionTime = Duration.fromMillis(stats.tasks?.avgDeliveryTime10OrdersZapp)
  const avgPromisedTime = Duration.fromMillis(stats.tasks?.avgDeliveryPromise10OrdersZapp)

  return (
    <>
      <SoundEffect value={orderStats.waiting} />
      <AppBar
        title={
          <Stack direction="row" spacing={3} alignItems="center">
            <Typography fontWeight="bold">
              {stats.shortName} • {stats.pickUpAddress?.city}
            </Typography>
            <WarehouseStatusIndicator warehouse={stats} />
          </Stack>
        }
        backTo={routes.home}
        actions={
          <Box display="none">
            <Button component={Link} variant="contained" color="primary" to={routes.manager.replace(':id', id)}>
              Manager
            </Button>
            <Box mx={1} />
            <Button component={Link} variant="contained" color="primary" to={routes.riders.replace(':id', id)}>
              Riders
            </Button>
          </Box>
        }
      />

      <Stack m={2} spacing={2}>
        <Box display="flex" m={-1} className={classes.tiles}>
          <Box m={1} flex={1} display="flex" className={classes.headTile}>
            <Box display="flex" flex={1} height="1">
              <Box m={-1} display="flex" flex={1} className={classes.head}>
                <Box m={1} flex={1}>
                  {stats && <WarehouseCard stats={stats} />}
                </Box>

                <Box m={1} flex={1}>
                  <Card className={cx(classes.summaryCard)}>
                    <Description>Today (from 00:00)</Description>
                    <Box display="flex" className={classes.paperIdle} mb={-1}>
                      <Box flex={1} m={1}>
                        <Number size="small">{stats.orders?.fulfilledOrdersNum || 0}</Number>
                        <Subtitle>Fulfilled</Subtitle>
                      </Box>
                      <Box
                        flex={1}
                        m={1}
                        className={cx(
                          stats.orders?.cancelledOrdersNum > CANCELLED_DANGER ? classes.paperDanger : classes.paperIdle
                        )}
                      >
                        <Number size="small">{stats.orders?.cancelledOrdersNum || 0}</Number>
                        <Subtitle>Cancelled</Subtitle>
                      </Box>
                    </Box>
                  </Card>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box m={1} flex={1} className={classes.totals}>
            <Box height="1" mx={-1} display="flex">
              <Box flex={1} mx={1}>
                <Card
                  className={cx(
                    stats.orders.unfulfilledOrdersNum < UNFULFILLED_DANGER
                      ? classes.idle
                      : stats.orders.unfulfilledOrdersNum < UNFULFILLED_DANGER
                      ? classes.warning
                      : classes.danger,
                    classes.item
                  )}
                >
                  <Title>Unfulfilled</Title>
                  <Number>{stats.orders.unfulfilledOrdersNum || 0}</Number>
                  <Subtitle>Unfulfilled Orders</Subtitle>
                </Card>
              </Box>
              <Box flex={1} mx={1}>
                <Card
                  className={cx(
                    orderStats.unassigned < UNASSIGNED_WARNING
                      ? classes.idle
                      : orderStats.unassigned < UNASSIGNED_DANGER
                      ? classes.warning
                      : classes.danger,
                    classes.item
                  )}
                >
                  <Title>Unassigned</Title>
                  <Number>{orderStats.unassigned}</Number>
                  <Subtitle>Unassigned tasks</Subtitle>
                </Card>
              </Box>
              <Box flex={1} mx={1}>
                <Card
                  className={cx(
                    orderStats.pending < PENDING_WARNING
                      ? classes.idle
                      : orderStats.pending < PENDING_DANGER
                      ? classes.warning
                      : classes.danger,
                    classes.item
                  )}
                >
                  <Title>Pending</Title>
                  <Number>{orderStats.pending}</Number>
                  <Subtitle>Pending 3P rider</Subtitle>
                </Card>
              </Box>
              <Box flex={1} mx={1}>
                <Card
                  className={cx(
                    orderStats.waiting < WAITING_WARNING
                      ? classes.idle
                      : orderStats.waiting < WAITING_DANGER
                      ? classes.warning
                      : classes.danger,
                    classes.item
                  )}
                >
                  <Title>Waiting</Title>
                  <Number>{orderStats.waiting}</Number>
                  <Subtitle>Orders waiting to be picked</Subtitle>
                </Card>
              </Box>
              <Box flex={1} mx={1}>
                <Card
                  className={cx(
                    orderStats.picking < PICKING_WARNING
                      ? classes.idle
                      : orderStats.picking < PICKING_DANGER
                      ? classes.warning
                      : classes.danger,
                    classes.item
                  )}
                >
                  <Title>Picking</Title>
                  <Number>{orderStats.picking}</Number>
                  <Subtitle>Orders in picking</Subtitle>
                </Card>
              </Box>
              <Box flex={1} mx={1}>
                <Card
                  className={cx(
                    orderStats.assigned < ASSIGNED_WARNING
                      ? classes.idle
                      : orderStats.assigned < ASSIGNED_DANGER
                      ? classes.warning
                      : classes.danger,
                    classes.item
                  )}
                >
                  <Title>Assigned</Title>
                  <Number>{orderStats.assigned}</Number>
                  <Subtitle>Assigned tasks</Subtitle>
                </Card>
              </Box>
              <Box flex={1} mx={1}>
                <Card
                  className={cx(
                    orderStats.active < ACTIVE_WARNING
                      ? classes.idle
                      : orderStats.active < ACTIVE_DANGER
                      ? classes.warning
                      : classes.danger,
                    classes.item
                  )}
                >
                  <Title>In Delivery</Title>
                  <Number>{orderStats.active}</Number>
                  <Subtitle>Active tasks</Subtitle>
                </Card>
              </Box>
            </Box>
          </Box>

          <Box m={1} flex={0.6} display="flex" className={classes.timersTile}>
            <Box m={-1} flex="1" display="flex">
              <Box height="1" flex="1" display="flex" flexDirection="column">
                <Box textAlign="center" m={1} flex="1">
                  <Card
                    className={cx(
                      classes.time,
                      stats.tasks?.completedTasksWithinPromisePercentZapp <= DELIVERED_TO_PROMISE_DANGER
                        ? classes.danger
                        : stats.tasks?.completedTasksWithinPromisePercentZapp <= DELIVERED_TO_PROMISE_WARNING
                        ? classes.warning
                        : classes.idle
                    )}
                  >
                    <Time>{stats.tasks?.completedTasksWithinPromisePercentZapp?.toFixed(0)}%</Time>
                    <Subtitle>Delivered to promise</Subtitle>
                    <Description>Today (from 00:00)</Description>
                  </Card>
                </Box>

                <Box textAlign="center" m={1} flex="1">
                  <Card
                    className={cx(
                      stats.tasks?.avgDeliveryTime10OrdersZapp < DELIVERY_10AVG_WARNING
                        ? classes.idle
                        : stats.tasks?.avgDeliveryTime10OrdersZapp < DELIVERY_10AVG_DANGER
                        ? classes.warning
                        : classes.danger,
                      classes.time
                    )}
                  >
                    <Time>{avgCompletionTime.toFormat('hh:mm:ss')}</Time>
                    <Subtitle>Avg Delivery Duration</Subtitle>
                    <Description>Last 10 orders</Description>
                  </Card>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box m={1} flex={0.3} display="flex" className={classes.timersTile}>
            <Box m={-1} flex="1" display="flex">
              <Box height="1" flex="1" display="flex" flexDirection="column">
                <Box textAlign="center" m={1} flex="1">
                  <Card
                    className={cx(
                      classes.time,
                      stats.tasks?.completedTasksWithinTargetsZapp.in20MinPercent +
                        stats.tasks?.completedTasksWithinTargetsZapp.in30MinPercent <=
                        DELIVERED_ON_TIME_DANGER
                        ? classes.danger
                        : stats.tasks?.completedTasksWithinTargetsZapp.in20MinPercent +
                            stats.tasks?.completedTasksWithinTargetsZapp.in30MinPercent <=
                          DELIVERED_ON_TIME_WARNING
                        ? classes.warning
                        : classes.idle
                    )}
                  >
                    <Time>
                      {(
                        stats.tasks?.completedTasksWithinTargetsZapp.in20MinPercent +
                        stats.tasks?.completedTasksWithinTargetsZapp.in30MinPercent
                      )?.toFixed(0)}
                      %
                    </Time>
                    <Subtitle>Delivered within 30min</Subtitle>
                    <Description>Today (from 00:00)</Description>
                  </Card>
                </Box>

                <Box textAlign="center" m={1} flex="1">
                  <Card
                    className={cx(
                      stats.tasks?.avgDeliveryPromise10OrdersZapp < PROMISED_10AVG_WARNING
                        ? classes.idle
                        : stats.tasks?.avgDeliveryPromise10OrdersZapp < PROMISED_10AVG_DANGER
                        ? classes.warning
                        : classes.danger,
                      classes.time
                    )}
                  >
                    <Time>{avgPromisedTime.toFormat('hh:mm:ss')}</Time>
                    <Subtitle>Avg Delivery Promise</Subtitle>
                    <Description>Last 10 orders</Description>
                  </Card>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {session?.user?.admin && <RevenueAndWorkloadStats warehouses={[stats]} />}

        <OrdersList selectedOrderId={orderId} orders={orders} />
      </Stack>
    </>
  )
}

export default withAuthentication(Warehouse)
