import ShoppingBagOutlined from '@mui/icons-material/ShoppingBagOutlined'
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined'
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined'
import { BottomNavigation, BottomNavigationAction, Box, styled } from '@mui/material'
import React, { FC, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import routes from '../../pages/routes'

export const TABBAR_HEIGHT = 60

export const Tabbar: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname

  const handleNavigationChange = useCallback(
    (_, path) => {
      if (!path) return
      navigate(path)
    },
    [navigate]
  )

  return (
    <>
      <TabbarSpacer />
      <TabbarWrapper>
        <StyledBottomNavigation value={currentPath} onChange={handleNavigationChange} showLabels>
          <BottomNavigationAction value={routes.home} label="Dashboard" icon={<WidgetsOutlinedIcon />} />
          <BottomNavigationAction value={routes.stores} label="Stores" icon={<StoreOutlinedIcon />} />
          <BottomNavigationAction value={routes.v2Orders} label="Orders" icon={<ShoppingBagOutlined />} />
          <BottomNavigationAction
            value={routes.old}
            label="Old ZB"
            icon={
              <img
                src={'/icons/old-zb.svg'}
                alt={'Old ZB'}
                width={28}
                height={28}
                style={{
                  cursor: 'pointer',
                }}
              />
            }
          />
        </StyledBottomNavigation>
      </TabbarWrapper>
    </>
  )
}

const TabbarSpacer = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: TABBAR_HEIGHT,
}))

const TabbarWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  padding: theme.spacing(1),
  height: TABBAR_HEIGHT,
  boxSizing: 'border-box',
  borderTop: '1px solid',
  borderColor: theme.palette.divider,
  background: theme.palette.background.paper,
  zIndex: 10,
}))

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  width: '100%',
  borderRadius: 8,
  background: 'transparent',

  '& .MuiBottomNavigationAction-root': {
    minWidth: 'auto',
    '&:not(.Mui-selected)': {
      color: theme.palette.common.white,
    },
  },

  '& .MuiBottomNavigationAction-root svg': {
    fontSize: '1.8rem',
  },

  '& .MuiBottomNavigationAction-root .MuiBottomNavigationAction-label': {
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
}))
