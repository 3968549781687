import { Box, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { ProgressTableData } from '../../types/ProgressTableData'
import { WidgetPanel } from '../WidgetPanel/WidgetPanel'

interface Props {
  title: string
  subtitle?: string
  leftHeading: string
  rightHeading: string
  data: ProgressTableData[]
  link?: string
  loading?: boolean
}

const renderTitle = (title: string) => (
  <Typography sx={{ fontSize: '16px', lineHeight: '19px', color: 'text.secondary', fontWeight: 500 }}>
    {title}
  </Typography>
)

const renderItem = (item: ProgressTableData, padLeft = false) => (
  <Stack
    key={item.name}
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    sx={{
      borderBottom: '1px solid',
      borderColor: 'divider',
      pl: padLeft ? 1 : 0,
      mb: 1,
      '&:nth-child(5)': {
        borderBottom: 'none',
      },
    }}
  >
    <Typography sx={{ fontWeight: 500, fontSize: '18px' }}>{item.name}</Typography>
    <Typography
      sx={{
        textAlign: 'right',
        fontSize: '20px',
        fontWeight: 600,
        color: item.completed ? 'success.main' : 'text.main',
        pr: padLeft ? 0 : 1,
      }}
    >
      {item.value}
    </Typography>
  </Stack>
)

const tableHeading = (leftHeading: string, rightHeading: string, padLeft = false) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    sx={{
      my: 1,
      pb: 0.5,
      pl: padLeft ? 1 : 0,
      pr: padLeft ? 0 : 1,
    }}
    borderBottom="1px solid"
    borderColor="divider"
  >
    {renderTitle(leftHeading)}
    {renderTitle(rightHeading)}
  </Stack>
)

export const ProgressTable: React.FC<Props> = ({ title, subtitle, leftHeading, rightHeading, data, link, loading }) => (
  <WidgetPanel
    title={title}
    link={link}
    loading={loading}
    sx={{
      overflow: 'hidden',
    }}
  >
    {subtitle && (
      <Typography sx={{ color: 'text.secondary', fontSize: '12px' }} gutterBottom>
        {subtitle}
      </Typography>
    )}
    <Stack direction="row" justifyContent="space-between">
      <Box flexBasis="50%">
        {tableHeading(leftHeading, rightHeading)}
        <Stack>
          {data
            .sort((a, b) => a.value - b.value)
            .slice(0, 5)
            .map((data) => renderItem(data))}
        </Stack>
      </Box>
      <Divider orientation="vertical" />
      <Box flexBasis="50%">
        {tableHeading(leftHeading, rightHeading, true)}
        <Stack>
          {data
            .sort((a, b) => a.value - b.value)
            .slice(5)
            .map((data) => renderItem(data, true))}
        </Stack>
      </Box>
    </Stack>
  </WidgetPanel>
)
