// https://github.com/CSFrequency/react-firebase-hooks/blob/master/firestore/helpers/index.ts
import { DocumentSnapshot } from 'firebase/firestore'

export const snapshotToData = (snapshot: DocumentSnapshot, idField?: string) => {
  if (!snapshot.exists) {
    return undefined
  }

  return {
    ...snapshot.data(),
    ...(idField ? { [idField]: snapshot.id } : null),
  }
}
