import { Order } from '@quickcommerceltd/zappboard'
import { Query, collectionGroup, or, query, where, collection, orderBy } from 'firebase/firestore'
import { db, orderTimestampConverter } from '../../../firebase'

export const constructOrdersBySearchTermQuery = (
  searchTerm: string,
  singleWarehouseId: string | undefined
): Query<Order> | undefined => {
  if (!searchTerm) return

  return query(
    singleWarehouseId ? collection(db, `warehouseStats/${singleWarehouseId}/orders`) : collectionGroup(db, 'orders'),
    or(
      where('number', '==', parseInt(searchTerm)),
      where('externalId', '==', searchTerm.toUpperCase()),
      where('skuIds', 'array-contains', searchTerm.toLowerCase()),
      where('skuNames', 'array-contains', searchTerm.toLowerCase()),
      ...searchTerm
        .split(' ')
        .slice(0, 3)
        .map((term) => where('skuIds', 'array-contains', term.toLowerCase())),
      ...searchTerm
        .split(' ')
        .slice(0, 3)
        .map((term) => where('skuNames', 'array-contains', term.toLowerCase()))
    ),
    orderBy('createdAt', 'desc')
  ).withConverter(orderTimestampConverter)
}
