import { WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { DateTime } from 'luxon'
import { PickerLevelMetrics } from '../../types/PickerLevelMetrics'

const LAST_ACTIVE_MIN_THRESHOLD = 5
type TimestampRaw = { _seconds: number }
export const getPickerLevels = (warehouseStats?: WarehouseStatsV2): PickerLevelMetrics[] => {
  if (!warehouseStats) return []
  const activeThreshold = DateTime.now().minus({ minutes: LAST_ACTIVE_MIN_THRESHOLD }).toSeconds()
  const warehouses = Object.values(warehouseStats.statsByWarehouse)
  return warehouses.map((stat) => {
    return {
      warehouse: stat.warehouse.shortName,
      activePickers: stat.pickerLevels.activePickers.filter(
        (d) => d.lastSeen && (d.lastSeen as unknown as TimestampRaw)._seconds >= activeThreshold
      ).length,
      expectedPickers: stat.pickerLevels.expectedShifts.reduce((acc, current) => acc + current.workers, 0),
    }
  })
}
