import { TaskStatsV2, WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { OrderOrigin } from '@quickcommerceltd/zappboard/lib/types/order-origin'
import { ChartData } from '../../types/ChartData'
import { MinMaxWarehouseMetric } from '../../types/MinMaxValue'
import { PickUpMetrics } from '../../types/PickUpMetrics'
import { filterStatsByKey } from './filterStatsByKey'
import { getCommonTimeData, getStatsIdToLocalTime } from './getCommonTimeData'
import { getMillisToMinutes } from './getMillisToMinutes'
import { getSafeAverage } from './getSafeAverage'
import { millisFormatter } from './minutesFormatter'

interface ReducerStats {
  avgPickupTime: number
  avgWaitingAtPickUpTime: number
  avgHandOverTime: number
}

const defaultStats: ReducerStats = {
  avgPickupTime: 0,
  avgWaitingAtPickUpTime: 0,
  avgHandOverTime: 0,
}

const marketplace = [OrderOrigin.DELIVEROO, OrderOrigin.UBER, OrderOrigin.JET] as string[]

const avgPickUpAcrossAllOrigins = (taskStats: TaskStatsV2[]) => {
  const result = taskStats.reduce(
    (acc, curr) => ({
      avgPickupTime: acc.avgPickupTime + curr.avgPickupTime,
      avgWaitingAtPickUpTime: acc.avgWaitingAtPickUpTime + curr.avgWaitingAtPickUpTime,
      avgHandOverTime: acc.avgHandOverTime + curr.avgHandOverTime,
    }),
    { ...defaultStats }
  )

  result.avgPickupTime = getSafeAverage(result.avgPickupTime, taskStats.length)
  result.avgWaitingAtPickUpTime = getSafeAverage(result.avgWaitingAtPickUpTime, taskStats.length)
  result.avgHandOverTime = getSafeAverage(result.avgHandOverTime, taskStats.length)

  return result
}

export const getPickUpMetrics = (stats?: WarehouseStatsV2[], originFilter: string[] = []): PickUpMetrics[] => {
  if (!stats?.length) return []

  return stats
    ?.map((stat) => {
      const warehouses = Object.values(stat.statsByWarehouse)
      const pickUpMetricsAllWarehouse = warehouses
        .map((warehouse) =>
          avgPickUpAcrossAllOrigins(
            filterStatsByKey<TaskStatsV2>(
              warehouse.delivery,
              originFilter.filter((origin) => !marketplace.includes(origin))
            )
          )
        )
        .reduce(
          (acc, curr) => ({
            avgPickupTime: acc.avgPickupTime + curr.avgPickupTime,
            avgWaitingAtPickUpTime: acc.avgWaitingAtPickUpTime + curr.avgWaitingAtPickUpTime,
            avgHandOverTime: acc.avgHandOverTime + curr.avgHandOverTime,
          }),
          { ...defaultStats }
        )

      pickUpMetricsAllWarehouse.avgPickupTime = getSafeAverage(
        pickUpMetricsAllWarehouse.avgPickupTime,
        warehouses.length
      )
      pickUpMetricsAllWarehouse.avgWaitingAtPickUpTime = getSafeAverage(
        pickUpMetricsAllWarehouse.avgWaitingAtPickUpTime,
        warehouses.length
      )
      pickUpMetricsAllWarehouse.avgHandOverTime = getSafeAverage(
        pickUpMetricsAllWarehouse.avgHandOverTime,
        warehouses.length
      )

      const localTime = getStatsIdToLocalTime(stat.id)
      return {
        pickUpMetrics: pickUpMetricsAllWarehouse,
        ...getCommonTimeData(localTime),
        sortId: localTime.toMillis(),
      }
    })
    .sort((a, b) => a.sortId - b.sortId)
}

export const getHourlyWAPUTimeChartData = (metrics: PickUpMetrics[]): ChartData[] =>
  metrics.map((metric, idx) => ({
    chartData: {
      x: idx === metrics.length - 1 ? 'Now' : metric.minute,
      y: getMillisToMinutes(metric.pickUpMetrics.avgWaitingAtPickUpTime),
      time: metric.time,
    },
    tooltip: `Time: ${metric.time}%`,
  }))

export const getHourlyHandOverTimeChartData = (metrics: PickUpMetrics[]): ChartData[] =>
  metrics.map((metric, idx) => ({
    chartData: {
      x: idx === metrics.length - 1 ? 'Now' : metric.minute,
      y: getMillisToMinutes(metric.pickUpMetrics.avgHandOverTime),
      time: metric.time,
    },
    tooltip: `Time: ${metric.time}`,
  }))

export const getPickUpMetricsMinMax = (
  warehouseStats?: WarehouseStatsV2,
  originFilter: string[] = []
): { pickup: MinMaxWarehouseMetric; hapu: MinMaxWarehouseMetric; wapu: MinMaxWarehouseMetric } | null => {
  if (!warehouseStats) return null

  const warehouses = Object.values(warehouseStats.statsByWarehouse)
  const pickUpMetricsAllWarehouse = warehouses
    .map((warehouse) => ({
      avgPickUpAcrossAllOrigins: avgPickUpAcrossAllOrigins(
        filterStatsByKey<TaskStatsV2>(
          warehouse.delivery,
          originFilter.filter((origin) => !marketplace.includes(origin))
        )
      ),
      warehouse: warehouse.warehouse.shortName,
    }))
    .reduce((acc, curr) => {
      if (!(curr.warehouse in acc)) {
        acc[curr.warehouse] = { ...defaultStats, warehouse: curr.warehouse }
      }
      acc[curr.warehouse].avgPickupTime += curr.avgPickUpAcrossAllOrigins.avgPickupTime
      acc[curr.warehouse].avgWaitingAtPickUpTime += curr.avgPickUpAcrossAllOrigins.avgWaitingAtPickUpTime
      acc[curr.warehouse].avgHandOverTime += curr.avgPickUpAcrossAllOrigins.avgHandOverTime
      return acc
    }, {} as Record<string, ReducerStats & { warehouse: string }>)

  const pickUpMetricValues = Object.values(pickUpMetricsAllWarehouse)

  if (!pickUpMetricValues.length) return null

  const sortedPickupTime = pickUpMetricValues.sort((a, b) => a.avgPickupTime - b.avgPickupTime)
  const sortedWaitingAtPickUpTime = pickUpMetricValues.sort(
    (a, b) => a.avgWaitingAtPickUpTime - b.avgWaitingAtPickUpTime
  )
  const sortedHandOverTime = pickUpMetricValues.sort((a, b) => a.avgHandOverTime - b.avgHandOverTime)

  return {
    pickup: {
      min: {
        value: millisFormatter(sortedPickupTime[0].avgPickupTime),
        warehouse: sortedPickupTime[0].warehouse,
      },
      max: {
        value: millisFormatter(sortedPickupTime[sortedPickupTime.length - 1].avgPickupTime),
        warehouse: sortedPickupTime[sortedPickupTime.length - 1].warehouse,
      },
    },
    wapu: {
      min: {
        value: millisFormatter(sortedWaitingAtPickUpTime[0].avgWaitingAtPickUpTime),
        warehouse: sortedWaitingAtPickUpTime[0].warehouse,
      },
      max: {
        value: millisFormatter(sortedWaitingAtPickUpTime[sortedWaitingAtPickUpTime.length - 1].avgWaitingAtPickUpTime),
        warehouse: sortedWaitingAtPickUpTime[sortedWaitingAtPickUpTime.length - 1].warehouse,
      },
    },
    hapu: {
      min: {
        value: millisFormatter(sortedHandOverTime[0].avgHandOverTime),
        warehouse: sortedHandOverTime[0].warehouse,
      },
      max: {
        value: millisFormatter(sortedHandOverTime[sortedHandOverTime.length - 1].avgHandOverTime),
        warehouse: sortedHandOverTime[sortedHandOverTime.length - 1].warehouse,
      },
    },
  }
}
