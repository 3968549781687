import { OrderStatsV2, WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import { ChartData } from '../../types/ChartData'
import { OOSDefectMetrics } from '../../types/OOSDefectMetrics'
import { filterStatsByKey } from './filterStatsByKey'
import { getCommonTimeData, getStatsIdToLocalTime } from './getCommonTimeData'

export const getOOSDefectMetrics = (stats?: WarehouseStatsV2[], originFilter: string[] = []): OOSDefectMetrics[] => {
  if (!stats?.length) return []

  return stats
    ?.map((stat) => {
      const warehouses = Object.values(stat.statsByWarehouse)

      const oosAllWarehouse = warehouses.map((stat) => {
        const filteredOrderStats = filterStatsByKey<OrderStatsV2>(stat.orders, originFilter)
        return filteredOrderStats.reduce((acc, current) => acc + (current.ordersWithOOSItemsNum ?? 0), 0)
      })

      const allWarehouseTotal = oosAllWarehouse.reduce((acc, current) => acc + current, 0)
      const localTime = getStatsIdToLocalTime(stat.id)

      return {
        oosDefects: allWarehouseTotal,
        ...getCommonTimeData(localTime),
        sortId: localTime.toMillis(),
      }
    })
    .sort((a, b) => a.sortId - b.sortId)
}

export const getOOSDefectHourlyChartData = (metrics: OOSDefectMetrics[]): ChartData[] =>
  metrics.map((metric, idx) => ({
    chartData: {
      x: idx === metrics.length - 1 ? 'Now' : metric.minute,
      y: metric.oosDefects,
      time: metric.time,
    },
    tooltip: `Time: ${metric.time}`,
  }))
