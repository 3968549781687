import { useEffect, useState } from 'react'

export default function useFullscreen() {
  const [isFullscreen, setIsFullscreen] = useState(false)

  const handleResize = () => {
    const fullHeight = window.innerHeight === window.screen.height
    const fullWidth = window.innerWidth === window.screen.width
    setIsFullscreen(fullHeight && fullWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return [isFullscreen]
}
