import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from 'firebase/firestore'

export class GenericDataConverter<T> implements FirestoreDataConverter<T> {
  toFirestore(object: WithFieldValue<T>): DocumentData {
    return object as DocumentData
  }

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions) {
    return snapshot.data(options) as T
  }
}
