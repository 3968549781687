import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import { Stack, Typography } from '@mui/material'
import { FC, useMemo } from 'react'

const OFFSET_TOP = 50

interface Props {
  unseenOrders: number
  onClick: () => void
}

export const OrderTableUnseenOrdersIndicator: FC<Props> = ({ unseenOrders, onClick }) => {
  const text = useMemo(() => {
    return unseenOrders > 1 ? `${unseenOrders} New Orders` : `${unseenOrders} New Order`
  }, [unseenOrders])

  return (
    <Stack
      component="td"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: OFFSET_TOP,
        left: '50%',
        bgcolor: 'primary.main',
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 3px 8px',
        borderRadius: '40px',
        padding: '8px 12px',
        cursor: 'pointer',
      }}
    >
      <Stack pl={1} spacing={-0.5}>
        <Typography variant="body2" fontWeight="bold">
          Scroll to Top
        </Typography>
        <Typography variant="caption">{text}</Typography>
      </Stack>
      <Stack fontSize={28}>
        <ArrowCircleUpIcon fontSize="inherit" />
      </Stack>
    </Stack>
  )
}
