import { Order, TaskState } from '@quickcommerceltd/zappboard'
import { DateTime } from 'luxon'
import { env } from '../../../config'

export interface OrderFlowEvent {
  type: 'rider' | 'store'
  event: string
  timestamp: DateTime
  inHandOverTime: boolean
}

export const createZephyrTaskLink = (taskId: string | null): string | undefined => {
  if (taskId) {
    return `https://${env}.zephyr.quickcommerce.org/tasks/${taskId}`
  }
  return undefined
}

const createEvent = (event: string, type: 'store' | 'rider', timestamp: number): OrderFlowEvent => {
  return {
    type,
    event,
    timestamp: DateTime.fromMillis(timestamp),
    inHandOverTime: false,
  }
}

export const getOrderEvents = (order: Order): OrderFlowEvent[] => {
  let inHandOverTime = false
  return [
    createEvent('Created', 'store', order.createdAt),
    ...(order.pickingStartedAt ? [createEvent('Picking', 'store', order.pickingStartedAt)] : []),
    ...(order.packingStartedAt ? [createEvent('Packing', 'store', order.packingStartedAt)] : []),
    ...(order.packingEndedAt ? [createEvent('Racking', 'store', order.packingEndedAt)] : []),
    ...(order.deliveryAssignedAt ? [createEvent('Assigned', 'rider', order.deliveryAssignedAt)] : []),
    ...(order.deliveryRiderArrivedForPickupAt
      ? [createEvent('At Store', 'rider', order.deliveryRiderArrivedForPickupAt)]
      : []),
    ...(order.deliveryPickedUpAt ? [createEvent('Picked Up', 'rider', order.deliveryPickedUpAt)] : []),
    ...(order.deliveryStartedAt ? [createEvent('Started', 'rider', order.deliveryStartedAt)] : []),
    ...(order.deliveryRiderArrivedAtCustomerAt
      ? [createEvent('At Customer', 'rider', order.deliveryRiderArrivedAtCustomerAt)]
      : []),
    ...(order.deliveredAt && order.deliveryStatus !== TaskState.FAILED
      ? [createEvent('Delivered', 'rider', order.deliveredAt)]
      : []),
    ...(order.deliveryTaskUpdatedAt && order.deliveryStatus === TaskState.FAILED
      ? [createEvent('Failed', 'rider', order.deliveryTaskUpdatedAt)]
      : []),
  ]
    .sort((a, b) => a.timestamp.toMillis() - b.timestamp.toMillis())
    .map((event) => {
      if (event.event === 'Racking') {
        inHandOverTime = true
      }
      if (event.event === 'Picked Up') {
        inHandOverTime = false
      }
      return {
        ...event,
        inHandOverTime,
      }
    })
}
