import CloseIcon from '@mui/icons-material/Close'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  styled,
  TableContainer,
  Tooltip,
  Typography,
} from '@mui/material'
import { Line, Order } from '@quickcommerceltd/zappboard'
import { FC, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '../Table'
import { useOrderLinesStylesV2 } from './useOrderLinesStyleV2'

const ZCOM_LINK = 'https://gb.zcommerce.quickcommerce.org'

interface OrderPickedItems extends Order {
  pickedItems?: {
    [key: string]: number
  }
}

const TableHeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '22px',
  letterSpacing: 0,
}))

export const OrderLinesV2: FC<{ order: OrderPickedItems }> = ({ order }) => {
  const [selectedLine, setSelectedLine] = useState<Line | null>(null)
  const { classes } = useOrderLinesStylesV2()

  return (
    <Box px={0}>
      <Dialog
        onClose={() => setSelectedLine(null)}
        open={!!selectedLine}
        PaperProps={{
          style: { borderRadius: '20px' },
        }}
      >
        <DialogTitle className={classes.dialogTitle}>Product Information</DialogTitle>
        <IconButton onClick={() => setSelectedLine(null)} className={classes.closeIcon}>
          <CloseIcon />
        </IconButton>
        <div className={classes.divider} />

        {selectedLine && (
          <DialogContent className={classes.paper}>
            <Box marginY={2}>
              <table className={classes.table}>
                <tbody>
                  <tr>
                    <td>Name:</td>
                    <td>{selectedLine?.productName || 'Unknown'}</td>
                  </tr>
                  <tr>
                    <td>SKU:</td>
                    <td>{selectedLine?.productSku || 'Unknown'}</td>
                  </tr>
                  <tr>
                    <td>Variant:</td>
                    <td>{selectedLine?.variantName || 'Unknown'}</td>
                  </tr>
                  <tr>
                    <td>Unit price:</td>
                    <td>{`${selectedLine?.unitPriceNet || 'Unknown'} ${selectedLine.currency || 'Unkown'}`}</td>
                  </tr>
                  <tr>
                    <td>Quantity:</td>
                    <td>{selectedLine?.quantity || 'Unknown'}</td>
                  </tr>
                </tbody>
              </table>
              <Box textAlign="center">
                <img
                  alt={selectedLine.productName}
                  className={classes.productImageLarge}
                  src={selectedLine.productImage?.image || selectedLine.productImage?.thumbnail}
                />
              </Box>
            </Box>
          </DialogContent>
        )}
      </Dialog>

      <Typography sx={{ fontSize: '27px', fontWeight: 700, lineHeight: '33px', px: 1.5 }} gutterBottom>
        Items ({order.lines?.length ?? 0})
      </Typography>

      <TableContainer sx={{ px: 0, overflowY: 'auto', maxHeight: '260px' }}>
        <Table aria-label="customized table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <TableHeaderText>Product Name</TableHeaderText>
              </TableCell>
              <TableCell align="center">
                <TableHeaderText>QTY</TableHeaderText>
              </TableCell>
              <TableCell>
                <TableHeaderText>SKU</TableHeaderText>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.lines?.map((line) => (
              <Tooltip
                arrow
                key={line.id}
                placement={'top'}
                title={
                  <Box padding={1}>
                    <Box
                      flexDirection="row"
                      display="flex"
                      justifyContent="space-between"
                      lineHeight="1.2em"
                      fontSize={16}
                      mb={1}
                    >
                      <Box>{line.productName}</Box>
                      <Box>{line.productSku}</Box>
                    </Box>
                    <Box textAlign="center">
                      <Avatar
                        variant="rounded"
                        className={classes.productImageBig}
                        src={line.productImage?.image || line.productImage?.thumbnail}
                      >
                        {false}
                      </Avatar>
                    </Box>
                  </Box>
                }
              >
                <TableRow hover onClick={() => setSelectedLine(line)}>
                  <TableCell className={classes.productImageCell}>
                    <Avatar
                      variant="rounded"
                      className={classes.productImage}
                      src={line.productImage?.thumbnail || line.productImage?.image}
                    >
                      {false}
                    </Avatar>
                  </TableCell>
                  <TableCell>{line.productName}</TableCell>
                  <TableCell align="center">
                    {!line.hasOOSDefect && (
                      <Typography variant="body2">
                        {[order.pickedItems?.[line.productSku] ?? 0, line.quantity].join('/')}
                      </Typography>
                    )}

                    {line.hasOOSDefect && (
                      <Chip
                        color="error"
                        sx={{ borderRadius: 1 }}
                        label={
                          <Stack direction="row" alignItems="center" spacing={0.5}>
                            <Typography variant="body2">
                              {[order.pickedItems?.[line.productSku] ?? 0, line.quantity].join('/')}
                            </Typography>
                            <Typography variant="body2" sx={{ opacity: 0.6 }}>
                              OOS
                            </Typography>
                          </Stack>
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="flex-start" justifyContent="center">
                      <Typography noWrap variant="body2">
                        {line.productSku}
                      </Typography>
                      <Link
                        href={`${ZCOM_LINK}/dashboard/products/${line.productId}`}
                        underline="none"
                        color="primary"
                        rel="noopener"
                        target="_blank"
                      >
                        <OpenInNewIcon sx={{ fontSize: '15px', ml: 0.5 }} />
                      </Link>
                    </Box>
                  </TableCell>
                </TableRow>
              </Tooltip>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
