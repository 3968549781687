import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PhotoCameraOutlined from '@mui/icons-material/PhotoCameraOutlined'
import { Box, Divider, IconButton, Link, Stack, styled, Typography } from '@mui/material'
import grey from '@mui/material/colors/grey'
import { Order } from '@quickcommerceltd/zappboard'
import { OrderOrigin } from '@quickcommerceltd/zappboard/lib/types/order-origin'
import cx from 'classnames'
import React, { FC, useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { ProofOfDeliveryDialog } from '../ProofOfDeliveryDialog/ProofOfDeliveryDialog'
import { createZephyrTaskLink, getOrderEvents } from './_helpers/getOrderFlowData'
import { OrderFlowTimeline } from './OrderFlowTimeline'

const useStyles = makeStyles()((theme) => {
  return {
    line: {
      fontSize: '.9em',
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    lineMarker: {
      marginLeft: '12px',
      display: 'inline-block',
      backgroundColor: theme.palette.success.main,
      borderRadius: '50%',
      width: '8px',
      height: '8px',
      border: '1px solid #000',
    },
    photoText: {
      color: theme.palette.primary.main,
      marginTop: -theme.spacing(1),
      fontSize: '1.3em',
    },
    photoIcon: {
      color: theme.palette.primary.main,
      marginLeft: '4px',
      marginTop: '2px',
      fontSize: '1.1em',
    },
    disabledPod: {
      '&.Mui-disabled': {
        color: grey[700],
      },
    },
    disabledPodIcon: {
      color: grey[700],
    },
    date: {
      marginLeft: '4px',
      whiteSpace: 'nowrap',
      fontSize: '0.9em',
    },
    linkStyle: {
      color: '#02BAFF',
    },
    noHistoryStyle: {
      lineHeight: '2.5em',
    },
  }
})

const SubHeader = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '22px',
  letterSpacing: 0,
  textAlign: 'left',
}))

interface Props {
  order: Order
}

export const OrderFlow: FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const [showProofOfDeliveryDialog, setShowProofOfDeliveryDialog] = useState(false)
  const { order } = props
  const pictureOfDelivery = order.thirdPartyProviderPictureOfDelivery

  const orderEvents = useMemo(() => getOrderEvents(order), [order])

  const toggleProofOfDeliveryDialog = () => {
    setShowProofOfDeliveryDialog((prev) => !prev)
  }

  return (
    <Box px={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" mb={1}>
          <Typography sx={{ fontSize: '27px', fontWeight: 700, lineHeight: '33px' }}>Order Flow</Typography>
        </Stack>
        {!!pictureOfDelivery && origin === 'ZAPP' && (
          <Box>
            <IconButton
              onClick={toggleProofOfDeliveryDialog}
              className={cx(classes.photoText, {
                [classes.disabledPod]: !pictureOfDelivery,
              })}
              disabled={!pictureOfDelivery}
            >
              PoD
              <PhotoCameraOutlined
                className={cx(classes.photoIcon, {
                  [classes.disabledPodIcon]: !pictureOfDelivery,
                })}
              />
            </IconButton>

            <ProofOfDeliveryDialog
              open={showProofOfDeliveryDialog}
              pictureOfDelivery={pictureOfDelivery}
              onClose={toggleProofOfDeliveryDialog}
            />
          </Box>
        )}
        {order.deliveryTaskId && order.orderOrigin === OrderOrigin.ZAPP && (
          <Link
            href={createZephyrTaskLink(order.deliveryTaskId)}
            underline="none"
            color="primary"
            rel="noopener"
            target="_blank"
          >
            <Box display="flex" alignItems="center">
              <Typography>Open in Zephyr</Typography>
              <OpenInNewIcon sx={{ fontSize: '25px', ml: 0.5 }} />
            </Box>
          </Link>
        )}
      </Stack>
      <Box display="flex" justifyContent="space-between" pb={0.5} mt={1}>
        <SubHeader>STORE</SubHeader>
        <SubHeader>RIDER</SubHeader>
      </Box>
      <Divider />
      <Box my={2} sx={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '210px' }}>
        {!orderEvents ? (
          <span className={classes.noHistoryStyle}>Could not fetch order events</span>
        ) : (
          <OrderFlowTimeline data={orderEvents} />
        )}
      </Box>
    </Box>
  )
}
