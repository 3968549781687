import {
  DocumentSnapshot,
  Query,
  SnapshotListenOptions,
  getDocs,
  limit,
  onSnapshot,
  query,
  startAfter,
} from 'firebase/firestore'
import * as React from 'react'
import { useCallback, useRef, useState } from 'react'
import { snapshotToData } from './helper'
import usePaginationValue, { PaginationHook } from './util/usePaginationValue'

const { useEffect, useMemo } = React

const DEFAULT_LIMIT = 20

export const usePagination = (
  searchQuery: Query | undefined | null,
  options?: {
    snapshotListenOptions?: SnapshotListenOptions
    limit?: number
    subscribeToAllPages?: boolean
  }
): PaginationHook<DocumentSnapshot> => {
  const { loaded, loadingMore, error, setError, setValue, reset, value, after, loadMore, hasMore } =
    usePaginationValue()

  const ref = useRef(searchQuery)
  const [subscriptions, setSubscriptions] = useState<Function[]>([])

  // cleans all open subscriptions
  const cleanupSubscriptions = useCallback(() => {
    subscriptions.forEach((unsubscribe: Function) => {
      unsubscribe()
    })
    setSubscriptions([])
  }, [subscriptions])

  // reset on query change
  useEffect(() => {
    if (!searchQuery) return
    ref.current = searchQuery
    reset()
    cleanupSubscriptions()
  }, [searchQuery]) // eslint-disable-line react-hooks/exhaustive-deps

  // cleanup function that unsubscribes from
  // all open subscriptions on unmount
  useEffect(() => {
    return cleanupSubscriptions
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!ref.current) return

    const stepLimit = options?.limit || DEFAULT_LIMIT
    let queryLimited = query(ref.current, limit(stepLimit))

    if (after) {
      queryLimited = query(queryLimited, startAfter(after))
    }

    // load following pages without a subscription
    if (after && !options?.subscribeToAllPages) {
      getDocs(queryLimited).then(setValue(stepLimit))
      return
    }

    const snapshotOption = options?.snapshotListenOptions
    const listener = snapshotOption
      ? onSnapshot(queryLimited, snapshotOption, setValue(stepLimit), setError)
      : onSnapshot(queryLimited, setValue(stepLimit), setError)

    setSubscriptions((subs) => [...subs, listener])
  }, [ref.current, after]) // eslint-disable-line react-hooks/exhaustive-deps

  return [
    value,
    {
      loaded,
      loadingMore,
      hasMore,
      loadMore,
    },
    error,
  ]
}

export const usePaginationData = <T>(
  query?: Query | null,
  options?: {
    idField?: string
    limit?: number
    snapshotListenOptions?: SnapshotListenOptions
  }
): PaginationHook<T> => {
  const idField = options ? options.idField : undefined

  const [snapshot, fields, error] = usePagination(query, {
    snapshotListenOptions: options?.snapshotListenOptions,
    limit: options?.limit,
  })
  const values = useMemo(
    () => (snapshot ? snapshot.map((doc) => snapshotToData(doc, idField)) : undefined) as T[],
    [snapshot, idField]
  )
  return [values, fields, error]
}
