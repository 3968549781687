import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, DialogTitle, IconButton } from '@mui/material'
import { FC } from 'react'
import { useProofDeliveryDialogStyles } from './ProofOfDeliveryDialog.style'

interface Props {
  open: boolean
  pictureOfDelivery?: string
  onClose: () => void
}

export const ProofOfDeliveryDialog: FC<Props> = ({ open, pictureOfDelivery, onClose }: Props) => {
  const { classes } = useProofDeliveryDialogStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: '20px' },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>Picture Of Delivery</DialogTitle>
      <IconButton onClick={onClose} className={classes.closeIcon}>
        <CloseIcon />
      </IconButton>
      <div className={classes.divider} />
      <Box padding={2} className={classes.paper}>
        <img alt={'pictureOfDelivery'} src={pictureOfDelivery} className={classes.image} />
      </Box>
    </Dialog>
  )
}
