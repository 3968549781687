import { FulfillmentStatus, Order } from '@quickcommerceltd/zappboard'
import { OrderOrigin } from '@quickcommerceltd/zappboard/lib/types/order-origin'
import { collection, orderBy, query, where } from 'firebase/firestore'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { db, orderTimestampConverter } from '../../firebase'

export const useStoreViewDataDeliveroo = (storeId: string) => {
  const now = DateTime.now()
  const last10Minutes = useMemo(() => now.minus({ minutes: 10 }).toJSDate(), [now.minute]) // eslint-disable-line react-hooks/exhaustive-deps

  return useCollectionData<Order>(
    query(
      collection(db, `warehouseStats/${storeId}/orders`),
      where('fulfillmentStatus', '==', FulfillmentStatus.FULFILLED),
      where('fulfilledAt', '>', last10Minutes),
      where('orderOrigin', '==', OrderOrigin.DELIVEROO),
      orderBy('createdAt', 'desc')
    ).withConverter(orderTimestampConverter)
  )
}
