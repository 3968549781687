import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'
import ErrorBoundary from './components/ErrorBoundary'
import App from './pages/App'
import Error from './pages/Error'

// material ui theme
import { unregister } from './serviceWorkerRegistration'
import { ThemeProvider } from './theme'

// some global css
import './index.css'

Sentry.init({
  dsn: 'https://ed4c37b3865e44a3afe6344e941eed75@o552852.ingest.sentry.io/6043754',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
})

ReactDOM.render(
  <ThemeProvider>
    <RecoilRoot>
      <StrictMode>
        <ErrorBoundary onError={console.error} errorComponent={<Error />}>
          <App />
        </ErrorBoundary>
      </StrictMode>
    </RecoilRoot>
  </ThemeProvider>,
  document.getElementById('root')
)

// serviceWorkerRegistration.register();
unregister()
