import { fetchAndActivate, getString } from 'firebase/remote-config'
import { useEffect, useState } from 'react'
import { remoteConfig } from '../firebase'

export const useStoresToHide = () => {
  const [storesToHide, setStoresToHide] = useState<string[]>([])

  useEffect(() => {
    fetchAndActivate(remoteConfig)
    const fetchStoresToHide = async () => {
      const CONFIG_STORES_TO_HIDE = getString(remoteConfig, 'storesToHide')
      const STORES_TO_HIDE = JSON.parse(CONFIG_STORES_TO_HIDE)
      setStoresToHide(STORES_TO_HIDE)
    }

    fetchStoresToHide()
  }, [])

  return storesToHide
}
