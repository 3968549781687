import { FulfillmentStatus, Order, TaskState } from '@quickcommerceltd/zappboard'
import { DateTime } from 'luxon'
import React, { FC, useEffect, useRef, useState } from 'react'
import { toFirestoreTimestamp } from '../../utils/toFirestoreTimestamp'
import { OrderListV2 } from '../OrderListV2/OrderListV2'
import { FirebaseFilter } from '../OrderListV2/types/FirebaseFilter'
import { OrderTableColumn } from '../OrderListV2/types/OrderTableColumn'
import { OrderTableColumnId } from '../OrderListV2/types/OrderTableColumnId'

const ORDER_TABLE_COLUMNS: OrderTableColumn[] = [
  {
    id: OrderTableColumnId.ID,
    label: 'Order #',
    align: 'left',
  },
  {
    id: OrderTableColumnId.ORDERED_ITEM_TYPES,
    label: 'Type',
    align: 'left',
  },
  {
    id: OrderTableColumnId.PACKAGE_COUNT,
    label: 'Packages',
    align: 'left',
  },
  {
    id: OrderTableColumnId.HANDOVER_AT_PICKUP,
    label: 'Handover Time',
    align: 'left',
  },
  {
    id: OrderTableColumnId.RIDER_V2,
    label: 'Rider',
    align: 'left',
  },
  {
    id: OrderTableColumnId.RIDER_STATUS,
    label: 'Rider',
    align: 'left',
  },
]

interface Props {
  warehouseId: string
}

export const TileOrderList: FC<Props> = (props) => {
  const now = DateTime.now()
  const lastUpdated = useRef(now.toSeconds())
  const [filters, setFilters] = useState<FirebaseFilter<Order>[]>([
    ['warehouseId', '==', props.warehouseId],
    ['fulfillmentStatus', '==', FulfillmentStatus.UNFULFILLED],
    ['deliveryStatus', '==', TaskState.ASSIGNED],
    ['createdAt', '>=', toFirestoreTimestamp(now.minus({ hours: 2 }))],
  ])

  useEffect(() => {
    if (now.toSeconds() - lastUpdated.current > 10 || props.warehouseId !== filters[0][2]) {
      setFilters([
        ['warehouseId', '==', props.warehouseId],
        ['fulfillmentStatus', '==', FulfillmentStatus.UNFULFILLED],
        ['deliveryStatus', '==', TaskState.ASSIGNED],
        ['createdAt', '>=', toFirestoreTimestamp(now.minus({ hours: 2 }))],
      ])
      lastUpdated.current = now.toSeconds()
    }
  }, [now.second, props.warehouseId]) // eslint-disable-line react-hooks/exhaustive-deps

  return <OrderListV2 columns={ORDER_TABLE_COLUMNS} filters={filters} />
}
