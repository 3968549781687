import React, { useMemo } from 'react'
import { ErrorAlertV2 } from '../../components/ErrorAlertV2/ErrorAlertV2'
import { SingleMetricPanel } from '../../components/SingleMetricPanel/SingleMetricPanel'
import { Tier0TableSkuTable } from '../../components/Tier0SkuTable/Tier0TableSkuTable'
import { useCommonWarehouseStatsV2 } from '../../hooks/useCommonWarehouseStatsV2/useCommonWarehouseStatsV2'
import { getStatsIdToLocalString } from '../../utils/warehouseStatsV2/getCommonTimeData'
import { getLowTier0UniqueSkuMetrics } from '../../utils/warehouseStatsV2/getLowTier0Metrics'
import { getTier0SkuList } from '../../utils/warehouseStatsV2/getTier0SkuList'
import { PageLayout } from '../PageLayout'
import { PanelLayout } from '../PanelLayout'
import { withAuthentication } from '../withAuthentication'

const Tier0: React.FC = () => {
  const {
    latestAcc,
    yesterdaySameTimeAcc,
    lastWeekSameTimeAcc,
    errorLatest,
    yesterdayStatsSameTimeError,
    lastWeekStatsSameTimeError,
    loadingLatest,
    yesterdayStatsSameTimeLoading,
    lastWeekStatsSameTimeLoading,
  } = useCommonWarehouseStatsV2(24)

  const lowTierToday = useMemo(() => Object.keys(getLowTier0UniqueSkuMetrics(latestAcc[0])).length, [latestAcc])
  const lowTierYesterday = useMemo(
    () => Object.keys(getLowTier0UniqueSkuMetrics(yesterdaySameTimeAcc[0])).length,
    [yesterdaySameTimeAcc]
  )
  const lowTierWoW = useMemo(
    () => Object.keys(getLowTier0UniqueSkuMetrics(lastWeekSameTimeAcc[0])).length,
    [lastWeekSameTimeAcc]
  )
  const tier0List = useMemo(() => getTier0SkuList(latestAcc[0]), [latestAcc])

  const error = errorLatest || yesterdayStatsSameTimeError || lastWeekStatsSameTimeError
  if (error) {
    return <ErrorAlertV2 error={error} />
  }

  const dodTime = getStatsIdToLocalString(yesterdaySameTimeAcc?.[0]?.id)
  const wowTime = getStatsIdToLocalString(lastWeekSameTimeAcc?.[0]?.id)

  return (
    <PageLayout title="Low Tier0 SKUs">
      <PanelLayout>
        <SingleMetricPanel
          heading={`${lowTierToday}`}
          subHeading="Total SKUs with less than 5 units"
          miniHeading={'Now'}
          loading={loadingLatest}
        />
        <SingleMetricPanel
          heading={`${lowTierYesterday}`}
          subHeading={'DoD Comparison'}
          miniHeading={`${dodTime}`}
          loading={yesterdayStatsSameTimeLoading}
          compareNow={lowTierToday}
          comparePast={lowTierYesterday}
          reverseCompare
        />
        <SingleMetricPanel
          heading={`${lowTierWoW}`}
          subHeading={'WoW Comparison'}
          miniHeading={`${wowTime}`}
          loading={lastWeekStatsSameTimeLoading}
          compareNow={lowTierToday}
          comparePast={lowTierWoW}
          reverseCompare
        />
      </PanelLayout>
      <Tier0TableSkuTable tier0List={tier0List} loading={loadingLatest} />
    </PageLayout>
  )
}

export default withAuthentication(React.memo(Tier0))
