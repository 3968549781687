import { makeStyles } from 'tss-react/mui'

export const useOrderLinesStyles = makeStyles()((theme) => ({
  paper: {
    width: 'min(100%, 600px)',
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: '24px',
  },
  divider: {
    borderTopWidth: '1px',
    borderWidth: '1px',
    borderStyle: 'solid',
    opacity: 0.4,
    marginTop: '-8px',
    marginBottom: '4px',
    minWidth: '600px',
  },
  closeIcon: {
    position: 'absolute',
    top: '4px',
    right: '4px',
    color: 'inherit',
  },
  table: {
    fontSize: '20px',
    borderSpacing: '16px 8px',
    marginBottom: '12px',
    marginLeft: '8px',
    marginTop: '-12px',
  },
  productImage: { width: theme.spacing(4), height: theme.spacing(4) },
  productImageCell: { paddingRight: 0, width: theme.spacing(4) },
  productImageBig: { width: 255, height: 255, margin: '0 auto' },
  productImageLarge: { maxWidth: '100%', minWidth: '400px' },
}))
