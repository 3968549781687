import { Box, Stack } from '@mui/material'
import { WarehouseStats } from '@quickcommerceltd/zappboard'
import cx from 'classnames'
import { DateTime } from 'luxon'
import { FC, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Card, cardStyles } from '../Card'
import FormatDuration from '../FormatDuration'
import { Description, Subtitle } from '../Text'
import { calculatePercent } from './_helpers/calculatePercent'
import { ActiveBadgePercentage } from './ActiveBadgePercentage'
import { ActiveFormatDuration } from './ActiveFormatDuration'
import { ActiveNumber } from './ActiveNumber'

const NECESSARY_OFFSET = 1 //Needed to use the FormatDuration component
export interface RevenueWarehouseStats extends WarehouseStats {
  revenue: RevenueStats
  workload: WorkloadStats
}

interface RevenueStats {
  today: number
  todayTotalGrowthNum: number
  todayExpectedTotal: number
  todayExpectedTotalGrowthNum: number
  lastWeekSameDayTotal: number
  lastWeekSameDaySameTime: number
}

interface WorkloadStats {
  timeFor100OrdersTodayMs: number
  timeFor100OrdersLastWeekSameDayMs: number
  timeFor100OrdersTodayLastWeekSameDayDifferenceMs: number
  timeFor100OrdersLastWeekSameDayPercent: number
  globalTimeFor100OrdersTodayMs: number
  globalTimeFor100OrdersLastWeekSameDayMs: number
  globalTimeFor100OrdersTodayLastWeekSameDayDifferenceMs: number
  globalTimeFor100OrdersLastWeekSameDayPercent: number
}

const useStyles = makeStyles()(() => ({
  durationText: {
    fontSize: '1.8rem',
  },
}))

export const RevenueAndWorkloadStats: FC<{
  warehouses?: Partial<RevenueWarehouseStats>[]
}> = ({ warehouses }) => {
  const classes = { ...cardStyles().classes, ...useStyles().classes }
  const stats = useMemo(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log('warehouses', warehouses)
    }

    const revenueAccumulated = {
      today: 0,
      todayTotalGrowthNum: 0,
      allTodayTotalGrowthPercent: 0,
      todayExpectedTotal: 0,
      todayExpectedTotalGrowthNum: 0,
      allTodayExpectedTotalGrowthPercent: 0,
      lastWeekSameDayTotal: 0,
      lastWeekSameDaySameTime: 0,
    }

    const workloadAccumulated = {
      timeFor100OrdersTodayMs: 0,
      timeFor100OrdersLastWeekSameDayMs: 0,
      timeFor100OrdersTodayLastWeekSameDayDifferenceMs: 0,
      timeFor100OrdersLastWeekSameDayPercent: 0,
    }

    warehouses?.forEach((w) => {
      revenueAccumulated.today += w.revenue?.today ?? 0
      revenueAccumulated.todayTotalGrowthNum += w.revenue?.todayTotalGrowthNum ?? 0
      revenueAccumulated.todayExpectedTotal += w.revenue?.todayExpectedTotal ?? 0
      revenueAccumulated.todayExpectedTotalGrowthNum += w.revenue?.todayExpectedTotalGrowthNum ?? 0
      revenueAccumulated.lastWeekSameDayTotal += w.revenue?.lastWeekSameDayTotal ?? 0
      revenueAccumulated.lastWeekSameDaySameTime += w.revenue?.lastWeekSameDaySameTime ?? 0
    })

    if (warehouses && warehouses.length === 1) {
      workloadAccumulated.timeFor100OrdersTodayMs = warehouses[0].workload?.timeFor100OrdersTodayMs ?? 0
      workloadAccumulated.timeFor100OrdersLastWeekSameDayMs =
        warehouses[0].workload?.timeFor100OrdersLastWeekSameDayMs ?? 0
      workloadAccumulated.timeFor100OrdersTodayLastWeekSameDayDifferenceMs =
        warehouses[0].workload?.timeFor100OrdersTodayLastWeekSameDayDifferenceMs ?? 0
    }

    if (warehouses && warehouses.length > 1) {
      workloadAccumulated.timeFor100OrdersTodayMs = warehouses[0].workload?.globalTimeFor100OrdersTodayMs ?? 0
      workloadAccumulated.timeFor100OrdersLastWeekSameDayMs =
        warehouses[0].workload?.globalTimeFor100OrdersLastWeekSameDayMs ?? 0
      workloadAccumulated.timeFor100OrdersTodayLastWeekSameDayDifferenceMs =
        warehouses[0].workload?.globalTimeFor100OrdersTodayLastWeekSameDayDifferenceMs ?? 0
    }

    workloadAccumulated.timeFor100OrdersLastWeekSameDayPercent = calculatePercent(
      workloadAccumulated.timeFor100OrdersTodayMs,
      workloadAccumulated.timeFor100OrdersLastWeekSameDayMs
    )

    revenueAccumulated.allTodayTotalGrowthPercent = calculatePercent(
      revenueAccumulated.today,
      revenueAccumulated.lastWeekSameDaySameTime
    )
    revenueAccumulated.allTodayExpectedTotalGrowthPercent = calculatePercent(
      revenueAccumulated.todayExpectedTotal,
      revenueAccumulated.lastWeekSameDayTotal
    )

    return {
      ...revenueAccumulated,
      ...workloadAccumulated,
    }
  }, [warehouses])

  const now = DateTime.now()
  const weekday = now.toFormat('MM/dd/yy')
  const lastWeekday = now.minus({ day: 7 }).toFormat('MM/dd/yy')
  const time = now.toFormat('HH:mm')

  return (
    <Stack mt={2} height="100%" direction={{ xs: 'column', lg: 'row' }} spacing={2} fontSize=".9em">
      <Card className={cx(classes.card)}>
        <Box mt={-1}>
          <Subtitle>
            Time for 100 orders [{weekday} {time}]
          </Subtitle>
        </Box>

        <Stack m={1} direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <ActiveBadgePercentage
            value={stats.timeFor100OrdersTodayLastWeekSameDayDifferenceMs}
            inverted={true}
            duration={true}
          />
          <ActiveNumber size="small">
            <ActiveFormatDuration value={stats.timeFor100OrdersTodayMs} />
          </ActiveNumber>
          <ActiveBadgePercentage value={stats.timeFor100OrdersLastWeekSameDayPercent} inverted={true} />
        </Stack>
        <Box mb={-1}>
          <Subtitle>
            <FormatDuration from={NECESSARY_OFFSET} to={stats.timeFor100OrdersLastWeekSameDayMs + NECESSARY_OFFSET} />
          </Subtitle>
          <Description>
            Time for 100 orders [{lastWeekday} {time}]
          </Description>
        </Box>
      </Card>
    </Stack>
  )
}
