import { CycleCountProgress } from '@quickcommerceltd/zappboard'
import { doc, getDoc } from 'firebase/firestore'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { db } from '../../firebase'
import { ProgressTableData } from '../../types/ProgressTableData'

export const useCycleCountProgress = (stores: string[], warehouseNameMap: Record<string, string>) => {
  const now = DateTime.now()
  const todayDate = useMemo(() => new Date().toISOString().split('T')[0], [now.hour])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [ccProgress, setCCProgress] = useState<ProgressTableData[]>([])

  useEffect(() => {
    if (!stores.length) {
      return
    }

    const getCCProgress = async () => {
      const ccPromises = stores.map((storeId) =>
        getDoc(doc(db, 'warehouseStats', storeId, 'cycleCountProgress', todayDate)).then((doc) => {
          if (doc.exists()) {
            const data = doc.data() as CycleCountProgress
            return {
              name: warehouseNameMap[data.warehouseId] ?? data.warehouseId,
              value: data.noOfPriorityLocationsDone ?? 0,
              completed: data.isPriorityCycleCountCompleted,
            } as ProgressTableData
          }
          return null
        })
      )
      const ccs = await Promise.all(ccPromises)
      setCCProgress(ccs.filter((cc) => cc !== null) as ProgressTableData[])
      setLoading(false)
      setError(null)
    }

    getCCProgress().catch((e) => {
      setLoading(false)
      setCCProgress([])
      setError(e.message)
    })
  }, [stores, todayDate, warehouseNameMap])

  return { loading, ccProgress, error }
}
