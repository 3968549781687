import CloseIcon from '@mui/icons-material/Close'
import { Alert } from '@mui/lab'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { Device } from '@quickcommerceltd/zipp'
import { collection, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { FC, useEffect, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { db, functions } from '../../firebase'
import { GenericDataConverter } from '../../firebase/genericDataConverter/genericDataConverter'
import { usePickingDeviceAssignmentDialogStyles } from './PickingDeviceAssignmentDialog.style'

const deviceConverter = new GenericDataConverter<Device>()

interface Props {
  open: boolean
  orderId: string
  orderNumber: number
  warehouseId: string
  onClose: () => void
}

export const PickingDeviceAssignmentDialog: FC<Props> = ({ open, orderId, orderNumber, warehouseId, onClose }) => {
  const { classes } = usePickingDeviceAssignmentDialogStyles()
  const [activeDeviceId, setActiveDeviceId] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [pickingDevices] = useCollectionData<Device>(
    query(
      collection(db, 'pickingDevices'),
      where('warehouseId', '==', warehouseId),
      where('isOnline', '==', true)
    ).withConverter(deviceConverter)
  )

  const currentPickingDevice = useCollectionData<Device>(
    query(collection(db, 'pickingDevices'), where('task.id', '==', orderId)).withConverter(deviceConverter)
  )?.[0]?.[0]

  useEffect(() => {
    setActiveDeviceId(currentPickingDevice?.id)
  }, [currentPickingDevice, setActiveDeviceId])

  const handleOnClose = () => {
    onClose()
    setActiveDeviceId('')
    setError('')
  }

  const handleActiveDeviceChange = (event: any) => {
    setActiveDeviceId(event.target.value)
  }

  const submitDeviceAssignment = () => {
    if (loading) return
    setLoading(true)
    setError('')

    const assignOrderToPickingDevice = httpsCallable(functions, 'api-assignOrderToPickingDevice')

    const taskId = orderId
    const deviceId = activeDeviceId
    assignOrderToPickingDevice({ taskId, deviceId })
      .then(() => {
        setLoading(false)
        onClose()
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
        setError(`Error: Could not assign order to device: ${error?.message}`)
      })
  }

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      PaperProps={{
        style: { borderRadius: '20px' },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>Assign Order To Picker</DialogTitle>
      <IconButton onClick={handleOnClose} className={classes.closeIcon}>
        <CloseIcon />
      </IconButton>
      <div className={classes.divider} />
      <DialogContent className={classes.paper}>
        <Box marginLeft={1}>
          <Typography className={classes.order}>Order #{orderNumber}</Typography>
        </Box>
        {!!currentPickingDevice && (
          <Alert severity="warning" variant="filled" className={classes.currentPicker}>
            {`Currently assigned to: 
            ${currentPickingDevice?.userName || 'Unknown'} ${
              !currentPickingDevice?.isOnline ? '(Offline)' : '(Online)'
            }`}
          </Alert>
        )}
        <Box padding={2}>
          <FormControl variant="outlined" className={classes.formControl} required>
            <InputLabel>Select a picker</InputLabel>
            <Select
              id="code"
              label="Select a picker"
              value={activeDeviceId || currentPickingDevice?.id || ''}
              onChange={handleActiveDeviceChange}
              className={classes.select}
              disabled={loading}
            >
              <MenuItem value="" disabled className={classes.menuItem}>
                {!!pickingDevices?.length ? 'Select a picker' : 'No picker online'}
              </MenuItem>
              {pickingDevices?.map((device: any) => (
                <MenuItem key={device.id} value={device.id} className={classes.menuItem}>
                  {device.userName} {device.task && `(${atob(device.task.id)})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={submitDeviceAssignment}
            fullWidth
            disabled={!activeDeviceId || currentPickingDevice?.id === activeDeviceId || loading}
            className={classes.button}
            children={loading ? <CircularProgress size={20} color="inherit" /> : 'Assign order to picker'}
          />
        </Box>

        {error && (
          <Typography variant="body2" color="error" style={{ textAlign: 'center' }}>
            {error}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  )
}
