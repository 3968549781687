import { Alert as MuiAlert, Snackbar as MuiSnackbar, styled } from '@mui/material'

export const Snackbar = styled(MuiSnackbar)(() => ({
  root: {
    width: '100%',
    bottom: 0,
  },
}))

export const Alert = styled(MuiAlert)(() => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
    fontSize: '20px',
    fontWeight: 'bold',
  },
  icon: {
    fontSize: '28px',
  },
}))
