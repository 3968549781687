import { Stack, Typography } from '@mui/material'
import { FulfillmentStatus, Order, PickingStatus, TaskState } from '@quickcommerceltd/zappboard'
import { FC, useCallback, useMemo } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { DeliveryProviderBadge } from '../DeliveryProviderBadge'
import { constructOrdersByFiltersQuery } from '../OrderListV2/helpers/constructOrdersByFiltersQuery'

const DEFAULT_ENTRIES = 3

interface Props {
  warehouseId: string
}

export const TileHandingOver: FC<Props> = (props) => {
  const [orders = []] = useCollectionData<Order>(
    constructOrdersByFiltersQuery([
      ['warehouseId', '==', props.warehouseId],
      ['fulfillmentStatus', '==', FulfillmentStatus.UNFULFILLED],
      ['pickingStatus', '==', PickingStatus.PACKED],
      ['deliveryStatus', '==', TaskState.ASSIGNED],
    ])
  )

  const ordersWaitingForHandover = useMemo<(Order | undefined)[]>(() => {
    return orders
      .filter((order) => order.deliveryRiderArrivedForPickupAt)
      .sort((a, b) => (a.deliveryRiderArrivedForPickupAt ?? 0) - (b.deliveryRiderArrivedForPickupAt ?? 0))
      .concat(Array(DEFAULT_ENTRIES).fill(undefined)) // pad array to always have 3 entries
      .slice(0, DEFAULT_ENTRIES)
  }, [orders])

  const formatRiderName = useCallback((riderName?: string) => {
    if (riderName === 'DELIVEROO') return
    if (riderName?.includes('Waiting for')) return
    return riderName
      ?.replace(/\s*\([^)]*\)/g, '') // remove brackets e.g. (Uber Eats)
      .toLowerCase()
      .split(' ')
      .slice(0, 2)
      .join(' ')
  }, [])

  return (
    <Stack width="inherit" height="inherit">
      <Stack width="100%" direction="row" flex={1}>
        {ordersWaitingForHandover?.map((order, index) => {
          const riderName = formatRiderName(order?.rider.name)
          return (
            <Stack
              key={index}
              flex={1}
              p={2}
              justifyContent="center"
              alignItems="center"
              borderRight={1}
              borderColor="divider"
            >
              {order && (
                <>
                  <Typography variant="h1">#{order.externalId || order.number}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    Handing over now to
                  </Typography>
                  <Stack direction="row" alignItems="center" mt={2} gap={1}>
                    {riderName && (
                      <Stack
                        height="100%"
                        justifyContent="center"
                        px={1}
                        borderRadius={1}
                        bgcolor="text.secondary"
                        textTransform="capitalize"
                        color="background.paper"
                      >
                        {riderName}
                      </Stack>
                    )}
                    <DeliveryProviderBadge
                      orderOrigin={order.orderOrigin}
                      deliveryProvider={order.rider.thirdPartyProvider}
                    />
                  </Stack>
                </>
              )}
            </Stack>
          )
        })}
      </Stack>
    </Stack>
  )
}
