import { CircularProgress, Stack } from '@mui/material'
import { FulfillmentStatus, Order, PickingStatus } from '@quickcommerceltd/zappboard'
import { DateTime } from 'luxon'
import React, { FC, RefObject, useEffect, useMemo, useState } from 'react'
import { useDeepCompareMemo, useInView, usePaginationData } from '../../hooks'
import { OrderTabsV2 } from '../OrderTabsV2/OrderTabsV2'
import { SoundEffect } from '../SoundEffect'
import { constructOrdersByFiltersQuery } from './helpers/constructOrdersByFiltersQuery'
import { constructOrdersBySearchTermQuery } from './helpers/constructOrdersBySearchTermQuery'
import { searchOrderList } from './helpers/searchOrderList'
import { sortOrderList } from './helpers/sortOrderList'
import { OrderTable } from './OrderTable'
import { FirebaseFilter } from './types/FirebaseFilter'
import { OrderTableColumn } from './types/OrderTableColumn'

interface Props {
  width?: number | string
  height?: number | string
  top?: number // sticky header offset
  columns?: OrderTableColumn[]
  filters?: FirebaseFilter<Order>[]
  orFilters?: FirebaseFilter<Order>[]
  searchTerm?: string
  selectedOrderId?: string
  onSelect?: (order: Order) => void
  onClose?: (id: string) => void
  onMinimize?: (id: string) => void
  refresh?: boolean
  onPause?: (paused: boolean) => void
}

export const OrderListV2: FC<Props> = ({
  width,
  height,
  top,
  columns,
  filters,
  orFilters,
  searchTerm = '',
  selectedOrderId,
  onSelect,
  onClose,
  onMinimize,
  refresh,
  onPause,
}) => {
  // we use this to determine whether we use collection or collectionGroup due to permissions
  const now = DateTime.now()
  const singleWarehouseId = useMemo(() => {
    const warehouseFilter = filters?.find((filter) => filter[0] === 'warehouseId')
    return warehouseFilter && warehouseFilter[1] === '==' ? (warehouseFilter?.[2] as string) : undefined
  }, [filters])
  const [inViewRefTop, { inView: inViewTop }] = useInView({})

  // get orders by filters
  const depQueryArray = [filters]
  const ordersQuery = useDeepCompareMemo(
    () => constructOrdersByFiltersQuery(filters, orFilters, singleWarehouseId),
    depQueryArray
  )
  const [orders, { loadMore: loadMoreOrders, hasMore, loaded }] = usePaginationData<Order>(ordersQuery, {
    idField: 'id',
    limit: 50,
  })

  // get orders by search term
  const orderBySearchTermQuery = useMemo(
    () => constructOrdersBySearchTermQuery(searchTerm, singleWarehouseId),
    [searchTerm, singleWarehouseId]
  )
  const [ordersBySearchTerm = [], { loadMore: loadMoreSearch, hasMore: hasMoreSearch }] = usePaginationData<Order>(
    orderBySearchTermQuery,
    {
      idField: 'id',
      limit: 50,
    }
  )

  const loadMore = () => {
    if (searchTerm) loadMoreSearch()
    else loadMoreOrders()
  }

  const [inViewRef, { rootRef }] = useInView({ onInView: loadMore })

  // combine, sort and search orders
  const orderList = useMemo(() => [...orders, ...ordersBySearchTerm], [orders, ordersBySearchTerm])
  const sortedOrderList = useMemo(() => sortOrderList(orderList), [orderList])
  const searchedOrderList = useMemo(() => searchOrderList(sortedOrderList, searchTerm), [sortedOrderList, searchTerm])

  const waitingOrders = useMemo(() => {
    return (
      orders?.filter(
        (o) => o.fulfillmentStatus === FulfillmentStatus.UNFULFILLED && o.pickingStatus === PickingStatus.OPEN
      )?.length ?? 0
    )
  }, [orders])

  // helps with flicker and make transitions smoother
  const [fadeIn, setFadeIn] = useState(loaded)
  useEffect(() => {
    if (loaded) {
      setTimeout(() => setFadeIn(true), 500)
    } else {
      setFadeIn(false)
    }
  }, [loaded])

  useEffect(() => {
    if (!onPause) return
    if (refresh && inViewTop) {
      onPause(false)
      return
    }
    onPause(true)
  }, [onPause, refresh, inViewTop])

  return (
    <Stack
      position="relative"
      width={width}
      height={height}
      overflow={height ? 'scroll' : 'visible'}
      sx={{
        opacity: fadeIn ? 1 : 0,
        overflowX: 'auto',
        '@media (max-width: 600px)': {
          overflowX: 'scroll',
        },
        transition: 'opacity 1s ease-in-out',
      }}
      ref={height ? (rootRef as RefObject<HTMLDivElement>) : null}
    >
      <SoundEffect value={waitingOrders} />
      <div ref={inViewRefTop as React.RefObject<HTMLDivElement>} />
      {/* Order Table */}
      <OrderTable
        key={refresh && inViewTop ? now.minute : 'no-refresh'}
        orders={searchedOrderList}
        columns={columns}
        top={top}
        selectedOrderId={selectedOrderId}
        onSelect={onSelect}
      />

      {/* Loading Ref */}
      {(hasMore || hasMoreSearch) && (
        <Stack ref={inViewRef as React.RefObject<HTMLDivElement>} direction="row" justifyContent="center" m={2}>
          <CircularProgress size={14} />
        </Stack>
      )}

      {/* Order Tabs */}
      {onClose && onMinimize && onSelect && (
        <OrderTabsV2
          activeOrderId={selectedOrderId}
          orders={orderList}
          onMinimize={onMinimize}
          onClose={onClose}
          onSelect={onSelect}
        />
      )}
    </Stack>
  )
}
