import { Order, PickingStatus, ShippingMethod, TaskState } from '@quickcommerceltd/zappboard'

export const getStoreViewMetrics = (orders: Order[] | undefined) => {
  if (!orders?.length) return null

  return orders.reduce(
    (acc, current) => {
      const deliveryStatus = current.deliveryStatus
      const pickingStatus = current.pickingStatus

      switch (deliveryStatus) {
        case TaskState.UNASSIGNED:
          acc.unassigned += 1
          break
        case TaskState.PENDING:
          acc.pending += 1
          break
      }

      switch (pickingStatus) {
        case PickingStatus.OPEN:
          acc.waiting += 1
          break
        case PickingStatus.PICKING:
          acc.picking += 1
          break
        case PickingStatus.PICKED:
          acc.packing += 1
          break
      }

      if (
        pickingStatus === PickingStatus.PACKED &&
        !(current.deliveryStartedAt || current.deliveryPickedUpAt || current.shippingMethod === ShippingMethod.PICKUP)
      ) {
        acc.racking += 1
      }

      return acc
    },
    {
      waiting: 0,
      picking: 0,
      packing: 0,
      racking: 0,
      unassigned: 0,
      pending: 0,
    }
  )
}
