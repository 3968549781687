import { ShippingAddress } from '@quickcommerceltd/zappboard'

export function formatAddress(address: ShippingAddress) {
  const isValidAddress = address && (address.streetAddress1 || address.streetAddress2)
  return isValidAddress ? `${address?.streetAddress1} ${address?.streetAddress2}`.slice(0, 30) : undefined
}

export function formatName(address: ShippingAddress) {
  return `${address.firstName} ${address.lastName}`
}
