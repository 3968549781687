import { WarehouseStatsV2 } from '@quickcommerceltd/zappboard'
import upperFirst from 'lodash/upperFirst'
import { Tier0SkuLowStockStatsWithWarehouse } from '../../types/Tier0SkuLowStockStatsWithWarehouse'

export const getTier0SkuList = (warehouseStats?: WarehouseStatsV2): Tier0SkuLowStockStatsWithWarehouse[] => {
  if (!warehouseStats) return []

  const warehouses = Object.values(warehouseStats.statsByWarehouse)
  return warehouses
    .flatMap((warehouse) => {
      return warehouse.tier0LowStock.map((sku) => {
        return {
          ...sku,
          type: upperFirst(sku.type),
          lastStockUpdate: new Date(sku.updatedAt).toLocaleString(),
          warehouse: warehouse.warehouse.shortName,
        }
      })
    })
    .sort((a, b) => (a.stockLevel === b.stockLevel ? a.name.localeCompare(b.name) : a.stockLevel - b.stockLevel))
}
