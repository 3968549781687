import PhotoCameraOutlined from '@mui/icons-material/PhotoCameraOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Divider, Grid, IconButton, keyframes, Stack, Tooltip, Typography } from '@mui/material'
import grey from '@mui/material/colors/grey'
import cx from 'classnames'
import { httpsCallable } from 'firebase/functions'
import { DateTime } from 'luxon'
import { FC, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { functions } from '../../firebase'
import { ProofOfDeliveryDialog } from '../ProofOfDeliveryDialog/ProofOfDeliveryDialog'
import { OrderHistoryEvent, parseOrderHistoryEvent } from './_helpers/parseOrderHistory'

const useStyles = makeStyles()((theme) => {
  const rotatingAnimation = keyframes`
    0% { transform: rotate(0deg) },
    100% { transform: rotate(360deg) },
`
  return {
    line: {
      fontSize: '.9em',
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    lineMarker: {
      marginLeft: '12px',
      display: 'inline-block',
      backgroundColor: theme.palette.success.main,
      borderRadius: '50%',
      width: '8px',
      height: '8px',
      border: '1px solid #000',
    },
    photoText: {
      color: theme.palette.primary.main,
      marginTop: -theme.spacing(1),
      fontSize: '1.3em',
    },
    photoIcon: {
      color: theme.palette.primary.main,
      marginLeft: '4px',
      marginTop: '2px',
      fontSize: '1.1em',
    },
    disabledPod: {
      '&.Mui-disabled': {
        color: grey[700],
      },
    },
    disabledPodIcon: {
      color: grey[700],
    },
    date: {
      marginLeft: '4px',
      whiteSpace: 'nowrap',
      fontSize: '0.9em',
    },
    linkStyle: {
      color: '#02BAFF',
    },
    rotating: {
      animation: rotatingAnimation,
      animationDuration: '1s',
      animationIterationCount: 'infinite',
    },
    noHistoryStyle: {
      lineHeight: '2.5em',
    },
  }
})

interface Props {
  orderId: string
  origin: string
  pictureOfDelivery?: string
}

export const OrderHistory: FC<Props> = (props: Props) => {
  const { orderId, origin, pictureOfDelivery } = props
  const { classes } = useStyles()

  const [data, setData] = useState<any[] | undefined>()
  const [loading, setLoading] = useState(false)
  const [showProofOfDeliveryDialog, setShowProofOfDeliveryDialog] = useState(false)

  const getEvents = async () => {
    setLoading(true)
    const getZCommerceOrderHistory = httpsCallable<any, any>(functions, 'api-getZCommerceOrderHistory')

    await getZCommerceOrderHistory({ orderId })
      .then((response) => {
        setLoading(false)
        const orderHistory: any[] = response.data?.map((event: any): OrderHistoryEvent => {
          return parseOrderHistoryEvent(event)
        })
        setData(orderHistory?.reverse())
      })
      .catch((error) => {
        console.log(`Could not fetch order history for ${orderId} with error:`, error)
        setLoading(false)
      })
  }

  const toggleProofOfDeliveryDialog = () => {
    setShowProofOfDeliveryDialog((prev) => !prev)
  }

  useEffect(() => {
    getEvents()
  }, [orderId]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Typography variant="h3">Order History</Typography>

          <IconButton className={!!loading ? classes.rotating : ''} onClick={() => getEvents()}>
            <RefreshIcon color="primary" />
          </IconButton>
        </Stack>
        {!!pictureOfDelivery && origin === 'ZAPP' && (
          <Box>
            <IconButton
              onClick={toggleProofOfDeliveryDialog}
              className={cx(classes.photoText, {
                [classes.disabledPod]: !pictureOfDelivery,
              })}
              disabled={!pictureOfDelivery}
            >
              PoD
              <PhotoCameraOutlined
                className={cx(classes.photoIcon, {
                  [classes.disabledPodIcon]: !pictureOfDelivery,
                })}
              />
            </IconButton>

            <ProofOfDeliveryDialog
              open={showProofOfDeliveryDialog}
              pictureOfDelivery={pictureOfDelivery}
              onClose={toggleProofOfDeliveryDialog}
            />
          </Box>
        )}
      </Stack>
      <Divider />
      <Box my={2}>
        {!data ? (
          <span className={classes.noHistoryStyle}>Could not fetch order history</span>
        ) : (
          data?.map((event: OrderHistoryEvent, index: number) => {
            return (
              <Grid container spacing={1} key={index} className={classes.line}>
                <Grid item xs={1}>
                  <span className={classes.lineMarker} />
                </Grid>
                <Grid item xs={9}>
                  {event.link ? (
                    <a target="_blank" rel="noopener noreferrer" href={event.link} className={classes.linkStyle}>
                      {event.message}
                    </a>
                  ) : (
                    event.message
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Tooltip
                    title={
                      event.date
                        ? DateTime.fromISO(event.date).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
                        : '-'
                    }
                  >
                    <span className={classes.date}>
                      {DateTime.fromISO(event.date).toRelative({
                        style: 'short',
                      })}
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            )
          })
        )}
      </Box>
    </>
  )
}
